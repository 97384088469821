import React, { forwardRef, memo, useCallback } from 'react'
import { debounce } from 'lodash'
import { CloseCircleFilled } from '@ant-design/icons'
import { Empty, Select } from 'antd'
import { getText } from '../../lang'
import LoadingSpinner from '../LoadingSpinner'
import './MatSelect.scss'

const MatSelectSecondary = forwardRef((props, ref) => {
  const debouncedChange = useCallback(
    debounce((options) => props.onSearch && props.onSearch(options), 500),
    []
  )

  return (
    <div className='mat-select-wrapper-secondary'>
      {props.label ? <label className='select-label'>{props.label}</label> : null}
      <div
        className={
          'mat-select-sub-wrapper ' +
          (props.icon ? 'select-with-icon ' : '') +
          (props.primary ? 'primary ' : '')
        }
      >
        {props.icon && <div className='select-left-icon'>{props.icon}</div>}
        <Select
          className={`mat-select ${props.className || ''} ${
            props.multipleNewStyle ? 'mat-multiple-select' : ''
          }`}
          ref={ref}
          defaultValue={props.defaultValue}
          value={props.loading ? getText('WORD_LOADING') : props.value}
          labelInValue={props.labelInValue}
          popupClassName={
            'mat-form-select-dropdown ' +
            (props.primary ? 'primary ' : '') +
            (props.dropdownClassName || '')
          }
          loading={props.loading}
          mode={props.mode}
          placeholder={props.placeholder}
          disabled={props.disabled}
          showSearch={props.showSearch}
          onSearch={props.showSearch && debouncedChange}
          maxTagCount={props.maxTagCount}
          filterOption={
            props.hasOwnProperty('filterOption')
              ? props.filterOption
              : (input, option) => {
                  return (
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) > -1
                  )
                }
          }
          suffixIcon={props.suffixIcon}
          allowClear={
            props.showClearIcon
              ? {
                  clearIcon: <CloseCircleFilled onClick={props.onClear} />,
                }
              : false
          }
          onClear={props.onClear}
          popupMatchSelectWidth={props.dropdownMatchSelectWidth}
          dropdownStyle={props.dropdownStyle}
          menuItemSelectedIcon={props.menuItemSelectedIcon}
          onDropdownVisibleChange={props.onDropdownVisibleChange}
          getPopupContainer={
            props.getPopupContainer
              ? props.getPopupContainer
              : (trigger) => trigger.parentNode
          }
          onSelect={(value) => {
            props.onSelect && props.onSelect(value)
            let found = props.options.find((option) => option.value === value)
            if (found) {
              props.onSelectItem && props.onSelectItem(found)
            }
          }}
          onDeselect={props.onDeselect}
          notFoundContent={
            props.loading ? (
              <LoadingSpinner />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          onPopupScroll={(e) => {
            e.persist()
            props.onPopupScroll && props.onPopupScroll(e)
            if (
              e.target.scrollTop + e.target.offsetHeight >=
              e.target.scrollHeight - 10
            ) {
              props.onLoadMore && props.onLoadMore()
            }
          }}
          onBlur={props.onBlur}
          options={props.customOption && props.options}
          onChange={(e) => {
            props.onChange && props.onChange(e)
            if (props.onChangeItem && props.options) {
              if (props.mode === 'multiple') {
                let find = []
                if (props.usePreviousValueOnChange) {
                  const duplicateIds = {}

                  for (const oo of props.options) {
                    if (!duplicateIds[oo.value]) {
                      if (props.labelInValue) {
                        let includes = false
                        for (const element of e) {
                          if (element.value === oo.value) {
                            includes = true
                            break
                          }
                        }
                        if (includes) {
                          find.push(oo)
                          duplicateIds[oo.value] = true
                        }
                      } else {
                        if (e.includes(oo.value)) {
                          find.push(oo)
                          duplicateIds[oo.value] = true
                        }
                      }
                    }
                  }
                  for (const oo of props.value) {
                    if (!duplicateIds[oo.value]) {
                      if (props.labelInValue) {
                        let includes = false
                        for (const element of e) {
                          if (element.value === oo.value) {
                            includes = true
                            break
                          }
                        }
                        if (includes) {
                          find.push(oo)
                          duplicateIds[oo.value] = true
                        }
                      } else {
                        if (e.includes(oo.value)) {
                          find.push(oo)
                          duplicateIds[oo.value] = true
                        }
                      }
                    }
                  }
                } else {
                  find = props.options.filter((oo) => {
                    return e.includes(oo.value)
                  })
                }
                if (find) {
                  props.onChangeItem(e, find)
                }
              } else {
                let find = props.options.find((oo) => {
                  return oo.value === e
                })
                if (find) {
                  props.onChangeItem(e, find)
                }
              }
            }
          }}
        >
          {props.customOption
            ? props.children
            : props.options.map((option, index) => (
                <Select.Option key={option.value + '_' + index} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
        </Select>
      </div>
    </div>
  )
})

MatSelectSecondary.defaultProps = {
  showClearIcon: true,
}

export default memo(MatSelectSecondary)
