import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Form, Rate, Tooltip } from 'antd'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import authActions from '../../../../store/modules/authActions'
import locationActions from '../../../../store/modules/locationActions'
import { getText, getTextServerError } from '../../../../lang'
import SVGDragDrop from '../../../../icons/SVG/SVGDragDrop.js'
import MatForm from '../../../../components/Form/MatForm'
import UploadFileForm from '../../../../components/Form/UploadFileForm'
import MatColorPicker from '../../../../components/MatColorPicker'
import LoadingSpinner from '../../../../components/LoadingSpinner/index.js'
import MatadorConnectLayout from '../matadorConnect/matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnect/matadorConnectComponents/MatadorConnectListTitle'
import InputFormTextSecondary from '../../../../components/Form/InputFormText/InputFormTextSecondary'
import SelectFormSecondary from '../../../../components/Form/SelectForm/SelectFormSecondary.js'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider.js'
import SVGInfo from '../../../../icons/SVG/SVGInfo.js'
import SVGStarIcon from '../../../../icons/SVG/SVGStarIcon.js'
import { useLocationLayout } from '../../locationProvider/LocationProvider.js'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions.js'
import { convertNumberTo5PointSystemValue } from '../../../../utils/index.js'
import ReviewInvitePanel from './ReviewInvitePanel'
import './ReviewInvite.scss'

const MAX_TOTAL_URLS = 8
const DEFAULT_RATING_PAGE_TEXTS = {
  five_points: {
    en: `On a scale of 1 to 5 stars, how likely are you to recommend our business to a friend or colleague?`,
    fr: `Sur une échelle de 1 à 5 étoiles, quelle est la probabilité que vous recommandiez notre entreprise à un ami ou à un collègue ?`,
    sp: `En una escala de 1 a 5 estrellas, ¿qué probabilidad hay de que recomiende nuestra empresa a un amigo o colega?`,
  },
  ten_points: {
    en: `On a scale of 1 to 10, how likely are you to recommend our business to a friend or colleague?`,
    fr: `Sur une échelle de 1 à 10, quelle est la probabilité que vous recommandiez notre entreprise à un ami ou à un collègue ?`,
    sp: `En una escala del 1 al 10, ¿qué probabilidad hay de que recomiende nuestra empresa a un amigo o colega?`,
  },
}

const LocationReviewInvite = () => {
  const { setFooterButtons, setLoading } = useLayout()
  const { locationObj, setLocationObj } = useLocationLayout()

  const [imageUrlLang1, setImageUrlLang1] = useState('')
  const [imageUrlLang2, setImageUrlLang2] = useState('')
  const [minRedirectScore, setminRedirectScore] = useState(
    locationObj?.minRedirectScore
      ? locationObj?.is5StarReviewEnabled
        ? 5 -
          convertNumberTo5PointSystemValue(true, locationObj.minRedirectScore) +
          1
        : 10 - locationObj.minRedirectScore + 1
      : 0
  )
  const [form] = Form.useForm()
  const org = authActions.getOrganization()

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    org.languages.forEach((lang, index) => {
      index === 1
        ? setImageUrlLang1((locationObj && locationObj[`pic_${lang}`]) || '')
        : setImageUrlLang2((locationObj && locationObj[`pic_${lang}`]) || '')
    })
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    const { urls, msg, ...rest } = form.getFieldsValue(true)
    const payload = {
      ...rest,
      msg_en: msg.en,
      msg_fr: msg.fr,
      msg_sp: msg.sp,
      custom_urls: [],
      is5StarReviewEnabled: rest.invite_type === 'five_points',
      minRedirectScore:
        rest.invite_type === 'ten_points'
          ? 10 - minRedirectScore + 1
          : 5 - minRedirectScore + 1,
    }
    delete payload.invite_type
    if (!isValid(urls)) {
      setLoading(false)
      return
    }

    urls.forEach((urlObj, index) => {
      if (!urlObj.name || !urlObj.url) return

      urlObj.weight = urls.length - index
      if (urlObj.name === 'Google') {
        payload.url_google = {
          url: urlObj.url,
          weight: urlObj.weight,
        }
      } else if (urlObj.name === 'Facebook') {
        payload.url_fb = {
          url: urlObj.url,
          weight: urlObj.weight,
        }
      } else if (urlObj.name === 'Yelp') {
        payload.url_yelp = {
          url: urlObj.url,
          weight: urlObj.weight,
        }
      } else {
        const customUrl = {
          name: urlObj.name,
          url: urlObj.url,
          weight: urlObj.weight,
          color: urlObj.color,
        }
        payload.custom_urls.push(customUrl)
      }
    })
    const result = await locationActions.saveLocationReviewInvite(
      payload,
      locationObj.id
    )

    if (result.success) {
      notifySuccess(getText('TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY'))
      let loc = authActions.getLocation()
      setLocationObj(result.data)
      if (result.data._id === (loc && loc._id)) {
        authActions.setLocation(result.data)
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const getInitialUrls = () => {
    let finalUrls = locationObj.urls ? [...locationObj.urls] : []

    const currentUrlsLength = finalUrls.length
    for (let i = 0; i < MAX_TOTAL_URLS - currentUrlsLength; i++) {
      finalUrls.push({
        name: '',
        url: '',
        color: '#000000',
        weight: MAX_TOTAL_URLS - currentUrlsLength - i,
      })
    }
    return finalUrls
  }

  const isEditable = (name) => {
    return name !== 'Facebook' && name !== 'Google' && name !== 'Yelp'
  }

  const isValid = (urls) => {
    const isValidUrl = urls.some(
      (obj) =>
        obj.url && !(obj.url.startsWith('http://') || obj.url.startsWith('https://'))
    )
    const isUrlHasName = urls.some((obj) => obj.url && obj.name === '')
    if (isValidUrl) {
      notifyError(getText('ERR_INVALID_URLS'))
      return false
    }
    if (isUrlHasName) {
      notifyError(getText('ERR_MISSING_CUSTOM_URL_NAME'))
      return false
    }

    return true
  }

  const handleReorder = (e) => {
    if (e.destination) {
      if (e.source.index === e.destination.index) {
        return
      }
      const from = e.source.index
      const to = e.destination.index

      const urlsClone = form.getFieldValue('urls')
      const [removed] = urlsClone.splice(from, 1)
      urlsClone.splice(to, 0, removed)

      form.setFieldsValue({
        urls: urlsClone,
      })
    }
  }

  const handleUploadImage = (file, lang, index) => {
    if (file.status !== 'done') return
    form.setFieldsValue({
      [`pic_${lang}`]: file.url,
    })
    index === 1 ? setImageUrlLang1(file.url) : setImageUrlLang2(file.url)
  }

  const listVariablesReviews = [
    {
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
    },
    {
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
    },
    {
      label: 'location_name',
      value: '{{location_name}}',
    },
    {
      label: 'organization_name',
      value: '{{organization_name}}',
    },
  ]

  Form.useWatch('urls', form)
  const invite_type = Form.useWatch('invite_type', form)

  if (!locationObj._id) {
    return <LoadingSpinner />
  }
  return (
    <MatadorConnectLayout className='location-review-invites-wrapper'>
      <MatadorConnectListTitle
        title={getText('ACTION_REVIEW_INVITE')}
        className='location-review-invites-layout'
      />

      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          msg: {
            en: locationObj.msg_en || '',
            fr: locationObj.msg_fr || '',
            sp: locationObj.msg_sp || '',
          },
          reviewInviteMsg: {
            en: locationObj.reviewInviteMsg.en || '',
            fr: locationObj.reviewInviteMsg.fr || '',
            sp: locationObj.reviewInviteMsg.sp || '',
          },
          reviewInviteLocatonMsg: {
            en: locationObj.reviewInviteLocatonMsg.en || '',
            fr: locationObj.reviewInviteLocatonMsg.fr || '',
            sp: locationObj.reviewInviteLocatonMsg.sp || '',
          },
          reviewInviteRatingPage: {
            en: locationObj.reviewInviteRatingPage.en || '',
            fr: locationObj.reviewInviteRatingPage.fr || '',
            sp: locationObj.reviewInviteRatingPage.sp || '',
          },
          reviewInvitePositivePage: {
            en: locationObj.reviewInvitePositivePage.en || '',
            fr: locationObj.reviewInvitePositivePage.fr || '',
            sp: locationObj.reviewInvitePositivePage.sp || '',
          },
          reviewInviteNegativePage: {
            en: locationObj.reviewInviteNegativePage.en || '',
            fr: locationObj.reviewInviteNegativePage.fr || '',
            sp: locationObj.reviewInviteNegativePage.sp || '',
          },
          pic_en: locationObj.pic_en || '',
          pic_fr: locationObj.pic_fr || '',
          pic_sp: locationObj.pic_sp || '',
          invite_type: locationObj?.is5StarReviewEnabled
            ? 'five_points'
            : 'ten_points',
          urls: getInitialUrls(),
        }}
      >
        <div className='review_invite_block'>
          <SelectFormSecondary
            label={`${getText('TEXT_REVIEW_INVITE_TYPE')}:`}
            name={'invite_type'}
            options={[
              { value: 'five_points', label: getText('TEXT_FIVE_STARS') },
              { value: 'ten_points', label: getText('TEXT_NPS_SCORE_10_POINTS') },
            ]}
            onChange={(val) => {
              form.setFieldsValue({
                reviewInviteRatingPage: DEFAULT_RATING_PAGE_TEXTS[val],
              })
            }}
            showSearch={false}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p>{getText('TEXT_REVIEW_POSTING_THRESHOLD')}</p>
            <Tooltip
              title={getText(
                'TEXT_SETS_THE_MINIMUM_RATING_REQUIRED_BEFORE_CUSTOMERS_CAN_POST_THEIR_REVIEW_ON_EXTERNAL_PLATFORMS'
              )}
              trigger={['hover', 'click']}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SVGInfo
                  fill={'#28303F'}
                  width={18}
                  height={18}
                  style={{ marginLeft: 13 }}
                />
              </div>
            </Tooltip>
          </div>
          {Boolean(invite_type === 'ten_points') ? (
            <Rate
              className={'review_invite_rate'}
              defaultValue={3}
              character={({ index = 0 }) => (
                <div className='review_invite_rate_item'>{10 - index}</div>
              )}
              count={10}
              style={{ marginTop: 12 }}
              onChange={(val) => {
                setminRedirectScore(val)
              }}
              allowClear={false}
              value={minRedirectScore}
            />
          ) : (
            <Rate
              className={'review_invite_rate'}
              defaultValue={2}
              character={() => (
                <div className='review_invite_rate_item'>
                  <SVGStarIcon pointerEvents={'none'} />
                </div>
              )}
              count={5}
              style={{ marginTop: 14 }}
              onChange={(val) => {
                setminRedirectScore(val)
              }}
              allowClear={false}
              value={minRedirectScore}
            />
          )}
        </div>
        <div className='review-content-wrapper'>
          <div className='review-templates'>
            <ReviewInvitePanel
              title={getText('WORD_REVIEW_SMS_TEMPLATE_TITLE')}
              languages={
                org.defaultLanguage !== org.languages[0]
                  ? org.languages.reverse()
                  : org.languages
              }
              variables={listVariablesReviews}
              formName='msg'
              form={form}
            />
            <ReviewInvitePanel
              title={getText('WORD_REVIEW_EMAIL_TEMPLATE_TITLE')}
              languages={
                org.defaultLanguage !== org.languages[0]
                  ? org.languages.reverse()
                  : org.languages
              }
              variables={listVariablesReviews}
              formName='reviewInviteMsg'
              formNameSubject='reviewInviteLocatonMsg'
              form={form}
            />
            <ReviewInvitePanel
              title={getText('WORD_REVIEW_RATING_PAGE_TITLE')}
              languages={
                org.defaultLanguage !== org.languages[0]
                  ? org.languages.reverse()
                  : org.languages
              }
              variables={listVariablesReviews}
              formName='reviewInviteRatingPage'
              form={form}
            />
            <ReviewInvitePanel
              title={getText('WORD_REVIEW_POSITIVE_FEEDBACK_PAGE_TITLE')}
              languages={
                org.defaultLanguage !== org.languages[0]
                  ? org.languages.reverse()
                  : org.languages
              }
              variables={listVariablesReviews}
              formName='reviewInvitePositivePage'
              form={form}
            />
            <ReviewInvitePanel
              title={getText('WORD_REVIEW_POSITIVE_NEGATIVE_PAGE_TITLE')}
              languages={
                org.defaultLanguage !== org.languages[0]
                  ? org.languages.reverse()
                  : org.languages
              }
              variables={listVariablesReviews}
              formName='reviewInviteNegativePage'
              form={form}
            />
          </div>
          <div className='review-posters'>
            <h3 className='review-invites-title'>
              {getText('WORD_REVIEW_IMAGE_TITLE')}
            </h3>
            <div className='posters-cards'>
              {org.languages.map((lang, index) => {
                const imageUrl = index === 1 ? imageUrlLang1 : imageUrlLang2
                return (
                  <div className='poster-wrapper-lang' key={lang}>
                    <InputFormTextSecondary
                      label={getText(`WORD_REVIEW_IMAGE_${lang.toUpperCase()}`)}
                      name={`pic_${lang}`}
                      disabled
                    />
                    <UploadFileForm
                      mediaTypes={'image/jpeg, image/jpg, image/png, image/gif'}
                      beforeUploadProps
                      errMsg={getText('ERROR_MESSAGE_ONLY_IMAGE_ALLOWED')}
                      maxCount={1}
                      onUpload={(file) => handleUploadImage(file, lang, index)}
                      showUploadList={false}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          className='uploaded-poster'
                          alt='poster'
                        />
                      ) : (
                        getText('UPLOAD_IMAGE')
                      )}
                    </UploadFileForm>
                  </div>
                )
              })}
            </div>
            <Form.List name='urls'>
              {(fields) => (
                <DragDropContext onDragEnd={handleReorder}>
                  <Droppable droppableId='table-droppable' type='LOCATION-URL'>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {fields.map((field, index) => {
                          const urlObj = form.getFieldValue('urls')[field.name]
                          return (
                            <Draggable
                              key={index}
                              draggableId={`draggable-${index}`}
                              index={index}
                              type='LOCATION-URL'
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className='drag-drop-item'
                                >
                                  <div
                                    className='grab-logo'
                                    {...provided.dragHandleProps}
                                  >
                                    <SVGDragDrop />
                                  </div>
                                  <InputFormTextSecondary
                                    name={[field.name, 'name']}
                                    disabled={!isEditable(urlObj.name)}
                                    allowClear={false}
                                    required={isEditable(urlObj.name) && urlObj.url}
                                    errorMessage={getText(
                                      'ERR_MISSING_CUSTOM_URL_NAME'
                                    )}
                                  />
                                  <InputFormTextSecondary
                                    name={[field.name, 'url']}
                                    placeholder='https://'
                                    allowClear={false}
                                  />
                                  {isEditable(urlObj.name) ? (
                                    <MatColorPicker
                                      color={urlObj.color}
                                      onChangeColor={(newColor) => {
                                        const urlsUpdated =
                                          form.getFieldValue('urls')
                                        urlsUpdated[index].color = newColor
                                        form.setFieldsValue({
                                          urls: urlsUpdated,
                                        })
                                      }}
                                    />
                                  ) : (
                                    <div className='mat-color-trigger none' />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Form.List>
          </div>
        </div>
      </MatForm>
    </MatadorConnectLayout>
  )
}

export default LocationReviewInvite
