import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='23'
    height='22'
    viewBox='0 0 23 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Iconly/Light/Chat'>
      <g id='vuesax/linear/eye'>
        <g id='eye'>
          <path
            id='Vector'
            d='M14.7816 11.0004C14.7816 12.8154 13.3149 14.2821 11.4999 14.2821C9.68493 14.2821 8.21826 12.8154 8.21826 11.0004C8.21826 9.18542 9.68493 7.71875 11.4999 7.71875C13.3149 7.71875 14.7816 9.18542 14.7816 11.0004Z'
            stroke='#0E0E0E'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_2'
            d='M11.4999 18.581C14.7357 18.581 17.7515 16.6743 19.8507 13.3743C20.6757 12.0818 20.6757 9.90932 19.8507 8.61682C17.7515 5.31682 14.7357 3.41016 11.4999 3.41016C8.26402 3.41016 5.24819 5.31682 3.14902 8.61682C2.32402 9.90932 2.32402 12.0818 3.14902 13.3743C5.24819 16.6743 8.26402 18.581 11.4999 18.581Z'
            stroke='#0E0E0E'
            stroke-width='1.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
      </g>
    </g>
  </svg>
)

const SVGFeatureVisibilityIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFeatureVisibilityIcon
