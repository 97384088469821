import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_11915_255348)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_11915_255348)'>
          <circle cx='18' cy='17' r='13' fill='#FD78B5' />
        </g>
        <path
          d='M20.3866 18C20.3866 19.32 19.3199 20.3867 17.9999 20.3867C16.6799 20.3867 15.6133 19.32 15.6133 18C15.6133 16.68 16.6799 15.6133 17.9999 15.6133C19.3199 15.6133 20.3866 16.68 20.3866 18Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.9999 23.5133C20.3532 23.5133 22.5466 22.1266 24.0732 19.7266C24.6732 18.7866 24.6732 17.2066 24.0732 16.2666C22.5466 13.8666 20.3532 12.48 17.9999 12.48C15.6466 12.48 13.4532 13.8666 11.9266 16.2666C11.3266 17.2066 11.3266 18.7866 11.9266 19.7266C13.4532 22.1266 15.6466 23.5133 17.9999 23.5133Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_11915_255348)'
      />
      <defs>
        <filter
          id='filter0_f_11915_255348'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_11915_255348'
          />
        </filter>
        <linearGradient
          id='paint0_linear_11915_255348'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_11915_255348'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashVideoVideoLeadsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashVideoVideoLeadsIcon
