import React, { useMemo } from 'react'
import { Card } from 'antd'
import Div from '../../../../components/Div/Div'
import MyDefaultButton from '../../../../components/MyDefaultButton/MyDefaultButton'
import SVGVoiceAiEmptyBookSalesIcon from '../../../../icons/SVG/SVGVoiceAiEmptyBookSalesIcon'
import SVGVoiceAiEmptySendSMSFollowUpIcon from '../../../../icons/SVG/SVGVoiceAiEmptySendSMSFollowUpIcon'
import SVGVoiceAiEmptyTransferCallsIcon from '../../../../icons/SVG/SVGVoiceAiEmptyTransferCallsIcon'
import SVGVoiceAiEmptyAnswerMissedCallsIcon from '../../../../icons/SVG/SVGVoiceAiEmptyAnswerMissedCallsIcon'
import SVGVoiceAiEmptyHandleRoutineIcon from '../../../../icons/SVG/SVGVoiceAiEmptyHandleRoutineIcon'
import { getText } from '../../../../lang'
import './VoiceAiEmptyBlock.scss'

const VoiceAiEmptyBlock = () => {
  const [wordNever, ...neverRest] = getText('TEXT_NEVER_MISS_A_CALL_AGAIN').split(
    ' '
  )

  const blockItemsArr = useMemo(
    () => [
      {
        title: getText('TEXT_BOOK_SALES_AND_SERVICE_APPOINTMENTS'),
        icon: <SVGVoiceAiEmptyBookSalesIcon />,
      },
      {
        title: getText('TEXT_INTELLIGENTLY_SEND_SMS_FOLLOW_UPS_WHEN_HANDLING_CALLS'),
        icon: <SVGVoiceAiEmptySendSMSFollowUpIcon />,
      },
      {
        title: getText('TEXT_TRANSFER_CALLS_TO_RELEVANT_DEPARTMENTS'),
        icon: <SVGVoiceAiEmptyTransferCallsIcon />,
      },
      {
        title: getText(
          'TEXT_ANSWER_ALL_YOUR_MISSED_CALLS_ENSURING_NO_CUSTOMER_IS_LEFT_BEHIND'
        ),
        icon: <SVGVoiceAiEmptyAnswerMissedCallsIcon />,
      },
      {
        title: getText(
          'TEXT_HANDLE_ROUTINE_CONVERSATIONS_WITH_EXPERT_KNOWLEDGE_OF_YOUR_BUSINESS'
        ),
        icon: <SVGVoiceAiEmptyHandleRoutineIcon />,
      },
    ],
    []
  )

  return (
    <Card className='dashboard_block voice_ai_empty_state'>
      <Div className={`dashboard_block--blurred-part gradient`}></Div>
      <span className='title'>
        <span>{wordNever} </span>
        {neverRest.join(' ')}
      </span>
      <span className='subtitle'>
        {getText('TEXT_FROM_MISSED_CALLS_TO_BUSINESS_OPPORTUNITIES')}
      </span>
      <span className='description'>
        {getText('TEXT_ACTIVATE_A_VOICE_AI_AGENT_THAT_WILL_AUTOMATICALLY')}
      </span>
      <Div className='block_items_container'>
        {blockItemsArr.map((item, index) => {
          return (
            <Div className='item' key={index}>
              {item.icon} {item.title}
            </Div>
          )
        })}
      </Div>
      <MyDefaultButton
        buttonText={getText('TEXT_DEMO_VOICE_AI')}
        onClick={() => {
          window.open('https://www.matador.ai/voice/')
        }}
      />
    </Card>
  )
}

export default VoiceAiEmptyBlock
