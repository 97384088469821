import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'antd'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../lang'
import OrganizationLayout from '../../components/organizationNewPage/OrganizationLayout'
import OrganizationListTitle from '../../components/organizationNewPage/OrganizationListTitle'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MatForm from '../../../../components/Form/MatForm'
import InputFormTextSecondary from '../../../../components/Form/InputFormText/InputFormTextSecondary'
import SVGIntentPositiveIcon from '../../../../icons/SVG/SVGIntentPositiveIcon'
import { idOrganizationSelector } from '../../../../store/selectors/selectors'
import workflowActions from '../../../../store/modules/workflowActions'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import SVGIntentNegativeIcon from '../../../../icons/SVG/SVGIntentNegativeIcon'
import SVGIntentNoReplyIcon from '../../../../icons/SVG/SVGIntentNoReplyIcon'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions'
import WorkflowDetectIntent from './WorkflowDetectIntent'
import './Workflow.scss'

const WorkflowEdit = () => {
  const { setFooterButtons } = useLayout()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()

  let orgId = useSelector(idOrganizationSelector)

  const [workflowItem] = useState(
    location.state && location.state.fromOrgList ? location.state.item : false
  )

  const [createWorkflow] = useState(!workflowItem)
  const [isLoading, setisLoading] = useState(true)

  const RADIO_OPTION = useMemo(
    () => [
      {
        label: getText('WORD_POSITIVE_REPLY'),
        value: 0,
        subValue: 'positive reply',
        icon: <SVGIntentPositiveIcon />,
      },
      {
        label: getText('WORD_NEGATIVE_REPLY'),
        value: 1,
        subValue: 'negative reply',
        icon: <SVGIntentNegativeIcon />,
      },
      {
        label: getText('WORD_NO_REPLY'),
        value: 2,
        subValue: 'no reply',
        icon: <SVGIntentNoReplyIcon />,
      },
    ],
    []
  )

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
  }, [])

  useEffect(() => {
    if (!createWorkflow) {
      const positiveReply = workflowItem.actions[0].intents.find(
        (intent) => intent.type === 'positive reply'
      )
      const negativeReply = workflowItem.actions[0].intents.find(
        (intent) => intent.type === 'negative reply'
      )
      const noReply = workflowItem.actions[0].intents.find(
        (intent) => intent.type === 'no reply'
      )
      if (!positiveReply) {
        workflowItem.actions[0].intents.push({
          type: 'positive reply',
          config: {
            duration: 1,
            unit: getText('WORD_DAYS').toLowerCase(),
          },
          actions: [],
        })
      }
      if (!negativeReply) {
        workflowItem.actions[0].intents.push({
          type: 'negative reply',
          config: {
            duration: 1,
            unit: getText('WORD_DAYS').toLowerCase(),
          },
          actions: [],
        })
      }
      if (!noReply) {
        workflowItem.actions[0].intents.push({
          type: 'no reply',
          config: {
            duration: 1,
            unit: getText('WORD_DAYS').toLowerCase(),
          },
          actions: [],
        })
      }
      const sortedIntents = [
        workflowItem.actions[0].intents.find(
          (intent) => intent.type === 'positive reply'
        ),
        workflowItem.actions[0].intents.find(
          (intent) => intent.type === 'negative reply'
        ),
        workflowItem.actions[0].intents.find((intent) => intent.type === 'no reply'),
      ].filter((intent) => intent !== undefined)

      const updatedIntents = sortedIntents.map((intent) => {
        if (intent.type === 'positive reply' || intent.type === 'negative reply') {
          return {
            ...intent,
            config: {
              duration: sortedIntents.find((i) => i.type === 'no reply').config
                .duration,
              unit: sortedIntents.find((i) => i.type === 'no reply').config.unit,
            },
          }
        }
        return intent
      })

      Object.assign(workflowItem.actions[0].intents, updatedIntents)
      form.setFieldsValue(workflowItem)
    }
    setisLoading(false)
  }, [])

  const onFinish = async (values) => {
    const removeValue = (valArray, obj) => {
      if (!obj) {
        return
      }
      for (const [key, value] of Object.entries(obj)) {
        for (const val of valArray) {
          if (key === val) {
            delete obj[val]
          }
        }

        if (Array.isArray(value)) {
          removeValue(valArray, value)
        } else if (typeof value === 'object') {
          removeValue(valArray, value)
        }
      }
    }

    const allEmpty = values.actions[0].intents.some(
      (intent) => intent.actions.length > 0
    )

    if (!allEmpty) {
      notifyError(getText('ERROR_MSG_WORKFLOW'))
      return
    } else {
      for (let i = 0; i < values.actions[0].intents.length; i++) {
        if (
          values.actions[0].intents[i].actions.length === 0 &&
          values.actions[0].intents[i].type !== 'no reply'
        ) {
          values.actions[0].intents.splice(i, 1)
          i--
        } else if (values.actions[0].intents[i].actions.length > 0) {
          if (values.actions[0].intents[i].type !== 'no reply') {
            delete values.actions[0].intents[i].config
          }
        }
      }

      values.actions.forEach((obj) => {
        obj.intents.forEach((intent) => {
          if (intent.config) {
            intent.config.unit =
              intent.config.unit === getText('WORD_DAYS').toLowerCase()
                ? 'days'
                : intent.config.unit
          }
          intent.actions.forEach((action) => {
            if (action.type === 'assign users') {
              action.config.users_to_assign = action.config.users_to_assign.map(
                (user) => user.id || user
              )
            }
            if (action.type === 'add tags') {
              action.config.tag_ids = action.config.tags.map((tag) => tag.value)
            }
          })
        })
      })
      removeValue(['users', 'tags'], values)
    }
    let result

    if (createWorkflow) {
      result = await workflowActions.createWorkflow(
        values.name,
        orgId,
        values.actions
      )
    } else {
      result = await workflowActions.editWorkflow(
        values.name,
        workflowItem._id,
        values.actions
      )
    }
    if (result && result.success) {
      notifySuccess(getText('TEXT_WORKFLOW_WAS_SAVED_SUCCESSFULLY'))
      if (createWorkflow && location.state && location.state.fromBroadcast) {
        location.state.segmentState.workflow = Object.assign(result.data, {
          label: result.data.name,
          value: result.data._id,
        })
        navigate(`/broadcasting/create-campaign/sub-pages`, {
          state: { fromOrg: true, item: location.state.segmentState },
        })
      } else {
        navigate(`/organization/settings/${orgId}/workflow/list`)
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.warn('Failed:', errorInfo.values)
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <OrganizationLayout className={'workflow-edit-organization-page'}>
      <OrganizationListTitle
        modalButton
        title={
          <>
            <span className='title-trigger'>
              {getText('WORD_CHOSE_YOUR_DIFFERENT_TRIGGERS') + ' ⚡️'}
            </span>
            {getText('WORD_BUILD_YOUR_WORKFLOW')}
            <span className='title-small'>
              {getText(
                'WORD_BUILD_A_WORKFLOW_BASED_ON_TYPE_OF_RESPONSE_YOU_RECEIVE_FROM_YOUR_BROADCAST_CAMPAIGN'
              )}
            </span>
          </>
        }
      />
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          name: createWorkflow ? '' : workflowItem.name,
          actions: createWorkflow
            ? [
                {
                  type: 'detect intent',
                  intents: [
                    {
                      type: 'positive reply',
                      config: {
                        duration: 1,
                        unit: getText('WORD_DAYS').toLowerCase(),
                      },
                      actions: [],
                    },
                    {
                      type: 'negative reply',
                      config: {
                        duration: 1,
                        unit: getText('WORD_DAYS').toLowerCase(),
                      },
                      actions: [],
                    },
                    {
                      type: 'no reply',
                      config: {
                        duration: 1,
                        unit: getText('WORD_DAYS').toLowerCase(),
                      },
                      actions: [],
                    },
                  ],
                },
              ]
            : workflowItem.actions,
        }}
        className='workflow-edit-form'
      >
        <InputFormTextSecondary
          inputClassName='workflow-name-input'
          name='name'
          label={getText('WORD_ADD_NAME_FOR_YOUR_WORKFLOW')}
          placeholder={getText('WORD_WORKFLOW_NAME')}
          required
        />
        {Boolean(!isLoading) && (
          <Form.List name='actions'>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => {
                  return (
                    <WorkflowDetectIntent
                      createWorkflow={createWorkflow}
                      key={field.key}
                      mainField={field}
                      radioOption={RADIO_OPTION}
                      form={form}
                    />
                  )
                })}
              </>
            )}
          </Form.List>
        )}
      </MatForm>
    </OrganizationLayout>
  )
}

export default WorkflowEdit
