import React, { useCallback } from 'react'
import { DatePicker } from 'antd'
import localeSP from 'antd/es/date-picker/locale/eu_ES'
import localEN from 'antd/es/date-picker/locale/en_US'
import localeFR from 'antd/es/date-picker/locale/fr_FR'
import authActions from '../../store/modules/authActions'
import SVGMyDefaultDatePickerIcon from '../../icons/SVG/SVGMyDefaultDatePickerIcon'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import useDeviceCheck from '../../utils/useDeviceCheck'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import './MyDefaultRangePicker.scss'

const { RangePicker } = DatePicker

const MyDefaultRangePicker = ({
  color = 'grey',
  className,
  allowEmpty,
  dropdownClassName,
  disabledLeft,
  disabledRight,
  onChange,
  onPanelChange,
  disabledDate,
  showNow,
  value,
  separator,
  inputReadOnly,
  onCalendarChange,
  placeholder = ['YYYY-MM-DD', 'YYYY-MM-DD'],
  isForm,
  name,
  label,
  hasFeedback,
  disabled,
  required,
  message,
  formStyle,
  isLast,
  suffixIcon,
  placement,
}) => {
  const userLang = authActions.getLanguage()
  const { isMobile } = useDeviceCheck()

  const rules = useCallback(() => {
    const rules = []
    if (required && !disabled) {
      rules.push({ required: required, message: message })
    }
    return rules
  }, [required, disabled])

  return isForm ? (
    <MyDefaultFormItem
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={rules()}
      style={formStyle}
      isLast={isLast}
    >
      <RangePicker
        className={`my_default_rangepicker ${color} ${className || ''}`}
        allowEmpty={allowEmpty}
        popupClassName={`my_default_rangepicker_dropdown ${dropdownClassName || ''}`}
        disabled={[disabledLeft, disabledRight]}
        onChange={onChange}
        onPanelChange={onPanelChange}
        suffixIcon={suffixIcon || <SVGMyDefaultDatePickerIcon />}
        locale={
          userLang === 'fr' ? localeFR : userLang === 'sp' ? localeSP : localEN
        }
        disabledDate={disabledDate}
        showNow={showNow}
        separator={separator || '-'}
        inputReadOnly={isMobile || inputReadOnly}
        onCalendarChange={onCalendarChange}
        placeholder={placeholder}
        placement={placement}
      />
    </MyDefaultFormItem>
  ) : (
    <RangePicker
      className={`my_default_rangepicker ${color} ${className || ''}`}
      allowEmpty={allowEmpty}
      popupClassName={`my_default_rangepicker_dropdown ${dropdownClassName || ''}`}
      disabled={[disabledLeft, disabledRight]}
      onChange={onChange}
      onPanelChange={onPanelChange}
      suffixIcon={suffixIcon || <SVGMyDefaultDatePickerIcon />}
      locale={userLang === 'fr' ? localeFR : userLang === 'sp' ? localeSP : localEN}
      disabledDate={disabledDate}
      showNow={showNow}
      value={value}
      separator={separator || '-'}
      inputReadOnly={isMobile || inputReadOnly}
      onCalendarChange={onCalendarChange}
      placeholder={placeholder}
      placement={placement}
    />
  )
}

export default MyDefaultRangePicker
