import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='24' height='24' rx='12' fill='#5B66EA' />
      <path
        d='M11.1 16.74V7.26C11.1 6.36 10.72 6 9.76 6H7.34C6.38 6 6 6.36 6 7.26V16.74C6 17.64 6.38 18 7.34 18H9.76C10.72 18 11.1 17.64 11.1 16.74Z'
        fill={props.color || 'white'}
      />
      <path
        d='M17.9999 16.74V7.26C17.9999 6.36 17.6199 6 16.6599 6H14.2399C13.2866 6 12.8999 6.36 12.8999 7.26V16.74C12.8999 17.64 13.2799 18 14.2399 18H16.6599C17.6199 18 17.9999 17.64 17.9999 16.74Z'
        fill={props.color || 'white'}
      />
    </svg>
  )
}

const SVGPauseIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGPauseIcon
