import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='0.5'
      width='24'
      height='24'
      rx='4'
      fill={props.rectcolor || '#E05D4C'}
    />
    <path
      d='M9.74026 8.91623C10.665 7.32123 11.1274 6.52373 11.7377 6.26828C12.2252 6.06422 12.7747 6.06422 13.2622 6.26828C13.8724 6.52373 14.3348 7.32123 15.2596 8.91623L17.5006 12.7815C18.4253 14.3765 18.8877 15.174 18.8048 15.828C18.7385 16.3504 18.4637 16.8243 18.0425 17.1427C17.5152 17.5412 16.5905 17.5412 14.7409 17.5412H10.2589C8.40935 17.5412 7.48458 17.5412 6.9573 17.1427C6.53607 16.8243 6.26133 16.3504 6.19505 15.828C6.11208 15.174 6.57447 14.3765 7.49923 12.7815L9.74026 8.91623Z'
      fill={props.innercolor || 'white'}
    />
    <path
      d='M11.869 9.81316C11.869 9.46456 12.1516 9.18197 12.5002 9.18197C12.8488 9.18197 13.1314 9.46456 13.1314 9.81316V13.1839C13.1314 13.5325 12.8488 13.8151 12.5002 13.8151C12.1516 13.8151 11.869 13.5325 11.869 13.1839V9.81316Z'
      fill={props.color || '#E05D4C'}
    />
    <path
      d='M11.869 15.5681C11.869 15.221 12.1531 14.9396 12.5002 14.9396C12.8473 14.9396 13.1314 15.221 13.1314 15.5681C13.1314 15.9152 12.8473 16.1966 12.5002 16.1966C12.1531 16.1966 11.869 15.9152 11.869 15.5681Z'
      fill={props.color || '#E05D4C'}
    />
  </svg>
)

const SVGResponseRequiredIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGResponseRequiredIcon
