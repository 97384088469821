export const userSelector = (state) => state.authReducer.user
export const locationSelector = (state) => state.authReducer.user.location
export const organizationSelector = (state) => state.authReducer.user.organization
export const myMessagesInboxUnreadMessagesSelector = (state) =>
  state.authReducer.myMessagesInboxUnreadMessages
export const unreadMessagesSelector = (state) => state.authReducer.unreadMessages
export const archivedMessagesSelector = (state) => state.authReducer.archivedMessages
export const unassignedMessagesSelector = (state) =>
  state.authReducer.unassignedMessages
export const lastActionTimeSelector = (state) => state.authReducer.lastActionTime
export const selfActionTimeSelector = (state) => state.authReducer.selfActionTime
export const isMobileAppSelector = (state) => state.authReducer.isMobileApp
export const accessTokenSelector = (state) => state.authReducer.accessToken
export const idOrganizationSelector = (state) =>
  state.authReducer.user.organization?._id
export const loadingOrgSelector = (state) => state.authReducer.loadingOrg
export const organizationTimzeZoneSelector = (state) =>
  state.authReducer.user.organization.timezone
export const isSuperAdminSelector = (state) => state.authReducer.user.isSuperAdmin
export const isAdminSelector = (state) => state.authReducer.user.isAdmin
export const isSupportAgentSelector = (state) =>
  state.authReducer.user.isSupportAgent
export const menuOpenSelector = (state) => state.authReducer.menuOpen
export const vehicleOfInterestSelector = (state) =>
  state.authReducer.vehicleOfInterest
export const selectedConversationSelector = (state) =>
  state.authReducer.selectedConversation
export const useEnterBtnToSendMessageSelector = (state) =>
  state.authReducer.user?.additionalConfigs?.useEnterBtnToSendMessage || false
export const idUserSelector = (state) => state.authReducer.user?._id
export const aiSummarySelector = (state) => state.authReducer.aiSummary
export const orgEnablePaymentsSelector = (state) =>
  state.authReducer.user?.organization?.enable_payments || false
export const needsAttentionMessagesSelector = (state) =>
  state.authReducer.needsAttentionMessages || {
    mainInboxCount: 0,
    myMessagesInbox: 0,
  }
export const hasManyOrganizationsSelector = (state) =>
  state.authReducer.user.accessibleOrganizations &&
  state.authReducer.user.accessibleOrganizations.length >= 2
export const manualConsentEditSelector = (state) =>
  state.customerReducer.manualConsentEdit
export const changeReceiverSelector = (state) => state.messageReducer.receiver
