import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import authActions from '../../store/modules/authActions'
import appActions from '../../store/modules/appActions'
import { getText } from '../../lang'
import { moveToLoginPage } from '../../utils'
import CongratsIcon from '../../icons/congrats.png'
import { useSocket } from '../../utils/socket'
import MatButton from '../MatButton'
import MatModal from '../MatModal'
import MatRow from '../MatRow'
import { setSessionId } from '../../utils/sessionStorage'
import { redirect } from '../../utils/UrlHelper'

const UpdateApp = () => {
  const [isOpen, setIsOpen] = useState(false)

  const ignorePath = useMemo(
    () => ['/live_chat', '/google_my_business', '/login'],
    []
  )

  const checkVersion = useCallback((backendVersion) => {
    try {
      if (ignorePath.some((v) => window.location.pathname.includes(v))) {
        return
      }
      let version = localStorage.getItem('version')
      if (version) {
        version = JSON.parse(version)
      } else {
        version = { frontend: '', backend: '' }
      }

      if (
        authActions.isLoggedIn() &&
        (version.frontend !== backendVersion.frontend ||
          version.backend !== backendVersion.backend)
      ) {
        moveToLoginPage(redirect, true)
        // setIsOpen(true)
      }
    } catch (e) {}

    localStorage.setItem('version', JSON.stringify(backendVersion))
  }, [])

  useEffect(() => {
    const fill = async () => {
      setSessionId()
      const { data } = await appActions.getVersion()
      checkVersion(data)
    }
    fill()
  }, [])

  useSocket(`global`, `BACKEND_VERSION_UPDATE`, (data) => checkVersion(data))

  return (
    <MatModal
      noTrigger
      hideFooter
      visible={isOpen}
      onCancel={() => {
        setIsOpen(false)
      }}
    >
      <MatRow style={{ flexDirection: 'column', padding: '15px 0' }}>
        <h2
          style={{
            marginRight: '0',
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          Rev up your engines, we've made some great upgrades{' '}
          <img
            style={{ width: '26px', height: '26px' }}
            src={CongratsIcon}
            alt='#'
          />{' '}
          log back in for the latest version of Matador!
        </h2>
        <MatRow style={{ justifyContent: 'center' }}>
          <MatButton
            htmlType={'button'}
            typeButton={'cancel'}
            buttonText={getText('ACTION_CANCEL')}
            onClick={() => {
              setIsOpen(false)
            }}
          />
          <MatButton
            buttonText={getText('WORD_REFRESH')}
            onClick={async () => {
              window.location.reload()
              setIsOpen(false)
            }}
            htmlType={'button'}
          />
        </MatRow>
      </MatRow>
    </MatModal>
  )
}

export default memo(UpdateApp)
