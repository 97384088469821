import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.1 14.1571C8.1 14.3391 8.14932 14.5047 8.25593 14.6269C8.36467 14.7517 8.51933 14.8154 8.69522 14.8154C8.87092 14.8154 9.02636 14.7518 9.13626 14.6279C9.24437 14.5059 9.29556 14.3402 9.29556 14.1571V7.84033C9.29556 7.65716 9.24437 7.49147 9.13626 7.36953C9.02636 7.24556 8.87092 7.18203 8.69522 7.18203C8.51933 7.18203 8.36467 7.24575 8.25593 7.37045C8.14932 7.49271 8.1 7.65825 8.1 7.84033V14.1571ZM10.669 14.1571C10.669 14.3391 10.7183 14.5047 10.8249 14.6269C10.9336 14.7517 11.0883 14.8154 11.2642 14.8154C11.4401 14.8154 11.5947 14.7517 11.7035 14.6269C11.8101 14.5047 11.8594 14.3391 11.8594 14.1571V12.0197H13.347C14.0535 12.0197 14.6528 11.787 15.0759 11.3643C15.499 10.9415 15.7333 10.3413 15.7333 9.62999C15.7333 8.92379 15.4989 8.32266 15.0777 7.89757C14.6564 7.47235 14.0596 7.23498 13.3572 7.23498H11.6531C11.3391 7.23498 11.0813 7.2915 10.9057 7.46011C10.7285 7.63026 10.669 7.8814 10.669 8.1845V14.1571ZM12.779 10.9096H11.8645V8.35038H13.3061C13.7078 8.35038 14.0048 8.47134 14.2023 8.67901C14.4008 8.88776 14.5173 9.20381 14.5173 9.62999C14.5173 10.1353 14.3975 10.4354 14.1528 10.6202C13.8959 10.8143 13.4676 10.9096 12.779 10.9096Z'
        fill='#28303F'
        stroke='#28303F'
        strokeWidth='0.3'
      />
      <path
        d='M19.25 9.98018C19.25 14.4803 14.0938 20.1654 11 20.1654C7.90625 20.1654 2.75 14.4803 2.75 9.98018C2.75 5.48008 6.44365 1.83203 11 1.83203C15.5563 1.83203 19.25 5.48008 19.25 9.98018Z'
        stroke='#28303F'
        strokeWidth='1.5'
      />
    </svg>
  )
}

const SVGOrgIPWhiteListIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgIPWhiteListIcon
