import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { getText } from '../../lang'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import useDeviceCheck from '../../utils/useDeviceCheck'
import './MyDefaultInputNumber.scss'

const MyDefaultInputNumber = forwardRef(
  (
    {
      inputClassName,
      onChange,
      placeholder = '',
      disabled = false,
      value,
      label,
      maxLength = 255,
      minLength = 0,
      style,
      step = 1,
      size = 'middle',
      controls = false,
      isForm,
      required = false,
      hasFeedback = true,
      name,
      formClassName,
      initialValue,
      errorMessage,
      min,
      max,
      noStyle,
      validateStatus,
      color = 'grey',
      maxMessage,
      minMessage,
    },
    ref
  ) => {
    const inputAreaRef = useRef()
    const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()

    useImperativeHandle(ref, () => ({
      autoFocus() {
        if (!isMobileOrTabletOrTabletPortrait) {
          inputAreaRef.current && inputAreaRef.current.focus()
        }
      },
    }))

    return isForm ? (
      <MyDefaultFormItem
        className={formClassName}
        label={label}
        name={name}
        initialValue={initialValue}
        hasFeedback={hasFeedback}
        rules={[
          {
            required: required,
            message: errorMessage,
            // type: 'number',
          },
          {
            min: min,
            validator:
              typeof min !== 'undefined'
                ? (item, value) => {
                    if (value >= min) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      minMessage || new Error(getText('ERR_MSG_INPUT_NUMBER'))
                    )
                  }
                : () => Promise.resolve(),
          },
          {
            max: max,
            validator: max
              ? (item, value) => {
                  if (value < max + 1) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    maxMessage ||
                      new Error(
                        `${
                          label ? label : placeholder
                        } ${getText('TEXT_MUST_BE_MAXIMUM')} ${max} ${getText('WORD_CHARACTERS')}.`
                      )
                  )
                }
              : () => Promise.resolve(),
          },
        ]}
        style={style}
        noStyle={noStyle}
        validateStatus={validateStatus}
      >
        <input
          className={`my_default_input_number ${color} ${inputClassName || ''}`}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          step={step}
          size={size}
          type='number'
          controls={controls}
          maxLength={maxLength}
          minLength={minLength}
        />
      </MyDefaultFormItem>
    ) : (
      <input
        className={`my_default_input_number ${color} ${inputClassName || ''}`}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder ? placeholder : label}
        step={step}
        value={value}
        size={size}
        type='number'
        controls={controls}
        maxLength={maxLength}
        minLength={minLength}
      />
    )
  }
)

export default MyDefaultInputNumber
