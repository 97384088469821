import React, { memo } from 'react'
import { Flex } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import SVGCustomerEditIcon from '../../../../icons/SVG/SVGCustomerEditIcon'
import SVGCopyIcon from '../../../../icons/SVG/SVGCopyIcon'
import SVGCloseTag from '../../../../icons/SVG/SVGCloseTag'
import { notifySuccess } from '../../../../utils/Notify'
import { checkFormatPhoneNumber, getCustomerName } from '../../../../utils'
import { getText } from '../../../../lang'
import ProfileLettersCustomers from '../../../../components/ProfileLetters/ProfileLettersCustomers'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import ContactEditModal from './ContactEditModal'
import './MessageProfileInfo.scss'

const MessageProfileInfo = ({
  conversation,
  disabled,
  onSaveReciver,
  onCloseDrawer,
}) => {
  const { isMobile } = useDeviceCheck()

  const authorName = getCustomerName(conversation)
  const receiver = conversation?.receiver
  const receiverEmail = receiver?.email || ''
  const receiverPhone = checkFormatPhoneNumber(receiver?.phone || '')

  return (
    <div className='message_right_panel--customer-info'>
      <div className='message_right_panel--customer-info-letters'>
        {authorName && (
          <ProfileLettersCustomers
            name={authorName}
            temperature={
              conversation.temperature ? conversation.temperature.score : ''
            }
          />
        )}
      </div>
      <div className='message_right_panel--customer-info-details'>
        <div className='message_right_panel--customer-info-details-name'>
          <div className='customer_name'>
            <span className='name'>{authorName}</span>
            {!receiver?.fullName && (
              <CopyToClipboard
                text={authorName}
                onCopy={() => {
                  if (authorName.includes('@')) {
                    notifySuccess(getText('WORD_EMAIL_COPIED'))
                  } else {
                    notifySuccess(getText('WORD_PHONE_COPIED'))
                  }
                }}
              >
                <SVGCopyIcon />
              </CopyToClipboard>
            )}
          </div>
          <Flex>
            {receiver && (
              <ContactEditModal
                conversation={conversation}
                disabled={disabled}
                customer={receiver}
                trigger={<SVGCustomerEditIcon />}
                onSuccess={(data) => {
                  onSaveReciver && onSaveReciver(data)
                }}
              />
            )}
            {isMobile ? (
              <SVGCloseTag
                width={12}
                height={12}
                style={{ marginLeft: '10px' }}
                onClick={onCloseDrawer}
              />
            ) : null}
          </Flex>
        </div>
        {receiver?.fullName && receiverPhone && (
          <div className='customer_contact'>
            <span>{receiverPhone || '-'}</span>
            <CopyToClipboard
              text={receiverPhone}
              onCopy={() => {
                notifySuccess(getText('WORD_PHONE_COPIED'))
              }}
            >
              <SVGCopyIcon />
            </CopyToClipboard>
          </div>
        )}
        {receiverEmail && authorName !== receiverEmail && (
          <div className='customer_contact'>
            <span>{receiverEmail}</span>
            <CopyToClipboard
              text={receiverEmail}
              onCopy={() => {
                notifySuccess(getText('WORD_EMAIL_COPIED'))
              }}
            >
              <SVGCopyIcon />
            </CopyToClipboard>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MessageProfileInfo)
