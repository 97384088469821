import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.83337 11.8066V10.1933C1.83337 9.23998 2.61254 8.45165 3.57504 8.45165C5.23421 8.45165 5.91254 7.27832 5.07837 5.83915C4.60171 5.01415 4.88587 3.94165 5.72004 3.46498L7.30587 2.55748C8.03004 2.12665 8.96504 2.38332 9.39587 3.10748L9.49671 3.28165C10.3217 4.72082 11.6784 4.72082 12.5125 3.28165L12.6134 3.10748C13.0442 2.38332 13.9792 2.12665 14.7034 2.55748L16.2892 3.46498C17.1234 3.94165 17.4075 5.01415 16.9309 5.83915C16.0967 7.27832 16.775 8.45165 18.4342 8.45165C19.3875 8.45165 20.1759 9.23082 20.1759 10.1933V11.8066C20.1759 12.76 19.3967 13.5483 18.4342 13.5483C16.775 13.5483 16.0967 14.7216 16.9309 16.1608C17.4075 16.995 17.1234 18.0583 16.2892 18.535L14.7034 19.4425C13.9792 19.8733 13.0442 19.6166 12.6134 18.8925L12.5125 18.7183C11.6875 17.2791 10.3309 17.2791 9.49671 18.7183L9.39587 18.8925C8.96504 19.6166 8.03004 19.8733 7.30587 19.4425L5.72004 18.535C4.88587 18.0583 4.60171 16.9858 5.07837 16.1608C5.91254 14.7216 5.23421 13.5483 3.57504 13.5483C2.61254 13.5483 1.83337 12.76 1.83337 11.8066Z'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGOrgGeneralIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgGeneralIcon
