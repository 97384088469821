import authActions from '../../store/modules/authActions'

const PushNotification = (socketEvent) => {
  const {
    conversation,
    newInsertedMsg = { body: '', whoSentThis_name: '', from: {} },
  } = socketEvent
  const { body = ' ', from, whoSentThis_name } = newInsertedMsg

  const user = authActions.getUserData()
  const isConversationAssignedToMe = conversation.users.find(
    (value) => value.toString() === user?._id.toString()
  )
  const options = {
    body,
    tag: body,
    data: {
      url: '/messages?type=all',
    },
  }
  const title = whoSentThis_name || from
  const ignorePath = ['/live_chat']

  navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      if (
        !ignorePath.includes(window.location.pathname) &&
        isConversationAssignedToMe
      ) {
        new Notification(title, options)
      }
    })
    .catch((err) => console.log(err))
}

export default PushNotification
