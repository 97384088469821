import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='24' height='24' rx='12' fill='#EEEFFC' />
    <path
      d='M17.6465 9.60838L13.2715 6.48328C12.9004 6.2185 12.4559 6.07617 12 6.07617C11.5441 6.07617 11.0996 6.2185 10.7285 6.48328L6.3533 9.60859C6.07031 9.81126 5.83964 10.0784 5.68039 10.3879C5.52114 10.6974 5.43788 11.0404 5.4375 11.3885V15.7379C5.43818 16.3178 5.66887 16.8738 6.07896 17.2839C6.48904 17.694 7.04505 17.9247 7.625 17.9254H16.375C16.955 17.9247 17.511 17.694 17.921 17.2839C18.3311 16.8738 18.5618 16.3178 18.5625 15.7379V11.3885C18.5622 11.0404 18.4789 10.6973 18.3196 10.3877C18.1603 10.0782 17.9296 9.81103 17.6465 9.60838ZM17.6875 15.7379C17.6871 16.0858 17.5487 16.4195 17.3027 16.6655C17.0566 16.9116 16.723 17.05 16.375 17.0504H7.625C7.27702 17.05 6.9434 16.9116 6.69734 16.6655C6.45128 16.4195 6.31288 16.0858 6.3125 15.7379V11.3885C6.31275 11.1797 6.36272 10.9739 6.45826 10.7882C6.5538 10.6025 6.69217 10.4422 6.86194 10.3206L11.2369 7.1955C11.4596 7.03656 11.7264 6.95112 12 6.95112C12.2736 6.95112 12.5404 7.03656 12.7631 7.1955L17.1381 10.3204C17.3078 10.4421 17.4462 10.6024 17.5417 10.7881C17.6373 10.9738 17.6872 11.1796 17.6875 11.3885V15.7379Z'
      fill='#5B66EA'
    />
    <path
      d='M16.1391 10.9929L12.7066 13.1875C12.4954 13.3219 12.2502 13.3933 11.9998 13.3933C11.7495 13.3932 11.5043 13.3218 11.2931 13.1873L7.86059 10.9929C7.81217 10.9619 7.75812 10.9408 7.70151 10.9306C7.6449 10.9205 7.58686 10.9217 7.5307 10.934C7.47454 10.9464 7.42136 10.9696 7.3742 11.0025C7.32704 11.0354 7.28683 11.0773 7.25587 11.1258C7.22491 11.1742 7.2038 11.2283 7.19375 11.2849C7.1837 11.3415 7.1849 11.3996 7.1973 11.4557C7.2097 11.5119 7.23304 11.565 7.26599 11.6122C7.29894 11.6593 7.34086 11.6994 7.38935 11.7304L10.8218 13.9245C11.1739 14.1486 11.5827 14.2677 12 14.2678C12.4174 14.2678 12.8262 14.1488 13.1783 13.9247L16.6108 11.7304C16.7086 11.6678 16.7775 11.569 16.8025 11.4556C16.8274 11.3422 16.8062 11.2236 16.7437 11.1258C16.6811 11.028 16.5823 10.9591 16.4689 10.9342C16.3556 10.9092 16.2369 10.9304 16.1391 10.9929Z'
      fill='#5B66EA'
    />
    <path
      d='M11.007 11.6309C11.2705 11.8939 11.6276 12.0415 11.9999 12.0415C12.3722 12.0415 12.7293 11.8939 12.9928 11.6309L13.5982 11.0253C13.6388 10.9847 13.6711 10.9365 13.693 10.8834C13.715 10.8303 13.7263 10.7734 13.7263 10.716C13.7263 10.6586 13.715 10.6017 13.693 10.5486C13.6711 10.4955 13.6388 10.4473 13.5982 10.4067C13.5576 10.3661 13.5094 10.3338 13.4563 10.3118C13.4032 10.2899 13.3463 10.2785 13.2889 10.2785C13.2314 10.2785 13.1746 10.2899 13.1215 10.3118C13.0684 10.3338 13.0202 10.3661 12.9796 10.4067L12.4374 10.949V8.54492C12.4374 8.42889 12.3913 8.31761 12.3093 8.23556C12.2272 8.15352 12.1159 8.10742 11.9999 8.10742C11.8839 8.10742 11.7726 8.15352 11.6905 8.23556C11.6085 8.31761 11.5624 8.42889 11.5624 8.54492V10.949L11.0202 10.4067C10.9796 10.3661 10.9314 10.3338 10.8783 10.3118C10.8252 10.2899 10.7683 10.2785 10.7109 10.2785C10.6534 10.2785 10.5966 10.2899 10.5435 10.3118C10.4904 10.3338 10.4422 10.3661 10.4016 10.4067C10.3609 10.4473 10.3287 10.4955 10.3067 10.5486C10.2848 10.6017 10.2734 10.6586 10.2734 10.716C10.2734 10.7734 10.2848 10.8303 10.3067 10.8834C10.3287 10.9365 10.3609 10.9847 10.4016 11.0253L11.007 11.6309Z'
      fill='#5B66EA'
    />
  </svg>
)

const SVGMessageSmartIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageSmartIcon
