import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.00016 14.6667H10.0002C13.3335 14.6667 14.6668 13.3333 14.6668 10V6C14.6668 2.66667 13.3335 1.33334 10.0002 1.33334H6.00016C2.66683 1.33334 1.3335 2.66667 1.3335 6V10C1.3335 13.3333 2.66683 14.6667 6.00016 14.6667Z'
        stroke='#3F8CFF'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.99984 10.3333C9.2885 10.3333 10.3332 9.28866 10.3332 8C10.3332 6.71133 9.2885 5.66666 7.99984 5.66666C6.71117 5.66666 5.6665 6.71133 5.6665 8C5.6665 9.28866 6.71117 10.3333 7.99984 10.3333Z'
        stroke='#3F8CFF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7576 4.66667H11.7653'
        stroke='#3F8CFF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGDashInstagramIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashInstagramIcon
