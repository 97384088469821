import React, { useEffect, useRef, useState } from 'react'
import { Flex } from 'antd'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { BUTTON, TABLE_DEFAULT_ROW_HEIGHT } from '../../../../devOptions'
import { getText, getTextServerError } from '../../../../lang'
import workflowActions from '../../../../store/modules/workflowActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultTableList from '../../../../components/MyDefaultTable/MyDefaultTableList/MyDefaultTableList'
import MyDefaultTableTitle from '../../../../components/MyDefaultTable/MyDefaultTableTitle/MyDefaultTableTitle'
import MyDefaultTableText from '../../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'
import authActions from '../../../../store/modules/authActions'
import SVGMyDefaultDeleteIcon from '../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import SVGMyDefaultEditIcon from '../../../../icons/SVG/SVGMyDefaultEditIcon'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'

const Workflow = () => {
  const { selectedOrganization: organization } = useOrganizationLayout()
  const { setPageTitle } = useLayout()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [workflowList, setWorkflowList] = useState({
    list: [],
    total: 0,
  })

  const layoutRef = useRef(null)

  useEffect(() => {
    setPageTitle(getText('WORD_WORKFLOWS'))
  }, [])

  const getWorkflowList = async () => {
    setLoading(true)
    const result = await workflowActions.getWorkflowList(
      page,
      TABLE_DEFAULT_ROW_HEIGHT,
      organization?._id
    )
    if (result.success) {
      let data = []
      if (page === 0) {
        data = result.data.workflows
      } else {
        data = [...workflowList.list, ...result.data.workflows]
      }
      setWorkflowList({
        list: data,
        total: result.data.total_items,
      })
    } else {
      notifyError(result.errMsg)
    }
    setLoading(false)
  }

  useEffect(() => {
    getWorkflowList()
  }, [page])

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      isAdvanced
      headerProps={{
        right: [
          {
            type: BUTTON,
            title: '+ ' + getText('WORD_ADD_NEW_WORKFLOW'),
            onClick: () => {
              navigate(`/organization/settings/${organization?._id}/workflow/create`)
            },
          },
        ],
      }}
    >
      <MyDefaultTableList
        pageStart={page}
        loading={loading}
        data={workflowList.list || []}
        getScrollParent={() => layoutRef?.current}
        onLoadMore={() => {
          if (!loading && workflowList?.list?.length < workflowList?.total) {
            setLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={workflowList?.total || 0}
        columns={[
          {
            title: getText('WORD_WORKFLOW_NAME'),
            dataIndex: 'name',
            render: (_, { name }) => {
              return <MyDefaultTableTitle title={name || ''} />
            },
          },
          {
            title: getText('WORD_UPDATED_AT'),
            dataIndex: 'updatedAt',
            render: (_, { updatedAt }) => {
              return (
                <MyDefaultTableText
                  text={moment(updatedAt)
                    .locale(authActions.getLanguage())
                    .format('D MMM, YYYY hh:mm a')}
                />
              )
            },
          },
          {
            title: getText('WORD_UPDATED_BY'),
            dataIndex: 'updatedBy',
            render: (_, { updated_by }) => {
              return <MyDefaultTableText text={updated_by} />
            },
          },
          {
            title: '',
            dataIndex: 'actions',
            width: 60,
            render: (_, item) => {
              return (
                <Flex align='center' gap={20}>
                  <SVGMyDefaultEditIcon
                    onClick={() => {
                      navigate(
                        `/organization/settings/${organization?._id}/workflow/edit`,
                        {
                          state: { fromOrgList: true, item },
                        }
                      )
                    }}
                  />
                  <ConfirmPopup
                    title={getText(
                      'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SEQUENCE'
                    )}
                    placement={'topRight'}
                    onConfirm={async () => {
                      setLoading(true)
                      const result = await workflowActions.deleteWorkflow(item._id)
                      if (result.success) {
                        setWorkflowList((ov) => {
                          const newData = ov.list.filter((wf) => wf._id !== item._id)
                          return { list: [...newData], total: ov.total }
                        })
                        notifySuccess(
                          getText('TEXT_WORKFLOW_WAS_DELETED_SUCCESSFULLY')
                        )
                      } else {
                        notifyError(getTextServerError(result.errMsg))
                      }
                      setLoading(false)
                    }}
                    okText={getText('WORD_YES')}
                    cancelText={getText('ACTION_CANCEL')}
                    trigger={<SVGMyDefaultDeleteIcon />}
                  />
                </Flex>
              )
            },
          },
        ]}
      />
    </MyDefaultPageLayout>
  )
}

export default Workflow
