import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6663 8.59967V11.3997C10.6663 13.733 9.73301 14.6663 7.39967 14.6663H4.59967C2.26634 14.6663 1.33301 13.733 1.33301 11.3997V8.59967C1.33301 6.26634 2.26634 5.33301 4.59967 5.33301H7.39967C9.73301 5.33301 10.6663 6.26634 10.6663 8.59967Z'
        stroke={props.color || '#A3A1B7'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6663 4.59967V7.39967C14.6663 9.73301 13.733 10.6663 11.3997 10.6663H10.6663V8.59967C10.6663 6.26634 9.73301 5.33301 7.39967 5.33301H5.33301V4.59967C5.33301 2.26634 6.26634 1.33301 8.59967 1.33301H11.3997C13.733 1.33301 14.6663 2.26634 14.6663 4.59967Z'
        stroke={props.color || '#A3A1B7'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGCopyIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGCopyIcon
