import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.35807 2.7265C9.02864 1.31382 10.9661 1.31382 11.6367 2.7265L12.7956 5.16797C13.0619 5.72895 13.5767 6.11777 14.1721 6.20773L16.7635 6.59924C18.263 6.82577 18.8617 8.74161 17.7767 9.84123L15.9015 11.7417C15.4706 12.1783 15.274 12.8074 15.3757 13.424L15.8184 16.1074C16.0745 17.6601 14.5071 18.8442 13.1659 18.1111L10.8481 16.8442C10.3155 16.5531 9.67928 16.5531 9.14671 16.8442L6.82887 18.1111C5.48772 18.8442 3.92025 17.6601 4.17638 16.1075L4.61905 13.424C4.72076 12.8074 4.52416 12.1783 4.0933 11.7417L2.21813 9.84123C1.13312 8.74161 1.73184 6.82577 3.23128 6.59924L5.82271 6.20773C6.41814 6.11777 6.93287 5.72895 7.19915 5.16797L8.35807 2.7265Z'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGSendInvitationIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSendInvitationIcon
