import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../../lang'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import storyActions from '../../../../../store/modules/storyActions'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../../../devOptions'
import MatadorConnectListTitle from '../matadorConnectComponents/MatadorConnectListTitle'
import MatadorConnectLayout from '../matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListItem from '../matadorConnectComponents/MatadorConnectListItem'
import DraggableMatList from '../../../../../components/MatList/DraggableMatList'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import './Stories.scss'

const ListStories = () => {
  const { locationObj } = useLocationLayout()
  const { _id } = useParams()
  const navigate = useNavigate()

  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [perPage, setPerPage] = useState(TABLE_DEFAULT_ROW_NUMBER)
  const [stories, setStories] = useState([])

  useEffect(() => {
    if (locationObj._id) {
      getData()
    }
  }, [page, locationObj._id])

  const getData = async () => {
    setLoading(true)
    let resultStories = await storyActions.getAllList(
      page + 1,
      perPage,
      locationObj._id
    )
    if (resultStories.success) {
      setStories(resultStories.data)
      setTotalCount(resultStories.max)
    }
    setLoading(false)
  }

  const handleStorySwitch = async (checked, item) => {
    const data = {
      locationId: locationObj._id,
      title: item.title,
      status: checked ? 'ACTIVE' : 'DEACTIVATED',
      imageUrl: item.imageUrl,
      thumbnailUrl: item.thumbnailUrl,
      videoUrl: item.videoUrl,
    }

    const result = await storyActions.update(data, item.id)
    if (!result.success) {
      notifyError(getTextServerError(result.errMsg))
      return
    }
    notifySuccess(getText('MSG_STORY_WAS_EDITED_SUCCESSFULLY'))
    await getData()
  }

  const handleStoryEdit = (val, item) => {
    if (val === 'edit') {
      navigate(`/location/settings/${_id}/stories/edit`, { state: item })
    } else if (val === 'delete') {
      Modal.confirm({
        title: getText(
          'MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_STORIES_TYPE'
        ).replace('[storiesType]', getText('WORD_STORIES').toLowerCase()),
        async onOk() {
          const result = await storyActions.delete(item.id)
          if (!result.success) {
            notifyError(getTextServerError(result.errMsg))
            return
          }
          notifySuccess(getText('MSG_STORY_WAS_DELETED_SUCCESSFULLY'))
          await getData()
        },
        okText: getText('WORD_YES'),
        cancelText: getText('ACTION_CANCEL'),
        okButtonProps: {
          type: 'primary',
          className: 'mat-btn mat-btn-primary',
        },
        cancelButtonProps: {
          type: 'cancel',
          className: 'mat-btn',
        },
      })
    }
  }

  const updateList = async (list) => {
    setStories(list)
    const storiesIds = list.map((item) => item.id)
    const result = await storyActions.reorderStories(locationObj._id, storiesIds)
    if (!result.success) {
      notifyError(getTextServerError(result.errMsg))
      return
    }
    notifySuccess(getText('MSG_STORIES_WERE_REORDERED_SUCCESSFULLY'))
  }

  return (
    <MatadorConnectLayout>
      <MatadorConnectListTitle
        title={getText('WORD_STORIES')}
        buttonTitle={'+ ' + getText('WORD_ADD_NEW_STORY')}
        disabledButtonInfo={getText('TEXT_YOU_CAN_CREATE_ONLY_4_STORIES')}
        url={`/location/settings/${_id}/stories/create`}
        disabled={totalCount >= 4}
        subTitle={getText('STORIES_REORDER_DESCRIPTION')}
      />
      <DraggableMatList
        loading={loading}
        data={stories}
        pagination={true}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={page}
        paginationTotalCount={totalCount}
        onPaginationChange={setPage}
        onShowSizeChange={setPerPage}
        containerClassName='matador-connect-mat-list-wrapper'
        header={
          <div className='matador-connect-mat-list-header'>
            <div className='matador-connect-mat-list-header-item'>
              {getText('WORD_TITLE')}
            </div>
            <div className='matador-connect-mat-list-header-item'>
              {getText('WORD_DATE')}
            </div>
            <div className='matador-connect-mat-list-header-item' />
          </div>
        }
        renderItem={(item, index) => {
          return (
            <MatadorConnectListItem
              key={index}
              item={item}
              style={{
                backgroundColor: index % 2 === 0 ? '#F8F9F9' : '#ffffff',
              }}
              hasSwitch
              hasImage
              handleSwitch={handleStorySwitch}
              handleEdit={handleStoryEdit}
            />
          )
        }}
        updateList={updateList}
      />
    </MatadorConnectLayout>
  )
}

export default ListStories
