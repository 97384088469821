import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { getText, getTextServerError } from '../../../../../lang'
import { listVariables } from '../../../../../components/AddVariableInMessage/ListVariables'
import InputMentionSecondary from '../../../../../components/InputMentions/InputMentionSecondary'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError } from '../../../../../utils/Notify'
import { TAG } from '../../../utils/constants'
import { useEnterBtnToSendMessageSelector } from '../../../../../store/selectors/selectors'

const SendMessageInput = (props) => {
  const {
    setnewMessageValue,
    sendingMsg,
    setIsTemplate,
    usersTagList,
    smsTemplatesList,
    setuploadFiles,
    handleSendMsg,
    isCustomerYellowStatusAndRestricted,
    isCustomerRedStatus,
    isCustomerPhoneNumberValid,
    setSelectedUserTagList,
    setcurrentCursorPosition,
    messageInputRef,
    isCustomerPhoneTemporaryBlocked,
    setFocusOverInput,
    conversation,
    newMessageValue,
    msgChangeEmailSMSType,
    onChangeEmailSMSType,
  } = props

  const userAdditionalConfigsBtn = useSelector(useEnterBtnToSendMessageSelector)

  const replaceDynamicTagsText = (
    changedText,
    selectedTemplate,
    newPlainTextValue
  ) => {
    setnewMessageValue({
      inputMessageForTags: newPlainTextValue.replace(
        selectedTemplate.name,
        changedText
      ),
      inputMessageForSending: newPlainTextValue.replace(
        selectedTemplate.name,
        changedText
      ),
    })
    if (selectedTemplate.media && selectedTemplate.media.length) {
      setuploadFiles((oldList) => {
        oldList.push(...selectedTemplate.media)
        return [...oldList]
      })
    }
  }

  return (
    <>
      <InputMentionSecondary
        ref={messageInputRef}
        sendingMsg={sendingMsg}
        placeholder={
          msgChangeEmailSMSType === TAG
            ? 'Type @ to tag someone and add your message'
            : Boolean(isCustomerPhoneTemporaryBlocked)
              ? getText(
                  'TEXT_THIS_PHONE_NUMBER_IS_CURRENTLY_NOT_ACCEPTING_SMS_MESSAGES'
                )
              : isCustomerPhoneNumberValid
                ? getText(
                    'TEXT_THIS_PHONE_NUMBER_IS_LANDLINE_AND_CURRENTLY_NOT_ACCEPTING_SMS_MESSAGES'
                  )
                : conversation.kind === 'gbm'
                  ? getText('TEXT_GOOGLE_DISCONTINUED')
                  : isCustomerRedStatus
                    ? getText('TEXT_THE_CLIENT_HAS_NOT_GIVEN_SMS_CONSENT')
                    : conversation.kind === 'sms'
                      ? getText('TEXT_TYPE_A_MESSAGE_TO_SMS_TO_THE_CUSTOMER')
                      : getText('WORD_TYPE_A_MESSAGE_TO_SEND_TO_CUSTOMER')
        }
        disabled={
          (isCustomerPhoneNumberValid ||
            isCustomerRedStatus ||
            isCustomerYellowStatusAndRestricted ||
            conversation.kind === 'gbm') &&
          msgChangeEmailSMSType !== TAG
        }
        value={newMessageValue.inputMessageForTags}
        onChange={async (newValue, newPlainTextValue, mentions) => {
          let selectedTemplate
          setSelectedUserTagList((oldList) => {
            const newArray = oldList.filter((item1) => {
              return mentions.some((item2) => item1.name === item2.id)
            })
            return newArray
          })
          if (mentions.length) {
            selectedTemplate = smsTemplatesList.find((item1) =>
              mentions.some((item2) => item1.display === item2.display)
            )
          }
          if (!sendingMsg && !selectedTemplate) {
            setnewMessageValue({
              inputMessageForTags: newValue,
              inputMessageForSending: newPlainTextValue,
            })
            setcurrentCursorPosition && setcurrentCursorPosition(newValue.length)
          } else if (Boolean(selectedTemplate)) {
            setIsTemplate(true)
            if (conversation.isNew) {
              replaceDynamicTagsText(
                selectedTemplate.replacementItem,
                selectedTemplate,
                newPlainTextValue
              )
            } else {
              const result = await messageActions.messagePreviewDynamicTags(
                selectedTemplate.getValue(),
                conversation._id
              )
              if (result.success) {
                replaceDynamicTagsText(
                  result.data.message,
                  selectedTemplate,
                  newPlainTextValue
                )
              } else {
                notifyError(getTextServerError(result.errMsg))
              }
            }
          }
        }}
        onSelect={async (prefix, id, name) => {
          if (prefix === '@') {
            setSelectedUserTagList((oldList) => {
              oldList.push({ id: id, name: name, _id: id })
              return [...oldList]
            })
            if (msgChangeEmailSMSType !== TAG) {
              onChangeEmailSMSType && onChangeEmailSMSType(TAG)
            }
          }
        }}
        onKeyDown={(e) => {
          if (userAdditionalConfigsBtn) {
            if (!sendingMsg && !e.shiftKey && e.key === 'Enter') {
              handleSendMsg()
            }
          } else {
            if (!sendingMsg && e.shiftKey && e.key === 'Enter') {
              handleSendMsg()
            }
          }
          setcurrentCursorPosition &&
            setcurrentCursorPosition(e.target.selectionStart)
        }}
        onClick={(e) => {
          setcurrentCursorPosition &&
            setcurrentCursorPosition(e.target.selectionStart)
        }}
        userList={usersTagList}
        dynamicTagsList={listVariables()}
        templateList={smsTemplatesList}
        onFocus={() => setFocusOverInput(true)}
        onBlur={() => setFocusOverInput(false)}
      />
    </>
  )
}

export default memo(SendMessageInput)
