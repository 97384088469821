import React from 'react'

const SVGSendMessageNew = (props) => {
  return (
    <div className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={props.width || 16}
        height={props.height || 16}
        viewBox='0 0 16 16'
        fill='none'
        {...props}
      >
        <path
          fill={props.color || '#77808D'}
          d='M14.2909 1.72148C13.9574 1.37923 13.4639 1.25172 13.0037 1.38594L2.27265 4.50651C1.78712 4.6414 1.44298 5.02862 1.35027 5.52053C1.25557 6.02116 1.58637 6.65669 2.01854 6.92244L5.37392 8.9847C5.71806 9.1961 6.16224 9.14308 6.44702 8.85585L10.2893 4.9897C10.4827 4.78837 10.8028 4.78837 10.9962 4.9897C11.1896 5.18431 11.1896 5.49973 10.9962 5.70105L7.14731 9.56788C6.86186 9.85444 6.80851 10.3007 7.01859 10.647L9.06876 14.036C9.30886 14.4387 9.72237 14.6668 10.1759 14.6668C10.2292 14.6668 10.2893 14.6668 10.3426 14.6601C10.8628 14.593 11.2763 14.2373 11.4297 13.734L14.611 3.01669C14.7511 2.56035 14.6244 2.06374 14.2909 1.72148Z'
        />
      </svg>
    </div>
  )
}

export default SVGSendMessageNew
