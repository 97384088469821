import { fetchFromUrlPOSTAsync } from '../../utils/UrlHelper'

const urlMeet = '/meet'

const isEmptyObject = (obj) => {
  return JSON.stringify(obj) === '{}'
}

export const pushTempUrlParamsForConversaction = (
  conversation,
  urlParams,
  withCustomer = true,
  withPhone = false
) => {
  if (conversation && conversation.isNew) {
    let data = {}
    if (withCustomer) {
      data.customer = {
        firstName: conversation.receiver.firstName,
        lastName: conversation.receiver.lastName,
        email: conversation.receiver.email,
        language: conversation.receiver.language,
        enable_auto_reply: conversation.receiver.enable_auto_reply,
      }
      if (withPhone) {
        data.customer.phone = conversation.receiver.phone
      }
      if (!data.customer.email) {
        delete data.customer.email
      }
      let additionalInfo = {}
      if (conversation.receiver.source) {
        additionalInfo.source = conversation.receiver.source
      }
      if (conversation.receiver.source) {
        additionalInfo.website = conversation.receiver.website
      }
      if (!isEmptyObject(additionalInfo)) {
        urlParams.push({ key: 'additionalInfo', value: additionalInfo })
      }
    }
    if (conversation.isSetDataReciverOnlyLang && withCustomer) {
      data.customer = {
        language: conversation.receiver.language,
      }
    }
    if (conversation.notes.length) {
      data.notes = conversation.notes.map((oo) => {
        return { text: oo.text }
      })
    }
    if (conversation.tags.length) {
      data.tags = conversation.tags.map((oo) => {
        return oo._id
      })
    }
    urlParams.push({
      key: 'isNew',
      value: conversation.isNew,
    })

    if (!isEmptyObject(data)) {
      urlParams.push({
        key: 'data',
        value: data,
      })
    }
  }
}

const fs = {
  createRoom: async (obj, conversation) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    pushTempUrlParamsForConversaction(conversation, urlParams)
    const result = await fetchFromUrlPOSTAsync(urlMeet + '/room/create', urlParams)
    return result
  },
}

const loc = {}

const meetActions = Object.assign(fs, loc)

export default meetActions
