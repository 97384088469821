import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { Flex, Table, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import { organizationSelector } from '../../../store/selectors/selectors'
import broadcastingActions from '../../../store/modules/broadcastingActions'
import { getText, getTextServerError } from '../../../lang'
import { formatDateTime, getLongName } from '../../../utils'
import SVGReviewCheck from '../../../icons/SVG/SVGReviewCheck'
import SVGDownloadIconNew from '../../../icons/SVG/SVGDownloadIconNew'
import ConfirmPopup from '../../../components/ConfirmPopup'
import MyDefaultTableHeader from '../../../components/MyDefaultTable/MyDefaultTableHeader/MyDefaultTableHeader'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import MyDefaultTableList from '../../../components/MyDefaultTable/MyDefaultTableList/MyDefaultTableList'
import MyDefaultPageLayout from '../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import { useLayout } from '../../../layout/LayoutProvider/LayoutProvider'
import {
  AUTOMATION,
  BUTTON,
  RANGEPICKER,
  SEARCH,
  SELECT,
  TABLE_DEFAULT_ROW_HEIGHT,
} from '../../../devOptions'
import MyDefaultTableTitle from '../../../components/MyDefaultTable/MyDefaultTableTitle/MyDefaultTableTitle'
import MyDefaultTagBlock from '../../../components/MyDefaultTagBlock/MyDefaultTagBlock'
import MyDefaultTableText from '../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'
import broadcastListUtils, {
  CANCELED,
  FINISHED,
  IN_PROGRESS,
  SCHEDULED,
} from './broadcastListUtils'
import BrodcastListExpandedItem from './BroadcastListComponents/BrodcastListExpandedItem'
import BroadcastListExpandedItemWorkflow from './BroadcastListComponents/BroadcastListExpandedItemWorkflow'
import BrodcastListExpandedItemMessage from './BroadcastListComponents/BrodcastListExpandedItemMessage'

const SORT = {
  asc: 'ascend',
  desc: 'descend',
}

const BroadcastingList = ({ createdBy }) => {
  const { setPageTitle } = useLayout()
  const navigate = useNavigate()

  const organization = useSelector(organizationSelector)

  const [loadingData, setLoadingData] = useState(false)
  const [page, setPage] = useState(0)
  const [data, setData] = useState([
    {
      list: [],
      total: 0,
    },
  ])
  const [filter, setFilter] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [sorting, setSorting] = useState(null)

  const layoutRef = useRef()
  const isFirstRender = useRef(true)

  useEffect(() => {
    setPageTitle(
      getText(
        createdBy === AUTOMATION ? 'WORD_AUTOMATIONS' : 'TITLE_BROADCASTING_LIST'
      )
    )
  }, [createdBy])

  const fill = useCallback(
    async (createdBySend) => {
      setLoadingData(true)
      const result = await broadcastingActions.getPageList(
        page,
        TABLE_DEFAULT_ROW_HEIGHT,
        organization?._id,
        {
          ...filter,
          creatorFilter: {
            value: createdBySend || createdBy,
          },
        },
        sorting
      )
      if (!organization.enable_broadcast) {
        notifyError(
          getTextServerError(
            getText('TEXT_YOU_DO_NOT_HAVE_ACCESS_TO_THIS_FEATURE_BROADCAST')
          )
        )
      } else if (result.success) {
        const newData = page === 0 ? result.data : [...data.list, ...result.data]
        setData({
          list: newData,
          total: result.max,
        })
      }
      setLoadingData(false)
    },
    [organization, page, filter, sorting]
  )

  useEffect(() => {
    if (organization?._id) {
      fill()
    }
  }, [fill])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    if (!filter && !searchInput && !sorting && page === 0) {
      fill(createdBy)
    } else {
      setFilter('')
      setSearchInput('')
      setSorting(null)
      setPage(0)
    }
  }, [createdBy])

  const cancelBroadcastSending = (_id) => {
    return (
      <ConfirmPopup
        title={getText(
          'MSG_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_BROADCAST_CAMPAIGN'
        )}
        onConfirm={async () => {
          setLoadingData(true)
          const result = await broadcastingActions.cancel(_id)
          if (result.success) {
            setData((ov) => {
              const find = ov.list.find((broadcast) => _id === broadcast._id)
              if (Boolean(find)) {
                find.status = CANCELED
              }
              return {
                total: ov.total,
                list: ov.list,
              }
            })
            notifySuccess(getText('TEXT_CAMPAIGN_WAS_CANCELED_SUCCESSFULLY'))
            setLoadingData(false)
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        }}
        trigger={<CloseOutlined />}
        okText={getText('WORD_YES')}
        cancelText={getText('WORD_NO')}
      />
    )
  }

  const getStatus = (status, action_time, canceled, _id) => {
    const showCancelButton =
      !canceled && dayjs(action_time).add(1, 'day').isAfter(dayjs())
    const commonStyles = {
      FINISHED: {
        color: 'var(--green)',
        backgroundColor: 'var(--greenLight)',
        title: getText('WORD_SENT'),
      },
      CANCELED: {
        color: 'var(--red)',
        backgroundColor: 'var(--lightRedSecondary)',
        title: getText('WORD_CANCELLED'),
      },
      SCHEDULED: {
        color: 'var(--red)',
        backgroundColor: 'var(--lightRedSecondary)',
      },
      DEFAULT: {
        color: 'var(--orange)',
        backgroundColor: 'var(--orangeLight)',
      },
    }

    const createTitle = (text) => (
      <Flex align='center' gap={3}>
        {text} {showCancelButton && cancelBroadcastSending(_id)}
      </Flex>
    )

    switch (status) {
      case FINISHED:
        return commonStyles.FINISHED
      case CANCELED:
        return commonStyles.CANCELED
      case SCHEDULED:
        return {
          ...commonStyles.SCHEDULED,
          title: createTitle(getText('WORD_NOT_SENT')),
        }
      default:
        return {
          ...commonStyles.DEFAULT,
          title: createTitle(getText('WORD_IN_PROGRESS')),
        }
    }
  }

  const filterOption = useMemo(() => {
    return [
      {
        label: getText('WORD_SENT'),
        value: FINISHED,
      },
      {
        label: getText('WORD_IN_PROGRESS'),
        value: IN_PROGRESS,
      },
      {
        label: getText('WORD_NOT_SENT'),
        value: SCHEDULED,
      },
      {
        label: getText('WORD_CANCELLED'),
        value: CANCELED,
      },
    ]
  }, [])

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setFilter({
        ...filter,
        search: options,
      })
    }, 500),
    [filter]
  )

  const getSortedData = (sort) => {
    const fieldMap = {
      name: 'name',
      status: 'status',
      who_created: 'who_created',
      action_time: 'action_time',
      total_customers_count: 'total_customers_count',
      reached_count: 'reached_count',
      responded_count: 'responded_count',
      opted_out_count: 'opted_out_count',
      opportunity_count: 'opportunity_count',
      response_percent: 'response_percent',
    }

    if (sort.order && sort.field && fieldMap[sort.field]) {
      setSorting({
        order: sort.order === 'ascend' ? 'asc' : 'desc',
        sort: fieldMap[sort.field],
      })
    } else {
      setSorting(null)
    }

    if (page > 0) {
      setPage(0)
    }
  }

  const isFiltered = filter?.status || filter?.search || filter?.date

  const BroadcastTableList = useMemo(() => {
    return (
      <MyDefaultTableList
        pageStart={page}
        loading={loadingData}
        data={data.list || []}
        getScrollParent={() => layoutRef?.current}
        onLoadMore={() => {
          if (!loadingData && data?.list?.length < data?.total) {
            setLoadingData(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={data?.total || 0}
        scroll={{
          x: 'max-content',
          scrollToFirstRowOnChange: true,
        }}
        onChange={(_, __, sort) => {
          getSortedData(sort)
        }}
        columns={[
          {
            title: getText('TEXT_NAME_OF_BROADCAST'),
            dataIndex: 'name',
            sorter: true,
            fixed: 'left',
            sortOrder: sorting?.sort === 'name' ? SORT[sorting?.order] : null,
            render: (_, { name }) => {
              return <MyDefaultTableTitle title={name || ''} />
            },
          },
          {
            title: getText('WORD_STATUS'),
            dataIndex: 'status',
            sorter: true,
            width: 140,
            sortOrder: sorting?.sort === 'status' ? SORT[sorting?.order] : null,
            render: (_, { status, action_time, canceled, _id }) => {
              const broadcastStatus = getStatus(status, action_time, canceled, _id)
              return (
                <MyDefaultTagBlock
                  disableMargin
                  fontSize={12}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  width={'fit-content'}
                  title={broadcastStatus?.title}
                  titleColor={broadcastStatus?.color}
                  backgroundColor={broadcastStatus?.backgroundColor}
                />
              )
            },
          },
          {
            title: getText('WORD_CREATED_BY'),
            dataIndex: 'who_created',
            width: 200,
            sortOrder: sorting?.sort === 'who_created' ? SORT[sorting?.order] : null,
            render: (_, { who_created }) => {
              return (
                <MyDefaultTableText
                  text={Boolean(who_created) && who_created.fullName}
                />
              )
            },
          },
          {
            title: getText('WORD_EXECUTION_DATE_AND_TIME'),
            dataIndex: 'action_time',
            sorter: true,
            width: 200,
            sortOrder: sorting?.sort === 'action_time' ? SORT[sorting?.order] : null,
            render: (_, { action_time }) => {
              return <MyDefaultTableText text={formatDateTime(action_time)} />
            },
          },
          {
            title: getText('WORD_AUDIENCE'),
            dataIndex: 'total_customers_count',
            sorter: true,
            width: 100,
            sortOrder:
              sorting?.sort === 'total_customers_count'
                ? SORT[sorting?.order]
                : null,
            render: (_, { total_customers_count, emptyMessage }) => {
              return emptyMessage ? (
                <Tooltip title={emptyMessage}>
                  <MyDefaultTableText
                    color={'var(--red)'}
                    text={getText('WORD_EMPTY')}
                  />
                </Tooltip>
              ) : (
                <MyDefaultTableText
                  color={'var(--black)'}
                  fontWeight={500}
                  text={total_customers_count}
                />
              )
            },
          },
          {
            title: getText('WORD_DELIVERED'),
            dataIndex: 'reached_count',
            sorter: true,
            width: 100,
            sortOrder:
              sorting?.sort === 'reached_count' ? SORT[sorting?.order] : null,
            render: (_, { reached_count }) => {
              return (
                <MyDefaultTableText
                  color={'var(--black)'}
                  fontWeight={500}
                  text={reached_count}
                />
              )
            },
          },
          {
            title: getText('WORD_REPLY'),
            dataIndex: 'responded_count',
            sorter: true,
            width: 100,
            sortOrder:
              sorting?.sort === 'responded_count' ? SORT[sorting?.order] : null,
            render: (_, item) => {
              const responded_count = item.responded_count
              return (
                <MyDefaultTagBlock
                  disableMargin
                  fontSize={12}
                  width={'fit-content'}
                  title={responded_count}
                  titleColor={'var(--white)'}
                  backgroundColor={'var(--mainColor)'}
                  onClick={() => {
                    navigate('/messages?type=all', {
                      state: {
                        broadcast: item,
                        reply: true,
                      },
                    })
                  }}
                />
              )
            },
          },
          {
            title: getText('WORD_OPTED_OUT'),
            dataIndex: 'opted_out_count',
            sorter: true,
            width: 120,
            sortOrder:
              sorting?.sort === 'opted_out_count' ? SORT[sorting?.order] : null,
            render: (_, { opted_out_count, optedOuts, id, name }) => {
              return (
                <MyDefaultTableText
                  color={'var(--black)'}
                  fontWeight={500}
                  text={
                    <Flex gap={5}>
                      {opted_out_count}
                      {optedOuts.length > 0 && (
                        <SVGDownloadIconNew
                          onClick={async () => {
                            await broadcastingActions.downloadOptedOutCustomer(
                              id,
                              name
                            )
                          }}
                        />
                      )}
                    </Flex>
                  }
                />
              )
            },
          },
          {
            title: getText('WORD_OPPORTUNITIES'),
            dataIndex: 'opportunity_count',
            sorter: true,
            width: 130,
            sortOrder:
              sorting?.sort === 'opportunity_count' ? SORT[sorting?.order] : null,
            render: (_, item) => {
              const opportunity_count = item.opportunity_count
              return (
                <MyDefaultTagBlock
                  disableMargin
                  width={'fit-content'}
                  fontSize={12}
                  title={opportunity_count}
                  titleColor={'var(--white)'}
                  backgroundColor={'var(--mainColor)'}
                  onClick={() => {
                    navigate('/messages?type=all', {
                      state: {
                        broadcast: item,
                        opportunities: true,
                      },
                    })
                  }}
                />
              )
            },
          },
          {
            title: getText('WORD_RESPONSE'),
            dataIndex: 'response_percent',
            sorter: true,
            width: 100,
            sortOrder:
              sorting?.sort === 'response_percent' ? SORT[sorting?.order] : null,
            render: (_, { response_percent }) => {
              return (
                <MyDefaultTableText
                  color={'var(--black)'}
                  fontWeight={500}
                  text={response_percent ? `${response_percent}%` : '0%'}
                />
              )
            },
          },
          Table.EXPAND_COLUMN,
        ]}
        expandable
        expandedRowRender={(record) => {
          const broadcastLanguage = broadcastListUtils.getBroadcastLanguage(record)
          const broadcastTags = broadcastListUtils.getBroadcastTags(record)
          const broadcastLogicalTags =
            broadcastListUtils.getBroadcastLogicalTags(record)
          const broadcastAssignedUser =
            broadcastListUtils.getBroadcastAssignedUsers(record)
          const broadcastTouchpoints =
            broadcastListUtils.getBroadcastTouchpoints(record)
          return (
            <Flex gap={20} wrap>
              <BrodcastListExpandedItemMessage
                record={record}
                onUpdatedMessage={(_id, updatedMessage) => {
                  setData((oldData) => {
                    const find = oldData.list.find((item) => item._id === _id)
                    if (Boolean(find)) {
                      find.message = updatedMessage
                    }
                    return {
                      total: oldData.total,
                      list: [...oldData],
                    }
                  })
                }}
              />
              {record?.location && (
                <BrodcastListExpandedItem
                  title={getText('WORD_LOCATION')}
                  text={record?.location?.name}
                />
              )}
              {record?.excelDoc && (
                <BrodcastListExpandedItem
                  title={getText('WORD_UPLOADED_FILE')}
                  text={
                    <Flex align='center' gap={5}>
                      {record?.excelDoc?.endsWith('.csv')
                        ? getText('WORD_CSV_FILE')
                        : getText('WORD_EXCEL_FILE')}
                      <a href={record?.excelDoc} download>
                        <SVGDownloadIconNew />
                      </a>
                    </Flex>
                  }
                />
              )}
              <BrodcastListExpandedItem
                title={getText('WORD_OPTED_IN')}
                text={
                  <Flex align='center' gap={5}>
                    {!record?.send_consent ? (
                      <SVGReviewCheck width={14} height={14} />
                    ) : null}
                    {getText(!record?.send_consent ? 'WORD_YES' : 'WORD_NO')}
                  </Flex>
                }
              />
              {broadcastLanguage?.length > 0 && (
                <BrodcastListExpandedItem
                  title={getText('WORD_LANGUAGE')}
                  text={broadcastLanguage.map((lang) => getLongName(lang))}
                />
              )}
              {Boolean(record?.days_to_skip) && (
                <BrodcastListExpandedItem
                  title={getText(
                    'TEXT_EXCLUDE_CUSTOMERS_WHO_HAVE_BEEN_MESSAGED_IN_THE_LAST'
                  )}
                  text={`${record?.days_to_skip || 0} ${getText('WORD_DAYS').toLowerCase()}`}
                />
              )}
              {broadcastTags?.length > 0 && (
                <BrodcastListExpandedItem
                  title={getText('WORD_TAGS')}
                  list={broadcastTags}
                />
              )}
              {broadcastLogicalTags?.length > 0 && (
                <BrodcastListExpandedItem
                  title={getText('WORD_TAGS')}
                  list={broadcastLogicalTags.map((item, index) => item[index])}
                />
              )}
              {broadcastAssignedUser?.length > 0 && (
                <BrodcastListExpandedItem
                  title={getText('WORD_ASSIGNED_TO')}
                  list={broadcastAssignedUser}
                />
              )}
              {broadcastTouchpoints?.length > 0 && (
                <BrodcastListExpandedItem
                  title={getText('WORD_TOUCHPOINTS')}
                  list={broadcastTouchpoints}
                />
              )}
              {record?.workflow && (
                <BroadcastListExpandedItemWorkflow
                  title={getText('WORD_WORKFLOW')}
                  name={record?.workflow.name}
                  workflowData={record?.workflowData}
                />
              )}
            </Flex>
          )
        }}
      />
    )
  }, [data, loadingData, page])

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      disableHeader={!isFiltered && !data?.list?.length && !loadingData}
      isAdvanced
      headerProps={{
        left: [
          {
            type: SEARCH,
            onChange: (value) => {
              setLoadingData(true)
              setSearchInput(value)
              debouncedChange(value)
            },
            value: searchInput,
          },
          {
            type: SELECT,
            options: filterOption,
            value: filter?.status,
            placeholder: getText('TEXT_FILTER_BY_STATUS'),
            onChange: (value) => {
              setLoadingData(true)
              setFilter({
                ...filter,
                status: value,
              })
            },
            onClear: () => {
              setFilter({
                ...filter,
                status: '',
              })
            },
            isInDrawer: true,
          },
          {
            type: RANGEPICKER,
            value: Boolean(filter)
              ? filter?.date &&
                filter?.date.map((item) =>
                  item === '' ? item : dayjs(item, 'YYYY-MM-DD')
                )
              : undefined,
            onChange: (_, value) => {
              if (!value[0] && !value[1]) {
                if (Boolean(filter.date)) {
                  setFilter({
                    ...filter,
                    date: '',
                  })
                }
              } else {
                setFilter({
                  ...filter,
                  date: value,
                })
              }
            },
            isInDrawer: true,
          },
        ],
        right:
          createdBy === AUTOMATION
            ? []
            : [
                {
                  type: BUTTON,
                  title: getText('WORD_CREATE_CAMPAIGN'),
                  onClick: () => {
                    navigate('/broadcasting/create-campaign')
                  },
                },
              ],
        isDrawerExist: true,
        onClearAll: () => {
          setFilter('')
        },
      }}
    >
      <MyDefaultTableHeader
        title={getText('WORD_TOTAL_CAMPAIGNS')}
        disableRight
        totalCount={data?.total}
      />
      {BroadcastTableList}
    </MyDefaultPageLayout>
  )
}

export default BroadcastingList
