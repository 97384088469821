import React from 'react'
import { Space } from 'antd'
import { getText } from '../../../../lang'
import SVGCloseTag from '../../../../icons/SVG/SVGCloseTag'
import { DAY, NIGHT, ALL } from '../../../../devOptions'
import './MessageFilterView.scss'

const MessageLeftFilterView = ({ onClick, statuses }) => {
  const getStatusName = () => {
    const { dashboardClickableStats, broadcastFilter, opportunities } = statuses
    if (dashboardClickableStats.superhuman_influenced_walkins)
      return getText('TEXT_INFLUENCED_WALK_INS_BY_SUPERHUMAN')
    if (dashboardClickableStats.influenced_walkins)
      return getText('WORD_INFLUENCED_WALK_INS')
    if (dashboardClickableStats.superhuman_assisted_handoffs)
      return getText('WORD_SALES_HANDOFFS')
    if (dashboardClickableStats?.superhuman_appointments_type === NIGHT)
      return getText('TEXT_AFTER_HOURS')
    if (dashboardClickableStats?.superhuman_appointments_type === DAY)
      return getText('TEXT_WORKING_HOURS')
    if (dashboardClickableStats?.superhuman_appointments_type === ALL)
      return getText('WORD_TOTAL_CREATED_APPOINTMENTS')

    const list = []
    if (broadcastFilter.name) {
      list.push({
        title: getText('WORD_BROADCAST_NAME'),
        description: broadcastFilter.name,
      })
    }
    if (opportunities) {
      list.push({
        title: getText('WORD_FILTERED_BY'),
        description: getText('WORD_OPPORTUNITIES'),
      })
    }
    return list
  }

  const statusName = getStatusName()

  return (
    <div className='message_page_broadcast_filter'>
      <SVGCloseTag
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: '10px',
          top: '17px',
        }}
        color='#A3A6BE'
        width={10}
        height={10}
        onClick={onClick}
      />
      {Array.isArray(statusName) ? (
        statusName.map((item, index) => (
          <Space key={index} size={4}>
            <span>{item.title}:</span>
            <span>{item.description}</span>
          </Space>
        ))
      ) : (
        <Space size={4}>
          <span>{getText('WORD_FILTERED_BY')}:</span>
          <span>{statusName}</span>
        </Space>
      )}
    </div>
  )
}

export default MessageLeftFilterView
