import React, { useState } from 'react'
import { Typography, Divider, Collapse } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { APP_URL } from '../../../../../config'
import MatButton from '../../../../../components/MatButton'
import InputTextarea from '../../../../../components/InputTextarea'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import { getText } from '../../../../../lang'

const { Title, Text } = Typography
const CopySection = ({ label, text, isCopied, setIsCopy }) => (
  <MatBlockWithLabel label={label} className='text-area-copy-wrapper' black>
    <InputTextarea autoSize value={text} disabled />
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setIsCopy(true)
      }}
    >
      <MatButton
        size='small'
        htmlType='button'
        className='copy-button'
        buttonText={isCopied ? getText('WORD_COPIED') : getText('WORD_COPY')}
      />
    </CopyToClipboard>
  </MatBlockWithLabel>
)

const SubscriberGrowthInstructions = ({ locationId }) => {
  const [htmlCodeCopied, setHtmlCodeCopied] = useState(false)

  const getHtmlCode = () => {
    return `<!-- Start of Matador Subscriber Growth Script -->
    <script>
        (function(d, t) {
          var g = d.createElement(t),
            s = d.getElementsByTagName(t)[0];
          g.src = '${APP_URL}/_subscriber-growth.js';
          g.async = true;
          s.parentNode.insertBefore(g, s);
          g.onload = function() {
            subscriberGrowth("${locationId}");
          }
        })(document, "script");
    </script>
<!-- End of Matador Subscriber Growth Script -->`
  }

  const content = () => (
    <div>
      <Title level={4}>
        {getText('TEXT_INCLUDE_THE_FOLLOWING_CODE_ON_THE_WEBSITE')}
      </Title>
      <Text>
        {getText(
          'TEXT_TO_ENABLE_SUBSCRIBER_GROWTH_MODAL_ON_YOUR_WEBSITE_INCLUDE_THE_FOLLOWING_CODE_SNIPPET'
        )}
      </Text>
      <Divider />
      <CopySection
        text={getHtmlCode()}
        isCopied={htmlCodeCopied}
        setIsCopy={setHtmlCodeCopied}
      />

      <pre style={{ maxWidth: '100%', fontSize: '80%' }}>{}</pre>
      <Text>
        {getText(
          'TEXT_THIS_CODE_SNIPPET_WILL_LOAD_THE_NECESSARY_SCRIPT_FOR_SUBSCRIBER_GROWTH'
        )}
      </Text>
      <Divider />
      <Text strong>{getText('TEXT_TRIGGER_SUBSCRIBER_GROWTH_MODAL')}</Text>
      <br />
      <Text>
        {`${getText('TEXT_TO_TRIGGER_THE_SUBSCRIBER_GROWTH_MODAL_ADD_THE_CLASS')} `}
        <code>{getText('TEXT_SUBSCRIBER_GROWTH')}</code>{' '}
        {getText('TEXT_TO_ANY_HTML_ELEMENT_FOR_EXAMPLE')}
        <br />
      </Text>
      <pre style={{ maxWidth: '100%' }}>
        {`<div class="subscriber-growth btn btn-primary">
  ${getText('TEXT_IM_INTERESTED')}
</div>`}
      </pre>
      <Text>
        {getText('TEXT_BY_ADDING_THE')}{' '}
        <code>{getText('TEXT_SUBSCRIBER_GROWTH')}</code>{' '}
        {getText(
          'TEXT_CLASS_TO_AN_HTML_ELEMENT_YOU_MAKE_IT_A_TRIGGER_FOR_OPENING_THE_SUBSCRIBER_GROWTH_MODAL'
        )}
      </Text>
      <Divider />
      <Text>
        {`${getText('TEXT_IF_YOU_ENCOUNTER_ANY_ISSUES_OR_HAVE_QUESTIONS_ABOUT_THE_IMPLEMENTATION_CONTACT_OUR_SUPPORT_TEAM_AT')} `}
        <a href='mailto:support@matador.ai'>
          {getText('VEHICLE_DETAILS_SUPPORT_EMAIL')}.
        </a>
        {getText('TEXT_WE_RE_HERE_TO_HELP')}
      </Text>
    </div>
  )

  const items = [
    {
      key: '2',
      label: getText('TEXT_INSTRUCTIONS_TRIGGER_SUBSCRIBER_GROWTH_MODAL'),
      children: content(),
    },
  ]

  return <Collapse items={items} />
}

export default SubscriberGrowthInstructions
