import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import paymentsActions from '../../../store/modules/paymentsAction'
import { organizationSelector } from '../../../store/selectors/selectors'
import { getText, getTextServerError } from '../../../lang'
import SVGThreePointVerticalIcon from '../../../icons/SVG/SVGThreePointVerticalIcon'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import utilDate from '../../../utils/utilsDate'
import MatList from '../../../components/MatList'
import { notifyError } from '../../../utils/Notify'
import MatDropMenu from '../../../components/MatDropMenu'
import MatTable from '../../../components/MatTable'
import { getEmptyResult } from '../PaymentPageUtils'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import ReportMobileView from './ReportMobileView'

const Reports = ({ reportsOption }) => {
  const { isMobile } = useDeviceCheck()

  let organization = useSelector(organizationSelector)

  const [reportList, setReportList] = useState([])
  const [page, setPage] = useState(0)
  const [loadingData, setLoadingData] = useState(false)

  const fill = useCallback(async () => {
    let result
    if (reportsOption) {
      result = await paymentsActions.getPaymentReportListByDate({
        date: reportsOption,
        page: page,
        perPage: TABLE_DEFAULT_ROW_NUMBER,
      })
    } else {
      result = await paymentsActions.getPaymentReportList(
        page,
        TABLE_DEFAULT_ROW_NUMBER
      )
    }
    if (result.success) {
      const list = reportsOption ? [result.data] : result.data.items
      setReportList({
        list: list.map((item, index) => {
          Object.assign(item, {
            key: index,
          })
          return item
        }),
        total: result.data.total_items,
      })
    } else {
      getEmptyResult(result.errMsg, setReportList, {
        list: [],
        total: 0,
      })
      notifyError(getTextServerError(result.errMsg))
    }
    setLoadingData(false)
  }, [reportsOption, page, organization?._id])

  useEffect(() => {
    fill()
  }, [fill])

  const handleEditReport = async (val, singleItem) => {
    if (val === 'download') {
      const result = await paymentsActions.downloadPaymentReportItem(singleItem._id)
      if (result.success) {
        const link = document.createElement('a')
        link.href = `data:text/csv;charset=utf-8,${result.data}`
        link.download = singleItem.name
        document.body.appendChild(link)
        link.click()
        link.remove()
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    } else if (val === 'delete') {
      const result = await paymentsActions.deletePaymentReportItem(singleItem._id)
      if (result.success) {
        fill()
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    }
  }

  const scheduledDepositsColumn = [
    {
      title: getText('WORD_REPORT_NAME'),
      dataIndex: 'name',
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      },
      render: (_, { name }) => {
        return name
      },
    },
    {
      title: (
        <>
          {getText('WORD_DATE_TIME')} {getText('WORD_CREATED').toLowerCase()}
        </>
      ),
      dataIndex: 'updatedAt',
      sorter: (a, b) => {
        if (a.updatedAt < b.updatedAt) {
          return -1
        }
        if (a.updatedAt > b.updatedAt) {
          return 1
        }
        return 0
      },
      render: (_, { updatedAt }) => {
        return (
          <span className='payment-grey-item'>
            {updatedAt ? utilDate.getDateTimeForPayment(updatedAt) : ''}
          </span>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      render: (_, item) => {
        return (
          <MatDropMenu
            placement={'bottomRight'}
            showArrow={false}
            overlayClassName='organization-new-list-dot-drop-menu'
            overlayStyle={{ width: '100px' }}
            options={[
              {
                name: getText('WORD_DOWNLOAD'),
                value: 'download',
              },
              {
                name: getText('ACTION_DELETE'),
                value: 'delete',
              },
            ]}
            onChange={(val) => {
              handleEditReport && handleEditReport(val, item)
            }}
            trigger={
              <div className='payment-page-subscription-icon in-report-page'>
                <SVGThreePointVerticalIcon />
              </div>
            }
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        )
      },
    },
  ]

  return isMobile ? (
    <MatList
      loading={loadingData}
      data={reportList.list}
      pagination
      paginationCurrentPage={page}
      paginationTotalCount={reportList.total}
      defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
      onPaginationChange={setPage}
      containerClassName=''
      renderItem={(item) => {
        return (
          <ReportMobileView
            key={item._id}
            item={item}
            handleEditReport={handleEditReport}
          />
        )
      }}
    />
  ) : (
    <MatTable
      columns={scheduledDepositsColumn}
      dataSource={reportList.list}
      loading={loadingData}
      defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
      paginationCurrentPage={page}
      paginationTotalCount={reportList.total}
      onPaginationChange={setPage}
      className='reports-table'
      scroll={{
        x: 700,
      }}
    />
  )
}

export default Reports
