import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='35' height='35' rx='17.5' fill='#5B66EA' />
    <rect
      x='0.5'
      y='0.5'
      width='35'
      height='35'
      rx='17.5'
      stroke='url(#paint0_linear_22769_202696)'
    />
    <path
      d='M24.6468 22.2197C24.6468 22.4597 24.5935 22.7063 24.4802 22.9463C24.3668 23.1863 24.2202 23.413 24.0268 23.6263C23.7002 23.9863 23.3402 24.2463 22.9335 24.413C22.5335 24.5797 22.1002 24.6663 21.6335 24.6663C20.9535 24.6663 20.2268 24.5063 19.4602 24.1797C18.6935 23.853 17.9268 23.413 17.1668 22.8597C16.4002 22.2997 15.6735 21.6797 14.9802 20.993C14.2935 20.2997 13.6735 19.573 13.1202 18.813C12.5735 18.053 12.1335 17.293 11.8135 16.5397C11.4935 15.7797 11.3335 15.053 11.3335 14.3597C11.3335 13.9063 11.4135 13.473 11.5735 13.073C11.7335 12.6663 11.9868 12.293 12.3402 11.9597C12.7668 11.5397 13.2335 11.333 13.7268 11.333C13.9135 11.333 14.1002 11.373 14.2668 11.453C14.4402 11.533 14.5935 11.653 14.7135 11.8263L16.2602 14.0063C16.3802 14.173 16.4668 14.3263 16.5268 14.473C16.5868 14.613 16.6202 14.753 16.6202 14.8797C16.6202 15.0397 16.5735 15.1997 16.4802 15.353C16.3935 15.5063 16.2668 15.6663 16.1068 15.8263L15.6002 16.353C15.5268 16.4263 15.4935 16.513 15.4935 16.6197C15.4935 16.673 15.5002 16.7197 15.5135 16.773C15.5335 16.8263 15.5535 16.8663 15.5668 16.9063C15.6868 17.1263 15.8935 17.413 16.1868 17.7597C16.4868 18.1063 16.8068 18.4597 17.1535 18.813C17.5135 19.1663 17.8602 19.493 18.2135 19.793C18.5602 20.0863 18.8468 20.2863 19.0735 20.4063C19.1068 20.4197 19.1468 20.4397 19.1935 20.4597C19.2468 20.4797 19.3002 20.4863 19.3602 20.4863C19.4735 20.4863 19.5602 20.4463 19.6335 20.373L20.1402 19.873C20.3068 19.7063 20.4668 19.5797 20.6202 19.4997C20.7735 19.4063 20.9268 19.3597 21.0935 19.3597C21.2202 19.3597 21.3535 19.3863 21.5002 19.4463C21.6468 19.5063 21.8002 19.593 21.9668 19.7063L24.1735 21.273C24.3468 21.393 24.4668 21.533 24.5402 21.6997C24.6068 21.8663 24.6468 22.033 24.6468 22.2197Z'
      stroke='white'
      strokeMiterlimit='10'
    />
    <path
      d='M20.8203 15.1793L23.1803 12.8193'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M23.1803 15.1793L20.8203 12.8193'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_22769_202696'
        x1='0'
        y1='0'
        x2='38'
        y2='40.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.12' />
        <stop offset='1' stopColor='white' stopOpacity='0.04' />
      </linearGradient>
    </defs>
  </svg>
)

const SVGVoiceAiEmptyAnswerMissedCallsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGVoiceAiEmptyAnswerMissedCallsIcon
