import React, { memo } from 'react'
import { Radio } from 'antd'
import MyDefaultFormItem from '../../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultRadioTabs.scss'

const MyDefaultRadioTabs = ({
  className,
  value,
  disabled,
  onChange,
  isForm,
  required = false,
  name,
  label,
  hasFeedback,
  formStyle,
  initialValue,
  isLast,
  isFirst,
  optionType,
  buttonStyle,
  size,
  radioOptions,
}) => {
  return isForm ? (
    <MyDefaultFormItem
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={[
        {
          required: required,
          message: 'error radio button',
        },
      ]}
      style={formStyle}
      initialValue={initialValue}
      isLast={isLast}
      isFirst={isFirst}
    >
      <Radio.Group
        className={`my_default_radio_tabs ${className || ''}`}
        onChange={onChange}
        value={value}
        optionType={optionType}
        buttonStyle={buttonStyle}
        size={size}
        disabled={disabled}
      >
        {radioOptions.map((option) => {
          return (
            <Radio.Button
              disabled={option.disabled}
              value={option.value}
              key={option.value}
            >
              {option.icon}
              <span>{option.label}</span>
            </Radio.Button>
          )
        })}
      </Radio.Group>
    </MyDefaultFormItem>
  ) : (
    <Radio.Group
      className={`my_default_radio_tabs ${className || ''}`}
      onChange={(e) => {
        onChange && onChange(e.target.value)
      }}
      value={value}
      optionType={optionType}
      buttonStyle={buttonStyle}
      size={size}
      disabled={disabled}
    >
      {radioOptions.map((option) => {
        return (
          <Radio.Button
            disabled={option.disabled}
            value={option.value}
            key={option.value}
          >
            {option.icon}
            <span>{option.label}</span>
          </Radio.Button>
        )
      })}
    </Radio.Group>
  )
}

export default memo(MyDefaultRadioTabs)
