import React, { forwardRef } from 'react'
import { List } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { LoadingOutlined } from '@ant-design/icons'
import './ListLoadMore.scss'

const ListLoadMore = forwardRef((props, ref) => {
  const spinerLoading = {
    spinning: props.loading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} />,
  }

  return (
    <InfiniteScroll
      ref={ref}
      initialLoad={props.initialLoad}
      pageStart={props.pageStart}
      loadMore={props.onLoadMore}
      isReverse={props.isReverse}
      hasMore={!props.loading && props.data.length < props.totalDataCount}
      useWindow={props.useWindow}
      threshold={150}
      className={`mat-infinite-scroll ${props.inifiniteScrollClass || ''}`}
      getScrollParent={props.getScrollParent}
      height={props.height}
    >
      {props.customList || (
        <List
          header={props.header}
          footer={props.footer}
          renderItem={props.renderItem}
          dataSource={props.data}
          locale={props.locale}
          loading={spinerLoading}
          className={`mat-list-wrapper ${props.listClass || ''}`}
        />
      )}
    </InfiniteScroll>
  )
})

ListLoadMore.defaultProps = {
  pageStart: 0,
  loading: false,
  isReverse: false,
  onLoadMore: () => {},
  initialLoad: false,
  useWindow: false,
  data: [],
  renderItem: () => {
    return null
  },
  totalDataCount: 0,
}

export default ListLoadMore
