import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
  memo,
} from 'react'
import { useSelector } from 'react-redux'
import { organizationSelector } from '../../../store/selectors/selectors'
import organizationActions from '../../../store/modules/organizationActions'
import { getText } from '../../../lang'
import MatSelectSecondary from '../../../components/MatSelect/MatSelectSecondary'

const TouchpointsFilter = forwardRef((props, ref) => {
  const { onChangeSource } = props

  const organization = useSelector(organizationSelector)

  const [listSources, setlistSources] = useState([])
  const [listSourcesSelected, setlistSourcesSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      resetSource: () => {
        setlistSourcesSelected([])
        onChangeSource([])
      },
    }
  })

  const fill = useCallback(async () => {
    setLoading(true)
    let resultSource = await organizationActions.getSequenceSourceList(
      organization?._id
    )
    if (resultSource.success) {
      let list = resultSource.data.map((src) => {
        return { label: src.name, value: src._id, ...src }
      })
      setlistSources(list)
      setlistSourcesSelected([])
    }
    setLoading(false)
  }, [organization])

  useEffect(() => {
    if (open) {
      fill()
    }
  }, [fill, open])

  return (
    <div className='dropdown-with-selected-item'>
      <MatSelectSecondary
        label={getText('WORD_TOUCHPOINTS')}
        placeholder={getText('WORD_SELECT_ITEM')}
        options={listSources}
        value={listSourcesSelected}
        loading={loading}
        mode='multiple'
        multipleNewStyle
        onChangeItem={(val, items) => {
          onChangeSource(items)
          setlistSourcesSelected(
            items.map((item) => {
              return item.value
            })
          )
        }}
        onDropdownVisibleChange={() => setOpen(true)}
      />
    </div>
  )
})

export default memo(TouchpointsFilter)
