import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_18735_102989)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_18735_102989)'>
          <circle cx='18' cy='17' r='13' fill='#EE30B9' />
        </g>
        <path
          d='M24.6468 22.2202C24.6468 22.4602 24.5935 22.7068 24.4802 22.9468C24.3668 23.1868 24.2202 23.4135 24.0268 23.6268C23.7002 23.9868 23.3402 24.2468 22.9335 24.4135C22.5335 24.5802 22.1002 24.6668 21.6335 24.6668C20.9535 24.6668 20.2268 24.5068 19.4602 24.1802C18.6935 23.8535 17.9268 23.4135 17.1668 22.8602C16.4002 22.3002 15.6735 21.6802 14.9802 20.9935C14.2935 20.3002 13.6735 19.5735 13.1202 18.8135C12.5735 18.0535 12.1335 17.2935 11.8135 16.5402C11.4935 15.7802 11.3335 15.0535 11.3335 14.3602C11.3335 13.9068 11.4135 13.4735 11.5735 13.0735C11.7335 12.6668 11.9868 12.2935 12.3402 11.9602C12.7668 11.5402 13.2335 11.3335 13.7268 11.3335C13.9135 11.3335 14.1002 11.3735 14.2668 11.4535C14.4402 11.5335 14.5935 11.6535 14.7135 11.8268L16.2602 14.0068C16.3802 14.1735 16.4668 14.3268 16.5268 14.4735C16.5868 14.6135 16.6202 14.7535 16.6202 14.8802C16.6202 15.0402 16.5735 15.2002 16.4802 15.3535C16.3935 15.5068 16.2668 15.6668 16.1068 15.8268L15.6002 16.3535C15.5268 16.4268 15.4935 16.5135 15.4935 16.6202C15.4935 16.6735 15.5002 16.7202 15.5135 16.7735C15.5335 16.8268 15.5535 16.8668 15.5668 16.9068C15.6868 17.1268 15.8935 17.4135 16.1868 17.7602C16.4868 18.1068 16.8068 18.4602 17.1535 18.8135C17.5135 19.1668 17.8602 19.4935 18.2135 19.7935C18.5602 20.0868 18.8468 20.2868 19.0735 20.4068C19.1068 20.4202 19.1468 20.4402 19.1935 20.4602C19.2468 20.4802 19.3002 20.4868 19.3602 20.4868C19.4735 20.4868 19.5602 20.4468 19.6335 20.3735L20.1402 19.8735C20.3068 19.7068 20.4668 19.5802 20.6202 19.5002C20.7735 19.4068 20.9268 19.3602 21.0935 19.3602C21.2202 19.3602 21.3535 19.3868 21.5002 19.4468C21.6468 19.5068 21.8002 19.5935 21.9668 19.7068L24.1735 21.2735C24.3468 21.3935 24.4668 21.5335 24.5402 21.7002C24.6068 21.8668 24.6468 22.0335 24.6468 22.2202Z'
          stroke='white'
          strokeMiterlimit='10'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_18735_102989)'
      />
      <defs>
        <filter
          id='filter0_f_18735_102989'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_18735_102989'
          />
        </filter>
        <linearGradient
          id='paint0_linear_18735_102989'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_18735_102989'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashCallsTransferIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashCallsTransferIcon
