import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
  memo,
} from 'react'
import { useSelector } from 'react-redux'
import {
  organizationSelector,
  userSelector,
} from '../../../store/selectors/selectors'
import userActions from '../../../store/modules/userActions'
import { getText } from '../../../lang'
import { GET_DATA_FETCH_SIZE } from '../../../utils'
import { getUsersListIds } from '../../broadcast/CreateCampaign/CreateSegment/utils'
import MatSelectSecondary from '../../../components/MatSelect/MatSelectSecondary'

const AssignUserFilter = forwardRef((props, ref) => {
  const { onChangeUser } = props

  const organization = useSelector(organizationSelector)
  const user = useSelector(userSelector)

  const [listUsers, setListUsers] = useState([])
  const [listUserSelected, setlistUserSelected] = useState([])
  const [assignSearchKeyword, setAssignSearchKeyword] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      resetAssignedUser: () => {
        setlistUserSelected([])
        onChangeUser([])
      },
    }
  })

  const searchUsers = useCallback(async () => {
    setLoading(true)
    const result = await userActions.getUsersForAssignUnassign(
      page,
      GET_DATA_FETCH_SIZE,
      user._organization_id,
      assignSearchKeyword
    )
    let stateValue = {}
    if (result.success) {
      const newListUsers =
        page > 0 ? [...listUsers.list, ...result.data] : result.data
      stateValue = {
        list: getUsersListIds(newListUsers),
        max: result.max,
      }
    }
    setListUsers(stateValue)
    setLoading(false)
  }, [assignSearchKeyword, organization, page])

  useEffect(() => {
    if ((user.isAdmin || user.isManager) && open) {
      searchUsers()
    }
  }, [searchUsers, open])

  return (
    <div className='dropdown-with-selected-item'>
      <MatSelectSecondary
        usePreviousValueOnChange
        label={getText('WORD_ASSIGNED_USER')}
        placeholder={getText('WORD_SELECT_ITEM')}
        options={listUsers.list || []}
        value={listUserSelected}
        onLoadMore={() => {
          if (listUsers.list.length < listUsers.max) {
            setPage(page + 1)
          }
        }}
        loading={loading}
        mode='multiple'
        multipleNewStyle
        onSearch={(search) => setAssignSearchKeyword(search)}
        showSearch={true}
        onChangeItem={(e, val) => {
          onChangeUser(e)
          setlistUserSelected(val)
          setAssignSearchKeyword('')
        }}
        onClear={() => {
          setAssignSearchKeyword('')
        }}
        onDropdownVisibleChange={() => setOpen(true)}
      />
    </div>
  )
})

export default memo(AssignUserFilter)
