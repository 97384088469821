import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Flex, Tooltip } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import MessageAssignSelector from '../MessageAssignUsersSelector/MessageAssignSelector'
import SVGArrowLeft from '../../../../../icons/SVG/SVGArrowLeft'
import ProfileLetters from '../../../../../components/ProfileLetters'
import { checkFormatPhoneNumber, getCustomerName } from '../../../../../utils'
import SVGMaximizeMobileIcon from '../../../../../icons/SVG/SVGMaximizeMobileIcon'
import MessageCenterArchiveButton from '../MessageArchiveAction/MessageCenterArchiveButton'
import MessageCenterHeaderCRMs from '../MessageCRMAction/MessageCenterHeaderCRMs'
import MessageCenterInterest from '../MessageIntents/MessageCenterInterest'
import CenterAISummerize from '../../centerMessageBody/CenterMessageItems/CenterAISummerize'
import { BROADCAST } from '../../../utils/constants'
import { notifySuccess } from '../../../../../utils/Notify'
import { getText } from '../../../../../lang'
import SVGCopyIcon from '../../../../../icons/SVG/SVGCopyIcon'
import { GREEN, RED, YELLOW } from '../../../../../devOptions'
import SVGCustomerRefusedIcon from '../../../../../icons/SVG/SVGCustomerRefusedIcon'
import SVGCustomerPendingIcon from '../../../../../icons/SVG/SVGCustomerPendingIcon'
import SVGCustomerApprovedIcon from '../../../../../icons/SVG/SVGCustomerApprovedIcon'
import { aiSummarySelector } from '../../../../../store/selectors/selectors'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'
import './MessageCenterMain.scss'

const MessageCenterHeader = ({
  removeConversationFromList,
  closeCenterDrawer,
  messageType,
  conversation,
  addToList,
  showRightBox,
  refreshConversation,
  isBlueExtension,
  additionalHeader,
}) => {
  let aiSummary = useSelector(aiSummarySelector)

  const { isMobileOrTabletOrTabletPortrait, isTabletPortraitOrMobile, isDesktop } =
    useDeviceCheck()

  const receiver = conversation?.receiver
  const receiverName = getCustomerName(conversation)
  const receiverEmail = receiver?.email || ''
  const receiverPhone = checkFormatPhoneNumber(receiver?.phone)

  const getCustomerStatus = (status) => {
    switch (status) {
      case RED:
        return {
          icon: <SVGCustomerRefusedIcon />,
          text: getText('WORD_OPTED_OUT'),
        }
      case YELLOW:
        return {
          icon: <SVGCustomerPendingIcon />,
          text: getText('WORD_PENDING'),
        }
      case GREEN:
        return {
          icon: <SVGCustomerApprovedIcon />,
          text: getText('WORD_OPTED_IN'),
        }
      default:
        break
    }
  }

  const customerStatusMain = getCustomerStatus(
    receiverName.includes('@')
      ? receiver?.status?.email?.state === YELLOW
        ? null
        : receiver?.status?.email?.state
      : receiver?.status?.state
  )
  const customerStatusPhone = getCustomerStatus(receiver?.status?.state)
  const customerStatusEmail = getCustomerStatus(
    receiver?.status?.email?.state === YELLOW ? null : receiver?.status?.email?.state
  )

  return (
    <React.Fragment>
      <div className='message_page_center_panel_header'>
        <div className={'message_page_center_panel_header--header'}>
          {!isMobileOrTabletOrTabletPortrait ? (
            <React.Fragment>
              <div className='message_page_center_panel_header--header--left'>
                <div className='message_page_center_panel_header--header--left-name-block'>
                  <ProfileLetters
                    className='contact-profile-letters medium'
                    name={receiverName}
                    withoutIcon
                  />
                  <span className='name'>{receiverName}</span>
                  {!isDesktop ? (
                    <SVGMaximizeMobileIcon
                      className={'show_right_panel'}
                      onClick={showRightBox}
                    />
                  ) : null}
                  {!conversation.isNew && (
                    <MessageCenterHeaderCRMs
                      conversation={conversation}
                      addToList={addToList}
                      refreshConversation={refreshConversation}
                    />
                  )}
                </div>
              </div>
              <div className='message_page_center_panel_header--header--right'>
                <MessageAssignSelector
                  messageType={messageType}
                  conversation={conversation}
                  removeConversationFromList={removeConversationFromList}
                />
                <MessageCenterArchiveButton
                  conversation={conversation}
                  messageType={messageType}
                  removeConversationFromList={removeConversationFromList}
                />
                {messageType !== BROADCAST && !conversation.isNew && (
                  <CenterAISummerize
                    conversation={conversation}
                    aiSummaryMessage={aiSummary}
                  />
                )}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='message_page_center_panel_header--header--left'>
                {!isBlueExtension && isTabletPortraitOrMobile ? (
                  <SVGArrowLeft
                    className='drawer_close_icon'
                    color={'var(--blue)'}
                    onClick={closeCenterDrawer}
                  />
                ) : null}
                <div className='message_page_center_panel_header--header--left-name-block'>
                  <Flex align={isTabletPortraitOrMobile ? 'start' : 'center'}>
                    {!isTabletPortraitOrMobile ||
                      (isBlueExtension && (
                        <ProfileLetters
                          className='contact-profile-letters medium'
                          name={receiverName}
                          withoutIcon
                        />
                      ))}
                    <div>
                      <span className='name'>
                        {!receiver?.fullName ? (
                          <div className='customer_contact'>
                            <Tooltip
                              title={customerStatusMain?.text}
                              destroyTooltipOnHide={true}
                              trigger={['hover', 'click']}
                              placement='right'
                            >
                              <div className='customer_contact--icon'>
                                {customerStatusMain?.icon}
                              </div>
                            </Tooltip>
                            <span>{receiverName}</span>
                            <CopyToClipboard
                              text={receiverName}
                              onCopy={() => {
                                if (receiverName.includes('@')) {
                                  notifySuccess(getText('WORD_EMAIL_COPIED'))
                                } else {
                                  notifySuccess(getText('WORD_PHONE_COPIED'))
                                }
                              }}
                            >
                              <SVGCopyIcon />
                            </CopyToClipboard>
                          </div>
                        ) : (
                          receiverName
                        )}
                      </span>
                      {isTabletPortraitOrMobile && (
                        <React.Fragment>
                          {receiver?.fullName && receiverPhone && (
                            <div className='customer_contact'>
                              <Tooltip
                                title={customerStatusPhone?.text}
                                destroyTooltipOnHide={true}
                                trigger={['hover', 'click']}
                                placement='right'
                              >
                                <div className='customer_contact--icon'>
                                  {customerStatusPhone?.icon}
                                </div>
                              </Tooltip>
                              <span>{receiverPhone || '-'}</span>
                              <CopyToClipboard
                                text={receiverPhone}
                                onCopy={() => {
                                  notifySuccess(getText('WORD_PHONE_COPIED'))
                                }}
                              >
                                <SVGCopyIcon />
                              </CopyToClipboard>
                            </div>
                          )}
                          {receiverEmail && receiverName !== receiverEmail && (
                            <div className='customer_contact'>
                              {Boolean(receiver?.status?.email) &&
                                receiver?.status?.email?.state !== YELLOW && (
                                  <Tooltip
                                    title={customerStatusEmail?.text}
                                    destroyTooltipOnHide={true}
                                    trigger={['hover', 'click']}
                                    placement='right'
                                  >
                                    <div className='customer_contact--icon'>
                                      {customerStatusEmail?.icon}
                                    </div>
                                  </Tooltip>
                                )}
                              <span>{receiverEmail}</span>
                              <CopyToClipboard
                                text={receiverEmail}
                                onCopy={() => {
                                  notifySuccess(getText('WORD_EMAIL_COPIED'))
                                }}
                              >
                                <SVGCopyIcon />
                              </CopyToClipboard>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </Flex>
                  <Flex align='center'>
                    <MessageAssignSelector
                      messageType={messageType}
                      conversation={conversation}
                      removeConversationFromList={removeConversationFromList}
                    />
                    {!isDesktop && (
                      <SVGMaximizeMobileIcon
                        className={'show_right_panel'}
                        onClick={showRightBox}
                      />
                    )}
                  </Flex>
                </div>
              </div>
              <div className='message_page_center_panel_header--header--right'>
                {!conversation.isNew && (
                  <MessageCenterHeaderCRMs
                    conversation={conversation}
                    addToList={addToList}
                    refreshConversation={refreshConversation}
                  />
                )}
                <Flex align='center' style={{ marginLeft: 'auto' }}>
                  <MessageCenterArchiveButton
                    conversation={conversation}
                    messageType={messageType}
                    removeConversationFromList={removeConversationFromList}
                  />
                  {messageType !== BROADCAST && !conversation.isNew && (
                    <CenterAISummerize
                      conversation={conversation}
                      aiSummaryMessage={aiSummary}
                    />
                  )}
                </Flex>
              </div>
            </React.Fragment>
          )}
        </div>
        <MessageCenterInterest conversation={conversation} />
      </div>
      {additionalHeader}
    </React.Fragment>
  )
}

export default memo(MessageCenterHeader)
