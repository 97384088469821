import React from 'react'
import { Typography, Divider, Collapse } from 'antd'
import { getText } from '../../../../../lang'

const { Title, Text } = Typography

const MatadorConnectVehiclePropertiesInstructions = () => {
  const content = () => (
    <div>
      <Title level={5}>
        {getText('VEHICLE_DETAILS_INTEGRATE_VEHICLE_DETAILS_WITH_MATADOR_CONNECT')}
      </Title>
      <Text>
        {getText(
          'VEHICLE_DETAILS_MATADOR_CONNECT_ENABLES_AUTOMATIC_INCLUSION_OF_KEY_VEHICLE_INFORMATION'
        )}
      </Text>
      <Divider />
      <Text strong>
        {getText('VEHICLE_DETAILS_USING_STRUCTURED_DATA_FOR_SEAMLESS_INTEGRATION')}
      </Text>
      <br />
      <Text>
        {getText('VEHICLE_DETAILS_TO_MAKE_THE_MOST_OF_MATADOR_CONNECT')}
        <a href='https://schema.org/Car' target='_blank' rel='noopener noreferrer'>
          {' '}
          {getText('VEHICLE_DETAILS_SCHEMA_ORG_CAR_SCHEMA')}
        </a>
        {getText('VEHICLE_DETAILS_MATADOR_CONNECT_WILL_AUTOMATICALLY_DETECT')}
      </Text>
      <Divider />
      <Text>
        {getText('VEHICLE_DETAILS_FOR_FURTHER_ASSISTANCE')}{' '}
        <a href='mailto:support@matador.ai'>
          {getText('VEHICLE_DETAILS_SUPPORT_EMAIL')}
        </a>
        .
      </Text>
    </div>
  )

  const items = [
    {
      key: '1',
      label: getText(
        'VEHICLE_DETAILS_INTEGRATING_MATADOR_CONNECT_WITH_VEHICLE_DETAILS'
      ),
      children: content(),
    },
  ]

  return <Collapse items={items} />
}

export default MatadorConnectVehiclePropertiesInstructions
