import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' width='24' height='24' rx='12' fill='#EEEFFC' />
    <path
      d='M8.75 11.125C8.75 10.8834 8.94588 10.6875 9.1875 10.6875H10.9375C11.1791 10.6875 11.375 10.8834 11.375 11.125C11.375 11.3666 11.1791 11.5625 10.9375 11.5625H9.1875C8.94588 11.5625 8.75 11.3666 8.75 11.125Z'
      fill='#5B66EA'
    />
    <path
      d='M9.1875 12.4375C8.94588 12.4375 8.75 12.6334 8.75 12.875C8.75 13.1166 8.94588 13.3125 9.1875 13.3125H13.5625C13.8041 13.3125 14 13.1166 14 12.875C14 12.6334 13.8041 12.4375 13.5625 12.4375H9.1875Z'
      fill='#5B66EA'
    />
    <path
      d='M14.4375 9.375C14.1959 9.375 14 9.57087 14 9.8125C14 10.0541 14.1959 10.25 14.4375 10.25H16.1875C16.4291 10.25 16.625 10.0541 16.625 9.8125C16.625 9.57087 16.4291 9.375 16.1875 9.375H14.4375Z'
      fill='#5B66EA'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5625 7.1875C6.5625 6.46263 7.15013 5.875 7.875 5.875H14C14.9369 5.875 15.7362 6.464 16.0479 7.29191C17.1397 7.60991 17.9375 8.61804 17.9375 9.8125C17.9375 10.9554 17.2071 11.9278 16.1875 12.2881V15.9375C16.1875 17.1456 15.2081 18.125 14 18.125H7.875C7.15013 18.125 6.5625 17.5374 6.5625 16.8125V7.1875ZM14 6.75C14.3978 6.75 14.7543 6.92698 14.995 7.20651C13.695 7.36325 12.6875 8.47022 12.6875 9.8125C12.6875 11.2622 13.8628 12.4375 15.3125 12.4375V14.625H7.4375V7.1875C7.4375 6.94588 7.63338 6.75 7.875 6.75H14ZM15.3125 15.9375V15.5H7.4375V16.8125C7.4375 17.0541 7.63338 17.25 7.875 17.25H14C14.7249 17.25 15.3125 16.6624 15.3125 15.9375ZM13.5625 9.8125C13.5625 8.846 14.346 8.0625 15.3125 8.0625C16.279 8.0625 17.0625 8.846 17.0625 9.8125C17.0625 10.779 16.279 11.5625 15.3125 11.5625C14.346 11.5625 13.5625 10.779 13.5625 9.8125Z'
      fill='#5B66EA'
    />
  </svg>
)

const SVGMessageUnreadIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMessageUnreadIcon
