import moment from 'moment'
import utilDate from '../../../utils/utilsDate'

export const FINISHED = 'Finished'
export const CANCELED = 'Canceled'
export const SCHEDULED = 'Scheduled'
export const IN_PROGRESS = 'In progress'

const broadcastListUtils = {
  getBroadcastLanguage: (item) => {
    let result = item?.segmentFilters?.language || []
    return result
  },
  getDiffMinutesForBroadcast: (action_time) => {
    const actionTime = moment(action_time)
    const currentTime = utilDate.getDateUTC()
    return actionTime.diff(currentTime, 'minutes')
  },
  getBroadcastTouchpoints: (item) => {
    let result = item?.segmentFilters?.source || []
    if (result.length) {
      result = result.map((item) => {
        return {
          name: item,
        }
      })
    }
    return result
  },
  getBroadcastLogicalTags: (item) => {
    let result = item?.segmentFilters?.logicalTags || []
    return result
  },
  getBroadcastAssignedUsers: (item) => {
    let result = item?.segmentFilters?.assigned_to
      ? item?.segmentFilters?.assigned_to
      : item?.users_to_assign?.length > 0
        ? item?.users_to_assign
        : []
    if (result.length) {
      result = result.map((item) => {
        return {
          ...item,
          name: item.fullName,
        }
      })
    }
    return result
  },
  getBroadcastTags: (item) => {
    let result = item?.segmentFilters?.tags
      ? item?.segmentFilters?.tags
      : item?.excelDoc && item?.tags_to_assign?.length > 0
        ? item?.tags_to_assign
        : []
    return result
  },
}

export default broadcastListUtils
