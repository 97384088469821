import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Form } from 'antd'
import { debounce } from 'lodash'
import { createCustomFooter } from '../../utils'
import userActions from '../../../../../../store/modules/userActions'
import { getUsersListIds } from '../../../CreateSegment/utils'
import BroadcastCampaignPage from '../../../../BroadcastCampaignPage'
import { getText } from '../../../../../../lang'
import SelectChecked from '../../../../../../components/Form/SelectChecked'
import MatDatePicker from '../../../../../../components/Form/MatDatePicker'
import MatTimePicker from '../../../../../../components/Form/MatTimePicker'
import MatRow from '../../../../../../components/MatRow'
import { GET_DATA_FETCH_SIZE, isAustralianOrg } from '../../../../../../utils'
import MatForm from '../../../../../../components/Form/MatForm'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import UtcTimezoneMessage from '../../../../../../components/api/UtcTimezoneMessage'
import utilDate from '../../../../../../utils/utilsDate'
import campaignActions from '../../../../../../store/modules/broadcastingActions'
import MatFormCheckbox from '../../../../../../components/Form/MatFormCheckbox'
import FormButtons from '../FormButtons/FormButtons'
import SelectFromTagsList from '../../../../../../components/api/SelectFromTagsList'
import { organizationSelector } from '../../../../../../store/selectors/selectors'
import useDeviceCheck from '../../../../../../utils/useDeviceCheck'

const Scheduler = React.memo(
  ({
    onChangeStep,
    onStateChange,
    user,
    date,
    users_to_assign,
    tags_to_assign,
    time,
    usersList,
    checkBoxValue,
    restrictions,
  }) => {
    const { isMobile } = useDeviceCheck()

    const organization = useSelector(organizationSelector)

    const restrictSendingBroadcastsOverTheWeekend =
      restrictions?.restrictSendingBroadcastsOverTheWeekend || false

    const [form] = Form.useForm()
    const [page, setPage] = useState(0)
    const [lastAvailableDay, setLastAvailableDay] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
    const [initialValues, setInitialValues] = useState({})
    const [unavailableTime, setUnavailableTime] = useState([])
    const [assignSearchKeyword, setAssignSearchKeyword] = useState('')
    const [listUsers, setListUsers] = useState({})

    const loadUsers = useCallback(async () => {
      const result = await userActions.getUsersForAssignUnassign(
        page,
        GET_DATA_FETCH_SIZE,
        user._organization_id,
        assignSearchKeyword
      )
      if (result.success) {
        const newListUsers =
          page > 0 ? [...listUsers.list, ...result.data] : result.data
        let stateValue = {
          users: getUsersListIds(newListUsers),
          maxUsers: result.max,
        }
        onStateChange('usersList', stateValue)
        setListUsers({
          list: stateValue.users,
          max: stateValue.maxUsers,
        })
      }
    }, [assignSearchKeyword, page])

    useEffect(() => {
      ;(async () => {
        let defaultData = {
          users_to_assign: users_to_assign,
          tags_to_assign: tags_to_assign,
          checkBoxValue: checkBoxValue,
        }
        if (usersList || usersList?.users?.length > 0) {
          setListUsers({
            list: usersList.users,
            max: usersList.maxUsers,
          })
        }
        if (date) {
          defaultData.schedulerDate = dayjs(date)
          defaultData.schedulerTime = dayjs(time, 'h:mm A')
        }
        setInitialValues(defaultData)
        setTimeout(() => {
          setIsLoading(false)
        }, 10)
      })()
    }, [])

    const debouncedChange = useCallback(
      debounce((options) => setAssignSearchKeyword(options), 1000),
      []
    )

    useEffect(() => {
      loadUsers()
    }, [loadUsers])

    useEffect(() => {
      ;(async () => {
        let result = await campaignActions.getUnavailableTime(user.organization?._id)

        if (result.success) {
          let list = result.data.filter((item) => {
            return (
              dayjs(item.action_time).format('DD-MM-YYYY') ===
              dayjs(date).format('DD-MM-YYYY')
            )
          })
          setUnavailableTime(
            list.map((tim) => {
              return dayjs(tim.action_time)
            })
          )
        }
      })()
    }, [date])

    const checkDisabled = (time) => {
      let find = unavailableTime.find((tim) => {
        return (
          dayjs(time).format('dd-mm-yyyy hh:mm A') ===
          dayjs(tim).format('dd-mm-yyyy hh:mm A')
        )
      })
      return Boolean(find)
    }

    useEffect(() => {
      if (date) {
        let now = utilDate.getDateDaysJSUTC()
        let isToday = dayjs(date).isSame(now, 'day')
        let startHH = isToday ? now.hour() : 9
        let endHH = isAustralianOrg() ? 19 : 20
        let items = []
        let minutesItems = [0, 15, 30, 45]
        for (let hour = startHH; hour <= endHH; hour++) {
          if (hour >= 9 && hour <= endHH) {
            if (hour === endHH) {
              items.push([hour, 0])
            }
            if (hour < endHH && hour === startHH && isToday) {
              for (let minute = 0; minute < minutesItems.length; minute++) {
                if (now.minute() < minutesItems[minute]) {
                  items.push([hour, minutesItems[minute]])
                }
              }
            } else if (hour < endHH && !isToday) {
              for (let minute = 0; minute < minutesItems.length; minute++) {
                items.push([hour, minutesItems[minute]])
              }
            } else if (hour < endHH && hour !== startHH && isToday) {
              for (let minute = 0; minute < minutesItems.length; minute++) {
                items.push([hour, minutesItems[minute]])
              }
            }
          }
        }
        const available = items.map((time) => {
          const [hour, minute] = time
          return dayjs(date).hour(hour).minute(minute)
        })
        setLastAvailableDay(available)
      }
    }, [date])

    useEffect(() => {
      if (lastAvailableDay && lastAvailableDay.length) {
        const availableTime = lastAvailableDay.find((time) => !checkDisabled(time))

        handleChangeTime(availableTime)
      } else {
        handleChangeTime('')
      }
    }, [unavailableTime])

    const handleChangeTime = (item) => {
      if (item === '' || !dayjs(item, 'HH:mm').isValid()) {
        form && form.setFieldsValue({ schedulerTime: '' })
      } else {
        form && form.setFieldsValue({ schedulerTime: dayjs(item, 'h:mm A') })
      }
      onStateChange('time', dayjs(item, 'h:mm A'))
      setTimeout(() => {
        setIsOpenTimePicker(false)
      }, 0)
    }

    return (
      <BroadcastCampaignPage title={getText('WORD_SCHEDULER')}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <MatForm
            form={form}
            name='formSaveCustomerScheduler'
            onFinish={() => {
              onChangeStep(1)
            }}
            onFinishFailed={() => {}}
            initialValues={initialValues}
          >
            <div className='create-campaign-form-wrapper ant-form-vertical'>
              <SelectChecked
                mode='multiple'
                multipleNewStyle
                name='users_to_assign'
                label={getText('TEXT_ASSIGN_CAMPAIGN_TO')}
                placeholder={getText('ACTION_SELECT_A_USER')}
                option={listUsers.list || []}
                showSearch={true}
                onSearch={debouncedChange}
                onChange={(usersId) => onStateChange('users_to_assign', usersId)}
                onLoadMore={() => {
                  if (listUsers.list.length < listUsers.max) {
                    setPage(page + 1)
                  }
                }}
                allowClear={true}
                onClear={() => setAssignSearchKeyword('')}
              />
              <MatRow flexStart style={{ position: 'relative' }}>
                <MatDatePicker
                  name={'schedulerDate'}
                  onChange={(a, dateString) => {
                    onStateChange('date', dateString)
                  }}
                  label={getText('WORD_DATE')}
                  required={true}
                  message={getText('WORD_PLEASE_SELECT_DATE')}
                  disabledDate={(current) => {
                    if (
                      (organization.workingHours ||
                        organization.customWorkingHours) &&
                      current
                    ) {
                      const currentDay = utilDate.getDateDaysJSUTC('', 'YYYY-MM-DD')
                      const differenceInDays = dayjs(current).diff(currentDay, 'day')
                      const isSameDay = dayjs(current).isSame(
                        utilDate.getDateDaysJSUTC(),
                        'day'
                      )

                      // Not allows days before today and when hours >= 4 PM
                      if (differenceInDays <= 0 && !isSameDay) {
                        return true
                      }

                      // If broadcast restricted on weekends and the day is weekend we disable.
                      if (
                        restrictSendingBroadcastsOverTheWeekend &&
                        utilDate.isWeekend(current)
                      ) {
                        return true
                      }

                      return false

                      // // Finally, we have to check is organization open at selected day
                      // const findItem = (organization?.customWorkingHours || []).find(
                      //   (item) =>
                      //     dayjs(current).format('YYYY-MM-DD') ===
                      //     dayjs(item.date).format('YYYY-MM-DD')
                      // )
                      // if (Boolean(findItem)) {
                      //   return !findItem.isOpen
                      // }
                      // return !organization.workingHours[dayjs(current).format('dddd')]
                      //   .isOpen
                    }
                  }}
                />
                <MatTimePicker
                  formStyle={{ marginRight: '0' }}
                  isOpenTimePicker={isOpenTimePicker}
                  name={'schedulerTime'}
                  panelRender={() =>
                    createCustomFooter(
                      lastAvailableDay || [],
                      handleChangeTime,
                      checkDisabled
                    )
                  }
                  hideDisabledOptions={true}
                  onOpen={(value) => {
                    setIsOpenTimePicker(value)
                  }}
                  inputReadOnly={true}
                  label={getText('WORD_TIME')}
                  format={'h:mm A'}
                  required={true}
                  message={
                    dayjs(time, 'HH:mm').isValid()
                      ? getText('WORD_PLEASE_SELECT_TIME')
                      : getText('TEXT_ALL_TIME_ZONES_UNAVAILABLE')
                  }
                  disabled={!date}
                />
                <UtcTimezoneMessage
                  style={{
                    position: isMobile ? 'relative' : 'absolute',
                    bottom: isMobile ? '7px' : '-35px',
                    right: isMobile ? '0' : '-12px',
                    maxWidth: isMobile ? '100%' : '222px',
                  }}
                  title={getText('TEXT_BROADCAST_TIME_ZONE')}
                />
              </MatRow>
              <MatFormCheckbox
                name={'checkBoxValue'}
                label={getText('TEXT_ADD_A_TAG_FOR_BROADCASTED_USERS')}
                style={{
                  maxWidth: '260px',
                }}
                value={checkBoxValue}
                onChange={(e) => {
                  onStateChange('checkBoxValue', e)
                }}
              />
              {checkBoxValue && (
                <SelectFromTagsList
                  multipleNewStyle
                  name={'tags_to_assign'}
                  mode='multiple'
                  label={getText('WORD_TAGS')}
                  onChange={(tagsId) => onStateChange('tags_to_assign', tagsId)}
                />
              )}
              <FormButtons
                prevAction={onChangeStep}
                currentFormName='formSaveCustomerScheduler'
                submitFormBtn={getText('WORD_NEXT')}
                cancelFormBtn={getText('WORD_PREVIOUS')}
              />
            </div>
          </MatForm>
        )}
      </BroadcastCampaignPage>
    )
  }
)

export default Scheduler
