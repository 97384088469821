import React from 'react'
import DropMeuManagers from './DropMeuManagers'

const AssignManager = ({ conversation, refreshConversation, addToList }) => {
  return conversation.isNew ? null : (
    <div className='message_page_assign_user_list'>
      <DropMeuManagers
        conversation={conversation}
        refreshConversation={refreshConversation}
        addToList={addToList}
      />
    </div>
  )
}

export default React.memo(AssignManager)
