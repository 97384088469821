import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='15' cy='15' r='15' fill={props.backgroundcolor || '#FCF1D3'} />
    <path
      d='M11.1582 14.3118H12.2396L11.698 12.5537L11.1582 14.3118Z'
      fill={props.color || '#EA9957'}
    />
    <path
      d='M13.1761 8.9502H9.47891C9.08665 8.9502 8.71052 9.1061 8.43313 9.38346C8.15577 9.6607 8 10.0368 8 10.4291V22.9996L12.4366 18.563H15.7641L13.1761 8.9502ZM12.4663 15.0506H10.9301L10.588 16.1598H9.84863L11.3275 11.3534H12.0669L13.5458 16.1598H12.8065L12.4663 15.0506Z'
      fill={props.color || '#EA9957'}
    />
    <path
      d='M21.3104 8.95026H13.916L16.5041 18.563H21.3105C21.7028 18.563 22.0789 18.4071 22.3563 18.1297C22.6336 17.8525 22.7894 17.4763 22.7894 17.0841V10.4291C22.7894 10.0368 22.6336 9.66072 22.3563 9.38346C22.0789 9.1061 21.7028 8.9502 21.3105 8.9502L21.3104 8.95026ZM18.7224 11.3535H19.4618V12.0928L18.7224 12.093V11.3535ZM20.9407 16.1598C20.2654 16.1658 19.5962 16.0336 18.9738 15.7716C18.4331 16.0279 17.842 16.1606 17.2435 16.1598V15.4203C17.5562 15.4211 17.8674 15.3775 18.1678 15.291C17.5966 14.8362 17.2435 14.2392 17.2435 13.5719H17.9829C17.9829 14.1043 18.3674 14.5904 18.9664 14.9324C19.7139 14.4935 20.1811 13.699 20.2013 12.8324H17.2435V12.093H20.9407V12.8324C20.9292 13.7764 20.4975 14.6663 19.7631 15.2595C20.1464 15.3672 20.5426 15.4214 20.9407 15.4203L20.9407 16.1598Z'
      fill={props.color || '#EA9957'}
    />
  </svg>
)

const SVGLanguage = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGLanguage
