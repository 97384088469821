import React from 'react'
import { Flex } from 'antd'
import { getText } from '../../lang'
import SVGMyDefaultDeleteIcon from '../../icons/SVG/SVGMyDefaultDeleteIcon'
import ConfirmPopup from '../ConfirmPopup'
import Div from '../Div/Div'

const MyDefaultAddAutoReplyMessageItem = ({
  icon,
  title,
  subTitle,
  deleteTitle,
  onDelete,
  onClick,
  item,
  isActive,
  percentageDetails,
  additionalIcon,
}) => {
  const objPercentage = percentageDetails ? percentageDetails(item) : null

  return (
    <Flex
      align='center'
      justify='space-between'
      gap={8}
      className={`my_default_add_auto_reply_message_item ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <Flex align='center' gap={8} style={{ overflow: 'hidden' }}>
        {icon && icon(item)}
        {Boolean(objPercentage) && (
          <Div
            className={`my_default_add_auto_reply_message_item--percentage ${objPercentage.color}`}
          >
            {objPercentage.percentage}
          </Div>
        )}
        <Flex
          vertical
          className={'my_default_add_auto_reply_message_item--info'}
          gap={4}
        >
          <span className='my_default_add_auto_reply_message_item--info-title'>
            {title && title(item)}
          </span>
          <span className='my_default_add_auto_reply_message_item--info-subtitle'>
            {subTitle && subTitle(item)}
          </span>
        </Flex>
      </Flex>
      <Flex gap={8} align='center'>
        {additionalIcon && additionalIcon(item)}
        {Boolean(item && !item.isFirstItem) && (
          <ConfirmPopup
            title={deleteTitle}
            onConfirm={(e) => {
              e.stopPropagation()
              onDelete && onDelete()
            }}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            trigger={<SVGMyDefaultDeleteIcon />}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default MyDefaultAddAutoReplyMessageItem
