import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.66683 12.6667H5.3335C2.66683 12.6667 1.3335 12 1.3335 8.66666V5.33333C1.3335 2.66666 2.66683 1.33333 5.3335 1.33333H10.6668C13.3335 1.33333 14.6668 2.66666 14.6668 5.33333V8.66666C14.6668 11.3333 13.3335 12.6667 10.6668 12.6667H10.3335C10.1268 12.6667 9.92683 12.7667 9.80016 12.9333L8.80016 14.2667C8.36016 14.8533 7.64016 14.8533 7.20016 14.2667L6.20016 12.9333C6.0935 12.7867 5.84683 12.6667 5.66683 12.6667Z'
        stroke='#3F8CFF'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.6665 5.33333H11.3332'
        stroke='#3F8CFF'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.6665 8.66667H8.6665'
        stroke='#3F8CFF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGDashSMSIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashSMSIcon
