import React from 'react'
import SVGMessagesAIReplyIcon from '../../../icons/SVG/SVGMessagesAIReplyIcon'
import MyDefaultButton from '../MyDefaultButton'
import { AI_REPLY_BUTTON } from '../../../devOptions'
import './MyDefaultAIReplyButton.scss'

const MyDefaultAIReplyButton = ({
  buttonText,
  onClick,
  disabled,
  className,
  style,
  tooltip,
  tooltipTitle,
  loading,
}) => {
  return (
    <MyDefaultButton
      style={style}
      typeButton={AI_REPLY_BUTTON}
      buttonText={buttonText}
      loading={loading}
      disabled={disabled}
      tooltip={tooltip}
      tooltipTitle={tooltipTitle}
      className={className}
      onClick={onClick}
      size={'small'}
      icon={
        <SVGMessagesAIReplyIcon
          color={disabled ? 'var(--black)' : 'var(--secondaryTextColor)'}
        />
      }
    />
  )
}

export default MyDefaultAIReplyButton
