import React from 'react'
import './MyDefaultActionByText.scss'
import Div from '../Div/Div'

const MyDefaultActionByText = ({
  actionBy,
  actionName,
  marginBottom,
  marginTop,
}) => {
  return (
    <Div
      className={`my_default_action_by_text`}
      style={{ marginTop: marginTop, marginBottom: marginBottom }}
    >
      <span className='my_default_action_by_text--action_name'>{`${actionName?.replace(':', '')}: `}</span>
      <span className='my_default_action_by_text--action_by'>{actionBy}</span>
    </Div>
  )
}

export default MyDefaultActionByText
