import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        fill='white'
        fillOpacity='0.12'
      />
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_12911_440947)'
      />
      <path
        d='M24.6668 16.6667V20C24.6668 23.3333 23.3335 24.6667 20.0002 24.6667H16.0002C12.6668 24.6667 11.3335 23.3333 11.3335 20V16C11.3335 12.6667 12.6668 11.3333 16.0002 11.3333H19.3335'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.6668 16.6667H22.0002C20.0002 16.6667 19.3335 16 19.3335 14V11.3333L24.6668 16.6667Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6665 18.6667H18.6665'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6665 21.3333H17.3332'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <linearGradient
          id='paint0_linear_12911_440947'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.32' />
          <stop offset='1' stopColor='white' stopOpacity='0.12' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const SVGDashWalkBySuperhumanIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashWalkBySuperhumanIcon
