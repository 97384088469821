import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MessageTypesList from '../MessageTypesList/MessageTypesList'
import MessageLeftMultipleActionsConversation from '../MultipleActions/MessageLeftMultipleActionsConversation'
import LeftFillterDrawer from '../../filters/LeftFillterDrawer'
import MessageLeftEditConversation from '../MessageConversationsEdit/MessageLeftEditConversation'
import MatRow from '../../../../components/MatRow'
import MessageLeftFilterView from '../MessageFilterView/MessageLeftFilterView'
import ListLoadMore from '../../../../components/ListLoadMore'
import MessageLeftConversations from '../MessageConversations/MessageLeftConversations'
import NewCustommerMessageModal from '../MessageNewCustomer/NewCustommerMessageModal'
import SVGNewMessage from '../../../../icons/SVG/SVGNewMessage'
import {
  handleArchiveadReadActions,
  handleUnArchivedAction,
} from '../../utils/messageUtils'
import { ARCHIVED, BROADCAST, PHONE } from '../../utils/constants'
import MessageConversationsSearch from '../MessageConversationsSearch/MessageConversationsSearch'
import MessageMutilpleActionButton from '../MultipleActions/MessageMutilpleActionButton'
import './MessageLeft.scss'
import {
  isMobileAppSelector,
  menuOpenSelector,
  organizationSelector,
} from '../../../../store/selectors/selectors'
import useDeviceCheck from '../../../../utils/useDeviceCheck'

const PER_PAGE = 50

const MessageLeftPanel = ({
  listRef,
  listConversation,
  setListConversation,
  selectedConversation,
  setSelectedConversation,
  saveSelectedConversation,
  selectMultipleConversationsType,
  setselectMultipleConversationsType,
  isLoading,
  setIsLoading,
  unreadOnly,
  setUnreadOnly,
  smartBox,
  setSmartBox,
  paginationOptionsForAPI,
  messageType,
  setMessageType,
  setSearchKeyword,
  isFilteredMessage,
  countOfFilter,
  filterToggleStatuses,
  setFilterToggleStatuses,
  totalCount,
  isExtension,
  needsAttention,
  setNeedsAttention,
  scrollConversation,
  filterConversationsBySearchTerm,
  onLoadMore,
  MessageCenterRef,
  setMsgChangeEmailSMSType,
  setCenterBoxVisible,
  backswipeAction,
  bsClassNamesArray,
  refreshConversation,
  searchBoxRef,
}) => {
  const { isTabletPortraitOrMobile, isPageDrawerSize } = useDeviceCheck()

  const organization = useSelector(organizationSelector)
  const isMobileApp = useSelector(isMobileAppSelector)
  const menuOpen = useSelector(menuOpenSelector)

  const navigate = useNavigate()

  const [archivedConversations, setArchivedConversations] = useState([])

  const resetCustomFilters = () => {
    listRef?.current?.resetSuperhumanFilter()
  }

  const addNewConversation = (conversation) => {
    const data = listConversation.filter(
      (item) => item.receiver_phone !== conversation.receiver_phone
    )
    setListConversation([conversation, ...data])
    setSelectedConversation(conversation)
    saveSelectedConversation(conversation)
  }

  return (
    <div className='messages_left_panel'>
      {!selectMultipleConversationsType.convEdit && (
        <MessageTypesList
          messageType={messageType}
          onChange={(value) => {
            if (isFilteredMessage) {
              listRef.current.resetFilters()
            }
            setMessageType(value)
            if (messageType !== value) {
              setIsLoading(true)
            }
          }}
        />
      )}
      <div className='messages_left_panel--header'>
        <MatRow>
          <MatRow
            className='messages_left_panel--header--search-panel'
            style={{
              display: selectMultipleConversationsType.convEdit ? 'none' : 'flex',
            }}
          >
            <MessageConversationsSearch
              ref={searchBoxRef}
              onSearch={(value) => {
                setSearchKeyword(value)
              }}
            />
            {messageType !== BROADCAST && (
              <MessageMutilpleActionButton
                selectMultipleConversationsType={selectMultipleConversationsType}
                setselectMultipleConversationsType={
                  setselectMultipleConversationsType
                }
                setUnreadOnly={setUnreadOnly}
              />
            )}
            <LeftFillterDrawer
              ref={listRef}
              countOfFilter={countOfFilter}
              filterToggleStatuses={filterToggleStatuses}
              onChangeFilterToggleStatuses={(value) => {
                if (
                  !Boolean(
                    window.history.state && window.history.state.changeMessageType
                  )
                ) {
                  if (value.fromBroadcastPage && !value.opportunities) {
                    value.campaign_responds = true
                  }
                  setFilterToggleStatuses(value)
                }
              }}
            />
          </MatRow>
        </MatRow>
        {selectMultipleConversationsType.convEdit && (
          <MessageLeftMultipleActionsConversation
            selectMultipleConversationsType={selectMultipleConversationsType}
            setselectMultipleConversationsType={setselectMultipleConversationsType}
            archivedConversations={archivedConversations}
            setArchivedConversations={setArchivedConversations}
            setIsLoading={setIsLoading}
            setUnreadOnly={setUnreadOnly}
            paginationOptionsForAPI={paginationOptionsForAPI}
            messageType={messageType}
          />
        )}
        <MessageLeftEditConversation
          listConversation={listConversation}
          unreadOnly={unreadOnly}
          setUnreadOnly={setUnreadOnly}
          smartBox={smartBox}
          setSmartBox={setSmartBox}
          messageType={messageType}
          selectMultipleConversationsType={selectMultipleConversationsType}
          setselectMultipleConversationsType={setselectMultipleConversationsType}
          totalCount={totalCount}
          isExtension={isExtension}
          isLoading={isLoading}
          isNeedsAttentionEnabled={needsAttention}
          onNeedsAttentionClick={() => {
            setNeedsAttention((ov) => !ov)
          }}
          organization={organization}
        />
        {(filterToggleStatuses.fromBroadcastPage ||
          filterToggleStatuses.dashboardClickableStats) && (
          <MessageLeftFilterView
            statuses={filterToggleStatuses}
            onClick={() => resetCustomFilters()}
          />
        )}
      </div>
      <section className='messages_left_conversations_list' ref={scrollConversation}>
        <ListLoadMore
          loading={isLoading}
          data={filterConversationsBySearchTerm(listConversation)}
          totalDataCount={totalCount}
          onLoadMore={onLoadMore}
          renderItem={(item, index) => {
            if (!selectedConversation && !isMobileApp) {
              return <React.Fragment key={`${item._id}_#_${index}`} />
            }
            return (
              <MessageLeftConversations
                key={`${item._id}_#_${index}`}
                item={item}
                selectedConversation={selectedConversation}
                selectMultipleConversationsType={selectMultipleConversationsType}
                messageType={messageType}
                onClick={() => {
                  if (selectMultipleConversationsType.selectButtonActionsType) {
                    handleArchiveadReadActions(
                      selectMultipleConversationsType,
                      setselectMultipleConversationsType,
                      item
                    )
                  } else if (
                    messageType === ARCHIVED &&
                    selectMultipleConversationsType.convEdit
                  ) {
                    handleUnArchivedAction(
                      selectMultipleConversationsType,
                      setselectMultipleConversationsType,
                      item
                    )
                  } else {
                    if (item._id !== selectedConversation._id) {
                      MessageCenterRef.current &&
                        MessageCenterRef.current.resetFields()
                      setMsgChangeEmailSMSType(PHONE)
                      setSelectedConversation(item)
                    }
                    saveSelectedConversation({
                      index: index < PER_PAGE ? index : 0,
                      conversation: { ...item },
                    })
                    setCenterBoxVisible(true)
                    if (isTabletPortraitOrMobile) {
                      document.body.style.overflow = 'hidden'
                    }
                    if (isMobileApp) {
                      navigate(`/messages?type=${messageType}&center=true`)
                      backswipeAction(bsClassNamesArray, 1, 'center', null, null)
                    }
                  }
                }}
              />
            )
          }}
        />
        {!isExtension && (
          <NewCustommerMessageModal
            trigger={
              <div
                className={
                  'message-create-new-button ' +
                  (!isPageDrawerSize && menuOpen ? 'message-button-menu-open' : '')
                }
              >
                <SVGNewMessage />
              </div>
            }
            onSendSuccess={(conversation) => {
              if (isTabletPortraitOrMobile) {
                document.body.style.overflow = 'hidden'
              }
              if (isMobileApp) {
                backswipeAction('.message_center_panel-drawer', 1, null, null, null)
              }
              setCenterBoxVisible(true)
              conversation.isNew
                ? addNewConversation(conversation)
                : refreshConversation(conversation)
            }}
          />
        )}
      </section>
    </div>
  )
}

export default MessageLeftPanel
