import { getText } from './lang'

export const TABLE_DEFAULT_ROW_OPTIONS = [10, 25, 50, 100, getText('WORD_ALL')]
export const TABLE_DEFAULT_ROW_NUMBER = 12
export const TABLE_DEFAULT_ROW_HEIGHT = 48

export const SELECT_UNSELECTED_VALUE = 'none'
export const ADMIN = 'Admin'
export const MANAGER = 'Manager'
export const SUPERHUMAN = 'Superhuman'
export const SUPPORT_AGENT = 'Support agent'
export const SMS = 'sms'
export const EMAIL = 'email'
export const AI_FOLLOW_UP = 'AI Follow Up'
export const DRIP_SEQUENCE = 'Drip Sequence'

export const DEFAULT = 'default'
export const NIGHT = 'night'
export const DAY = 'day'
export const ALL = 'all'

export const IMMEDIATELY = 'immediately'
export const DAYS = 'days'
export const MINUTES = 'minutes'
export const HOURS = 'hours'

export const MOBILE = 'mobile'

export const CUSTOM = 'custom'
export const START_DATE = 'start_date'
export const END_DATE = 'end_date'
export const ALL_TIME = 'all_time'
export const ORG = 'org'

export const YELLOW = 'yellow'
export const GREEN = 'green'
export const RED = 'red'

export const GBM = 'gbm'
export const MESSENGER = 'messenger'
export const FACEBOOK = 'facebook'
export const FB_MESSANGER = 'FB messenger'
export const IG_MESSANGER = 'IG messenger'

export const SEARCH = 'search'
export const BUTTON = 'button'
export const SELECT = 'select'

export const PRIMARY = 'primary'
export const NEGATIVE_BUTTON = 'negative_button'
export const POSITIVE_BUTTON = 'positive_button'
export const DROPDOWN_BUTTON = 'dropdown_button'
export const AI_REPLY_BUTTON = 'ai_reply_button'
export const RANGEPICKER = 'rangepicker'

export const TOUCHPOINT = 'touchpoint'
export const VIDEO = 'video'
export const PDF = 'pdf'
export const IMAGE = 'image'
export const APPLICATION_PDF = 'application/pdf'

export const FOLLOW_UP = 'follow_up'
export const USERS_TAB = 'Users'
export const VIDEO_TAB = 'Video'

export const FAILED = 'failed'
export const SUCCESS = 'success'

export const SUBMIT_BTN = 'submit'
export const CANCEL_BTN = 'cancel'

export const MANUALLY = 'manually'
export const AUTOMATION = 'automation'

// export const CONTACT_LIST_LIMIT = 50;

// export const NEXT_FIELD_KEYS = ["Tab", "Enter"];
export const NEXT_FIELD_KEYS = []

export const DEFAULT_IMAGE_UPLOAD_DIRECTORY = 'public/uploads'

export const SUPER_ADMIN_FAVORITE_EMAILS = [
  'mathieu@matador.ai',
  'nick@matador.ai',
  'nicolas@matador.ai',
  'serge@matador.ai',
  'harry@matador.ai',
  'paul@matador.ai',
  'kevin@matador.ai',
  'ayoub@matador.ai',
  'nadiia@matador.ai',
  'viktor@matador.ai',
  'josh@matador.ai',
  'joshua@matador.ai',
  'brooke@matador.ai',
  'vladyslav.patsuta@inoxoft.com',
  'mariia.kniazyk+1@inoxoft.com',
  'valentyna.rudkovska+1@inoxoft.com',
  'mykola.kopaihora@inoxoft.com',
  'mykola.kopaihora+10@inoxoft.com',
  'oleksandr.bondarenko@inoxoft.com',
  'grigor.nurijanyan@ubicross.pro',
  'grigor.nurijanyan+1@ubicross.pro',
  'davit.hovhannisyan@ubicross.pro',
  'narek@matador.ai',
  'claudiane@matador.ai',
]
