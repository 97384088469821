import React from 'react'
import { LocationProvider } from '../locationProvider/LocationProvider'
import './LocationNavigation.scss'

const LocationNavigation = ({ children }) => {
  return (
    <div className='loc-navigation-page'>
      <LocationProvider children={children} />
    </div>
  )
}

export default LocationNavigation
