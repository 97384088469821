import React from 'react'
import './MyDefaultInsidePageTabs.scss'
import { Tabs } from 'antd'

const MyDefaultInsidePageTabs = ({ children, items, onChange }) => {
  return (
    <Tabs
      items={items}
      onChange={onChange}
      className='my_default_inside_page_tabs'
    ></Tabs>
  )
}

export default MyDefaultInsidePageTabs
