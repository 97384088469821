import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Tooltip } from 'antd'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import InputFormNumberSecondary from '../../../../components/Form/InputFormNumber/InputFormNumberSecondary'
import SelectFormSecondary from '../../../../components/Form/SelectForm/SelectFormSecondary'
import InputFormTextSecondary from '../../../../components/Form/InputFormText/InputFormTextSecondary'
import InputFormTextAreaSecondary from '../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import MatForm from '../../../../components/Form/MatForm'
import MatModal from '../../../../components/MatModal'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import SelectCheckedSecondary from '../../../../components/Form/SelectChecked/SelectCheckedSecondary'
import { getText, getTextServerError } from '../../../../lang'
import {
  ACTIVIX,
  DEALER_PEAK,
  DEALER_SOCKET,
  ELEADS,
  ONE_EIGHTY,
  PBS,
  TEKION,
  VIN_SOLUTION,
} from '../../../../config/constants'
import locationActions from '../../../../store/modules/locationActions'
import authActions from '../../../../store/modules/authActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import SVGIntegrationModalSettings from '../../../../icons/SVG/SVGIntegrationModalSettings'
import { formatDateTime } from '../../../../utils'
import SVGInfoIconOrange from '../../../../icons/SVG/SVGInfoIconOrange'
import { userSelector } from '../../../../store/selectors/selectors'

const leadsCategoriesToIntegrateOptions = [
  {
    value: 'sales',
    label: 'Sales',
  },
  {
    value: 'service',
    label: 'Service',
  },
]

const PER_PAGE = 30

const CrmTypeSelector = (props) => {
  const { location, isNewLocation, setLocationObj } = props

  const user = useSelector(userSelector)

  const additionalConfigsOptions = useMemo(
    () => [
      {
        value: 1,
        label: getText('TEXT_LIVE_SYNC'),
      },
      {
        value: 2,
        label: getText('TEXT_SMART_SYNC'),
      },
      {
        value: 3,
        label: getText('TEXT_COMPREHENSIVE_SYNC'),
      },
    ],
    []
  )

  const noneCRM = { value: 'none', label: getText('WORD_NONE') }

  const selectTypeOptions = [
    noneCRM,
    { value: VIN_SOLUTION, label: 'VinSolutions' },
    { value: ELEADS, label: 'Elead' },
    { value: DEALER_SOCKET, label: 'DealerSocket' },
    { value: DEALER_PEAK, label: 'DealerPeak' },
    // { value: ONE_EIGHTY, name: 'One Eighty' }, // We are disabling 1-80 for now. Maybe will use in future
    { value: ACTIVIX, label: 'Activix' },
    { value: PBS, label: 'PBS' },
    { value: TEKION, label: 'Tekion' },
  ]

  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)
  const [selectedCrmType, setSelectedCrmType] = useState({})
  const [selectedCrmTypeAfterSaving, setSelectedCrmTypeAfterSaving] = useState({})
  const [crmOptions, setCrmOptions] = useState({})
  const [whoConnected, setWhoConnected] = useState({})
  const [vinUsers, setVinUsers] = useState({
    users: [],
    maxUsers: 0,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const currentCrm = selectTypeOptions.find(
      (type) => type.value === location.crmType
    )

    setSelectedCrmType(currentCrm || noneCRM)
    setSelectedCrmTypeAfterSaving(currentCrm || noneCRM)

    setWhoConnected({
      connectedBy: location.crmConfigs && location.crmConfigs.connectedBy,
      connectDate: location.crmConfigs && location.crmConfigs.connectDate,
    })

    setCrmOptions({
      crmConfigs: {
        vinSolution: location.crmConfigs && location.crmConfigs.vinSolution,
        eLeads: location.crmConfigs && location.crmConfigs.eLeads,
        dealerSocket: location.crmConfigs && location.crmConfigs.dealerSocket,
        dealerPeak: location.crmConfigs && location.crmConfigs.dealerPeak,
        oneEighty: location.crmConfigs && location.crmConfigs.oneEighty,
        activix: location.crmConfigs && location.crmConfigs.activix,
        pbs: location.crmConfigs && location.crmConfigs.pbs,
        tekion: location.crmConfigs && location.crmConfigs.tekion,
        connectedBy: location.crmConfigs && location.crmConfigs.connectedBy,
        connectDate: location.crmConfigs && location.crmConfigs.connectDate,
      },
    })
  }, [])

  useEffect(() => {
    if (selectedCrmType.value === VIN_SOLUTION) {
      if (
        crmOptions.crmConfigs &&
        crmOptions.crmConfigs.vinSolution &&
        crmOptions.crmConfigs.vinSolution.dealerId
      ) {
        getVinUsers(crmOptions.crmConfigs.vinSolution.dealerId)
      }
    }
  }, [selectedCrmType])

  const getVinUsers = async (dealerId) => {
    const result = await locationActions.getVinSolutionUsers(location.id, {
      dealerId,
      page: 1,
      perPage: PER_PAGE,
    })
    if (result.success) {
      crmOptions.crmConfigs.vinSolution.dealerId = dealerId
      setCrmOptions(crmOptions)
      setVinUsers({
        users: result.data.vinsUsers || [],
        maxUsers: result.data.total_items,
      })
    }
  }

  const handleScrollUsers = async () => {
    const dealerId = crmOptions.crmConfigs.vinSolution.dealerId
    const page = Math.floor(vinUsers.users.length / PER_PAGE + 1)
    if (vinUsers.maxUsers <= vinUsers.users.length) {
      return
    }
    const result = await locationActions.getVinSolutionUsers(location.id, {
      dealerId,
      page,
      perPage: PER_PAGE,
    })
    if (result.success) {
      setVinUsers({
        ...vinUsers,
        users: [...vinUsers.users, ...result.data.vinsUsers],
      })
    }
  }

  const handeSuccessLocationUpdateResponse = (updatedLocation) => {
    notifySuccess(
      isNewLocation
        ? getText('TEXT_LOCATION_WAS_CREATED_SUCCESSFULLY')
        : getText('TEXT_LOCATION_WAS_UPDATED_SUCCESSFULLY')
    )
    // TODO need testing here
    let loc = authActions.getLocation()

    if (location.facebookConnection && location.facebookConnection._id) {
      updatedLocation.facebookConnection = location.facebookConnection
    }

    if (location.featuresConnectingData) {
      updatedLocation.featuresConnectingData = location.featuresConnectingData
    }

    if (updatedLocation._id === (loc && loc._id) || !loc || loc._id === ' ') {
      authActions.setLocation(updatedLocation)
    }

    const crmTypeObject = selectTypeOptions.find(
      (e) => e.value === updatedLocation.crmType
    )

    // TODO do we need both seters?
    setSelectedCrmType(crmTypeObject || noneCRM)
    setSelectedCrmTypeAfterSaving(crmTypeObject || noneCRM)

    if (updatedLocation.crmType === 'none') {
      setWhoConnected({})
    } else {
      setWhoConnected({
        connectedBy:
          updatedLocation.crmConfigs && updatedLocation.crmConfigs.connectedBy,
        connectDate:
          updatedLocation.crmConfigs && updatedLocation.crmConfigs.connectDate,
      })
    }

    setLocationObj(updatedLocation)
    setVisible(false)
    if (isNewLocation) {
      navigate(`/location/settings/${updatedLocation._id}/edit`, {
        state: { locationObj: updatedLocation },
      })
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)

    const obj = {
      crmType: allValues.crmType,
      additionalConfigs: {
        crmSynchroFlow: allValues.additionalConfigs.crmSynchroFlow,
      },
      crmConfigs: {},
    }
    const selectedCRM = allValues.crmType
    if (selectedCRM !== 'none') {
      obj.crmConfigs[selectedCRM] = allValues.crmConfigs[selectedCRM]
    }

    if (selectedCRM === VIN_SOLUTION) {
      delete allValues.crmConfigs.vinSolution.leadSourceId
    }

    let result = {}

    if (!isNewLocation) {
      result = await locationActions.saveCRMIntegrationConfigs(obj, location?._id)
    }

    if (result.success) {
      handeSuccessLocationUpdateResponse(result.data)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const dealStatusesToIntegrateList = Form.useWatch(
    ['crmConfigs', 'pbs', 'availableDealsStatuses'],
    form
  )

  return (
    <MatBlockWithLabel className={'integration-item-footer-item'}>
      {selectedCrmTypeAfterSaving.value === 'none' ? (
        <div />
      ) : (
        <div>
          <div className='footer-connected-account'>
            <span>{getText('WORD_CONNECTED_CRM')}</span>
            <span>{selectedCrmTypeAfterSaving.label}</span>
          </div>
          <br />
          {whoConnected.connectedBy && (
            <>
              <div
                className='footer-connected-account'
                style={{ flexDirection: 'row' }}
              >
                <span>{getText('ACTION_CONNECTED_BY')}:</span>
                <span style={{ marginLeft: '3px' }}>{whoConnected.connectedBy}</span>
              </div>
              <div
                className='footer-connected-account'
                style={{ flexDirection: 'row' }}
              >
                <span>{getText('ACTION_CONNECTION_DATE')}</span>
                <span style={{ marginLeft: '3px' }}>
                  {formatDateTime(whoConnected.connectDate)}
                </span>
              </div>
            </>
          )}
        </div>
      )}
      <MatModal
        trigger={<SVGIntegrationModalSettings className={`modal-settings-icon`} />}
        title={getText('WORD_CONNECT_TO_CRM')}
        confirmLoading={loading}
        forceRender={false}
        visible={visible}
        onTrrigerClick={() => {
          setVisible(true)
          form.resetFields()
        }}
        onCancel={() => setVisible(false)}
        hideSubmit={!user.isSuperAdmin}
        okText={getText('ACTION_SAVE')}
      >
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            name: location.name,
            address: location.address,
            email: location.email,
            crmType: location.crmType ? location.crmType : 'none',
            crmConfigs: {
              activix: {
                account_id:
                  location.crmConfigs &&
                  location.crmConfigs.activix &&
                  location.crmConfigs.activix.account_id,
                api_key:
                  location.crmConfigs &&
                  location.crmConfigs.activix &&
                  location.crmConfigs.activix.api_key,
                matadorUserId:
                  location.crmConfigs &&
                  location.crmConfigs.activix &&
                  location.crmConfigs.activix.matadorUserId,
              },
              vinSolution: {
                dealerId:
                  location.crmConfigs &&
                  location.crmConfigs.vinSolution &&
                  location.crmConfigs.vinSolution.dealerId,
                userId:
                  location.crmConfigs &&
                  location.crmConfigs.vinSolution &&
                  location.crmConfigs.vinSolution.userId
                    ? location.crmConfigs.vinSolution.user.UserId
                    : '',
                isSourceMatador:
                  location.crmConfigs &&
                  location.crmConfigs.vinSolution &&
                  location.crmConfigs.vinSolution.isSourceMatador,
                user:
                  location.crmConfigs &&
                  location.crmConfigs.vinSolution &&
                  location.crmConfigs.vinSolution.user,
              },
              eLeads: {
                subscriptionId:
                  (location.crmConfigs &&
                    location.crmConfigs.eLeads &&
                    location.crmConfigs.eLeads.subscriptionId &&
                    location.crmConfigs.eLeads.subscriptionId
                      .replace(/[0-9a-wy-z]/gi, 'x')
                      .slice(0, -4) +
                      location.crmConfigs.eLeads.subscriptionId.slice(-4)) ||
                  '',
              },
              dealerSocket: {
                dealerNumber:
                  location.crmConfigs &&
                  location.crmConfigs.dealerSocket &&
                  location.crmConfigs.dealerSocket.dealerNumber,

                leadsCategoriesToIntegrate:
                  location.crmConfigs &&
                  location.crmConfigs.dealerSocket &&
                  location.crmConfigs.dealerSocket.leadsCategoriesToIntegrate,
              },
              dealerPeak: {
                dealerGroupID:
                  location.crmConfigs &&
                  location.crmConfigs.dealerPeak &&
                  location.crmConfigs.dealerPeak.dealerGroupID,
              },
              oneEighty: {
                dealerId:
                  location.crmConfigs &&
                  location.crmConfigs.oneEighty &&
                  location.crmConfigs.oneEighty.dealerId,
                quoteId:
                  location.crmConfigs &&
                  location.crmConfigs.oneEighty &&
                  location.crmConfigs.oneEighty.quoteId,
              },
              pbs: {
                serialNumber:
                  location.crmConfigs &&
                  location.crmConfigs.pbs &&
                  location.crmConfigs.pbs.serialNumber,

                availableDealsStatuses:
                  location.crmConfigs &&
                  location.crmConfigs.pbs &&
                  location.crmConfigs.pbs.availableDealsStatuses,

                dealStatusesToIntegrate:
                  location.crmConfigs &&
                  location.crmConfigs.pbs &&
                  location.crmConfigs.pbs.dealStatusesToIntegrate,
              },
              tekion: {
                dealerId:
                  location.crmConfigs &&
                  location.crmConfigs.tekion &&
                  location.crmConfigs.tekion.dealerId,
                clientId:
                  location.crmConfigs &&
                  location.crmConfigs.tekion &&
                  location.crmConfigs.tekion.clientId,
              },
            },
            additionalConfigs: {
              crmSynchroFlow:
                location.additionalConfigs &&
                location.additionalConfigs.crmSynchroFlow,
            },
          }}
        >
          <SelectFormSecondary
            label={getText('WORD_CRM_TYPE')}
            name={'crmType'}
            options={selectTypeOptions}
            onChange={(_, typeObj) => {
              setSelectedCrmType(typeObj)
            }}
            showSearch={false}
          />
          {selectedCrmType.value === VIN_SOLUTION && (
            <>
              <InputFormNumberSecondary
                label={getText('WORD_DEALER_ID')}
                name={['crmConfigs', 'vinSolution', 'dealerId']}
                onChange={debounce((value) => {
                  setVinUsers({ users: [], maxUsers: 0 })
                  getVinUsers(value)
                }, 1000)}
                required
                errorMessage={getText('TEXT_PLEASE_INPUT_DEALER_ID')}
                isEnterDisabled
              />
              {Boolean(
                crmOptions.crmConfigs &&
                  crmOptions.crmConfigs.vinSolution &&
                  crmOptions.crmConfigs.vinSolution.dealerId &&
                  vinUsers.users.length
              ) ? (
                <SelectFormSecondary
                  label={getText('WORD_USER')}
                  name={['crmConfigs', 'vinSolution', 'user', 'FullName']}
                  options={vinUsers.users.map((user) => ({
                    value: user.UserId,
                    label: user.FullName,
                  }))}
                  onChange={(userId) => {
                    const userObj = vinUsers.users.find(
                      (user) => user.UserId === userId
                    )

                    form.setFieldsValue({
                      crmConfigs: {
                        ...setCrmOptions.crmConfigs,
                        vinSolution: {
                          ...crmOptions.crmConfigs.vinSolution,
                          userId: userObj.UserId,
                          user: userObj,
                        },
                      },
                    })
                    setCrmOptions({
                      crmConfigs: {
                        ...setCrmOptions.crmConfigs,
                        vinSolution: {
                          ...crmOptions.crmConfigs.vinSolution,
                          userId: userObj.UserId,
                          user: userObj,
                        },
                      },
                    })
                  }}
                  showSearch={false}
                  onLoadMore={handleScrollUsers}
                />
              ) : null}
            </>
          )}
          {selectedCrmType.value === ELEADS && (
            <InputFormTextSecondary
              label={getText('WORD_SUBSCRIPTION_ID')}
              name={['crmConfigs', 'eLeads', 'subscriptionId']}
              allowClear={false}
              required
              isEnterDisabled
            />
          )}
          {selectedCrmType.value === DEALER_SOCKET && (
            <>
              <InputFormTextSecondary
                label={getText('WORD_DEALER_NUMBER')}
                name={['crmConfigs', 'dealerSocket', 'dealerNumber']}
                allowClear={false}
                required
                isEnterDisabled
              />
              <SelectFormSecondary
                label={getText('WORD_OPPORTUNITIES')}
                name={['crmConfigs', 'dealerSocket', 'leadsCategoriesToIntegrate']}
                options={leadsCategoriesToIntegrateOptions}
                showSearch={false}
                mode='multiple'
                required
                isEnterDisabled
              />
            </>
          )}
          {selectedCrmType.value === DEALER_PEAK && (
            <InputFormTextSecondary
              label={getText('WORD_DEALER_GROUP_ID')}
              name={['crmConfigs', 'dealerPeak', 'dealerGroupID']}
              allowClear={false}
              required
              isEnterDisabled
            />
          )}
          {selectedCrmType.value === ONE_EIGHTY && (
            <>
              <InputFormTextSecondary
                label={getText('WORD_DEALER_ID')}
                name={['crmConfigs', 'oneEighty', 'dealerId']}
                allowClear={false}
                required
                isEnterDisabled
              />
              <InputFormTextSecondary
                label={getText('WORD_QUOTE_ID')}
                name={['crmConfigs', 'oneEighty', 'quoteId']}
                allowClear={false}
                required
                isEnterDisabled
              />
            </>
          )}
          {selectedCrmType.value === ACTIVIX && (
            <>
              <InputFormTextSecondary
                label={getText('WORD_ACCOUNT_ID')}
                name={['crmConfigs', 'activix', 'account_id']}
                allowClear={false}
                required
                isEnterDisabled
              />
              <InputFormTextAreaSecondary
                label={getText('WORD_API_KEY')}
                name={['crmConfigs', 'activix', 'api_key']}
                allowClear={false}
                autoSize={false}
                required
                isEnterDisabled
              />
              <InputFormTextAreaSecondary
                label={getText('WORD_MATADOR_USER_ID')}
                name={['crmConfigs', 'activix', 'matadorUserId']}
                allowClear={false}
                autoSize={false}
                required
                isEnterDisabled
              />
            </>
          )}
          {selectedCrmType.value === TEKION && (
            <>
              <InputFormTextSecondary
                label={getText('WORD_DEALER_ID')}
                name={['crmConfigs', 'tekion', 'dealerId']}
                allowClear={false}
                required
                isEnterDisabled
              />
              <InputFormTextAreaSecondary
                label={getText('WORD_CLIENT_ID')}
                name={['crmConfigs', 'tekion', 'clientId']}
                allowClear={false}
                autoSize={false}
                required
                isEnterDisabled
              />
            </>
          )}
          {selectedCrmType.value === PBS && (
            <>
              <InputFormTextSecondary
                label={getText('WORD_SERIAL_NUMBER')}
                name={['crmConfigs', 'pbs', 'serialNumber']}
                allowClear={false}
                required
                isEnterDisabled
              />
              <SelectCheckedSecondary
                label={getText('WORD_AVAILABLE_STATUSES')}
                name={['crmConfigs', 'pbs', 'availableDealsStatuses']}
                option={[]}
                showSearch={false}
                hidden
                mode='multiple'
                multipleNewStyle
                disabled
              />
              <SelectCheckedSecondary
                label={getText('WORD_STATUSES_FOR_INTEGRATES')}
                name={['crmConfigs', 'pbs', 'dealStatusesToIntegrate']}
                option={
                  dealStatusesToIntegrateList && dealStatusesToIntegrateList.length
                    ? dealStatusesToIntegrateList.map((item) => {
                        return { value: item, label: item }
                      })
                    : []
                }
                showSearch={false}
                mode='multiple'
                multipleNewStyle
              />
            </>
          )}
          {[PBS, DEALER_SOCKET, VIN_SOLUTION].includes(selectedCrmType.value) && (
            <SelectFormSecondary
              label={
                <div className={'crm_select_label'}>
                  {getText('TEXT_CRM_SYCHRONIZATION_FLOW')}
                  <Tooltip
                    title={
                      <div className='crm_select_tooltip_wrapper'>
                        <p className='crm_select_tooltip_title'>{`${getText('TEXT_LIVE_SYNC')}:`}</p>
                        <p className='crm_select_tooltip_description'>{`${getText('TEXT_LIVE_SYNC_DESCRIPTION')}`}</p>
                        <p className='crm_select_tooltip_title'>{`${getText('TEXT_SMART_SYNC')}:`}</p>
                        <p className='crm_select_tooltip_description'>{`${getText('TEXT_SMART_SYNC_DESCRIPTION')}`}</p>
                        <p className='crm_select_tooltip_title'>{`${getText('TEXT_COMPREHENSIVE_SYNC')}:`}</p>
                        <p className='crm_select_tooltip_description'>{`${getText('TEXT_COMPREHENSIVE_SYNC_DESCRIPTION')}`}</p>
                      </div>
                    }
                  >
                    <div className='crm_select_info_icon'>
                      <SVGInfoIconOrange
                        color={'#A3A1B7'}
                        width={16.67}
                        height={16.67}
                      />
                    </div>
                  </Tooltip>
                </div>
              }
              name={['additionalConfigs', 'crmSynchroFlow']}
              options={additionalConfigsOptions}
              showSearch={false}
            />
          )}
        </MatForm>
      </MatModal>
    </MatBlockWithLabel>
  )
}

export default CrmTypeSelector
