import React, { useEffect, useRef, useState } from 'react'
import { Divider, Flex, Form } from 'antd'
import { getText, getTextServerError } from '../../../../lang'
import MatForm from '../../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import organizationActions from '../../../../store/modules/organizationActions'
import authActions from '../../../../store/modules/authActions'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultBlockTitleWithSwitch from '../../../../components/MyDefaultBlock/MyDefaultBlockTitleWithSwitch'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import SVGMyDefaultDeleteIcon from '../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MyDefaultBlockHeaderAdditionalButton from '../../../../components/MyDefaultBlock/MyDefaultBlockHeaderAdditionalButton'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'

const IPWhiteList = () => {
  const {
    selectedOrganization: organization,
    setSelectedOrganization: setOrganization,
  } = useOrganizationLayout()
  const { setPageTitle, setFooterButtons, setLoading } = useLayout()

  const [enableIPWhiteList, setEnableIPWhiteList] = useState(
    organization.ipWhitelist.enabled
  )

  const ipWhitelistRef = useRef(null)

  const [form] = Form.useForm()

  const getIpWhiteList = Form.useWatch(['ipWhitelist', 'ips'], form)

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
        action: () => {
          setEnableIPWhiteList(organization.ipWhitelist.enabled)
          form.setFieldsValue({
            ipWhitelist: organization.ipWhitelist,
          })
        },
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setPageTitle(getText('WORD_IP_WHITELIST'))
    form.setFieldsValue({
      ipWhitelist: organization.ipWhitelist,
    })
  }, [])

  const onFinish = async (values) => {
    setLoading(true)

    if (values.ipWhitelist.ips.length > 10) {
      setLoading(false)
      return notifyError(
        getText('MSG_THE_NUMBER_OF_IP_WHITELIST_CANNOT_BE_MORE_THAN')
      )
    }

    const ips = values.ipWhitelist.ips.filter((item) => item !== '')

    const obj = {
      ips: ips,
      enabled: enableIPWhiteList,
    }

    const result = await organizationActions.setIPWhiteList(obj, organization?._id)

    if (result.success) {
      notifySuccess(
        !organization?._id
          ? getText('TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY')
          : getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY')
      )
      organization.ipWhitelist = result.data
      setOrganization(organization)
      const currentOrganization = authActions.getOrganization()
      if (organization?._id === currentOrganization._id) {
        authActions.setOrganization(organization)
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }

    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      if ('Please input Please insert whitelist IP address!') {
        notifyError(getText('MSG_PLEASE_INSERT_WHITELIST_IP_ADDRESS'))
      } else {
        notifyError(getTextServerError(errorInfo.errorFields[0].errors[0]))
      }
    }
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MyDefaultBlock
        disableMarginTitle={!getIpWhiteList?.length}
        title={
          <MyDefaultBlockTitleWithSwitch
            title={getText('WORD_IP_WHITELIST')}
            value={enableIPWhiteList}
            onChange={(value) => setEnableIPWhiteList(value)}
          />
        }
        additionalHeader={
          <MyDefaultBlockHeaderAdditionalButton
            buttonText={getText('WORD_ADD_NEW_IP')}
            onClick={(e) => {
              ipWhitelistRef?.current?.add('')
            }}
          />
        }
      >
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            enabled: true,
          }}
        >
          <Form.List name={['ipWhitelist', 'ips']}>
            {(fields, { add, remove }) => {
              ipWhitelistRef.current = { add, remove }
              return fields.map((field, index) => {
                return (
                  <React.Fragment>
                    <ConfirmPopup
                      title={getText('MSG_DELETE_IP_WHITE_LIST')}
                      placement={'topRight'}
                      onConfirm={(e) => {
                        e.stopPropagation()
                        remove(index)
                      }}
                      okText={getText('WORD_YES')}
                      cancelText={getText('WORD_NO')}
                      trigger={
                        <Flex justify='flex-end'>
                          <SVGMyDefaultDeleteIcon style={{ cursor: 'pointer' }} />
                        </Flex>
                      }
                    />
                    <MyDefaultInputText
                      isForm
                      name={[field.name]}
                      placeholder={getText(
                        'TEXT_PLEASE_INSERT_WHITELIST_IP_ADDRESS'
                      )}
                      label={getText('WORD_IP_ADDRESS')}
                      isLast={true}
                    />
                    {Boolean(index + 1 < fields.length) && (
                      <Divider style={{ marginBottom: 24, marginTop: 24 }} />
                    )}
                  </React.Fragment>
                )
              })
            }}
          </Form.List>
        </MatForm>
      </MyDefaultBlock>
    </MyDefaultPageLayout>
  )
}

export default IPWhiteList
