import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_19823_48746)'>
        <path
          d='M10.6752 1.04688C5.09142 1.04688 1.04761 5.60402 1.04761 10.664C1.04761 12.2774 1.51904 13.9431 2.34665 15.4621C2.5038 15.7135 2.51427 16.0278 2.40951 16.3316L1.77046 18.4897C1.6238 19.0135 2.0638 19.3907 2.55618 19.244L4.49427 18.6678C5.01808 18.4897 5.43713 18.7202 5.92951 19.0135C7.33332 19.8412 9.08285 20.2602 10.6543 20.2602C15.4209 20.2602 20.2609 16.5726 20.2609 10.6431C20.2609 5.53068 16.1333 1.05734 10.6752 1.05734V1.04688Z'
          stroke='#0C0D0D'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.45093 14.2795H6.7395L7.28426 12.7186H10.249L10.8042 14.2795H12.0928L9.3795 7.01953H8.17474L5.4614 14.2795H5.45093ZM7.6614 11.65L8.75093 8.50714H8.77188L9.87188 11.65H7.65093H7.6614ZM13.2557 14.2795H14.4919V7.01953H13.2557V14.2795Z'
          fill='#0C0D0D'
        />
      </g>
      <defs>
        <clipPath id='clip0_19823_48746'>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGOrgConversationAIIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgConversationAIIcon
