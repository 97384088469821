import React, { memo, useEffect, useState } from 'react'
import { Form } from 'antd'
import { useMixpanel } from 'react-mixpanel-browser'
import { useSelector } from 'react-redux'
import SVGDetectedLanguage from '../../../../icons/SVG/SVGDetectedLanguage'
import { getText, getTextServerError } from '../../../../lang'
import locationActions from '../../../../store/modules/locationActions'
import invitationActions from '../../../../store/modules/invitationActions'
import {
  checkFormatPhoneNumber,
  getInitialCountry,
  getLongName,
  setMixpanel,
} from '../../../../utils'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MatForm from '../../../../components/Form/MatForm'
import InputFormEmail from '../../../../components/Form/InputFormEmail'
import InputFormText from '../../../../components/Form/InputFormText'
import InputFormPhone from '../../../../components/Form/InputFormPhone'
import ModalAntd from '../../../../components/MatModal'
import MatRow from '../../../../components/MatRow'
import messageActions from '../../../../store/modules/messageActions'
import { SMS } from '../../utils/constants'
import {
  locationSelector,
  organizationSelector,
  userSelector,
} from '../../../../store/selectors/selectors'
import './MessageInvitationModal.scss'

const ContactSendInvitationModal = (props) => {
  const {
    trigger,
    customer,
    conversation,
    disabled,
    disableInvitationPhoneNumber,
    onSaveInvitation,
    onCloseMobileDesk,
  } = props

  let organization = useSelector(organizationSelector)
  let user = useSelector(userSelector)
  let userLocation = useSelector(locationSelector)

  const [form] = Form.useForm()
  const mixpanel = useMixpanel()

  const [visible, setVisible] = useState(false)
  const [saving, setSaving] = useState(false)
  const [location, setLocation] = useState([])
  const [inviteLang, setinviteLang] = useState('')
  const [language_auto_detected, setlanguage_auto_detected] = useState('')

  useEffect(() => {
    if (customer && visible) {
      setlanguage_auto_detected(getLongName(customer.language_auto_detected))
      setinviteLang(customer.language)
      form.setFieldsValue({
        ...customer,
        firstName: customer ? customer.firstName : '',
        phone: checkFormatPhoneNumber(customer.phone, true),
      })
    }
  }, [form, conversation, customer, visible])

  useEffect(() => {
    const loadSelecedLocation = async () => {
      if (conversation._location_id) {
        const customerLocation = await locationActions.getSingle(
          conversation._location_id
        )
        if (customerLocation.success) {
          setLocation(customerLocation.data)
        }
      } else setLocation(userLocation)
    }
    if (visible) {
      loadSelecedLocation()
    }
  }, [conversation, visible])

  const onFinish = async (values) => {
    values._location_id = location?._id
    values.language = inviteLang
    if (values?.phone.startsWith('04')) {
      values.phone = `61${values.phone.slice(1)}`
    }
    if (!Object.keys(conversation).length) {
      const result = await messageActions.apply(values.phone)
      if (result.success) {
        if (result.data.conversation.is_archived) {
          notifyError(getText('TEXT_YOU_CANT_WRITE_MESSAGE_IN_THE_ARCHIVED_FOLDER'))
          return
        }
      }
    }
    if (conversation?.kind === 'messenger') {
      delete values.phone
    }
    if (!conversation || conversation?.kind === SMS) {
      if (values.hasOwnProperty('messengerId')) {
        delete values.messengerId
      }
    }
    if (conversation?.isNew) {
      values.conversationId = conversation._id
    }
    setSaving(true)
    const result = await invitationActions.sendInvite(values, conversation)
    onCloseMobileDesk && onCloseMobileDesk()
    setSaving(false)
    if (result.success) {
      onSaveInvitation && onSaveInvitation()
      notifySuccess(getText('NTF_SUCCESS_INVITE'))
      setVisible(false)
    } else {
      let invalidPhoneNumberError = `To number: +${values.phone}, is not a mobile number`
      if (result.errMsg === invalidPhoneNumberError) {
        notifyError(
          getTextServerError(
            `Impossible to send the invitation to number +${values.phone}`
          )
        )
        return
      }
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <ModalAntd
      id={'msg_invitation'}
      title={getText('ACTION_INVITE')}
      trigger={trigger}
      confirmLoading={saving}
      visible={visible}
      forceRender={false}
      formName='formSendInvitation'
      onTrrigerClick={() => {
        if (!disabled) {
          setVisible(true)
        }
        form.resetFields()
      }}
      onCancel={() => {
        setVisible(false)
      }}
      okText={getText('ACTION_INVITE')}
      onOk={() => {
        setMixpanel(
          mixpanel,
          'Review invite sent',
          user.createdAt,
          user.fullName,
          user.organization.name,
          user.location.name,
          null,
          user.organization?._id,
          user.location?._id,
          user?._id
        )
      }}
    >
      <MatForm
        form={form}
        name='formSendInvitation'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <MatRow>
          <InputFormText
            name='firstName'
            label={getText('WORD_FIRST_NAME')}
            required={false}
          />
          <InputFormText
            name='lastName'
            label={getText('WORD_LAST_NAME')}
            required={false}
          />
        </MatRow>
        <MatRow>
          {conversation.kind === 'messenger' ? (
            <InputFormText
              name='messengerId'
              label={getText('WORD_MESSENGER_ID')}
              required
              disabled={true}
            />
          ) : (
            <InputFormPhone
              initialCountry={getInitialCountry(
                customer?.phone || user?.organization?.twilio_number
              )}
              name='phone'
              label={getText('WORD_PHONE')}
              onChange={(value) => {
                form.setFieldsValue({ phone: value })
              }}
              disabled={!Boolean(disableInvitationPhoneNumber)}
              required={!Boolean(disableInvitationPhoneNumber)}
            />
          )}
          <InputFormEmail
            name='email'
            label={getText('WORD_EMAIL')}
            required={false}
            placeholder={getText('WORD_EMAIL')}
          />
        </MatRow>
        {!language_auto_detected && (
          <div className='customer-modal-auto-detect-language-div'>
            <SVGDetectedLanguage />
            {`${getText(
              'WORD_AUTO_DETECTED_LANGUAGE_IS'
            )} ${language_auto_detected}`}
          </div>
        )}
        {/* {!organization?.twilio_number.startsWith('+61') ? ( */}
        <div className='send-invitation-modal-image-previews'>
          {organization?.languages.includes('en') && (
            <div
              className={'lang-card ' + (inviteLang === 'en' ? ' selected' : '')}
              onClick={() => setinviteLang('en')}
            >
              <h3>{getText('WORD_MSG_PREVIEW_EN')}</h3>
              <div className='image-wrapper'>
                <img src={location.pic_en} alt='' />
              </div>
            </div>
          )}
          {organization?.languages.includes('fr') && (
            <div
              className={'lang-card ' + (inviteLang === 'fr' ? ' selected' : '')}
              onClick={() => setinviteLang('fr')}
            >
              <h3>{getText('WORD_MSG_PREVIEW_FR')}</h3>
              <div className='image-wrapper'>
                <img src={location.pic_fr} alt='' />
              </div>
            </div>
          )}
          {organization?.languages.includes('sp') && (
            <div
              className={'lang-card ' + (inviteLang === 'sp' ? ' selected' : '')}
              onClick={() => setinviteLang('sp')}
            >
              <h3>{getText('WORD_MSG_PREVIEW_SP')}</h3>
              <div className='image-wrapper'>
                <img src={location.pic_sp} alt='' />
              </div>
            </div>
          )}
        </div>
        {/* ) : (
          <SelectOrgLanguageForm
        //     name='language'
        //     label={getText('WORD_LANGUAGE')}
        //     organization={organization}
        //   />
         )} */}
      </MatForm>
    </ModalAntd>
  )
}

export default memo(ContactSendInvitationModal)
