import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import messageActions from '../../store/modules/messageActions'
import { userSelector } from '../../store/selectors/selectors'
import { getText, getTextServerError } from '../../lang'
import { notifyError, notifySuccess } from '../../utils/Notify'
import MatModal from '../MatModal'
import DateTimePicker from './DateTimePicker'

const DateTimePickerModal = (props) => {
  const {
    trigger,
    conversation,
    addToList,
    unarchiveConversation,
    unAssignConversation,
    closePopover,
    title,
    remindedDay,
    onCloseMobileDesk,
  } = props

  let user = useSelector(userSelector)

  const [visible, setVisible] = useState(false)
  const [saving] = useState(false)
  const [date, setDate] = useState()

  const handleSaveReminder = async (notifyAt) => {
    const result = await messageActions.conversationReminders(
      conversation._id,
      notifyAt
    )
    const isArchived = conversation.is_archived
    const unassigned = conversation.unassigned
    if (isArchived) {
      unarchiveConversation()
    }
    if (unassigned) {
      unAssignConversation(conversation)
      await messageActions.assignUser(conversation._id, user._id)
    }
    if (result.success) {
      result.data.isReminded = true
      addToList && addToList(result.data)
      notifySuccess(getText('TEXT_MESSAGE_REMINDED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  return (
    <MatModal
      width={346}
      className={'message_date_picker_dropdown'}
      title={title}
      trigger={trigger}
      confirmLoading={saving}
      visible={visible}
      onTrrigerClick={() => {
        setVisible(true)
      }}
      onCancel={() => {
        setVisible(false)
      }}
      okText={getText('ACTION_SAVE')}
      onOk={() => {
        handleSaveReminder(date)
        closePopover()
        onCloseMobileDesk && onCloseMobileDesk()
        setVisible(false)
      }}
    >
      <DateTimePicker
        visible={visible}
        setCorrectFormatDate={setDate}
        remindedDay={remindedDay}
        isRemindMeToFollowUp
      />
    </MatModal>
  )
}

export default memo(DateTimePickerModal)
