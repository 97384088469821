import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.5 13.1667V12.0694C14.5 11.5242 14.1681 11.0339 13.6619 10.8314L12.3058 10.289C11.6619 10.0314 10.9281 10.3104 10.618 10.9307L10.5 11.1667C10.5 11.1667 8.83333 10.8333 7.5 9.5C6.16667 8.16667 5.83333 6.5 5.83333 6.5L6.06934 6.38199C6.68959 6.07187 6.96857 5.33809 6.71103 4.69424L6.16859 3.33815C5.96611 2.83194 5.47583 2.5 4.93062 2.5H3.83333C3.09695 2.5 2.5 3.09695 2.5 3.83333C2.5 9.72437 7.27563 14.5 13.1667 14.5C13.903 14.5 14.5 13.903 14.5 13.1667Z'
        fill={props.color || '#6B75EC'}
      />
    </svg>
  )
}

const SVGCallsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGCallsIcon
