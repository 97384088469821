import React, { useImperativeHandle, forwardRef, memo } from 'react'
import MatRangePickerSecondary from '../../../components/MatRangePicker/MatRangePickerSecondary'
import utilDate from '../../../utils/utilsDate'
import useDeviceCheck from '../../../utils/useDeviceCheck'

const DateFilter = forwardRef(({ onChangeDate, selectedDate }, ref) => {
  const { isMobile } = useDeviceCheck()

  useImperativeHandle(ref, () => {
    return {
      resetDate: () => {
        onChangeDate([])
      },
    }
  })

  return (
    <div className='dropdown-with-selected-item'>
      <MatRangePickerSecondary
        allowEmpty={[true, true]}
        value={selectedDate}
        onChange={(mode, val) => {
          onChangeDate(
            val.map((item) => {
              if (item === '') {
                return item
              } else {
                return utilDate.getDateByDayJS(item)
              }
            })
          )
          if (!val[0] && !val[1]) {
            onChangeDate([])
          }
        }}
        inputReadOnly={isMobile}
        disabledDate={(current) => {
          return current && current > utilDate.endDateByDayJS('year')
        }}
      />
    </div>
  )
})

export default memo(DateFilter)
