import React, { forwardRef, useMemo } from 'react'
import { Table } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { getText } from '../../../lang'
import SVGSelectArrow from '../../../icons/SVG/SVGSelectArrow'
import './MyDefaultTableList.scss'

const MyDefaultTableList = forwardRef(
  (
    {
      loading,
      initialLoad,
      pageStart,
      onLoadMore,
      isReverse,
      data,
      totalDataCount,
      inifiniteScrollClass,
      getScrollParent,
      height,
      columns,
      onChange,
      scroll,
      expandable,
      rowKey,
      key,
      title,
      rowSelection,
      className,
      summary,
      expandedRowRender,
    },
    ref
  ) => {
    const newData = useMemo(() => {
      const list = (data || [])?.map((item, index) => {
        item.key = index
        return item
      })
      return list
    }, [data])

    return (
      <InfiniteScroll
        ref={ref}
        initialLoad={initialLoad}
        pageStart={pageStart}
        loadMore={onLoadMore}
        isReverse={isReverse}
        hasMore={!loading && data?.length < totalDataCount}
        useWindow={false}
        threshold={150}
        className={`my_default_infinite_scroll ${inifiniteScrollClass || ''}`}
        getScrollParent={getScrollParent}
        height={height}
      >
        <Table
          className={`my_default_table_list ${className || ''}`}
          key={key}
          sticky={{
            offsetHeader: -24,
          }}
          tableLayout={'auto'}
          rowKey={rowKey}
          columns={columns}
          loading={loading}
          dataSource={newData}
          onChange={onChange}
          rowSelection={rowSelection}
          scroll={
            scroll || {
              x: 600,
            }
          }
          expandable={
            expandable
              ? {
                  expandIcon: ({ expanded, onExpand, record }) => (
                    <SVGSelectArrow
                      onClick={(e) => onExpand(record, e)}
                      style={{
                        transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
                      }}
                    />
                  ),
                  expandedRowRender: expandedRowRender,
                }
              : undefined
          }
          title={title}
          summary={summary}
          locale={{
            triggerDesc: getText('TEXT_CLICK_SORT_DESCENDING'),
            triggerAsc: getText('TEXT_CLICK_SORT_ASCENDING'),
            cancelSort: getText('TEXT_CLICK_SORT_CANCEL'),
          }}
          pagination={false}
        />
      </InfiniteScroll>
    )
  }
)

export default MyDefaultTableList
