import React, { memo } from 'react'
import { Flex } from 'antd'
import SVGEmailSelect from '../../../../../icons/SVG/SVGEmailSelect'
import SVGSMSSelect from '../../../../../icons/SVG/SVGSMSSelect'
import { getText } from '../../../../../lang'
import MatSelect from '../../../../../components/MatSelect'
import { EMAIL_DEFAULT_OBJECT } from '../../../utils/messageUtils'
import { EMAIL, PHONE, TAG } from '../../../utils/constants'
import { MainColor } from '../../../../../utils'
import SVGTagSelect from '../../../../../icons/SVG/SVGTagSelect'
import { FB_MESSANGER, IG_MESSANGER } from '../../../../../devOptions'
import SVGAiBotFacebooklcon from '../../../../../icons/SVG/SVGAiBotFacebooklcon'
import SVGAiBotInstalcon from '../../../../../icons/SVG/SVGAiBotInstalcon'
import './MessageInputSwitchAction.scss'

const SelectSwitchSMSEmail = (props) => {
  const {
    msgTypeDefault,
    msgChangeEmailSMSType,
    setnewEmailSubject,
    setnewMessageValue,
    setmsgTypeDefault,
    onChangeEmailSMSType,
    receiver,
    disabled,
    isMobile,
    source,
  } = props

  const options = [
    ...(receiver?.email
      ? [
          {
            label: (
              <Flex justify='space-between' align='center' style={{ width: '100%' }}>
                <Flex>
                  <SVGEmailSelect
                    color={msgChangeEmailSMSType === EMAIL ? MainColor : ''}
                  />
                  <span className='not-show'>{getText('WORD_EMAIL')}</span>
                </Flex>
                {msgTypeDefault === EMAIL ? (
                  <span className='default'>{getText('WORD_DEFAULT')}</span>
                ) : (
                  ''
                )}
              </Flex>
            ),
            value: EMAIL,
          },
        ]
      : []),
    ...(receiver?.phone
      ? [
          {
            label: (
              <Flex justify='space-between' align='center' style={{ width: '100%' }}>
                <Flex>
                  <SVGSMSSelect
                    color={msgChangeEmailSMSType === PHONE ? MainColor : ''}
                  />
                  <span className='not-show'>{getText('WORD_SMS_UC')}</span>
                </Flex>
                {msgTypeDefault === PHONE ? (
                  <span className='default'>{getText('WORD_DEFAULT')}</span>
                ) : (
                  ''
                )}
              </Flex>
            ),
            value: PHONE,
          },
        ]
      : []),
    ...(source === FB_MESSANGER
      ? [
          {
            label: (
              <Flex justify='space-between' align='center' style={{ width: '100%' }}>
                <Flex>
                  <SVGAiBotFacebooklcon
                    color={
                      msgChangeEmailSMSType !== FB_MESSANGER &&
                      'var(--thridyTextColor)'
                    }
                    width={18}
                    height={18}
                  />
                  <span className='not-show'>{getText('WORD_FB_MESSENGER')}</span>
                </Flex>
                {msgTypeDefault === FB_MESSANGER ? (
                  <span className='default'>{getText('WORD_DEFAULT')}</span>
                ) : (
                  ''
                )}
              </Flex>
            ),
            value: FB_MESSANGER,
          },
        ]
      : []),
    ...(source === IG_MESSANGER
      ? [
          {
            label: (
              <Flex justify='space-between' align='center' style={{ width: '100%' }}>
                <Flex>
                  <SVGAiBotInstalcon
                    style={{
                      filter:
                        msgChangeEmailSMSType !== IG_MESSANGER
                          ? 'grayscale(1)'
                          : null,
                    }}
                    width={18}
                    height={18}
                  />
                  <span className='not-show'>{getText('WORD_IG_MESSENGER')}</span>
                </Flex>
                {msgTypeDefault === IG_MESSANGER ? (
                  <span className='default'>{getText('WORD_DEFAULT')}</span>
                ) : (
                  ''
                )}
              </Flex>
            ),
            value: IG_MESSANGER,
          },
        ]
      : []),
    ...(isMobile
      ? [
          {
            label: (
              <Flex justify='space-between' align='center' style={{ width: '100%' }}>
                <Flex>
                  <SVGTagSelect
                    color={msgChangeEmailSMSType === TAG ? MainColor : ''}
                  />
                  <span className='not-show'>Tag User</span>
                </Flex>
                {msgTypeDefault === TAG ? (
                  <span className='default'>{getText('WORD_DEFAULT')}</span>
                ) : (
                  ''
                )}
              </Flex>
            ),
            value: TAG,
          },
        ]
      : []),
  ]

  return (
    <Flex className={`message_type_select_wrapper ${!isMobile ? 'desktop' : ''}`}>
      <MatSelect
        disabled={disabled}
        options={options}
        onClick={
          options.length === 1 && !disabled
            ? () => {
                onChangeEmailSMSType && onChangeEmailSMSType(options[0].value)
              }
            : null
        }
        open={options.length <= 1 ? false : undefined}
        disableSuffix={options.length === 1}
        value={
          !isMobile && msgChangeEmailSMSType === TAG
            ? receiver?.phone
              ? PHONE
              : receiver?.email
                ? EMAIL
                : source === FB_MESSANGER
                  ? FB_MESSANGER
                  : source === IG_MESSANGER
                    ? IG_MESSANGER
                    : msgChangeEmailSMSType
            : msgChangeEmailSMSType
        }
        onSelect={(value) => {
          if (value === 'email') {
            setnewEmailSubject(EMAIL_DEFAULT_OBJECT)
          }
          setnewMessageValue((prevValues) => {
            return prevValues
          })
          setmsgTypeDefault(value)
          onChangeEmailSMSType && onChangeEmailSMSType(value)
        }}
        dropdownClassName={'sms-email-dropdown-wrapper'}
      />
      {Boolean(!isMobile) && (
        <SVGTagSelect
          className={'tag_select_icon'}
          color={msgChangeEmailSMSType === TAG ? MainColor : ''}
          onClick={() => {
            if (msgChangeEmailSMSType !== TAG) {
              onChangeEmailSMSType && onChangeEmailSMSType(TAG)
            }
          }}
        />
      )}
    </Flex>
  )
}

export default memo(SelectSwitchSMSEmail)
