import React from 'react'
import { Tag } from 'antd'
import './DashboardTags.scss'

const DashboardTags = ({ text, color, style }) => {
  return (
    <Tag style={style} className='dashboard_tags' color={color || 'var(--green)'}>
      {text}
    </Tag>
  )
}

export default DashboardTags
