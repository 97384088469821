import React from 'react'
import { Select, Form } from 'antd'

const { Option } = Select

const CustomQuestionSelector = ({ data, index, value, onChange }) => {
  const [form] = Form.useForm()

  const getInitialValues = () => {
    if (value) {
      return value.map((item) => {
        return JSON.stringify({
          locationId: item.locationId,
          organizationId: item.organizationId,
          questionId: item.questionId,
          organizationName: item.organizationName || item.organization.name,
          locationName: item.locationName || item.location.name,
          question: item.question.question.en,
        })
      })
    }
  }

  const findQuestion = (questionId) => {
    let question = null
    data.forEach((organization) => {
      organization.locations.forEach((location) => {
        location.questions.forEach((questionItem) => {
          if (questionItem._id === questionId) {
            question = {
              locationId: location?._id,
              organizationId: organization?._id,
              questionId: questionItem._id,
              organizationName: organization.name,
              locationName: location.name,
              question: {
                ...questionItem,
              },
            }
          }
        })
      })
    })
    return question
  }

  const onChangeEvent = (value) => {
    const parsedValue = value.map((item) => JSON.parse(item))

    const questions = parsedValue.map((item) => {
      return findQuestion(item.questionId)
    })

    onChange(questions)
  }

  return (
    <div>
      <Form form={form}>
        <Form.Item label='Select Questions *'>
          <Select
            mode='multiple'
            style={{ maxWidth: '100%' }}
            placeholder='Select questions'
            onChange={onChangeEvent}
            name={`${index}custom-questions`}
            defaultValue={getInitialValues()}
          >
            {data.map((organization) =>
              organization.locations.map((location) =>
                location.questions.map((question) => (
                  <Option
                    key={question._id}
                    value={JSON.stringify({
                      locationId: location?._id,
                      organizationId: organization?._id,
                      questionId: question._id,
                      organizationName: organization.name,
                      locationName: location.name,
                      question: question.question.en,
                    })}
                  >
                    {`${organization.name} -  ${location.name} - ${question.question.en}`}
                  </Option>
                ))
              )
            )}
          </Select>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CustomQuestionSelector
