import React, { useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { Collapse, Form } from 'antd'
import { useSelector } from 'react-redux'
import { userSelector } from '../../store/selectors/selectors'
import userActions from '../../store/modules/userActions'
import authActions from '../../store/modules/authActions'
import organizationActions from '../../store/modules/organizationActions'
import { getText, getTextServerError } from '../../lang'
import { getUserRole, moveToLoginPage } from '../../utils'
import { notifyError, notifySuccess } from '../../utils/Notify'
import PageFix from '../../components/PageFix'
import { useLayout } from '../../layout/LayoutProvider/LayoutProvider'
import MatForm from '../../components/Form/MatForm'
import LoadingSpinner from '../../components/LoadingSpinner'
import useDeviceCheck from '../../utils/useDeviceCheck'
import { SUBMIT_BTN } from '../../devOptions'
import CollapseTitle from './itemsCollapse/CollapseTitle'
import CollapseChildren from './itemsCollapse/CollapseChildren'

function transformData(data) {
  return data.map((item) => {
    const newItem = {}
    Object.keys(item).forEach((key) => {
      if (key === 'name') {
        newItem['title'] = item[key]
      } else if (key === '_id') {
        newItem['value'] = item[key]
        newItem['key'] = item[key]
      } else if (key === 'locations') {
        newItem['children'] = transformData(item[key])
      } else {
        newItem[key] = item[key]
      }
    })
    return newItem
  })
}

const reducerNotifications = (state, action) => {
  return { ...state, [action.name]: action.payload }
}

const ProfileNotification = () => {
  const { setPageTitle, setFooterButtons } = useLayout()
  const user = useSelector(userSelector)
  const { isMobile } = useDeviceCheck()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isOrgLoading, setIsOrgLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [organizationsData, setOrganizationsData] = useState({
    list: [],
    total: 0,
  })
  const [isDataTree, setIsDataTree] = useState(true)
  const [treeData, setTreeData] = useState({
    list: [],
    total: 0,
  })
  const [treePage, setTreePage] = useState(0)

  if (!user.notifications) {
    moveToLoginPage(navigate)
    localStorage.clear()
  }

  const { notifications = {} } = user

  const {
    assignToConversation = {},
    unreadMessage = {},
    unassignFromConversation = {},
    paymentStatus = {},
    integrationDisconnected = {},
    unhappyCustomer = {},
    newsletterReports = {},
    doNotDisturb,
    taggedInConversation = {},
  } = notifications

  const [notifState, dispatch] = useReducer(reducerNotifications, {
    expandedPanels: [],
    unreadMessageEnabled: unreadMessage.enabled || false,
    assignToConversationEnabled: assignToConversation.enabled || false,
    unassignFromConversationEnabled: unassignFromConversation.enabled || false,
    unreadMessageAllOrgs:
      unreadMessage.enabled && _.isEmpty(unreadMessage.organizations) ? true : false,
    assignToConversationAllOrgs:
      assignToConversation.enabled && _.isEmpty(assignToConversation.organizations)
        ? true
        : false,
    unassignFromConversationAllOrgs:
      unassignFromConversation.enabled &&
      _.isEmpty(unassignFromConversation.organizations)
        ? true
        : false,
    paymentNotificationEnabled: paymentStatus.enabled || false,
    integrationDisconnectedEnabled: integrationDisconnected.enabled || false,
    paymentNotificationAllOrgs:
      paymentStatus.enabled && _.isEmpty(paymentStatus.organizations) ? true : false,
    unhappyCustomerEnabled: unhappyCustomer.enabled || false,
    unhappyCustomerAllOrgs:
      unhappyCustomer.enabled && _.isEmpty(unhappyCustomer.organizations)
        ? true
        : false,
    newsletterReportsEnabled:
      (newsletterReports.enabled && user?.restrictions?.allowReceiveNewsletters) ||
      false,
    newsletterReportsAllOrgs:
      newsletterReports.enabled && newsletterReports.receiveFromAllOrganizations,
    doNotDisturbEnabled: doNotDisturb || false,
    taggedInConversationEnabled: true,
    taggedInConversationAllOrgs:
      taggedInConversation.enabled && _.isEmpty(taggedInConversation.organizations)
        ? true
        : false,
  })

  const [form] = Form.useForm()
  const organization = authActions.getOrganization()
  const hasAccessToMultipleOrganizations =
    user.accessibleOrganizations && user.accessibleOrganizations.length > 0

  useEffect(() => {
    if (user.isSuperAdmin || hasAccessToMultipleOrganizations) {
      const fill = async () => {
        const result = await organizationActions.getOrganizationsLocationsList(
          treePage,
          100,
          '',
          {
            enabled: true,
          },
          false,
          getUserRole(
            user.isSuperAdmin,
            user.isAdmin,
            user.isManager,
            user.isSupportAgent
          ),
          user._location_id
        )
        if (result.success) {
          const list = transformData(result.data)
          const newTreeData = treePage > 0 ? [...treeData.list, ...list] : list
          setTreeData({
            list: newTreeData,
            total: result.max,
          })
          if (newTreeData.length < result.max) {
            setTreePage(treePage + 1)
          }
        }
        setIsDataTree(false)
      }
      fill()
    }
  }, [treePage])

  useEffect(() => {
    if (user.isSuperAdmin || hasAccessToMultipleOrganizations) {
      const fill = async () => {
        const result = await organizationActions.getPageList(page, 50, '', {
          enabled: true,
        })
        if (result.success) {
          const organizationsList = result.data.map((org) => ({
            value: org._id,
            label: org.name,
            ...org,
          }))

          const newListOrganizations =
            page > 0
              ? [...organizationsData.list, ...organizationsList]
              : organizationsList
          setOrganizationsData({
            list: newListOrganizations,
            total: result.max,
          })
          if (newListOrganizations.length < result.max) {
            setPage(page + 1)
          }
        }
        setIsOrgLoading(false)
      }
      fill()
    }
  }, [page])

  useEffect(() => {
    setPageTitle(getText('WORD_PROFILE_NOTIFICATION'))
    setFooterButtons([
      {
        type: SUBMIT_BTN,
      },
    ])
  }, [])

  function createObject(obj, array) {
    const result = {}

    for (const item of obj) {
      const id = item.id

      if (Array.isArray(array) && array.includes(id)) {
        result[id] = []
      } else {
        for (const location of item.children) {
          const orgId = location._organization_id
          const childId = location.id
          if (Array.isArray(array) && array.includes(childId)) {
            if (!result[orgId]) {
              result[orgId] = []
            }

            result[orgId].push(location.id)
          }
        }
      }
    }

    return result
  }

  const onFinish = async (values) => {
    setIsLoading(true)
    const { notifications } = form.getFieldsValue(true)

    const notificationsObjToSend = JSON.parse(JSON.stringify(notifications))

    if (notifState.unreadMessageAllOrgs) {
      notificationsObjToSend.unreadMessage.organizations = {}
    } else {
      const changedData = createObject(
        treeData.list,
        values.notifications.unreadMessage.organizations
      )
      notificationsObjToSend.unreadMessage.organizations = changedData
    }
    if (notifState.assignToConversationAllOrgs) {
      notificationsObjToSend.assignToConversation.organizations = {}
    } else {
      const changedData = createObject(
        treeData.list,
        values.notifications.assignToConversation.organizations
      )
      notificationsObjToSend.assignToConversation.organizations = changedData
    }
    if (
      notifState.unassignFromConversationAllOrgs ||
      !Boolean(values.notifications.unassignFromConversation)
    ) {
      notificationsObjToSend.unassignFromConversation.organizations = {}
    } else {
      if (Boolean(values.notifications.unassignFromConversation)) {
        const changedData = createObject(
          treeData.list,
          values.notifications.unassignFromConversation.organizations
        )
        notificationsObjToSend.unassignFromConversation.organizations = changedData
      }
    }

    if (Boolean(values.notifications.newsletterReports)) {
      notificationsObjToSend.newsletterReports.reportTypes =
        values.notifications.newsletterReports.method || []

      notificationsObjToSend.newsletterReports.organizationsForReceive =
        notificationsObjToSend.newsletterReports.organizations
    }

    if (notificationsObjToSend?.newsletterReports?.method) {
      delete notificationsObjToSend.newsletterReports.method
    }
    if (notificationsObjToSend?.newsletterReports?.organizations) {
      delete notificationsObjToSend.newsletterReports.organizations
    }

    if (
      notifState.newsletterReportsAllOrgs ||
      !Boolean(values.notifications.newsletterReports)
    ) {
      notificationsObjToSend.newsletterReports.organizationsForReceive = []
      notificationsObjToSend.newsletterReports.receiveFromAllOrganizations = true
    }

    if (notifState.paymentNotificationAllOrgs) {
      notificationsObjToSend.paymentStatus.organizations = {}
    } else {
      const changedData = createObject(
        treeData.list,
        values.notifications.paymentStatus.organizations
      )
      notificationsObjToSend.paymentStatus.organizations = changedData
    }
    if (notifState.unhappyCustomerAllOrgs) {
      notificationsObjToSend.unhappyCustomer.organizations = {}
    } else {
      const changedData = createObject(
        treeData.list,
        values.notifications.unhappyCustomer &&
          values.notifications.unhappyCustomer.organizations
      )
      notificationsObjToSend.unhappyCustomer.organizations = changedData
    }
    if (notifState.taggedInConversationAllOrgs) {
      notificationsObjToSend.taggedInConversation.organizations = {}
    } else {
      const changedData = createObject(
        treeData.list,
        values.notifications.taggedInConversation &&
          values.notifications.taggedInConversation.organizations
      )
      notificationsObjToSend.taggedInConversation.organizations = changedData
    }

    if (user.isSupportAgent) {
      notificationsObjToSend.unhappyCustomer.enabled = false
      notificationsObjToSend.integrationDisconnected.enabled = false
    }

    const obj = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      isAdmin: user.isAdmin,
      isManager: user.isManager,
      isSuperAdmin: user.isSuperAdmin,
      isSupportAgent: user.isSupportAgent,
      _location_id: user._location_id,
      enabled: user.enabled,
      accessibleOrganizations: user.accessibleOrganizations,
      notifications: notificationsObjToSend,
    }

    if (user.notifications.devices && user.notifications.devices.length > 0) {
      obj.notifications.devices = user.notifications.devices
    }

    const result = await userActions.saveUpdate(obj, user?._id)
    if (result.success) {
      authActions.setUserData(result.data.user)
      dispatch({
        name: 'expandedPanels',
        payload: [],
      })
      notifySuccess(getText('NTF_SUCCESS_SAVE_PROFILE'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setIsLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const handleNotificationEnabledToggle = (value, notificationName, name) => {
    const newMethod = form.getFieldValue(name).method
    dispatch({ name: notificationName, payload: value })
    if (!value) {
      form.validateFields()
      if (newMethod) {
        let index = newMethod.indexOf('push')
        if (index !== -1) {
          newMethod.splice(index, 1)
        }
      }
    } else {
      if (
        newMethod &&
        newMethod.length === 0 &&
        notificationName !== 'newsletterReportsEnabled'
      ) {
        newMethod.push('push')
      }
    }
  }

  const getListOption = () => {
    let list = []
    if (Boolean(user.phone)) {
      list.push({ value: 'phone', label: getText('WORD_SMS').toUpperCase() })
    }
    if (Boolean(user.email)) {
      list.push({ value: 'email', label: getText('WORD_EMAIL') })
    }
    list.push({ value: 'push', label: getText('WORD_PUSH_NOTIFICATIONS') })
    return list
  }

  const handlePropagation = (e, panelKey) => {
    if (notifState.expandedPanels.includes(panelKey) || notifState[panelKey]) {
      e.stopPropagation()
    }
  }

  const getActivePanels = () => [
    notifState.unreadMessageEnabled && 'unreadMessage',
    notifState.assignToConversationEnabled && 'assignToConversation',
    notifState.unassignFromConversationEnabled && 'unassignFromConversation',
    notifState.paymentNotificationEnabled && 'paymentStatus',
    notifState.unhappyCustomerEnabled && 'unhappyCustomer',
    notifState.newsletterReportsEnabled && 'newsletterReports',
    notifState.taggedInConversationEnabled && 'taggedInConversation',
  ]

  const getNotificationOrganization = (org, field) => {
    if (_.isEmpty(org)) {
      form.setFieldValue(['notifications', field, 'organizations'], [])
      return []
    } else {
      const resultArr = []
      for (const key in org) {
        if (org[key].length === 0) {
          resultArr.push(key)
        } else {
          resultArr.push(...org[key])
        }
      }
      if (treeData.list.length > 0) {
        form.setFieldValue(['notifications', field, 'organizations'], resultArr)
        return resultArr
      }
    }
  }

  useEffect(() => {
    const initialValues = {
      unreadMessage: {
        enabled: unreadMessage ? unreadMessage.enabled : false,
        method: unreadMessage ? unreadMessage.method : [],
        period: unreadMessage ? unreadMessage.period : 1,
        organizations: unreadMessage
          ? getNotificationOrganization(unreadMessage.organizations, 'unreadMessage')
          : [],
      },
      newsletterReports: {
        enabled: newsletterReports.enabled || false,
        method: newsletterReports
          ? newsletterReports.method || newsletterReports.reportTypes || []
          : [],
        organizations: newsletterReports
          ? newsletterReports.organizationsForReceive
          : [],
      },
      unassignFromConversation: {
        enabled: unassignFromConversation.enabled || false,
        method: unassignFromConversation.method || [],
        period: unassignFromConversation.period || 1,
        organizations: unreadMessage
          ? getNotificationOrganization(
              unassignFromConversation.organizations,
              'unassignFromConversation'
            )
          : [],
      },

      assignToConversation: {
        enabled: assignToConversation.enabled || false,
        method: assignToConversation.method || [],
        organizations: unreadMessage
          ? getNotificationOrganization(
              assignToConversation.organizations,
              'assignToConversation'
            )
          : [],
      },
      paymentStatus: {
        enabled: paymentStatus.enabled || false,
        method: paymentStatus.method || [],
        organizations: unreadMessage
          ? getNotificationOrganization(paymentStatus.organizations, 'paymentStatus')
          : [],
      },
      integrationDisconnected: {
        enabled: integrationDisconnected.enabled || false,
      },
      unhappyCustomer: {
        enabled: unhappyCustomer.enabled || false,
        organizations: unhappyCustomer
          ? getNotificationOrganization(
              unhappyCustomer.organizations,
              'unhappyCustomer'
            )
          : [],
      },
      doNotDisturb: doNotDisturb || false,
      taggedInConversation: {
        enabled: true,
        method: taggedInConversation ? taggedInConversation.method : [],
        organizations: taggedInConversation
          ? getNotificationOrganization(
              taggedInConversation.organizations,
              'taggedInConversation'
            )
          : [],
      },
    }
    form.setFieldValue('notifications', initialValues)
  }, [treeData.list.length, user.notifications])

  const generateItemCollapse = (obj) => {
    return [
      {
        hidecollapse: obj.hidecollapse,
        collapse: obj.collapse,
        key: obj.key,
        label: (
          <CollapseTitle
            title={obj.title}
            infoBlockText={obj.infoBlockText}
            name={obj.name}
            isToolTip={obj.isToolTip}
            switchDisabled={obj.switchDisabled}
            onChange={(value) => obj.onChange && obj.onChange(value)}
            onClick={(e) => handlePropagation(e, obj.key)}
            isMobile={isMobile}
          />
        ),
        showArrow: false,
        children: obj.children,
      },
    ]
  }

  const arrItemsCollapse = [
    generateItemCollapse({
      key: 'unreadMessage',
      name: ['notifications', 'unreadMessage', 'enabled'],
      title: 'TEXT_UNREAD_CONVERSATIONS',
      onChange: (value) =>
        handleNotificationEnabledToggle(value, 'unreadMessageEnabled', [
          'notifications',
          'unreadMessage',
        ]),
      children: (
        <CollapseChildren
          isCheckBox={user.isSuperAdmin || hasAccessToMultipleOrganizations}
          collapseKey={'unreadMessage'}
          data={treeData}
          isCheckBoxEnabled={notifState.unreadMessageEnabled}
          onCheckBoxChange={(e) => {
            dispatch({
              name: 'unreadMessageAllOrgs',
              payload: e.target.checked,
            })
          }}
          checkBoxValue={notifState.unreadMessageAllOrgs}
          isLoading={isDataTree}
          getListOption={getListOption}
          isSecondary
          isInputNumber
          isTree
        />
      ),
    }),

    generateItemCollapse({
      key: 'assignToConversation',
      name: ['notifications', 'assignToConversation', 'enabled'],
      title: 'TEXT_ASSIGNED_TO_CONVERSATIONS',
      onChange: (value) =>
        handleNotificationEnabledToggle(value, 'assignToConversationEnabled', [
          'notifications',
          'assignToConversation',
        ]),
      children: (
        <CollapseChildren
          collapseKey={'assignToConversation'}
          isCheckBox={user.isSuperAdmin || hasAccessToMultipleOrganizations}
          data={organizationsData}
          isCheckBoxEnabled={notifState.assignToConversationEnabled}
          checkBoxValue={notifState.assignToConversationAllOrgs}
          isLoading={isOrgLoading}
          getListOption={getListOption}
          isSingle
          isSecondary
          onCheckBoxChange={(e) => {
            dispatch({
              name: 'assignToConversationAllOrgs',
              payload: e.target.checked,
            })
          }}
          onLoadMore={() => {
            if (organizationsData.list.length < organizationsData.total) {
              setPage(page + 1)
            }
          }}
        />
      ),
    }),
    generateItemCollapse({
      key: 'taggedInConversation',
      name: ['notifications', 'taggedInConversation', 'enabled'],
      title: 'TEXT_TAGGED_IN_A_CONVERSATION',
      switchDisabled: true,
      isToolTip: true,
      onChange: (value) =>
        handleNotificationEnabledToggle(value, 'taggedInConversationEnabled', [
          'notifications',
          'taggedInConversation',
        ]),
      children: (
        <CollapseChildren
          collapseKey={'taggedInConversation'}
          isCheckBox={user.isSuperAdmin || hasAccessToMultipleOrganizations}
          data={organizationsData}
          isCheckBoxEnabled={notifState.taggedInConversationEnabled}
          checkBoxValue={notifState.taggedInConversationAllOrgs}
          isLoading={isOrgLoading}
          getListOption={getListOption}
          isSingle
          isSecondary
          onCheckBoxChange={(e) => {
            dispatch({
              name: 'taggedInConversationAllOrgs',
              payload: e.target.checked,
            })
          }}
          onLoadMore={() => {
            if (organizationsData.list.length < organizationsData.total) {
              setPage(page + 1)
            }
          }}
        />
      ),
    }),
    generateItemCollapse({
      hidecollapse: !Boolean(
        (organization &&
          !organization?.restrictions
            .restrictSupportAgentsAccessToUnassignedFolder) ||
          (user && !user.isSupportAgent)
      ),
      key: 'unassignFromConversation',
      name: ['notifications', 'unassignFromConversation', 'enabled'],
      title: 'TEXT_UNASSIGNED_CONVERSATIONS',
      onChange: (value) =>
        handleNotificationEnabledToggle(value, 'unassignFromConversationEnabled', [
          'notifications',
          'unassignFromConversation',
        ]),
      children: (
        <CollapseChildren
          collapseKey={'unassignFromConversation'}
          isCheckBox={user.isSuperAdmin || hasAccessToMultipleOrganizations}
          data={treeData}
          isCheckBoxEnabled={notifState.unassignFromConversationEnabled}
          checkBoxValue={notifState.unassignFromConversationAllOrgs}
          isLoading={isDataTree}
          getListOption={getListOption}
          isTree
          isSecondary
          isInputNumber
          onCheckBoxChange={(e) => {
            dispatch({
              name: 'unassignFromConversationAllOrgs',
              payload: e.target.checked,
            })
          }}
        />
      ),
    }),
    generateItemCollapse({
      key: 'paymentStatus',
      name: ['notifications', 'paymentStatus', 'enabled'],
      title: 'WORD_PAYMENT_NOTIFICATION',
      onChange: (value) =>
        handleNotificationEnabledToggle(value, 'paymentNotificationEnabled', [
          'notifications',
          'paymentStatus',
        ]),
      children: (
        <CollapseChildren
          collapseKey={'paymentStatus'}
          isCheckBox={user.isSuperAdmin || hasAccessToMultipleOrganizations}
          data={organizationsData}
          isCheckBoxEnabled={notifState.paymentNotificationEnabled}
          checkBoxValue={notifState.paymentNotificationAllOrgs}
          isLoading={isOrgLoading}
          getListOption={getListOption}
          isSingle
          isSecondary
          onCheckBoxChange={(e) => {
            dispatch({
              name: 'paymentNotificationAllOrgs',
              payload: e.target.checked,
            })
          }}
          onLoadMore={() => {
            if (organizationsData.list.length < organizationsData.total) {
              setPage(page + 1)
            }
          }}
        />
      ),
    }),
    generateItemCollapse({
      hidecollapse: !Boolean((user.isSuperAdmin || user.isAdmin) && user.email),
      key: 'integrationDisconnected',
      collapse: 'disabled',
      name: ['notifications', 'integrationDisconnected', 'enabled'],
      title: 'TEXT_RECEIVER_EMAIL_SWITCH',
      onChange: (value) =>
        handleNotificationEnabledToggle(value, 'integrationDisconnectedEnabled'),
    }),

    generateItemCollapse({
      hidecollapse: !Boolean(user && !user.isSupportAgent && user.email),
      key: 'unhappyCustomer',
      name: ['notifications', 'unhappyCustomer', 'enabled'],
      title: 'TEXT_RECEIVE_EMAILS_FROM_UNHAPPY_CUSTOMERS',
      onChange: (value) =>
        handleNotificationEnabledToggle(value, 'unhappyCustomerEnabled'),
      children: (
        <CollapseChildren
          collapseKey={'unhappyCustomer'}
          isCheckBox={true}
          data={treeData}
          isCheckBoxEnabled={notifState.unhappyCustomerEnabled}
          checkBoxValue={notifState.unhappyCustomerAllOrgs}
          isLoading={isDataTree}
          isTree
          onCheckBoxChange={(e) => {
            dispatch({
              name: 'unhappyCustomerAllOrgs',
              payload: e.target.checked,
            })
          }}
        />
      ),
    }),
    generateItemCollapse({
      hidecollapse: !Boolean(
        (user?.restrictions?.allowReceiveNewsletters || user.isSuperAdmin) &&
          !user.isSupportAgent
      ),
      key: 'newsletterReports',
      name: ['notifications', 'newsletterReports', 'enabled'],
      title: 'TEXT_RECEIVE_REPORTS_BY_EMAIL',
      infoBlockText: getText('TEXT_RECEIVE_REPORTS_DESCRIPTION'),
      onChange: (value) =>
        handleNotificationEnabledToggle(value, 'newsletterReportsEnabled', [
          'notifications',
          'newsletterReports',
        ]),
      children: (
        <CollapseChildren
          collapseKey={'newsletterReports'}
          isCheckBox={true}
          data={organizationsData}
          isCheckBoxEnabled={notifState.newsletterReportsEnabled}
          checkBoxValue={notifState.newsletterReportsAllOrgs}
          isLoading={isOrgLoading}
          getListOption={() => {
            return [
              { value: 'weekly', label: getText('WEEKLY') },
              { value: 'monthly', label: getText('MONTHLY') },
            ]
          }}
          isSingle
          isSecondary
          onCheckBoxChange={(e) => {
            dispatch({
              name: 'newsletterReportsAllOrgs',
              payload: e.target.checked,
            })
          }}
          onLoadMore={() => {
            if (organizationsData.list.length < organizationsData.total) {
              setPage(page + 1)
            }
          }}
        />
      ),
    }),
    generateItemCollapse({
      key: 'doNotDisturbEnabled',
      collapse: 'disabled',
      name: ['notifications', 'doNotDisturb'],
      title: 'TEXT_DO_NOT_DISTURB_ME_DURING_NON_WORKING_HOURS',
      onChange: (value) =>
        handleNotificationEnabledToggle(value, 'doNotDisturbEnabled'),
      switchDisabled: !Boolean(
        notifState.assignToConversationEnabled ||
          notifState.integrationDisconnectedEnabled ||
          notifState.paymentNotificationEnabled ||
          notifState.unassignFromConversationEnabled ||
          notifState.unhappyCustomerEnabled ||
          notifState.newsletterReportsEnabled ||
          notifState.unreadMessageEnabled ||
          notifState.taggedInConversationEnabled
      ),
    }),
  ]

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <PageFix
          title={getText('WORD_PROFILE_NOTIFICATION')}
          className='profile-notification-page'
        >
          <MatForm form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            {arrItemsCollapse.map((item) => {
              if (!item[0] || item[0].hidecollapse) {
                return null
              }
              return (
                <Collapse
                  key={item[0].key}
                  onChange={(expandedPanelKeys) => {
                    dispatch({
                      name: 'expandedPanels',
                      payload: expandedPanelKeys,
                    })
                  }}
                  defaultActiveKey={getActivePanels()}
                  items={item}
                  collapsible={item[0].collapse || undefined}
                />
              )
            })}
          </MatForm>
        </PageFix>
      )}
    </>
  )
}

export default ProfileNotification
