import React from 'react'
import MyDefaultButton from '../MyDefaultButton'
import './MyDefaultTransparentButton.scss'

const MyDefaultTransparentButton = ({ buttonText, onClick, disabled }) => {
  return (
    <MyDefaultButton
      typeButton={'transparent_button'}
      buttonText={buttonText}
      disabled={disabled}
      onClick={onClick}
    />
  )
}

export default MyDefaultTransparentButton
