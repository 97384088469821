import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='14'
    height='14'
    {...props}
    id='noun_Tag_718340'
    xmlns='http://www.w3.org/2000/svg'
    fill={props.color}
    viewBox='0 0 15 15'
  >
    <path
      id='Path_5674'
      data-name='Path 5674'
      d='M5.059,13.73a1.174,1.174,0,0,0,1.658,0l6.458-6.056A2.678,2.678,0,0,0,14,6.2c.083-1.336.024-3.9-.016-5.251a.96.96,0,0,0-.925-.92C11.322-.014,7.65-.057,7.309.285L.2,6.719c-.456.457-.043,1.612.417,2.072ZM10.717,1.992a.99.99,0,1,1,0,1.4A.993.993,0,0,1,10.717,1.992Z'
      transform='translate(0 0)'
      fillRule='evenodd'
    />
  </svg>
)

const SVGTagIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGTagIcon
