import React from 'react'
import { Flex, Tooltip } from 'antd'
import MyDefaultBlockSubtitle from '../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultEllipsisText from '../../../../components/MyDefaultEllipsisText/MyDefaultEllipsisText'
import { getText } from '../../../../lang'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import broadcastListUtils, { CANCELED, FINISHED } from '../broadcastListUtils'
import SVGMyDefaultEditIcon from '../../../../icons/SVG/SVGMyDefaultEditIcon'
import BroadcastMessageEditModal from '../../BroadcastMessageEditModal'
import Div from '../../../../components/Div/Div'

const BrodcastListExpandedItemMessage = ({ record, onUpdatedMessage }) => {
  const { isTabletPortraitOrMobile } = useDeviceCheck()
  return (
    <Flex vertical gap={5} style={{ width: 210 }}>
      <MyDefaultBlockSubtitle
        subtitle={
          <Flex gap={5}>
            {getText('WORD_BROADCAST_MESSAGE')}
            {record?.status === FINISHED ||
            record?.status === CANCELED ||
            broadcastListUtils.getDiffMinutesForBroadcast(record?.action_time) <=
              5 ? (
              <Tooltip
                zIndex={10000}
                title={getText(
                  record?.canceled
                    ? 'NTF_YOU_CANT_EDIT_CANCELED_CAMPAIGN'
                    : 'TEXT_YOU_CAN_EDIT_CAMPAIGN_ONLY_MINUTES_BEFORE_START'
                )}
              >
                <Div />
                <SVGMyDefaultEditIcon
                  width={16}
                  height={16}
                  style={{ cursor: 'not-allowed' }}
                />
              </Tooltip>
            ) : (
              <BroadcastMessageEditModal
                message={record?.message}
                hasExcel={record?.excelDoc}
                itemId={record?._id}
                trigger={
                  <SVGMyDefaultEditIcon
                    width={16}
                    height={16}
                    style={{ cursor: 'pointer' }}
                  />
                }
                onClose={(updatedMessage) => {
                  onUpdatedMessage && onUpdatedMessage(record?._id, updatedMessage)
                }}
              />
            )}
          </Flex>
        }
      />
      <MyDefaultEllipsisText
        text={record?.message}
        customWidth={isTabletPortraitOrMobile ? null : true}
        width={210}
        image={
          record?.media && record?.media.length > 0
            ? record?.media[0].mediaUrl
            : null
        }
      />
    </Flex>
  )
}

export default BrodcastListExpandedItemMessage
