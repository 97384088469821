import React from 'react'
import { Collapse, Flex, Form } from 'antd'
import { getText } from '../../../../lang'
import CustomTimePicker from '../../../../components/CustomTimePicker/CustomTimePicker'
import MyDefaultSelect from '../../../../components/MyDefaultSelect/MyDefaultSelect'
import Div from '../../../../components/Div/Div'
import SVGSelectArrow from '../../../../icons/SVG/SVGSelectArrow'

const SingleWorkingDayVoiceAIForTablet = (props) => {
  const { field, form, index, disabled } = props

  const days = [
    getText('WORD_SUNDAY'),
    getText('WORD_MONDAY'),
    getText('WORD_TUESDAY'),
    getText('WORD_WEDNESDAY'),
    getText('WORD_THURSDAY'),
    getText('WORD_FRIDAY'),
    getText('WORD_SATURDAY'),
  ]

  const item = Form.useWatch(['workingHours', field.name], form)

  const [fromTime, fromPeriod] = item?.from?.split(' ') || ''
  const [toTime, toPeriod] = item?.to?.split(' ') || ''

  const [hoursFrom, minutesFrom] = fromTime?.split(':') || ''
  const [hoursTo, minutesTo] = toTime?.split(':') || ''

  const items = [
    {
      key: '1',
      label: days.find((day, index) => {
        return field.key === index
      }),
      children: (
        <Div style={{ gap: 8, display: 'grid' }}>
          <MyDefaultSelect
            disabled={disabled}
            className={'store_open_state'}
            name={[field.name, 'isOpen']}
            options={[
              {
                label: getText('TEXT_STORE_IS_OPEN'),
                value: true,
              },
              {
                label: getText('TEXT_STORE_IS_CLOSED'),
                value: false,
              },
            ]}
            showSearch={false}
            isForm
            isLast
            label={null}
          />
          {Boolean(item?.isOpen) && (
            <Flex align='center' vertical gap={8}>
              <CustomTimePicker
                hourValueInit={hoursFrom}
                minuteValueInit={minutesFrom}
                systemHourInit={fromPeriod}
                onChange={(val) => {
                  form.setFieldValue(['workingHours', field.name, 'from'], val)
                }}
                index={`${index}0`}
                disabled={disabled}
              />
              <CustomTimePicker
                hourValueInit={hoursTo}
                minuteValueInit={minutesTo}
                systemHourInit={toPeriod}
                onChange={(val) => {
                  form.setFieldValue(['workingHours', field.name, 'to'], val)
                }}
                index={`${index}1`}
                disabled={disabled}
              />
            </Flex>
          )}
        </Div>
      ),
      style: {
        marginBottom: 12,
        background: 'var(--newBackgroundGrey)',
        borderRadius: 10,
        border: '1px solid var(--borderLineColor)',
        fontSize: 16,
        fontWeight: '500',
        color: 'var(--black)',
      },
    },
  ]

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['0']}
      className='voice_ai_single_working_day_tablet'
      expandIconPosition='end'
      expandIcon={(expanded) =>
        expanded.isActive ? (
          <Div style={{ transform: 'rotate(180deg)' }}>
            <SVGSelectArrow />
          </Div>
        ) : (
          <SVGSelectArrow />
        )
      }
      items={items}
    />
  )

  // return (
  //   <MatRow className='single-working-day'>
  //     <MatRow className='single-working-day-row'>
  //       <span className='days'>
  //         {days.find((day, index) => {
  //           return field.key === index
  //         })}
  //       </span>
  //       <MyDefaultSelect
  //         disabled={props?.disabled}
  //         className={'store_open_state'}
  //         name={[field.name, 'isOpen']}
  //         options={[
  //           {
  //             label: getText('TEXT_STORE_IS_OPEN'),
  //             value: true,
  //           },
  //           {
  //             label: getText('TEXT_STORE_IS_CLOSED'),
  //             value: false,
  //           },
  //         ]}
  //         showSearch={false}
  //         isForm
  //         isLast
  //         label={null}
  //       />
  //     </MatRow>
  //     {Boolean(item?.isOpen) && (
  //       <Flex align='center'>
  //         <CustomTimePicker
  //           hourValueInit={hoursFrom}
  //           minuteValueInit={minutesFrom}
  //           systemHourInit={fromPeriod}
  //           onChange={(val) => {
  //             form.setFieldValue(['workingHours', field.name, 'from'], val)
  //           }}
  //           index={`${index}0`}
  //           disabled={props?.disabled}
  //         />
  //         <Divider />
  //         <CustomTimePicker
  //           hourValueInit={hoursTo}
  //           minuteValueInit={minutesTo}
  //           systemHourInit={toPeriod}
  //           onChange={(val) => {
  //             form.setFieldValue(['workingHours', field.name, 'to'], val)
  //           }}
  //           index={`${index}1`}
  //           disabled={props?.disabled}
  //         />
  //       </Flex>
  //     )}
  //   </MatRow>
  // )
}

export default SingleWorkingDayVoiceAIForTablet
