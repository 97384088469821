import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='35' height='35' rx='17.5' fill='#5B66EA' />
    <rect
      x='0.5'
      y='0.5'
      width='35'
      height='35'
      rx='17.5'
      stroke='url(#paint0_linear_22769_202690)'
    />
    <path
      d='M11.3335 15.6663C11.3335 13.333 12.6668 12.333 14.6668 12.333H21.3335C23.3335 12.333 24.6668 13.333 24.6668 15.6663V20.333C24.6668 22.6663 23.3335 23.6663 21.3335 23.6663H14.6668'
      stroke='white'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.3332 16L19.2465 17.6667C18.5598 18.2133 17.4332 18.2133 16.7465 17.6667L14.6665 16'
      stroke='white'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.3335 21H15.3335'
      stroke='white'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.3335 18.333H13.3335'
      stroke='white'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_22769_202690'
        x1='0'
        y1='0'
        x2='38'
        y2='40.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.12' />
        <stop offset='1' stopColor='white' stopOpacity='0.04' />
      </linearGradient>
    </defs>
  </svg>
)

const SVGVoiceAiEmptySendSMSFollowUpIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGVoiceAiEmptySendSMSFollowUpIcon
