import { getTextServerError } from '../../lang'
import { myWebViewPost } from '../../utils'
import { notifyError } from '../../utils/Notify'

const pageReload = (isMobileApp) => {
  if (isMobileApp) {
    myWebViewPost('HARD_RELOAD_MOBILE', 'HARD_RELOAD_MOBILE')
  } else {
    window.location.reload()
  }
}

export function organizationChangingNavigate(organization, navigate, isMobileApp) {
  var currentUrl = window.location.href
  if (currentUrl.includes('/broadcasting')) {
    if (!organization.enable_broadcast) {
      navigate('/dashboard')
      if (!currentUrl.includes('/broadcasting/list'))
        notifyError(
          getTextServerError(
            'You do not have access to this feature. Please contact your manager.'
          )
        )
    } else {
      if (currentUrl.includes('/broadcasting/create-campaign')) {
        navigate('/broadcasting/list')
      } else {
        pageReload(isMobileApp)
      }
    }
  }
  if (!organization.enable_payments && currentUrl.includes('/payments')) {
    navigate('/dashboard')
  }
  if (currentUrl.includes('/organization/settings')) {
    if (!isMobileApp) {
      const regex = /\/organization\/settings\/(\w+)\/([^/]+)/
      currentUrl = currentUrl.replace(
        regex,
        '/organization/settings/' + organization?._id + '/$2'
      )
      window.history.replaceState({ path: currentUrl }, '', currentUrl)
      window.location.reload()
    }
  }
  if (currentUrl.includes('/location/settings')) {
    if (!isMobileApp) {
      navigate('/location/list')
    }
  }
}
