import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

export default function useWindowSize() {
  const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
    isDesktop: window.innerWidth > 1400,
    isSmallDesktop: window.innerWidth <= 1400 && window.innerWidth > 1190,
    isTabletLandscape: window.innerWidth <= 1190 && window.innerWidth > 991,
    isTablet: window.innerWidth <= 991 && window.innerWidth > 767,
    isTabletPortrait: window.innerWidth <= 767 && window.innerWidth > 480,
    isMobile: window.innerWidth <= 480,
  })

  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(getWindowSize())
    }, 100)

    window.addEventListener('resize', handleResize)
    window.addEventListener('load', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('load', handleResize)
    }
  }, [])

  return windowSize
}
