import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.5' width='24' height='24' rx='12' fill='#5B66EA' />
      <g clipPath='url(#clip0_19121_90014)'>
        <mask
          id='mask0_19121_90014'
          maskUnits='userSpaceOnUse'
          x='4'
          y='4'
          width='16'
          height='17'
        >
          <path d='M4 4.5H20V20.5H4V4.5Z' fill='white' />
        </mask>
        <g mask='url(#mask0_19121_90014)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.7979 14.5425C20.4007 13.635 20.4007 11.3673 18.7979 10.459L10.6108 5.8208C9.00707 4.91242 7 6.04719 7 7.86303V17.1393C7 18.9552 9.00707 20.0899 10.6108 19.1806L18.7979 14.5425Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_19121_90014'>
          <rect width='16' height='16' fill='white' transform='translate(4 4.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGAudioIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAudioIcon
