import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.83317 6.66667V7.16667H8.33317H10.6339L10.2976 8.16667H8.33317H7.83317V8.66667V13.5H6.49984V8.66667V8.16667H5.99984H5.1665V7.16667H5.99984H6.49984V6.66667V4.66667C6.49984 3.47005 7.46991 2.5 8.6665 2.5H10.8332V3.5H8.99984C8.35549 3.5 7.83317 4.02234 7.83317 4.66667V6.66667Z'
        fill='#3F8CFF'
        stroke='#3F8CFF'
      />
    </svg>
  )
}

const SVGDashFacebookIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashFacebookIcon
