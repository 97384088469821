import React from 'react'
import Div from '../Div/Div'
import MyDefaultSwitch from '../MyDefaultSwitch/MyDefaultSwitch'

const clickableStyle = { color: 'var(--mainColor)', cursor: 'pointer' }

const MyDefaultBlockRow = ({
  isSwitch,
  label,
  value,
  onChange,
  isLast,
  disabled,
  titleTooltip,
  tooltipText,
  tooltip,
  onClickLabel,
  onClickValue,
  valueStyle,
}) => {
  const valueStyleLoc = Object.assign(
    {},
    valueStyle ? valueStyle : {},
    onClickValue ? clickableStyle : {}
  )

  if (isSwitch) {
    return (
      <MyDefaultSwitch
        disabled={disabled}
        titleTooltip={titleTooltip}
        tooltipText={tooltipText}
        tooltip={tooltip}
        title={label}
        value={value}
        onChangeSwitch={onChange}
        noText
        isLast={isLast}
      />
    )
  }
  return (
    <Div className={`my_default_block_row ${isLast ? 'last' : ''}`}>
      <span onClick={onClickLabel}>{label}</span>
      <span onClick={onClickValue} style={valueStyleLoc}>
        {value}
      </span>
    </Div>
  )
}

export default MyDefaultBlockRow
