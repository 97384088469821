import React from 'react'
import moment from 'moment'
import dayjs from 'dayjs'
import { getText } from '../../../../lang'
import MatButton from '../../../../components/MatButton'
import MatRow from '../../../../components/MatRow'
import locationActions from '../../../../store/modules/locationActions'
import { notifyError } from '../../../../utils/Notify'
import { BROADCASTING_TIME_PICKER } from '../../../../config'
import utilDate from '../../../../utils/utilsDate'

export const loadLocation = async (page = 0, fetchSize = 50) => {
  let data = await locationActions
    .getPageList(page, fetchSize, '', {}, true)
    .then((result) => {
      if (result.success) {
        let list = result.data.map((item) => {
          return { ...item, value: item._id, label: item.name }
        })
        return {
          locations: list,
          maxLocations: result.max,
        }
      }

      return { locations: [], maxLocations: 0 }
    })

  return data
}

export const nextOrPrevPage = (
  onClickAction,
  isDisableButtons = { disableNext: false, disablePrev: false }
) => {
  return (
    <MatRow style={{ marginTop: '65px', justifyContent: 'space-between' }}>
      <MatButton
        buttonText={getText('WORD_PREVIOUS')}
        htmlType={''}
        onClick={() => {
          onClickAction(-1)
        }}
        typeButton={'cancel'}
        disabled={isDisableButtons.disablePrev}
        size={'large'}
        style={{ maxWidth: '200px', width: '100%' }}
      />
      {
        <MatButton
          buttonText={getText('WORD_NEXT')}
          htmlType={'submit'}
          onClick={() => {
            onClickAction(1)
          }}
          className={''}
          disabled={isDisableButtons.disableNext}
          size={'large'}
          style={{ maxWidth: '200px', width: '100%' }}
        />
      }
    </MatRow>
  )
}

export const generateResponseObj = (data) => {
  if (canSubmit(data)) {
    let objSend = {
      message:
        data.inputMessage.activeTab === 'build_your_own' &&
        data.inputMessage.buildYourOwnText
          ? data.inputMessage.buildYourOwnText
          : data.inputMessage.aiGeneratedText,
      name: data.name,
      send_consent: !data.send_consent,
    }

    objSend.alternativeMessages = data.alternativeContent
      ? data.alternativeContent
          .filter((alter) => alter.isChecked)
          .map((alterObj) => alterObj.text)
      : []

    objSend.action_time = `${data.date} ${dayjs(data.time).format('HH:mm')}`

    if (data.users_to_assign && data.users_to_assign.length) {
      objSend.users_to_assign = data.users_to_assign
    }
    if (data.tags_to_assign && data.tags_to_assign.length) {
      objSend.tags_to_assign = data.tags_to_assign
    }
    if (data.send_review_invite) {
      objSend.send_review_invite = data.send_review_invite
    }

    if (data.uploadExcel && data.image) {
      objSend.image = data.image.file
    } else if (data.image) {
      objSend.image = data.image.mediaUrl
    }

    if (data.hasExcludeCustomers && data.days_to_skip) {
      objSend.days_to_skip = Number(data.days_to_skip)
    }

    if (data.updateCustomersData) {
      objSend.updateCustomersData = data.updateCustomersData
    }

    if (data.excludeArchived) {
      objSend.excludeArchived = data.excludeArchived
    }
    if (data.excludeDNC) {
      objSend.excludeDNC = data.excludeDNC
    }

    const tagsObj = {}
    const operators = data.listRoleSecond === 'and' ? ['or', 'and'] : ['and', 'or']
    if (data.listTagsSecond === undefined || data.listTagsSecond.length === 0) {
      if (data.listTagsFirst && data.listTagsFirst.length) {
        tagsObj[data.listRoleFirst] = data.listTagsFirst.map((tag) => {
          return Object.assign({
            type: 'SINGLE',
            value: tag,
          })
        })
      }
    }

    if (data.listTagsFirst === undefined || data.listTagsFirst.length === 0) {
      if (data.listTagsSecond && data.listTagsSecond.length) {
        tagsObj[data.listRoleSecond] = data.listTagsSecond.map((tag) => {
          return Object.assign({
            type: 'SINGLE',
            value: tag,
          })
        })
      }
    }

    if (
      data.listTagsSecond &&
      data.listTagsSecond.length &&
      data.listTagsFirst &&
      data.listTagsFirst.length
    ) {
      tagsObj[data.listRoleSecond] = operators.map((tag) => {
        return Object.assign({
          type:
            (data.listRoleFirst === tag && data.listTagsFirst.length === 1) ||
            (data.listRoleSecond === tag && data.listTagsSecond.length === 1)
              ? 'SINGLE'
              : tag === 'and'
                ? 'GROUP_AND'
                : 'GROUP_OR',
          [(data.listRoleFirst === tag && data.listTagsFirst.length === 1) ||
          (data.listRoleSecond === tag && data.listTagsSecond.length === 1)
            ? 'value'
            : 'values']:
            data.listRoleFirst === tag && data.listTagsFirst.length === 1
              ? data.listTagsFirst[0]
              : data.listRoleSecond === tag && data.listTagsSecond.length === 1
                ? data.listTagsSecond[0]
                : data.listRoleFirst === tag && data.listTagsFirst.length > 1
                  ? data.listTagsFirst
                  : data.listRoleSecond === tag && data.listTagsSecond.length > 1
                    ? data.listTagsSecond
                    : [],
        })
      })
    }

    if (
      (tagsObj['and'] && tagsObj['and'].length) ||
      (tagsObj['or'] && tagsObj['or'].length)
    ) {
      objSend.logicalOperatorTags = tagsObj
      objSend.useTagsLogicalOperators = true
    }

    if (data.workflow) {
      objSend._workflow_id = data.workflow._id
      objSend._workflow_version = data.workflow.version
    }

    if (data.uploadExcel) {
      if (data.addTag) {
        objSend.tag = data.tag.value
      }
      if (data._location_id) {
        objSend._location_id = data._location_id
      }
      if (data.import_id) {
        objSend.raw_file_URL = data.uploadExcel
        objSend.original_filename = data.uploadFileName
      }
      return objSend
    } else {
      objSend.language = data.listSelectedLanguages
      if (data._location_id) {
        objSend._location_id = data._location_id
      }
      if (data.listSelectedSource && data.listSelectedSource.length) {
        objSend.source = data.listSelectedSource
      }
      if (data.hasAppointment !== 'ANY') {
        objSend.appointment = data.hasAppointment === 'YES'
      }
      if (data.lastMessageDateGte && data.lastMessageDateGte !== '') {
        // objSend.last_message_date_gte = data.lastMessageDateGte.setHours(0, 0, 0, 0)
        objSend.last_message_date_gte = utilDate.getDate(data.lastMessageDateGte)
      }
      if (data.lastMessageDateLte && data.lastMessageDateLte !== '') {
        // objSend.last_message_date_lte = data.lastMessageDateLte.setHours(0, 0, 0, 0)
        objSend.last_message_date_lte = utilDate.getDate(data.lastMessageDateLte)
      }
      if (data.selectedUNASSIGNEDUSERS) {
        objSend.assigned_to = []
      } else if (data.listAssignedUsers && data.listAssignedUsers.length) {
        objSend.assigned_to = data.listAssignedUsers
      }
      if (
        objSend.hasOwnProperty('tags') ||
        objSend.hasOwnProperty('logicalOperatorTags') ||
        objSend.hasOwnProperty('source') ||
        objSend.hasOwnProperty('appointment') ||
        objSend.hasOwnProperty('last_message_date_gte') ||
        objSend.hasOwnProperty('assigned_to') ||
        objSend.language.length > 0
      ) {
        return objSend
      } else {
        notifyError('selects at least one filter')
        return
      }
    }
  }
  return false
}

export const canSubmit = (data) => {
  data.dateTime = new Date(`${data.date} ${moment(data.time).format('HH:mm')}`)
  let curretDate = new Date(`${data.date} ${moment(data.time).format('HH:mm')}`)
  let selectedDate = Math.floor(data.dateTime.getHours() / 1000)
  let minHouer = Math.floor(curretDate.setHours(9, 0, 0) / 1000)
  let maxHouer = Math.floor(curretDate.setHours(16, 0, 0))

  if (selectedDate >= minHouer && selectedDate <= maxHouer) {
    notifyError(
      `please choose time between ${BROADCASTING_TIME_PICKER.START_HOURS}-${BROADCASTING_TIME_PICKER.END_HOURS} `
    )
    return false
  }
  const message =
    data.inputMessage.activeTab === 'build_your_own' &&
    data.inputMessage.buildYourOwnText
      ? data.inputMessage.buildYourOwnText
      : data.inputMessage.aiGeneratedText
  if (!message) {
    notifyError(getText('ERR_EMPTY_MESSAGE'))
    return false
  }
  if (!data.dateTime) {
    notifyError('please fill Action Date')
    return false
  }
  return true
}

export const defaultAvailableHours = (HH) => {
  let hours = []

  for (let i = 0; i < 24; i++) {
    if (i < HH) {
      hours.push(i)
    } else if (i > BROADCASTING_TIME_PICKER.END_HOURS) {
      hours.push(i)
    }
  }
  return hours
}

export const availableNeededMinute = (mm = false) => {
  let minute = 0
  if (mm) {
    for (let i = 0; i < 60; i += BROADCASTING_TIME_PICKER.INTERVAL_TIME) {
      if (i >= mm) {
        minute = i
        break
      }
    }
    return minute
  }

  for (let i = 0; i < 60; i += BROADCASTING_TIME_PICKER.INTERVAL_TIME) {
    minute = i
  }
  return minute
}

export const defaultAvailableMinutes = (currentMinute, before = false) => {
  let minutes = []

  for (let i = 0; i < 60; i += BROADCASTING_TIME_PICKER.INTERVAL_TIME) {
    if (before) {
      if (i <= currentMinute) {
        minutes.push(i)
      }
    } else {
      if (i >= currentMinute) {
        minutes.push(i)
      }
    }
  }
  return minutes
}

export const notAvailableDays = () => {
  let currentHours = moment().format('HH')
  if (currentHours > BROADCASTING_TIME_PICKER.END_HOURS) {
    return moment().add(1, 'day')
  }

  return moment()
}

export const handleDefaultTime = () => {
  let currentHours = moment().format('HH')
  let currentMinute = moment().format('mm')

  if (currentHours > BROADCASTING_TIME_PICKER.END_HOURS) {
    return `0${BROADCASTING_TIME_PICKER.START_HOURS}:00`
  }

  if (currentHours < BROADCASTING_TIME_PICKER.START_HOURS) {
    return `0${BROADCASTING_TIME_PICKER.START_HOURS}:00`
  }

  if (currentMinute > availableNeededMinute()) {
    return `${++currentHours}:00`
  }

  return `${currentHours}:${availableNeededMinute(currentMinute)}`
}

////---------------------------------------------////////////////---------------------

export const getAvailableHours = (HH) => {
  let hours = []
  for (let i = 0; i < 24; i++) {
    if (i >= HH && i <= BROADCASTING_TIME_PICKER.END_HOURS) {
      hours.push(i)
    }
  }
  return hours
}

export const createAvailableMinute = (
  isToday = true,
  HH = moment().format('HH')
) => {
  let minutes = []
  if (isToday) {
    let currentMinute = moment().format('mm')
    let currentHours = moment().format('HH')
    for (let i = 0; i < 60; i += BROADCASTING_TIME_PICKER.INTERVAL_TIME) {
      if (HH === Number(currentHours)) {
        if (currentMinute < i) {
          minutes.push(i)
        }
      } else {
        minutes.push(i)
      }
    }
    return minutes
  }
  for (let i = 0; i < 60; i += BROADCASTING_TIME_PICKER.INTERVAL_TIME) {
    minutes.push(i)
  }
  return minutes
}

export const getAvailableMinutes = (day) => {
  if (!moment().isSame(day, 'day')) {
    return createAvailableMinute(false)
  }
  return createAvailableMinute()
}

export const availableHoursAmericanFormat = (workingHours, isToday) => {
  let startHour = Number(workingHours.from.substring(0, 2).replace(':', ''))
  let endHour = Number(workingHours.to.substring(0, 2).replace(':', ''))
  let startMin = Number(workingHours.from.slice(-5).slice(0, 2))
  let endMin = Number(workingHours.to.slice(-5).slice(0, 2))

  if (workingHours.from.endsWith('PM')) {
    startHour += 12
  }

  if (isToday) {
    let a = moment(moment(), 'HH:mm a')
    let b = moment(moment(workingHours.from.slice(0, 5), 'HH:mm a'))

    if (moment(a).diff(b, 'minutes') > 0) {
      startMin = availableNeededMinute(Number(moment().format('mm ')))
      if (startMin === 0) {
        startHour = Number(moment().format('HH ')) + 1
      } else {
        startHour = Number(moment().format('HH '))
      }
    }
  }

  // it's  the little things that make us happy

  if (workingHours.to.endsWith('PM')) {
    endHour += 7
  }
  let x = BROADCASTING_TIME_PICKER.INTERVAL_TIME //minutes interval
  let times = [] // time array
  let tt = startHour * 60 + startMin // start time

  let ap = ['AM', 'PM']

  for (let i = 0; tt < 24 * 60; i++) {
    //loop to increment the time and push results in array

    let hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
    let mm = tt % 60 // getting minutes of the hour in 0-55 format

    // pushing data in array in [00:00 - 12:00 AM/PM format]
    let timeHH = hh === 12 ? '12' : ('0' + (hh % 12)).slice(-2)
    let timeMM = ('0' + mm).slice(-2)
    let apTime = ap[Math.floor(hh / 12)]

    if (hh > 16 && apTime === 'PM') {
      times = ''
    } else {
      times.push(timeHH + ':' + timeMM + ' ' + apTime)
    }

    tt = tt + x

    // break loop after calculate available hours
    if (tt > endHour * 60 + endMin) {
      break
    }
  }

  return times
}

export const createAvailableDate = (date) => {
  let HH = Number(
    BROADCASTING_TIME_PICKER.START_HOURS < moment().format('HH')
      ? moment().format('HH')
      : BROADCASTING_TIME_PICKER.START_HOURS
  )
  let data = []

  if (moment().isSame(date, 'day')) {
    if (
      BROADCASTING_TIME_PICKER.START_HOURS <= moment().format('HH') &&
      availableNeededMinute(false) < moment().format('mm')
    ) {
      HH += 1
    }
  } else {
    HH = BROADCASTING_TIME_PICKER.START_HOURS
  }
  let housersList = getAvailableHours(HH)
  for (let i = 0; i < housersList.length; i++) {
    let minuteList =
      housersList[i] === BROADCASTING_TIME_PICKER.END_HOURS
        ? ['0']
        : createAvailableMinute(moment().isSame(date, 'day'), housersList[i])
    for (let j = 0; j < minuteList.length; j++) {
      data.push(
        `${housersList[i] < 10 ? '0' + housersList[i] : housersList[i]}:${
          minuteList[j] < 10 ? '0' + minuteList[j] : minuteList[j]
        } `
      )
    }
  }

  return data
}

export const createCustomFooter = (data, click, checkDisabled) => {
  return (
    <div className='mat-picker-custom-drop'>
      {data.map((item, index) => {
        const disable = checkDisabled(item)
        return (
          <div
            key={index}
            className={`time-item ${disable ? 'disabled' : ''}`}
            onClick={() => {
              if (!disable) {
                click(item.format('hh:mm A'))
              }
            }}
          >
            {dayjs(item).format('hh:mm A')}
          </div>
        )
      })}
    </div>
  )
}

export const getContentMessage = (
  tabValue,
  textMessage,
  settextMessage,
  onStateChange,
  newText
) => {
  if (tabValue === 'ai_generated') {
    settextMessage({
      ...textMessage,
      aiGeneratedText: newText,
    })
    onStateChange &&
      onStateChange('inputMessage', {
        ...textMessage,
        aiGeneratedText: newText,
        activeTab: tabValue,
      })
  } else {
    settextMessage({
      ...textMessage,
      buildYourOwnText: newText,
    })
    onStateChange &&
      onStateChange('inputMessage', {
        ...textMessage,
        buildYourOwnText: newText,
        activeTab: tabValue,
      })
  }
}
