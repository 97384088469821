import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='24'
        height='24'
        rx='4'
        fill={props.backgroundcolor || '#EBF3FF'}
      />
      <rect
        x='0.5'
        y='0.5'
        width='23'
        height='23'
        rx='3.5'
        stroke={props.color || '#3F8CFF'}
        strokeOpacity='0.2'
      />
      <path
        d='M13.7441 15.2082H14.625C16.3866 15.2082 17.8333 13.7673 17.8333 11.9998C17.8333 10.2382 16.3925 8.7915 14.625 8.7915H13.7441'
        stroke={props.color || '#3F8CFF'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2503 8.7915H9.37533C7.60783 8.7915 6.16699 10.2323 6.16699 11.9998C6.16699 13.7615 7.60783 15.2082 9.37533 15.2082H10.2503'
        stroke={props.color || '#3F8CFF'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.66699 12H14.3337'
        stroke={props.color || '#3F8CFF'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGFollowedLinkIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGFollowedLinkIcon
