import React from 'react'
import { getText } from '../../../../lang'
import { getLongName, replaceWords } from '../../../../utils'
import MyDefaultInsidePageTabs from '../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultAddVariablesInMessage from '../../../../components/MyDefaultAddVariablesInMessage/MyDefaultAddVariablesInMessage'
import { TEMPLATE_TYPES } from '.'

const SendMessageTab = (props) => {
  const {
    onChangeTextarea,
    onClickTextarea,
    onKeyUpTextarea,
    textAreaRef,
    onChangeTabs,
    languages,
    form,
    templateType,
    onChangeSubject,
    onKeyUpSubject,
    onClickSubject,
    textAreaSubjectRef,
    onAddVariableSubjectClick,
    variablesData,
    onAddTemplateText,
  } = props

  const getErrorMessage = (lng) => {
    if (Boolean(templateType === TEMPLATE_TYPES.EMAIL)) {
      return replaceWords(getText('ERROR_TEMPLATE_TEXTAREA_BODY'), { lang: lng })
    } else {
      return replaceWords(getText('ERROR_TEMPLATE_TEXTAREA'), { lang: lng })
    }
  }

  return (
    <MyDefaultInsidePageTabs
      onChange={onChangeTabs}
      items={languages.map((lng, index) => {
        return {
          label: getLongName(lng),
          key: index,
          forceRender: true,
          children: (
            <React.Fragment>
              {Boolean(templateType === TEMPLATE_TYPES.EMAIL) && (
                <React.Fragment>
                  <MyDefaultInputText
                    ref={textAreaSubjectRef}
                    isForm
                    name={['subject', lng]}
                    label={getText('LABEL_TEMPLATE_SUBJECT')}
                    required
                    onChange={(e) => {
                      onChangeSubject && onChangeSubject(e)
                    }}
                    onKeyUp={(e) => {
                      onKeyUpSubject && onKeyUpSubject(e)
                    }}
                    onClick={(e) => {
                      onClickSubject && onClickSubject(e)
                    }}
                  />

                  <MyDefaultAddVariablesInMessage
                    data={variablesData}
                    onClick={onAddVariableSubjectClick}
                  />
                </React.Fragment>
              )}
              <MyDefaultTextarea
                ref={textAreaRef}
                isForm
                name={['text', lng]}
                maxLength={1400}
                showCount={true}
                label={
                  Boolean(templateType === TEMPLATE_TYPES.EMAIL) &&
                  getText('LABEL_TEMPLATE_BODY')
                }
                placeholder={''}
                onChange={(e) => {
                  onChangeTextarea && onChangeTextarea(e.target.selectionStart)
                }}
                onClick={(e) => {
                  onClickTextarea && onClickTextarea(e.target.selectionStart)
                }}
                onKeyUp={(e) => {
                  onKeyUpTextarea && onKeyUpTextarea(e.target.selectionStart)
                }}
                maxLenghthMsg={
                  form &&
                  form.getFieldValue('text')[lng] &&
                  form.getFieldValue('text')[lng].length > 1400
                    ? getText('ERROR_MSG_MESSAGE_NAME_LENGTH')
                    : ''
                }
                message={getErrorMessage(lng)}
                required
              />
              <MyDefaultAddVariablesInMessage
                data={variablesData}
                onClick={onAddTemplateText}
                marginTop={30}
                disableMargin={Boolean(templateType === TEMPLATE_TYPES.EMAIL)}
              />
            </React.Fragment>
          ),
        }
      })}
    />
  )
}

export default SendMessageTab
