import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_14351_181030)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_14351_181030)'>
          <circle cx='18' cy='17' r='13' fill='#5B66EA' />
        </g>
        <path
          d='M18.353 23.6134H14.1397C12.033 23.6134 11.333 22.2134 11.333 20.8067V15.1934C11.333 13.0867 12.033 12.3867 14.1397 12.3867H18.353C20.4597 12.3867 21.1597 13.0867 21.1597 15.1934V20.8067C21.1597 22.9134 20.453 23.6134 18.353 23.6134Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.0135 21.4001L21.1602 20.1001V15.8934L23.0135 14.5934C23.9202 13.9601 24.6668 14.3468 24.6668 15.4601V20.5401C24.6668 21.6534 23.9202 22.0401 23.0135 21.4001Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.667 17.3333C18.2193 17.3333 18.667 16.8855 18.667 16.3333C18.667 15.781 18.2193 15.3333 17.667 15.3333C17.1147 15.3333 16.667 15.781 16.667 16.3333C16.667 16.8855 17.1147 17.3333 17.667 17.3333Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_14351_181030)'
      />
      <defs>
        <filter
          id='filter0_f_14351_181030'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_14351_181030'
          />
        </filter>
        <linearGradient
          id='paint0_linear_14351_181030'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_14351_181030'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashVideoTotalSentIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashVideoTotalSentIcon
