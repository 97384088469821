import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import authActions from '../../store/modules/authActions'
import locationActions from '../../store/modules/locationActions'
import {
  idOrganizationSelector,
  locationSelector,
} from '../../store/selectors/selectors'
import { getText } from '../../lang'
import SVGDrawerManageLocationsPurple from '../../icons/SVG/SVGDrawerManageLocationsPurple'
import MatSelect from '../MatSelect'
import MatSelectSecondary from '../MatSelect/MatSelectSecondary'

const SelectLocations = forwardRef((props, ref) => {
  const _idOrganization = useSelector(idOrganizationSelector)
  const location = useSelector(locationSelector)

  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(0)
  const [perPage] = useState(50)
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    openSelect() {
      setOpen(true)
    },
  }))

  const fill = useCallback(async () => {
    if (_idOrganization) {
      setLoadingData(true)
      let result = await locationActions.getPageList(page, perPage, '', {}, true)
      if (result.success) {
        let list = result.data.map((oo) => {
          return { value: oo._id, label: oo.name, ...oo }
        })
        let oldValue = listData
        if (page > 0) {
          list = oldValue.concat(list)
        }
        setListData(list)
        setTotalRows(result.max)
      }
      setLoadingData(false)
    }
  }, [page, perPage, _idOrganization])

  useEffect(() => {
    if (open || props.secondarySelect) {
      fill()
    }
  }, [fill, open])

  return props.secondarySelect ? (
    <MatSelectSecondary
      label={props.label}
      allowClear
      primary={props.primary}
      dropdownClassName={props.dropdownClassName}
      dropdownStyle={props.dropdownStyle}
      style={props.style}
      icon={!props.icon ? <SVGDrawerManageLocationsPurple /> : undefined}
      options={listData}
      loading={loadingData}
      onLoadMore={() => {
        if (listData.length < totalRows && !loadingData) {
          setPage(page + 1)
        }
      }}
      onSelectItem={props.onSelectItem}
      value={
        props.value
          ? props.value
          : props.location
            ? props.location.name
            : getText('WORD_SELECT_LOCATION')
      }
      onDropdownVisibleChange={props.onDropdownVisibleChange}
    />
  ) : (
    <MatSelect
      primary={props.primary}
      dropdownClassName={props.dropdownClassName}
      dropdownStyle={props.dropdownStyle}
      icon={<SVGDrawerManageLocationsPurple />}
      options={listData}
      loading={loadingData}
      style={props.style}
      onLoadMore={() => {
        if (listData.length < totalRows && !loadingData) {
          setPage(page + 1)
        }
      }}
      onSelectItem={async (item) => {
        await authActions.setLocation(item)
      }}
      value={location ? location.name : getText('WORD_SELECT_LOCATION')}
      onDropdownVisibleChange={() => {
        setOpen(true)
      }}
    />
  )
})

export default memo(SelectLocations)
