import React from 'react'
import { Collapse, Flex, Form } from 'antd'
import { getText } from '../../../lang'
import SVGMyDefaultDeleteIcon from '../../../icons/SVG/SVGMyDefaultDeleteIcon'
import SVGSelectArrow from '../../../icons/SVG/SVGSelectArrow'
import Div from '../../Div/Div'
import MyDefaultSelect from '../../MyDefaultSelect/MyDefaultSelect'
import MyDefaultCustomTimePicker from '../MyDefaultCustomTimePicker/MyDefaultCustomTimePicker'
import utilDate from '../../../utils/utilsDate'
import MyDefaultBlockSubtitle from '../../MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultDatePicker from '../../MyDefaultDatePicker/MyDefaultDatePicker'
import ConfirmPopup from '../../ConfirmPopup'

const MyDefaultOrganizationCustomHoursResponsive = ({
  field,
  form,
  index,
  disabled,
  formListName,
  onDelete,
}) => {
  const item = Form.useWatch([formListName, field.name], form)

  const [fromTime, fromPeriod] = item?.from?.split(' ') || ''
  const [toTime, toPeriod] = item?.to?.split(' ') || ''

  const [hoursFrom, minutesFrom] = fromTime?.split(':') || ''
  const [hoursTo, minutesTo] = toTime?.split(':') || ''

  if (item) {
    item.date = item?.date
      ? utilDate.getDateByDayJS(item?.date)
      : utilDate.getDateByDayJS()
  }

  if (!item) return null

  const items = [
    {
      key: '1',
      label: (
        <MyDefaultBlockSubtitle
          disableMargin
          subtitle={
            <Flex align='center' gap={12}>
              <MyDefaultDatePicker
                isForm
                name={[field.name, 'date']}
                isLast
                style={{ maxWidth: 166 }}
              />
              <ConfirmPopup
                title={getText('MSG_DELETE_CUSTOM_OPENING_HOURS')}
                placement={'top'}
                onConfirm={(e) => {
                  e.stopPropagation()
                  onDelete(item)
                }}
                okText={getText('WORD_YES')}
                cancelText={getText('WORD_NO')}
                trigger={<SVGMyDefaultDeleteIcon />}
              />
            </Flex>
          }
        />
      ),
      className: 'my_default_organization_opening_hours_responsive-item',
      children: (
        <Div style={{ gap: 8, display: 'grid' }}>
          <MyDefaultSelect
            disabled={disabled}
            className={'store_open_state'}
            name={[field.name, 'isOpen']}
            options={[
              {
                label: getText('TEXT_STORE_IS_OPEN'),
                value: true,
              },
              {
                label: getText('TEXT_STORE_IS_CLOSED'),
                value: false,
              },
            ]}
            showSearch={false}
            isForm
            isLast
            label={null}
          />
          {Boolean(item?.isOpen) && (
            <Flex align='center' vertical gap={8}>
              <MyDefaultCustomTimePicker
                hourValueInit={hoursFrom}
                minuteValueInit={minutesFrom}
                systemHourInit={fromPeriod}
                onChange={(val) => {
                  form.setFieldValue([formListName, field.name, 'from'], val)
                }}
                index={`${index}0`}
                disabled={disabled}
              />
              <MyDefaultCustomTimePicker
                hourValueInit={hoursTo}
                minuteValueInit={minutesTo}
                systemHourInit={toPeriod}
                onChange={(val) => {
                  form.setFieldValue([formListName, field.name, 'to'], val)
                }}
                index={`${index}1`}
                disabled={disabled}
              />
            </Flex>
          )}
        </Div>
      ),
    },
  ]

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['0']}
      className='my_default_organization_opening_hours_responsive'
      expandIconPosition='end'
      expandIcon={(expanded) =>
        expanded.isActive ? (
          <Div style={{ transform: 'rotate(180deg)' }}>
            <SVGSelectArrow />
          </Div>
        ) : (
          <SVGSelectArrow />
        )
      }
      items={items}
    />
  )
}

export default MyDefaultOrganizationCustomHoursResponsive
