import React, { useMemo } from 'react'
import { Divider, Flex, Form } from 'antd'
import { getText } from '../../../lang'
import MyDefaultBlockSubtitle from '../../MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultSelect from '../../MyDefaultSelect/MyDefaultSelect'
import MyDefaultCustomTimePicker from '../MyDefaultCustomTimePicker/MyDefaultCustomTimePicker'

const MyDefaultOrganizationOpeningHours = ({
  field,
  form,
  index,
  formListName,
  disabled,
}) => {
  const days = useMemo(() => {
    return [
      getText('WORD_SUNDAY'),
      getText('WORD_MONDAY'),
      getText('WORD_TUESDAY'),
      getText('WORD_WEDNESDAY'),
      getText('WORD_THURSDAY'),
      getText('WORD_FRIDAY'),
      getText('WORD_SATURDAY'),
    ]
  }, [])

  const item = Form.useWatch([formListName, field.name], form)

  const [fromTime, fromPeriod] = item?.from?.split(' ') || ''
  const [toTime, toPeriod] = item?.to?.split(' ') || ''

  const [hoursFrom, minutesFrom] = fromTime?.split(':') || ''
  const [hoursTo, minutesTo] = toTime?.split(':') || ''

  return (
    <Flex vertical>
      <MyDefaultBlockSubtitle subtitle={days[index]} fontSize={16} />
      <Flex align='center' gap={12}>
        <MyDefaultSelect
          selectStyle={{ minWidth: 166 }}
          disabled={disabled}
          className={''}
          name={[field.name, 'isOpen']}
          options={[
            {
              label: getText('TEXT_STORE_IS_OPEN'),
              value: true,
            },
            {
              label: getText('TEXT_STORE_IS_CLOSED'),
              value: false,
            },
          ]}
          showSearch={false}
          isForm
          isLast
        />
        {Boolean(item?.isOpen) && (
          <Flex align='center' gap={8}>
            <MyDefaultCustomTimePicker
              hourValueInit={hoursFrom}
              minuteValueInit={minutesFrom}
              systemHourInit={fromPeriod}
              onChange={(val) => {
                form.setFieldValue([formListName, field.name, 'from'], val)
              }}
              index={`${index}0`}
              disabled={disabled}
            />
            <Divider className='my_default_opening_hours_divider' />
            <MyDefaultCustomTimePicker
              hourValueInit={hoursTo}
              minuteValueInit={minutesTo}
              systemHourInit={toPeriod}
              onChange={(val) => {
                form.setFieldValue([formListName, field.name, 'to'], val)
              }}
              index={`${index}1`}
              disabled={disabled}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default MyDefaultOrganizationOpeningHours
