import React, { memo } from 'react'
import SVGCheckedIcon from '../../../../../icons/SVG/SVGCheckedIcon'
import SVGFollowedLinkIcon from '../../../../../icons/SVG/SVGFollowedLinkIcon'
import SVGFollowedRecIcon from '../../../../../icons/SVG/SVGFollowedRecIcon'
import SVGFollowedScoreIcon from '../../../../../icons/SVG/SVGFollowedScoreIcon'
import SVGFollowedTimesIcon from '../../../../../icons/SVG/SVGFollowedTimesIcon'
import { getText } from '../../../../../lang'
import CenterMessageReview from '../CenterMessageReview'
import { convertNumberTo5PointSystemValue } from '../../../../../utils'

const CenterInvitation = ({ message, index, conversation }) => {
  return (
    <CenterMessageReview
      conversation={conversation}
      message={message}
      index={index}
      inviteReviewBlock
      title={getText('TITLE_MESSAGE_INVITATION')}
      infoValueList={[
        {
          infoViewTitle: getText('WORD_FOLLOWED_LINK'),
          icon: <SVGFollowedLinkIcon />,
          bodyIcon: <SVGCheckedIcon />,
          noIcon: false,
          active: message.tracking.followed_link,
        },
        {
          infoViewTitle: getText('WORD_FOLLOWED_TIMES'),
          icon: <SVGFollowedTimesIcon />,
          count: message.tracking.followed_link_count,
          noIcon: true,
          active: message.tracking.followed_link_count,
        },
        {
          infoViewTitle: getText('WORD_RECOMMENDED'),
          icon: <SVGFollowedRecIcon />,
          bodyIcon: <SVGCheckedIcon />,
          noIcon: false,
          active: message.tracking.would_recommend,
        },
        {
          infoViewTitle: getText('WORD_SCORE'),
          icon: <SVGFollowedScoreIcon />,
          count: `${message.vote_value ? convertNumberTo5PointSystemValue(message?.location?.is5StarReviewEnabled, message.vote_value) : 0}/${message?.location?.is5StarReviewEnabled ? '5' : '10'}`,
          noIcon: true,
          active: message.vote_value || 0,
        },
      ]}
    />
  )
}

export default memo(CenterInvitation)
