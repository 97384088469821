import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='35' height='35' rx='17.5' fill='#5B66EA' />
    <rect
      x='0.5'
      y='0.5'
      width='35'
      height='35'
      rx='17.5'
      stroke='url(#paint0_linear_22769_202673)'
    />
    <path
      d='M15.3335 11.333V13.333'
      stroke='white'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.6665 11.333V13.333'
      stroke='white'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3335 16.0596H23.6668'
      stroke='white'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24 15.6663V21.333C24 23.333 23 24.6663 20.6667 24.6663H15.3333C13 24.6663 12 23.333 12 21.333V15.6663C12 13.6663 13 12.333 15.3333 12.333H20.6667C23 12.333 24 13.6663 24 15.6663Z'
      stroke='white'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.5 20.293H15.5'
      stroke='white'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 18.667V22.0003'
      stroke='white'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_22769_202673'
        x1='0'
        y1='0'
        x2='38'
        y2='40.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.12' />
        <stop offset='1' stopColor='white' stopOpacity='0.04' />
      </linearGradient>
    </defs>
  </svg>
)

const SVGVoiceAiEmptyBookSalesIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGVoiceAiEmptyBookSalesIcon
