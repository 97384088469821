import React from 'react'
import { getText } from '../../lang'
import MatModal from '../MatModal'
import VoiceAIDetails from './VoiceAIDetails'

const VoiceAIDetailsModal = ({
  visible,
  setVisible,
  item,
  isCallerInfoClickable,
}) => {
  return (
    <MatModal
      noTrigger
      hideFooter
      visible={visible}
      onCancel={() => setVisible(false)}
      title={`${getText('WORD_CALL_DETAILS')} ${item?.assistant?.assistantName || ''}`}
      destroyOnClose={true}
      width={'100%'}
      rootClassName={'voice_ai_list_modal'}
    >
      <VoiceAIDetails item={item} isCallerInfoClickable={isCallerInfoClickable} />
    </MatModal>
  )
}

export default VoiceAIDetailsModal
