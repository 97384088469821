import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '18'}
    height={props.height || '18'}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_23313_362375)'>
      <path
        d='M11.4862 8.99935C11.3048 11.1243 9.86894 12.3327 8.23921 12.3327C6.60947 12.3327 5.52473 10.8402 5.68154 8.99935C5.83834 7.15852 7.17785 5.66602 8.80758 5.66602C10.4373 5.66602 11.6426 7.16602 11.4862 8.99935Z'
        stroke={props.color || '#77808D'}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.683 15.8821C10.4735 16.3935 9.68072 16.5 8.32556 16.5C4.20925 16.5 1.16861 13.142 1.529 9C1.88939 4.85795 5.52291 1.5 9.63922 1.5C14.276 1.5 16.825 4.55668 16.4667 8.64759C16.1995 11.7017 14.2599 12.5817 13.2444 12.4006C12.289 12.2301 11.5004 11.3599 11.6461 9.6946L12.0052 5.59304'
        stroke={props.color || '#77808D'}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_23313_362375'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const SVGTagSelect = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGTagSelect
