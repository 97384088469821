import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='35' height='35' rx='17.5' fill='#5B66EA' />
    <rect
      x='0.5'
      y='0.5'
      width='35'
      height='35'
      rx='17.5'
      stroke='url(#paint0_linear_22769_202683)'
    />
    <path
      d='M18.0002 18C18.7365 18 19.3335 17.403 19.3335 16.6667C19.3335 15.9303 18.7365 15.3333 18.0002 15.3333C17.2638 15.3333 16.6668 15.9303 16.6668 16.6667C16.6668 17.403 17.2638 18 18.0002 18ZM18.0002 18C16.5274 18 15.3335 19.1939 15.3335 20.6667M18.0002 18C19.4729 18 20.6668 19.1939 20.6668 20.6667M17.3335 12H18.6668C21.9805 12 24.6668 14.6863 24.6668 18C24.6668 21.3137 21.9805 24 18.6668 24H14.0002C12.5274 24 11.3335 22.8061 11.3335 21.3333V18C11.3335 14.6863 14.0198 12 17.3335 12Z'
      stroke='white'
      strokeLinecap='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_22769_202683'
        x1='0'
        y1='0'
        x2='38'
        y2='40.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.12' />
        <stop offset='1' stopColor='white' stopOpacity='0.04' />
      </linearGradient>
    </defs>
  </svg>
)

const SVGVoiceAiEmptyHandleRoutineIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGVoiceAiEmptyHandleRoutineIcon
