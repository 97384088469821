import React, { useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getText } from '../../lang'
import useQueryParams from '../../utils/useQueryParams'
import { useLayout } from '../../layout/LayoutProvider/LayoutProvider'
import PaymentListHeader from './PaymentListHeader'
import PaymentListSummaryItem from './PaymentListSummaryItem'
import PaymentPageLayout from './PaymentPageLayout'
import PaymentListItemTitle from './PaymentListItemTitle'
import Payments from './paymentsList/Payments'
import Deposits from './depositsList/Deposits'
import Reports from './reportsList/Reports'
import './Payment.scss'

const PaymentList = () => {
  const { setPageTitle } = useLayout()
  const { type } = useQueryParams()
  const navigate = useNavigate()

  const [options, setOptions] = useState({ status: 'all', group: 'none' })
  const [tabActiveKey, setTabActiveKey] = useState('payments')
  const [reportsOption, setReportsOption] = useState('')

  const filterRef = useRef({})
  const paginationRef = useRef(null)

  useEffect(() => {
    setPageTitle(getText('WORD_PAYMENT_LIST'))
    if (type) {
      setTabActiveKey(type)
    } else {
      setTabActiveKey('payments')
      navigate('?type=payments')
    }
  }, [])

  return (
    <PaymentPageLayout>
      <PaymentListItemTitle title={getText('WORD_SUMMARY')} />
      <PaymentListSummaryItem />
      <PaymentListHeader
        tabActiveKey={tabActiveKey}
        reportsOption={reportsOption}
        options={options}
        filterRef={filterRef}
        onFiltersChange={(options, value) => {
          if (value) {
            setReportsOption(options)
          } else {
            paginationRef &&
              paginationRef.current &&
              paginationRef.current.paginationReset()
            setOptions(options)
          }
        }}
      />
      <Tabs
        className={`payment-page-tab ${
          tabActiveKey === 'deposits'
            ? 'payment-deposits-tab'
            : tabActiveKey === 'reports'
              ? 'payment-reports-tab'
              : ''
        }`}
        activeKey={tabActiveKey}
        onChange={(e) => {
          navigate('?type=' + e)
          setTabActiveKey(e + '')
        }}
        items={[
          {
            key: 'payments',
            label: getText('WORD_PAYMENTS'),
            children: <Payments ref={paginationRef} options={options} />,
          },
          {
            key: 'deposits',
            label: getText('WORD_DEPOSITS'),
            children: <Deposits options={options} />,
          },
          {
            key: 'reports',
            label: getText('WORD_REPORTS'),
            children: <Reports reportsOption={reportsOption} />,
          },
        ]}
      />
    </PaymentPageLayout>
  )
}

export default PaymentList
