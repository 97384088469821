import React from 'react'
import { useParams } from 'react-router-dom'
import { OrganizationProvider } from '../organizationProvider/OrganizationProvider'
import OrganizationMenu from './OrganizationMenu'
import './OrganizationNavigation.scss'

const OrganizationNavigation = ({ children }) => {
  const { _id } = useParams()

  return (
    <div className='org-navigation-page'>
      <OrganizationMenu id={_id} />
      <div className='org-navigation-page-container'>
        <OrganizationProvider _id={_id} children={children} />
      </div>
    </div>
  )
}

export default OrganizationNavigation
