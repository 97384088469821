import React from 'react'
import { Flex } from 'antd'
import { getText } from '../../lang'
import MyDefaultBlock from '../MyDefaultBlock/MyDefaultBlock'
import Div from '../Div/Div'
import './MyDefaultIntegrationBlock.scss'

const MyDefaultIntegrationBlock = ({
  enableMarginTop,
  icon,
  title,
  description,
  enabled,
  connectedBy,
  customButton,
}) => {
  return (
    <MyDefaultBlock enableMarginTop={enableMarginTop}>
      <Div className={'my_default_integration_block'}>
        <Flex className='my_default_integration_block--header'>
          {icon}
          <Div className={'my_default_integration_block--header-title'}>
            <p>{title}</p>
            <span>{description}</span>
          </Div>
        </Flex>
        <Flex
          className='my_default_integration_block--footer'
          align='center'
          justify='space-between'
        >
          <Div className={'my_default_integration_block--footer-left'}>
            {connectedBy ? (
              <React.Fragment>
                <span>
                  {enabled
                    ? getText('ACTION_CONNECTED_BY')
                    : getText('ACTION_DISCONNECTED_BY')}
                </span>
                <span>{connectedBy}</span>
              </React.Fragment>
            ) : (
              <Div />
            )}
          </Div>
          <Div className={'my_default_integration_block--footer-right'}>
            {customButton}
          </Div>
        </Flex>
      </Div>
    </MyDefaultBlock>
  )
}

export default MyDefaultIntegrationBlock
