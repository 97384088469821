import React, { memo, useRef } from 'react'
import { Tabs } from 'antd'
import SVGInfo from '../../../../icons/SVG/SVGInfo'
import SVGNote from '../../../../icons/SVG/SVGNote'
import SVGBooking from '../../../../icons/SVG/SVGBooking'
import { getText } from '../../../../lang'
import MessageTabInfo from './MessageTabInfo/MessageTabInfo'
import MessageTabNote from './MessageTabNotes/MessageTabNote'
import MessageTabBooking from './MessageTabBooking/MessageTabBooking'
import './MessageTabs.scss'

const MessageTabMain = ({
  conversation,
  onSaveReciver,
  refreshConversation,
  onSaveTags,
  isAwaitingConsent,
  msgChangeEmailSMSType,
}) => {
  const tabRef = useRef(null)

  return (
    <div ref={tabRef} className='profile-tabs'>
      <Tabs
        centered
        items={[
          {
            key: 'info',
            label: (
              <>
                <SVGInfo />
                <span>{getText('WORD_INFO')}</span>
              </>
            ),
            children: (
              <MessageTabInfo
                conversation={conversation}
                onSaveReciver={onSaveReciver}
                refreshConversation={refreshConversation}
                onSaveTags={onSaveTags}
              />
            ),
          },
          {
            key: 'notes',
            label: (
              <>
                <SVGNote />
                <span>{getText('WORD_NOTES')}</span>
                {conversation.notesCount || ''}
              </>
            ),
            children: <MessageTabNote conversation={conversation} />,
          },
          {
            key: 'booking',
            label: (
              <>
                <SVGBooking />
                <span>{getText('WORD_APPOINTMENTS')}</span>
              </>
            ),
            children: (
              <MessageTabBooking
                conversation={conversation}
                isAwaitingConsent={isAwaitingConsent}
                msgChangeEmailSMSType={msgChangeEmailSMSType}
              />
            ),
            disabled: conversation.kind === 'gbm',
          },
        ]}
      ></Tabs>
    </div>
  )
}

export default memo(MessageTabMain)
