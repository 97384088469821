import React, { memo } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import SVGPhoneCall from '../../../../../icons/SVG/SVGPhoneCall'
import { getText } from '../../../../../lang'
import MatButton from '../../../../../components/MatButton'
import {
  msgTooltipText,
  sendCustomPhone,
  sendPhoneCall,
} from '../../../utils/messageUtils'
import { MESSENGER } from '../../../utils/constants'
import {
  isMobileAppSelector,
  organizationSelector,
} from '../../../../../store/selectors/selectors'

const MessageCallPhoneButton = (props) => {
  const { conversation, disabled, mobileDeskSize, onCloseMobileDesk } = props

  const organization = useSelector(organizationSelector)
  const isMobileApp = useSelector(isMobileAppSelector)

  if (conversation.is_archived) {
    return null
  }

  return props.conversation.kind !== MESSENGER && conversation.receiver_phone ? (
    <MatButton
      tooltip={!mobileDeskSize}
      tooltipTitle={getText('WORD_PHONE_CALL')}
      dataIndex={'phone_call'}
      className={'icon-size only-stroke footerAction'}
      disabled={disabled || conversation.isActiveVoiceCall}
      onClick={() => {
        onCloseMobileDesk && onCloseMobileDesk()
        if (
          organization?.restrictions
            ?.restrictUserVoiceCallsFromUsingOrganizationNumbers
        ) {
          sendCustomPhone(conversation, isMobileApp)
        } else {
          sendPhoneCall(conversation.receiver_phone, conversation)
        }
      }}
      icon={<SVGPhoneCall />}
      buttonText={mobileDeskSize ? getText('WORD_PHONE_CALL') : ''}
    />
  ) : (
    <Tooltip title={msgTooltipText(conversation) || getText('WORD_PHONE_CALL')}>
      <div
        dataIndex={'phone_call'}
        className={'mat-btn icon-size only-stroke footerAction'}
        disabled={true}
      >
        <span className='ant-btn-icon'>
          <SVGPhoneCall />
        </span>
        {mobileDeskSize ? (
          <span>{msgTooltipText(conversation) || getText('WORD_PHONE_CALL')}</span>
        ) : (
          ''
        )}
      </div>
    </Tooltip>
  )
}

export default memo(MessageCallPhoneButton)
