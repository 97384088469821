import React, { useCallback, useEffect, useState } from 'react'
import { Empty } from 'antd'
import { useSelector } from 'react-redux'
import { getText, getTextServerError } from '../../../../../lang'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError } from '../../../../../utils/Notify'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import MatModal from '../../../../../components/MatModal'
import MatSelectSecondary from '../../../../../components/MatSelect/MatSelectSecondary'
import userActions from '../../../../../store/modules/userActions'
import { GET_DATA_FETCH_SIZE } from '../../../../../utils'
import SVGAISummaryIcon from '../../../../../icons/SVG/SVGAISummaryIcon'
import {
  organizationSelector,
  userSelector,
} from '../../../../../store/selectors/selectors'
import './CenterMessageModal.scss'

const CenterAISummarizeModal = ({ conversation, setVisible, visible }) => {
  const organization = useSelector(organizationSelector)
  const user = useSelector(userSelector)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState('')
  const [assignSearchKeyword, setAssignSearchKeyword] = useState('')
  const [listUsers, setListUsers] = useState([])
  const [open, setOpen] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [listUserSelected, setlistUserSelected] = useState([])
  const [saving, setSaving] = useState(false)
  const [page, setPage] = useState(0)

  const searchUsers = useCallback(async () => {
    if (assignSearchKeyword) {
      setPage(0)
    }
    const usersRes = await userActions.getUsersForAssignUnassign(
      page,
      GET_DATA_FETCH_SIZE,
      organization?._id,
      assignSearchKeyword,
      user.isSuperAdmin
        ? {
            fullList: true,
          }
        : {
            withoutSAdmins: true,
          }
    )
    if (usersRes.success) {
      let list = usersRes.data
      let oldList = listUsers
      if (page > 0) {
        list = oldList.concat(list)
      }
      setListUsers(
        list.map((item) => {
          return Object.assign(item, {
            label: item.fullName,
            value: item._id,
          })
        })
      )
    }
  }, [assignSearchKeyword, organization, page])

  useEffect(() => {
    if (open) {
      setLoadingUser(true)
      searchUsers()
      setLoadingUser(false)
    }
  }, [searchUsers, open])

  const getConversationSummary = async () => {
    setLoading(true)
    const result = await messageActions.getConversationSummary(conversation._id)
    if (result.success) {
      setData(result.data.summary)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  useEffect(() => {
    if (visible) {
      getConversationSummary()
    }
  }, [visible])

  return (
    <MatModal
      title={
        <>
          <SVGAISummaryIcon width={30} height={26} />
          {getText('WORD_SUMMARY')}
        </>
      }
      noTrigger
      confirmLoading={saving}
      visible={visible}
      onTrrigerClick={() => setVisible(true)}
      onCancel={() => setVisible(false)}
      okText={getText('ACTION_SEND')}
      cancelText={getText('WORD_SKIP')}
      submitButtonDisabled={!listUserSelected.length}
      centered={false}
      onOk={async () => {
        setSaving(true)
        const result = await messageActions.sendConversationSummary(
          data._id,
          listUserSelected.map((item) => item._id)
        )
        if (result.success) {
          setVisible(false)
        } else {
          notifyError(getTextServerError(result.errMsg))
        }
        setSaving(false)
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : data ? (
        <div className='ai_summarize_modal_body'>
          <p>{data.text}</p>
          <MatSelectSecondary
            placeholder={getText('PH_SEND_TO')}
            options={listUsers || []}
            mode='multiple'
            onLoadMore={() => {
              setPage(page + 1)
            }}
            value={listUserSelected}
            onSearch={(search) => setAssignSearchKeyword(search)}
            showSearch={true}
            onSelectItem={(item) => {
              setlistUserSelected((oldList) => {
                oldList.push(item)
                return [...oldList]
              })
              setAssignSearchKeyword('')
            }}
            onDeselect={(val) => {
              setlistUserSelected((oldList) => {
                const newList = oldList.filter((item) => item._id !== val)
                return [...newList]
              })
            }}
            loading={loadingUser}
            onDropdownVisibleChange={() => setOpen(true)}
            getPopupContainer={() => document.body}
            maxTagCount={10}
            multipleNewStyle
            onBlur={() => {
              setAssignSearchKeyword('')
            }}
            onClear={() => {
              setAssignSearchKeyword('')
              setlistUserSelected([])
            }}
          />
        </div>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </MatModal>
  )
}

export default CenterAISummarizeModal
