import React, { memo, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import authActions from '../../store/modules/authActions'
import { getText } from '../../lang'
import { formatDateAppoinment, MainColor } from '../../utils'
import SVGDateIcon from '../../icons/SVG/SVGDateIcon'
import SVGSendMessageNew from '../../icons/SVG/SVGSendMessageNew'
import SVGRemindMe from '../../icons/SVG/SVGRemindMe'
import MatButton from '../MatButton'
import MatRow from '../MatRow'
import MatSelect from '../MatSelect'
import utilDate from '../../utils/utilsDate'
import UtcTimezoneMessage from './UtcTimezoneMessage'

const DateTimePicker = (props) => {
  const { onClickSend, loading, isRemindMeToFollowUp, remindedDay, visible } = props
  const [systemHour, setSystemHour] = useState('')
  const [hourValue, setHourValue] = useState('')
  const [minuteValue, setMinuteValue] = useState('')
  const [selectedDate, setSelectedDate] = useState(utilDate.getDateByNewDateUTC())
  const [fullDateTimeSelected, setFullDateTimeSelected] = useState()

  useEffect(() => {
    let realHourValue
    if (systemHour === 'pm') {
      realHourValue = hourValue === '12' ? Number(hourValue) : Number(hourValue) + 12
    } else {
      realHourValue = hourValue === '12' ? 0 : Number(hourValue)
    }
    const fullDateSelected = new Date(selectedDate).setHours(
      realHourValue,
      minuteValue
    )

    const organization = authActions.getOrganization()
    const isoDate = moment(fullDateSelected)
      .tz(organization.timezone, true)
      .utc()
      .format()

    setFullDateTimeSelected(isoDate)
    if (isRemindMeToFollowUp) {
      props.setCorrectFormatDate(isoDate)
    }
  }, [systemHour, hourValue, minuteValue, selectedDate])

  useEffect(() => {
    if (!visible) {
      return
    }
    const minutesToAdd = isRemindMeToFollowUp ? 15 : 5
    const currentTimeSplit = utilDate
      .getDateUTC()
      .add(minutesToAdd, 'minutes')
      .format('hh:mm:a')
      .split(':')
    const currentHours = currentTimeSplit[0]
    const currentMinute = currentTimeSplit[1]

    setSystemHour(currentTimeSplit[2])
    setHourValue(currentHours)
    setMinuteValue(currentMinute)

    let initialSelected = utilDate.getDateByNewDateUTC()
    if (remindedDay === 'tomorrow') {
      const tomorrow = new Date(initialSelected)
      initialSelected = new Date(tomorrow.setDate(tomorrow.getDate() + 1))
    } else if (remindedDay === 'next_week') {
      const next_week = new Date(initialSelected)
      initialSelected = new Date(next_week.setDate(next_week.getDate() + 7))
    }
    setSelectedDate(initialSelected)
  }, [remindedDay, visible])

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target
    const [, fieldIndex] = name.split('-')
    let fieldIntIndex = parseInt(fieldIndex, 10)

    if (value.length >= maxLength) {
      if (fieldIntIndex < 2) {
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        )

        if (nextfield !== null) {
          nextfield.focus()
        }
      }
    }
  }

  return (
    <>
      <ReactDatePicker
        inline
        selected={new Date(selectedDate)}
        disabledKeyboardNavigation
        showDisabledMonthNavigation
        minDate={new Date()}
        onChange={(calDate) => {
          setSelectedDate(calDate)
        }}
      />
      <div className='time-picker-block-wrapper'>
        <div className='with-select'>
          <div className='time-picker-block'>
            <input
              name='field-1'
              value={hourValue}
              type='number'
              inputMode='numeric'
              onChange={(e) => {
                if (e.target.value.length === 3) {
                  return false
                }
                if (e.target.value > 12 || e.target.value < 0) {
                  return false
                }
                setHourValue(e.target.value)
                handleChange(e)
              }}
              onBlur={(e) => {
                if (e.target.value.length === 1) {
                  setHourValue(e.target.value.toString().padStart(2, '0'))
                  return
                }
              }}
              maxLength={2}
            />
            <div className='point'>:</div>
            <input
              name='field-2'
              value={minuteValue}
              type='number'
              inputMode='numeric'
              onChange={(e) => {
                if (e.target.value.length === 3) {
                  return false
                }
                if (e.target.value > 59 || e.target.value < 0) {
                  return false
                }
                setMinuteValue(e.target.value)
                handleChange(e)
              }}
              onBlur={(e) => {
                if (e.target.value.length === 1) {
                  setMinuteValue('0' + e.target.value)
                }
              }}
              maxLength={2}
            />
          </div>
          <MatSelect
            value={systemHour}
            options={[
              {
                label: 'AM',
                value: 'am',
              },
              {
                label: 'PM',
                value: 'pm',
              },
            ]}
            onSelectItem={(item) => {
              setSystemHour(item.value)
            }}
          />
        </div>
        {!isRemindMeToFollowUp && (
          <MatButton
            loading={loading}
            buttonText={<SVGSendMessageNew color={'#fff'} width={24} height={24} />}
            onClick={() => onClickSend(fullDateTimeSelected)}
          />
        )}
      </div>
      <UtcTimezoneMessage
        style={{ marginTop: '10px' }}
        title={
          isRemindMeToFollowUp
            ? getText('TEXT_REMINDER_TIME_ZONE')
            : getText('TEXT_SCHEDULED_TIME_ZONE')
        }
      />
      {isRemindMeToFollowUp && (
        <MatRow className='date-time-showed-wrapper'>
          <MatRow>
            <SVGDateIcon color={MainColor} />
            {formatDateAppoinment(selectedDate)}
          </MatRow>
          <MatRow>
            <SVGRemindMe />
            {hourValue + ':' + minuteValue + ' ' + systemHour}
          </MatRow>
        </MatRow>
      )}
    </>
  )
}

export default memo(DateTimePicker)
