import React, {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { Flex, Tag } from 'antd'
import { debounce } from 'lodash'
import statActions from '../../../store/modules/statActions'
import {
  locationSelector,
  organizationSelector,
} from '../../../store/selectors/selectors'
import { getText } from '../../../lang'
import { formatDate, formatTime } from '../../../utils'
import SVGSearch from '../../../icons/SVG/SVGSearch'
import MatTable from '../../../components/MatTable'
import { CUSTOM, ORG, TABLE_DEFAULT_ROW_HEIGHT } from '../../../devOptions'
import { notifyError } from '../../../utils/Notify'
import { DashboardContext } from '../Dashboard'
import Div from '../../../components/Div/Div'
import ListLoadMore from '../../../components/ListLoadMore'
import InputTextSecondary from '../../../components/InputText/InputTextSecondary'
import MatSelectSecondary from '../../../components/MatSelect/MatSelectSecondary'
import utilNumber from '../../../utils/utilNumber'
import DashboardInfoTooltip from '../DashboardComponents/DashboardInfoTooltip/DashboardInfoTooltip'
import './DashboardUsers.scss'

const UserType = ({ isAdmin, isManager, isSuperAdmin, isSupportAgent }) => {
  const setUserType = () => {
    if (isSuperAdmin) {
      return {
        color: 'var(--red)',
        title: getText('WORD_ROLE_SUPER_ADMIN'),
      }
    } else if (isAdmin) {
      return {
        color: 'var(--green)',
        title: getText('WORD_ROLE_ADMIN'),
      }
    } else if (isManager) {
      return {
        color: 'var(--blue)',
        title: getText('WORD_ROLE_MANAGER'),
      }
    } else if (isSupportAgent) {
      return {
        color: 'var(--mainColor)',
        title: getText('WORD_ROLE_SUPPORT'),
      }
    }
  }

  const userType = setUserType()
  return (
    <Tag
      className='dashboard_users_page--user-type'
      color={userType?.color || 'transparent'}
    >
      {userType?.title || ''}
    </Tag>
  )
}

const TableTitleWithTooltip = ({ title, tooltipTitle, sorter }) => {
  return (
    <span align='left'>
      {title}
      <DashboardInfoTooltip title={tooltipTitle} />
    </span>
  )
}

const DashboardUsers = forwardRef((_, ref) => {
  const {
    selectedRange,
    selectedDataType,
    selectCustomRange,
    dashboardUserPageRef,
  } = useContext(DashboardContext)

  const isFirstRender = useRef(true)

  const location = useSelector(locationSelector)
  const organization = useSelector(organizationSelector)

  const [loading, setLoading] = useState(false)
  const [usersData, setUsersData] = useState({
    list: [],
    total: 0,
  })
  const [page, setPage] = useState(0)
  const [searchDebounce, setSearchDebounce] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [userTypeValue, setUserTypeValue] = useState()
  const [sorting, setSorting] = useState({
    order: '',
    sort: '',
  })

  useImperativeHandle(ref, () => ({
    resetPage: () => {
      setPage(0)
    },
  }))

  const userTypeValueList = [
    {
      label: getText('WORD_ROLE_ADMIN'),
      value: 'isAdmin',
    },
    {
      label: getText('WORD_ROLE_MANAGER'),
      value: 'isManager',
    },
    {
      label: getText('WORD_ROLE_SUPPORT'),
      value: 'isSupportAgent',
    },
  ]

  const columns = [
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_NAME')}
          tooltipTitle={getText('TOOLTIP_USER_USER_NAME')}
        />
      ),
      dataIndex: 'user_name',
      key: 'user_name',
      sorter: true,
      render: (_, { user_name, email, phone }) => {
        return (
          <Flex vertical style={{ maxWidth: 160 }}>
            {Boolean(user_name?.trim()) ? user_name : '-'}
            <Div className='dashboard_users_page--email-phone'>{email || phone}</Div>
          </Flex>
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_USER_ROLES')}
          tooltipTitle={getText('TOOLTIP_USER_USER_TYPE')}
        />
      ),
      dataIndex: 'user_type',
      key: 'user_type',
      render: (_, { isAdmin, isManager, isSuperAdmin, isSupportAgent }) => {
        return (
          <UserType
            isAdmin={isAdmin}
            isManager={isManager}
            isSuperAdmin={isSuperAdmin}
            isSupportAgent={isSupportAgent}
          />
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('TEXT_LAST_LOGIN_DATE')}
          tooltipTitle={getText('TOOLTIP_USER_LAST_LOGIN')}
        />
      ),
      dataIndex: 'last_login_date',
      key: 'last_login_date',
      sorter: true,
      render: (_, { last_login_date }) => {
        return last_login_date ? (
          <Div style={{ minWidth: 100 }}>
            {formatDate(last_login_date)} {formatTime(last_login_date)}
          </Div>
        ) : (
          ''
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          sorter
          title={getText('WORD_APPOINTMENTS_CREATED')}
          tooltipTitle={getText('TOOLTIP_USER_APPOINTMENTS_CREATED')}
        />
      ),
      dataIndex: 'count_appointments',
      key: 'count_appointments',
      sorter: true,
      render: (_, { count_appointments }) => {
        return (
          <Div style={{ minWidth: 120 }}>
            {utilNumber.numberFractions(count_appointments)}
          </Div>
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('TEXT_VIDEO_SENT')}
          tooltipTitle={getText('TOOLTIP_USER_VIDEOS_SENT')}
        />
      ),
      dataIndex: 'count_videos_sent',
      key: 'count_videos_sent',
      sorter: true,
      render: (_, { count_videos_sent }) => {
        return (
          <Div style={{ minWidth: 90 }}>
            {utilNumber.numberFractions(count_videos_sent)}
          </Div>
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_SENT_MESSAGES')}
          tooltipTitle={getText('TOOLTIP_USER_SENT_MESSAGES')}
        />
      ),
      dataIndex: 'count_messages_sent',
      key: 'count_messages_sent',
      sorter: true,
      render: (_, { count_messages_sent }) => {
        return (
          <Div style={{ minWidth: 100 }}>
            {utilNumber.numberFractions(count_messages_sent)}
          </Div>
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_REVIEW_INVITES_SENT')}
          tooltipTitle={getText('TOOLTIP_REVIEW_INVITES_SENT')}
        />
      ),
      dataIndex: 'review_invites_count',
      key: 'review_invites_count',
      sorter: true,
      render: (_, { review_invites_count }) => {
        return (
          <Div style={{ minWidth: 120 }}>
            {utilNumber.numberFractions(review_invites_count)}
          </Div>
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_CLIENTS_MESSAGED')}
          tooltipTitle={getText('TOOLTIP_CLIENTS_MESSAGED')}
        />
      ),
      dataIndex: 'active_conversations_count',
      key: 'active_conversations_count',
      sorter: true,
      render: (_, { active_conversations_count }) => {
        return (
          <Div style={{ minWidth: 120 }}>
            {utilNumber.numberFractions(active_conversations_count)}
          </Div>
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_ASSIGNED_CLIENTS')}
          tooltipTitle={getText('TOOLTIP_ASSIGNED_CLIENTS')}
        />
      ),
      dataIndex: 'assigned_conversations_count',
      key: 'assigned_conversations_count',
      sorter: true,
      render: (_, { assigned_conversations_count }) => {
        return (
          <Div style={{ minWidth: 110 }}>
            {utilNumber.numberFractions(assigned_conversations_count)}
          </Div>
        )
      },
    },
  ]

  const fill = useCallback(
    async (location_id = '') => {
      if (selectedRange === CUSTOM && !selectCustomRange) {
        // Prevent API call if custom range is not selected
        return
      }
      setLoading(true)
      let locationId = selectedDataType === ORG ? '' : location_id || location?._id
      const result = await statActions.getUserDashboard(
        selectedRange,
        page,
        TABLE_DEFAULT_ROW_HEIGHT,
        locationId,
        selectCustomRange.start_date,
        selectCustomRange.end_date,
        searchDebounce,
        userTypeValue,
        sorting
      )
      if (result.success) {
        const newData =
          page === 0 ? result.data.stats : [...usersData.list, ...result.data.stats]
        setUsersData({
          list: newData.map((item, index) => {
            Object.assign(item, {
              key: index,
            })
            return item
          }),
          total: result.data.total_items,
        })
      } else {
        notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
      }
      setLoading(false)
    },
    [
      page,
      selectedRange,
      selectedDataType,
      selectCustomRange,
      searchDebounce,
      userTypeValue,
      sorting,
    ]
  )

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    if (page === 0) {
      fill(location?._id)
    } else {
      setPage(0)
    }
  }, [location?._id, organization?._id])

  useEffect(() => {
    fill()
  }, [fill])

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchDebounce(options)
    }, 700),
    []
  )

  const getSortedData = (sort) => {
    const fieldMap = {
      user_name: 'firstName',
      last_login_date: 'lastLoginDate',
      count_appointments: 'appointment_count',
      count_videos_sent: 'video_count',
      count_messages_sent: 'message_count',
      review_invites_count: 'review_invites_count',
      active_conversations_count: 'active_conversations_count',
      assigned_conversations_count: 'assigned_conversations_count',
    }

    if (sort.order && sort.field && fieldMap[sort.field]) {
      setSorting({
        order: sort.order === 'ascend' ? 'asc' : 'desc',
        sort: fieldMap[sort.field],
      })
    } else {
      setSorting(null)
    }

    if (page > 0) {
      setPage(0)
    }
  }

  return (
    <Div className={'dashboard_users_page'}>
      <Flex className='dashboard_users_page--inputs'>
        <InputTextSecondary
          placeholder={getText('WORD_SEARCH')}
          allowClear
          value={searchValue}
          prefix={<SVGSearch />}
          autoComplete={'off'}
          variant={false}
          onChange={(e) => {
            debouncedChange(e.target.value)
            setSearchValue(e.target.value)
          }}
          onClear={() => {
            debouncedChange()
          }}
          onPressEnter={() => {
            debouncedChange(searchValue)
          }}
        />
        <MatSelectSecondary
          secondary
          placeholder={getText('WORD_SELECT_ROLE')}
          value={userTypeValue}
          options={userTypeValueList}
          onSelect={(value) => {
            setPage(0)
            setUserTypeValue(value)
          }}
          showSearch={false}
          onClear={() => {
            setPage(0)
            setUserTypeValue()
          }}
        />
      </Flex>
      <ListLoadMore
        loading={loading}
        totalDataCount={usersData.total}
        onLoadMore={() => {
          if (!loading && usersData.list.length < usersData.total) {
            setLoading(true)
            setPage((page) => page + 1)
          }
        }}
        pageStart={0}
        getScrollParent={() => dashboardUserPageRef?.current || window}
        data={usersData.list}
        customList={
          <MatTable
            columns={columns}
            loading={loading}
            dataSource={usersData.list}
            withoutPagination
            sticky={{
              offsetHeader: -10,
            }}
            tableLayout={'auto'}
            scroll={{
              x: 800,
            }}
            showSorterTooltip={{
              target: 'sorter-icon',
            }}
            onChange={(_, __, sort) => {
              getSortedData(sort)
            }}
          />
        }
      />
    </Div>
  )
})

export default memo(DashboardUsers)
