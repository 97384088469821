import React, { memo } from 'react'
import { Card, Divider, Flex, Progress, Tooltip, Typography } from 'antd'
import { getText } from '../../../../../lang'
import Div from '../../../../../components/MatDiv/Div'
import SVGDashUniqueLeadsIcon from '../../../../../icons/SVG/SVGDashUniqueLeadsIcon'
import utilNumber from '../../../../../utils/utilNumber'
import DashboardInfoTooltip from '../../../DashboardComponents/DashboardInfoTooltip/DashboardInfoTooltip'

const DashboardUniqueLeads = ({ number, percent, content }) => {
  return (
    <Card className='dashboard_block dashboard_unique_leads'>
      <Div className={`dashboard_block--blurred-part green`}></Div>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        <SVGDashUniqueLeadsIcon />
        <Div className='dashboard_block--header--right'>
          <span>
            {getText('WORD_UNIQUE_LEADS')}
            <DashboardInfoTooltip title={getText('TOOLTIP_UNIQUE_LEADS')} />
          </span>
        </Div>
      </Flex>
      <Flex
        align='center'
        style={{ marginLeft: 0 }}
        className={'dashboard_sent_statistics--information'}
      >
        <Typography level={1}>{utilNumber.numberFractions(number)}</Typography>
      </Flex>
      <Divider style={{ borderBlockStart: '1px solid #38334D' }} />
      <Flex
        gap={12}
        vertical={'horizontal'}
        style={{ marginLeft: 0 }}
        className={'dashboard_sent_statistics--information'}
      >
        <Typography level={3} style={{ color: 'var(--white)' }}>
          {getText('WORD_LEADS_ENGAGED')}
        </Typography>
        <Typography level={1}>{utilNumber.numberFractions(content) || 0}</Typography>
        <Typography level={3} style={{ color: 'var(--white)', marginTop: 30 }}>
          {getText('WORD_LEADS_ENGAGED_PERCENTAGE')}
          <DashboardInfoTooltip title={getText('TOOLTIP_LEADS_ENGAGED')} />
        </Typography>
      </Flex>
      <Flex align='center' justify='space-between'>
        <div className='dashboard_block--tooltip-progress'>
          <Tooltip
            overlayClassName={'green'}
            getPopupContainer={(trigger) => trigger.parentNode}
            title={`${Math.round(percent || 0)}%`}
            align={{
              useCssRight: true,
              targetOffset: ['100%', '100%'],
            }}
            overlayStyle={{
              left: `calc(${Math.round(percent || 0)}% - 23px)`,
            }}
          >
            <Progress
              percent={Math.round(percent || 0)}
              percentPosition={{
                align: 'center',
                type: 'inner',
              }}
              showInfo={false}
              strokeColor={{
                '0%': '#D3C759',
                '100%': '#48AF81',
              }}
            />
          </Tooltip>
        </div>
      </Flex>
    </Card>
  )
}

export default memo(DashboardUniqueLeads)
