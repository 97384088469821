import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { runTagManager } from './utils/tagManager'
import MainLayout from './layout/MainLayout/MainLayout'
import LocationNavigation from './views/locations/navigation/LocationNavigation'
import OrganizationNavigation from './views/organization/navigation/OrganizationNavigation'
import authActions from './store/modules/authActions'
import { SUPER_ADMIN_FAVORITE_EMAILS } from './devOptions'
import userActions from './store/modules/userActions'
import { accessTokenSelector } from './store/selectors/selectors'

export const URValidator = (path, _id) => {
  const organization = authActions.getOrganization()
  const user = authActions.getUserData()
  const hasAccessToMultipleOrganizations =
    user.accessibleOrganizations && user.accessibleOrganizations.length > 1

  const isCSTeamEmail = SUPER_ADMIN_FAVORITE_EMAILS.includes(user.email)

  let roles = {
    '/payments':
      !user.isSupportAgent && organization && organization.enable_payments,
    '/organizations': user.isSuperAdmin,
    '/location/list': userActions.getUserHasPermission(
      user,
      organization?.restrictions?.allowAccessToAllLocations
    ),
    '/location/settings/new': user.isSuperAdmin,
    '/dashboard':
      user.isAdmin ||
      user.isManager ||
      (hasAccessToMultipleOrganizations && user.isSupportAgent),
    '/broadcasting':
      user.isSuperAdmin &&
      (user.isAdmin || user.isManager) &&
      organization.enable_broadcast,
    '/users': user.isAdmin || user.isManager,
    '/leaderboard': !user.isSupportAgent,
    [`/location/settings/${_id}/integration`]: user.isAdmin,
    [`/location/settings/${_id}/edit`]: userActions.getUserHasPermission(
      user,
      organization?.restrictions?.allowAccessToAllLocations
    ),
    [`/location/settings/${_id}/googlebusiness`]: user.isAdmin,
    '/organization/list': user.isSuperAdmin,
    [`/organization/settings/${_id}/general`]: user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/tags`]: user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/dripsequence/list`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/dripsequence/create`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/dripsequence/edit`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/touchpoints`]: user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/template/create`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/template/sms/create`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/template/email/edit`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/templates/sms/list`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/templates/email/list`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/dripsequence/template/list`]:
      user.isSuperAdmin && isCSTeamEmail,
    [`/organization/settings/${_id}/workflow`]: user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/conversation_ai`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/restrictions`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/opening_hours`]:
      user.isAdmin || user.isSuperAdmin,
    [`/organization/settings/${_id}/voice_ai`]: user.isAdmin || user.isSuperAdmin,
    '/broadcasting/list':
      (user.isAdmin || user.isManager) && organization.enable_broadcast,
    '/messages': true,
    '/profile/edit': true,
  }

  return roles[path] === undefined || roles[path]
}

const PrivateRoute = ({
  children,
  noFooter,
  isRouteProps,
  isRedirectedComponent,
  containerStyle,
}) => {
  const location = useLocation()
  const { _id } = useParams()

  const userLogin = useSelector(accessTokenSelector)

  const isOrganizationSubmenu = useMemo(
    () => location.pathname.includes('organization/settings'),
    [location?.pathname]
  )
  const isLocationSubMenu = useMemo(
    () => location.pathname.includes('location/settings'),
    [location?.pathname]
  )

  useEffect(() => {
    if (userLogin) {
      runTagManager()
    }
  }, [userLogin])

  if (!Boolean(userLogin)) {
    localStorage.setItem('redirectAfterLogin', location.pathname + location.search)
    return <Navigate to='/login' />
  }

  if (isRedirectedComponent) {
    return <Navigate to={`/profile/edit`} />
  }

  return URValidator(location.pathname, _id) ? (
    <MainLayout nofooter={noFooter} containerStyle={containerStyle}>
      {isOrganizationSubmenu ? (
        <OrganizationNavigation>
          {isRouteProps ? React.cloneElement(children) : children}
        </OrganizationNavigation>
      ) : isLocationSubMenu ? (
        <LocationNavigation>
          {isRouteProps ? React.cloneElement(children) : children}
        </LocationNavigation>
      ) : isRouteProps ? (
        React.cloneElement(children)
      ) : (
        children
      )}
    </MainLayout>
  ) : (
    <Navigate to='/error' />
  )
}

export default PrivateRoute
