import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M8 2V5'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16 2V5'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.5 9.09375L20.5 9.09375'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17L3 8.5C3 5.5 4.5 3.5 8 3.5L16 3.5C19.5 3.5 21 5.5 21 8.5Z'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.6947 13.7031H15.7037'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.6947 16.7031H15.7037'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9955 13.7031H12.0045'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9955 16.7031H12.0045'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.29431 13.7031H8.30329'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.29431 16.7031H8.30329'
      stroke={props.color || '#A3A1B7'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGMyDefaultDatePickerIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMyDefaultDatePickerIcon
