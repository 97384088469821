import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_12911_440704)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_12911_440704)'>
          <circle cx='18' cy='17' r='13' fill='#16B572' />
        </g>
        <path
          d='M22.3332 23H19.6665'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21 24.3334V21.6667'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.1067 17.2466C18.0401 17.24 17.9601 17.24 17.8867 17.2466C16.3001 17.1933 15.0401 15.8933 15.0401 14.2933C15.0334 12.66 16.3601 11.3333 17.9934 11.3333C19.6267 11.3333 20.9534 12.66 20.9534 14.2933C20.9534 15.8933 19.6867 17.1933 18.1067 17.2466Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.9934 24.54C16.78 24.54 15.5734 24.2333 14.6534 23.62C13.04 22.54 13.04 20.78 14.6534 19.7067C16.4867 18.48 19.4934 18.48 21.3267 19.7067'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_12911_440704)'
      />
      <defs>
        <filter
          id='filter0_f_12911_440704'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_12911_440704'
          />
        </filter>
        <linearGradient
          id='paint0_linear_12911_440704'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_12911_440704'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashUniqueLeadsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashUniqueLeadsIcon
