import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3332 8.33342V12.5001C18.3332 16.6667 16.6665 18.3334 12.4998 18.3334H7.49984C3.33317 18.3334 1.6665 16.6667 1.6665 12.5001V7.50008C1.6665 3.33341 3.33317 1.66675 7.49984 1.66675H11.6665'
        stroke='#A3A1B7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3332 8.33341H14.9998C12.4998 8.33341 11.6665 7.50008 11.6665 5.00008V1.66675L18.3332 8.33341Z'
        stroke='#A3A1B7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.8335 10.8333H10.8335'
        stroke='#A3A1B7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.8335 14.1667H9.16683'
        stroke='#A3A1B7'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGDetailsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDetailsIcon
