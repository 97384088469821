import React from 'react'
import { Flex } from 'antd'
import MyDefaultBlockSubtitle from '../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultRow from '../../../../components/MyDefaultRow/MyDefaultRow'
import SVGIntentPositiveIcon from '../../../../icons/SVG/SVGIntentPositiveIcon'
import SVGIntentNegativeIcon from '../../../../icons/SVG/SVGIntentNegativeIcon'
import SVGIntentNoReplyIcon from '../../../../icons/SVG/SVGIntentNoReplyIcon'
import { getText } from '../../../../lang'
import MyDefaultTableText from '../../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'

const BroadcastListExpandedItemWorkflow = ({ title, workflowData, name }) => {
  return (
    <Flex vertical gap={5}>
      <MyDefaultBlockSubtitle subtitle={title} />
      <MyDefaultTableText text={name} />
      <MyDefaultRow
        gap={10}
        fitContent
        leftSide={
          <Flex align='center' gap={5}>
            <SVGIntentPositiveIcon />
            {getText('WORD_POSITIVE_REPLY')}
          </Flex>
        }
        rightSide={workflowData.positiveCounter || 0}
      />
      <MyDefaultRow
        gap={10}
        fitContent
        leftSide={
          <Flex align='center' gap={5}>
            <SVGIntentNegativeIcon />
            {getText('WORD_NEGATIVE_REPLY')}
          </Flex>
        }
        rightSide={workflowData.negativeCounter || 0}
      />
      <MyDefaultRow
        gap={10}
        fitContent
        leftSide={
          <Flex align='center' gap={5}>
            <SVGIntentNoReplyIcon />
            {getText('WORD_NO_REPLY')}
          </Flex>
        }
        rightSide={workflowData.noReplyCounter || 0}
      />
    </Flex>
  )
}

export default BroadcastListExpandedItemWorkflow
