import React, { useCallback, useContext, useEffect, useState } from 'react'
import { capitalize } from 'lodash'
import { useSelector } from 'react-redux'
import { locationSelector } from '../../../store/selectors/selectors'
import statActions from '../../../store/modules/statActions'
import { getText } from '../../../lang'
import { MONTH_ORDER, MONTH_SHORT_ORDER } from '../../../utils'
import SVGDashSMSConvStartedIcon from '../../../icons/SVG/SVGDashSMSConvStartedIcon'
import SVGDashVoiceAiCreatedAppointmentsIcon from '../../../icons/SVG/SVGDashVoiceAiCreatedAppointmentsIcon'
import SVGDashVideoConsuptionIcon from '../../../icons/SVG/SVGDashVideoConsuptionIcon'
import SVGDashMissedCalledSavedIcon from '../../../icons/SVG/SVGDashMissedCalledSavedIcon'
import SVGDashCallbackScheduledIcon from '../../../icons/SVG/SVGDashCallbackScheduledIcon'
import SVGDashCallsTransferIcon from '../../../icons/SVG/SVGDashCallsTransferIcon'
import DashboardHoursBlock from '../DashboardComponents/DashboardHoursBlock/DashboardHoursBlock'
import Div from '../../../components/MatDiv/Div'
import DashboardMultiStatistics from '../DashboardComponents/DashboardStatistics/DashboardMultiStatistics'
import DashboardBlockPurple from '../DashboardComponents/DashboardBlockPurple/DashboardBlockPurple'
import { notifyError } from '../../../utils/Notify'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import { CUSTOM, ORG } from '../../../devOptions'
import { DashboardContext } from '../Dashboard'
import LoadingSpinner from '../../../components/LoadingSpinner'
import DashboardInfoBar from '../DashboardComponents/DashboardInfoBar/DashboardInfoBar'
import utilNumber from '../../../utils/utilNumber'
import VoiceAiEmptyBlock from './VoiceAiEmptyBlock/VoiceAiEmptyBlock'
import ValueGeneratedStatistic from './ValueGeneratedStatistic/ValueGeneratedStatistic'
import VoiceAIList from './VoiceAIList/VoiceAIList'
import './VoiceAI.scss'

const VoiceAI = () => {
  const location = useSelector(locationSelector)

  const { isTabletPortraitOrMobile } = useDeviceCheck()
  const { selectedRange, selectedDataType, selectCustomRange } =
    useContext(DashboardContext)

  const [loading, setLoading] = useState(true)
  const [voiceAIData, setVoiceAIData] = useState({})

  const fill = useCallback(async () => {
    if (selectedRange === CUSTOM && !selectCustomRange) {
      // Prevent API call if custom range is not selected
      return
    }
    setLoading(true)
    let locationId = selectedDataType === ORG ? '' : location?._id
    const result = await statActions.getVoiceAIStatsDashboard(
      selectedRange,
      locationId,
      selectCustomRange.start_date,
      selectCustomRange.end_date
    )
    if (result.success) {
      setVoiceAIData(result.data)
    } else {
      notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
    }
    setLoading(false)
  }, [location?._id, selectedRange, selectedDataType, selectCustomRange])

  useEffect(() => {
    fill()
  }, [fill])

  const convertVoiceAIChartData = (obj) => {
    const SORTED_LIST = isTabletPortraitOrMobile ? MONTH_SHORT_ORDER : MONTH_ORDER
    if (!Boolean(obj)) return []
    const result = Object.entries(obj)
      .map(([name, value]) => ({
        name: isTabletPortraitOrMobile
          ? getText(capitalize(name.substring(0, 3)))
          : getText(capitalize(name)),
        nameEng: isTabletPortraitOrMobile
          ? capitalize(name.substring(0, 3))
          : capitalize(name),
        calls_count: value?.calls_count,
        conversation_started_count: value?.conversation_started_count,
      }))
      .sort(
        (a, b) =>
          SORTED_LIST.indexOf(a.nameEng.toLowerCase()) -
          SORTED_LIST.indexOf(b.nameEng.toLowerCase())
      )
    return result
  }

  const totalGeneratedValue =
    voiceAIData?.voice_AI_stats?.service_value_generated +
    voiceAIData?.voice_AI_stats?.sales_value_generated +
    0 +
    voiceAIData?.voice_AI_stats?.other_value_generated

  const convertedVoiceAiChartData = convertVoiceAIChartData(
    voiceAIData?.voice_AI_chart
  )

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Div className={`overview_and_superhuman`}>
      {voiceAIData?.existsAssistant ? (
        <React.Fragment>
          <ValueGeneratedStatistic
            number={totalGeneratedValue}
            data={[
              {
                service: voiceAIData?.voice_AI_stats?.service_value_generated,
                sales: voiceAIData?.voice_AI_stats?.sales_value_generated,
                parts: 0,
                timeSaved: voiceAIData?.voice_AI_stats?.other_value_generated,
                gap: 10,
              },
            ]}
            responsiveChartHeight={isTabletPortraitOrMobile ? 100 : 74}
          />
          <Div className={'overview_and_superhuman--overview'}>
            <DashboardMultiStatistics
              leftNumber={voiceAIData?.voice_AI_stats?.total_calls}
              rightNumber={voiceAIData?.voice_AI_stats?.conversations_started}
              data={convertedVoiceAiChartData}
              leftIcon={<SVGDashMissedCalledSavedIcon />}
              leftTitle={getText('WORD_MISSED_CALLS_SAVED')}
              rightIcon={<SVGDashSMSConvStartedIcon />}
              rightTitle={getText('WORD_SMS_CONVERSATIONS_STARTED')}
              responsiveChartHeight={202}
            />
            <Div className={'overview_and_superhuman--overview--half'}>
              <Div className={'overview_and_superhuman--overview--half-items'}>
                <DashboardHoursBlock
                  number={voiceAIData?.voice_AI_stats?.appointments_booked}
                  afterHours={voiceAIData?.voice_AI_stats?.after_hours_appointments}
                  workingHours={
                    voiceAIData?.voice_AI_stats?.working_hours_appointments
                  }
                  icon={<SVGDashVoiceAiCreatedAppointmentsIcon />}
                  title={getText('WORD_VOICE_AI_CREATED_APPOINTMENTS')}
                  firstSubTitle={getText('TEXT_WORKING_HOURS')}
                  secondSubTitle={getText('TEXT_AFTER_HOURS')}
                />
                <DashboardInfoBar
                  icon={<SVGDashCallsTransferIcon />}
                  title={getText('WORD_CALLS_TRANSFERRED')}
                  customNumber={voiceAIData?.voice_AI_stats?.calls_transferred || 0}
                  color={'purple'}
                />
              </Div>
              <Div className={'overview_and_superhuman--overview--half-items'}>
                <DashboardBlockPurple
                  className={'dashboard_block_purple_billed_minutes'}
                  icon={<SVGDashVideoConsuptionIcon />}
                  count={utilNumber.formatSecondsToHms(
                    voiceAIData?.voice_AI_stats?.total_duration_seconds || 0
                  )}
                  title={getText('WORD_TOTAL_BILLED_MINUTES')}
                />
                <DashboardInfoBar
                  icon={<SVGDashCallbackScheduledIcon />}
                  title={getText('WORD_CALLBACKS_SCHEDULED')}
                  customNumber={voiceAIData?.voice_AI_stats?.callbacks_needed || 0}
                  color={'yellow'}
                />
              </Div>
            </Div>
          </Div>
          <VoiceAIList />
        </React.Fragment>
      ) : (
        <VoiceAiEmptyBlock />
      )}
    </Div>
  )
}

export default VoiceAI
