import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { menuOpenSelector } from '../../store/selectors/selectors'
import authActions from '../../store/modules/authActions'
import SVGDrawerOpenIcon from '../../icons/SVG/SVGDrawerOpenIcon'
import './SiderToggle.scss'

const SiderToggle = ({ theme }) => {
  const menuOpen = useSelector(menuOpenSelector)

  const openMenu = () => authActions.setMenuOpen(!menuOpen)

  return (
    <div
      className={`header-toggle-icon-container ${menuOpen ? ' icon-rotate ' : ' '}`}
      onClick={openMenu}
    >
      <SVGDrawerOpenIcon color={theme === 'dark' ? '#fff' : '#303436'} />
    </div>
  )
}

export default memo(SiderToggle)
