import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.40588 13.5946L13.5942 8.40625'
        stroke={props.color || '#0E0E0E'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5942 13.5946L8.40588 8.40625'
        stroke={props.color || '#0E0E0E'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25004 20.1654H13.75C18.3334 20.1654 20.1667 18.332 20.1667 13.7487V8.2487C20.1667 3.66536 18.3334 1.83203 13.75 1.83203H8.25004C3.66671 1.83203 1.83337 3.66536 1.83337 8.2487V13.7487C1.83337 18.332 3.66671 20.1654 8.25004 20.1654Z'
        stroke={props.color || '#0E0E0E'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGOrgRestricionsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgRestricionsIcon
