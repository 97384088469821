import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import { useSelector } from 'react-redux'
import { Empty } from 'antd'
import paymentsActions from '../../store/modules/paymentsAction'
import { organizationSelector } from '../../store/selectors/selectors'
import authActions from '../../store/modules/authActions'
import { getText } from '../../lang'
import SVGPaymentSalesMonthToDateIcon from '../../icons/SVG/SVGPaymentSalesMonthToDateIcon'
import SVGPaymentSalesLastMonth from '../../icons/SVG/SVGPaymentSalesLastMonth'
import SVGPaymentSalesTodayIcon from '../../icons/SVG/SVGPaymentSalesTodayIcon'
import SVGPaymentDepositsYesterday from '../../icons/SVG/SVGPaymentDepositsYesterday'
import LoadingSpinner from '../../components/LoadingSpinner'
import { getEmptyResult } from './PaymentPageUtils'

const PaymentListSummaryItem = () => {
  let organization = useSelector(organizationSelector)

  const getSummaryTitleIcon = (status) => {
    switch (status) {
      case 'thisMonthSales':
        return {
          icon: <SVGPaymentSalesMonthToDateIcon />,
          title: getText('WORD_SALES_MONTH_TO_DATE'),
          date: moment().format('01.MM') + ' - ' + moment().format('DD.MM'),
          color: '#EA9957',
        }
      case 'lastMonthSales':
        return {
          icon: <SVGPaymentSalesLastMonth />,
          title: getText('WORD_SALES_LAST_MONTH'),
          date: moment()
            .locale(authActions.getLanguage())
            .subtract(1, 'month')
            .format('MMMM'),
          color: '#3F8CFF',
        }
      case 'todaySales':
        return {
          icon: <SVGPaymentSalesTodayIcon />,
          title: getText('WORD_SALES_TODAY'),
        }
      case 'depositYesterday':
        return {
          icon: <SVGPaymentDepositsYesterday />,
          title: getText('WORD_DEPOSITS_YESTERDAY'),
        }
      default:
        return null
    }
  }

  const [loadingSummary, setLoadingSummary] = useState(false)
  const [summaryData, setSummaryData] = useState([])

  const formatAmount = (value) => {
    return `$ ${value && value.toLocaleString()}`
  }

  const fill = useCallback(async () => {
    setLoadingSummary(true)
    const result = await paymentsActions.getPaymentsSummary()
    const resultDeposits = await paymentsActions.getDepositsSummary()
    if (result.success && resultDeposits.success) {
      if (resultDeposits.data.currency) {
        delete resultDeposits.data.currency
      }
      resultDeposits.data.depositYesterday = {
        totalAmount: Number(resultDeposits.data.depositYesterday),
      }
      const combinedObj = {
        ...result.data,
        ...resultDeposits.data,
      }
      setSummaryData(Object.entries(combinedObj))
    } else {
      getEmptyResult(result.errMsg, setSummaryData)
    }
    setLoadingSummary(false)
  }, [organization?._id])

  useEffect(() => {
    fill()
  }, [fill])

  return loadingSummary ? (
    <LoadingSpinner />
  ) : (
    <div className='payment-page-summary-item-wrapper'>
      {summaryData && summaryData.length ? (
        summaryData.map((item, index) => {
          return (
            <div key={index} className='payment-page-summary-item'>
              <div className='summary-date-wrapper'>
                <div className='summary-item-header'>
                  {getSummaryTitleIcon(item[0]).icon}
                  <p>{getSummaryTitleIcon(item[0]).title}</p>
                </div>
                <span className='amount'>
                  {formatAmount(item[1].totalAmount || 0)}
                </span>
              </div>
              {getSummaryTitleIcon(item[0]).date && (
                <div
                  className='summary-date'
                  style={{
                    backgroundColor: getSummaryTitleIcon(item[0]).color,
                  }}
                >
                  {getSummaryTitleIcon(item[0]).date}
                </div>
              )}
            </div>
          )
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  )
}

export default PaymentListSummaryItem
