import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='36' height='36' rx='8' fill='#5B66EA' fillOpacity='0.05' />
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='#5B66EA'
        strokeOpacity='0.2'
      />
      <path
        d='M15.7812 19.5532C15.7812 20.4132 16.4413 21.1066 17.2613 21.1066H18.9346C19.6479 21.1066 20.2279 20.4999 20.2279 19.7532C20.2279 18.9399 19.8746 18.6532 19.3479 18.4666L16.6612 17.5332C16.1346 17.3466 15.7812 17.0599 15.7812 16.2466C15.7812 15.4999 16.3612 14.8932 17.0746 14.8932H18.7479C19.5679 14.8932 20.2279 15.5866 20.2279 16.4466'
        stroke='#5B66EA'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 14V22'
        stroke='#5B66EA'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0002 24.6667C21.6821 24.6667 24.6668 21.6819 24.6668 18C24.6668 14.3181 21.6821 11.3333 18.0002 11.3333C14.3183 11.3333 11.3335 14.3181 11.3335 18C11.3335 21.6819 14.3183 24.6667 18.0002 24.6667Z'
        stroke='#5B66EA'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGDashInfluencedSalescon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashInfluencedSalescon
