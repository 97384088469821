import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.61775 6.29228C2.54724 6.22177 2.54718 6.09851 2.61756 6.02791C2.61762 6.02785 2.61769 6.02778 2.61775 6.02772L6.03644 2.61819L6.03691 2.61772C6.07255 2.58208 6.11998 2.5625 6.16919 2.5625C6.1726 2.5625 6.17672 2.56279 6.18346 2.56422C6.18622 2.56481 6.18961 2.56563 6.19379 2.5668C6.21945 2.57886 6.24441 2.58806 6.26789 2.59509C6.28547 2.60507 6.30022 2.61646 6.31064 2.62689L9.70764 6.02388C9.78712 6.10829 9.77822 6.22553 9.71148 6.29228C9.67268 6.33107 9.62791 6.3475 9.57919 6.3475C9.53048 6.3475 9.4857 6.33107 9.44691 6.29228L7.21025 4.05561L6.35669 3.20206V4.40917V19.25C6.35669 19.3497 6.26889 19.4375 6.16919 19.4375C6.0695 19.4375 5.98169 19.3497 5.98169 19.25V4.4V3.19289L5.12814 4.04645L2.88231 6.29228C2.81174 6.36285 2.68832 6.36285 2.61775 6.29228Z'
        fill='#0E0E0E'
        stroke='#0E0E0E'
      />
      <path
        d='M19.7358 15.3542C19.47 15.0883 19.03 15.0883 18.7641 15.3542L16.5183 17.6V2.75C16.5183 2.37417 16.2066 2.0625 15.8308 2.0625C15.455 2.0625 15.1433 2.37417 15.1433 2.75V17.5908L12.9066 15.3542C12.6408 15.0883 12.2008 15.0883 11.935 15.3542C11.6691 15.62 11.6691 16.06 11.935 16.3258L15.3358 19.7267C15.4 19.7908 15.4825 19.8458 15.5741 19.8825C15.5833 19.8825 15.5925 19.8825 15.6016 19.8917C15.675 19.9192 15.7575 19.9375 15.84 19.9375C16.0233 19.9375 16.1975 19.8642 16.3258 19.7358L19.7358 16.3258C20.0016 16.0508 20.0016 15.62 19.7358 15.3542Z'
        fill='#0E0E0E'
      />
    </svg>
  )
}

const SVGOrgWorkflowIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgWorkflowIcon
