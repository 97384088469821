import React from 'react'

const SVGSendMessageLater = (props) => {
  return (
    <div className='anticon'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={20}
        height={20}
        fill='none'
        {...props}
      >
        <g fill='#5B66EA' clipPath='url(#a)'>
          <path d='m18.666 14.916-1.791 1.792-1.01-1.01a.781.781 0 1 0-1.105 1.104l1.563 1.563a.778.778 0 0 0 1.104 0l2.344-2.344a.781.781 0 0 0-1.105-1.105Z' />
          <path d='M10 0C4.393 0 0 4.393 0 10s4.393 10 10 10c1.31 0 2.586-.246 3.791-.732a.781.781 0 1 0-.583-1.449 8.55 8.55 0 0 1-3.208.619c-4.731 0-8.438-3.707-8.438-8.438 0-4.731 3.707-8.438 8.438-8.438 4.731 0 8.438 3.707 8.438 8.438 0 .653-.074 1.304-.219 1.934a.781.781 0 1 0 1.523.35A10.21 10.21 0 0 0 20 10c0-5.607-4.393-10-10-10Z' />
          <path d='M13.677 13.677a.781.781 0 0 0 0-1.104l-2.896-2.897V6.875a.781.781 0 1 0-1.562 0V10c0 .207.082.406.229.552l3.125 3.125c.305.306.8.306 1.104 0Z' />
        </g>
        <defs>
          <clipPath id='a'>
            <path fill='#fff' d='M0 0h20v20H0z' />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default SVGSendMessageLater
