import React, { useEffect, useRef, useState } from 'react'
import { Button, Tooltip } from 'antd'
import './MatButton.scss'

const MatButton = ({
  tooltip,
  tooltipTitle,
  buttonText,
  id,
  dataIndex,
  typeButton = 'primary',
  htmlType = 'submit',
  formName = 'formSubmit',
  onClick,
  loading,
  icon,
  radius,
  style,
  size,
  className,
  disabled,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  onMouseUp,
  onTouchEnd,
  onTouchStart,
}) => {
  const buttonRef = useRef(null)
  const [isEllipsed, setIsEllipsed] = useState(false)

  useEffect(() => {
    const parent = buttonRef?.current
    let span
    if (parent) {
      span = parent.querySelector('.ant-btn-icon')
      if (span) {
        span = parent.querySelector('span + span')
      } else {
        span = parent.querySelector('span')
      }
      const isTextEllipsed = span?.scrollWidth > span?.clientWidth
      setIsEllipsed(isTextEllipsed)
    }
  }, [])

  return tooltip || isEllipsed ? (
    <Tooltip
      title={tooltipTitle || buttonText}
      trigger={['hover', 'click']}
      destroyTooltipOnHide={true}
    >
      <Button
        ref={buttonRef}
        id={id}
        dataindex={dataIndex}
        type={typeButton}
        htmlType={htmlType}
        form={formName}
        onClick={onClick}
        loading={loading}
        icon={icon}
        style={{ borderRadius: radius, ...style }}
        className={`mat-btn ${typeButton ? `mat-btn-${typeButton}` : ''} ${
          size ? `mat-btn-${size}` : ''
        } ${className || ''}`}
        disabled={disabled}
        size={size}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        {buttonText || ''}
      </Button>
    </Tooltip>
  ) : (
    <Button
      ref={buttonRef}
      id={id}
      dataindex={dataIndex}
      type={typeButton}
      htmlType={htmlType}
      form={formName}
      onClick={onClick}
      loading={loading}
      icon={icon}
      style={{ borderRadius: radius, ...style }}
      className={`mat-btn ${typeButton ? `mat-btn-${typeButton}` : ''} ${
        size ? `mat-btn-${size}` : ''
      } ${className || ''}`}
      disabled={disabled}
      size={size}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      {buttonText || ''}
    </Button>
  )
}

export default MatButton
