import React, { useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import MyDefaultBlock from './MyDefaultBlock'
import './MyDefaultBlock.scss'

const MyDefaultBlockWithScroll = ({
  pageStart,
  loadMore,
  hasMore,
  children,
  disablePaddingTitle,
  ...restProps
}) => {
  const scrollBlockRef = useRef(null)
  return (
    <MyDefaultBlock
      className={`default_block_wrapper_with_scroll ${disablePaddingTitle ? 'disablePadding' : ''}`}
      ref={scrollBlockRef}
      disableMarginTitle
      {...restProps}
    >
      <InfiniteScroll
        pageStart={pageStart}
        loadMore={loadMore}
        hasMore={hasMore}
        useWindow={false}
        threshold={150}
        getScrollParent={() => scrollBlockRef.current}
      >
        {children}
      </InfiniteScroll>
    </MyDefaultBlock>
  )
}

export default MyDefaultBlockWithScroll
