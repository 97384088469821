import React, { forwardRef } from 'react'

const Div = forwardRef(({ children, style, className, onClick }, ref) => {
  return (
    <div ref={ref} className={className} style={style} onClick={onClick}>
      {children}
    </div>
  )
})

export default Div
