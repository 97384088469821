import React from 'react'
import Div from '../../../../components/Div/Div'
import { getText } from '../../../../lang'

const VoiceAIBlockRowRight = ({ isActive, isNoCalls }) => {
  return (
    <Div className={'voice_ai_block_row_right_wrapper'}>
      <Div
        className={`voice_ai_block_row_right_wrapper--block ${isActive ? 'active' : 'inactive'}`}
      >
        {isActive ? getText('WORD_ACTIVE') : getText('WORD_INACTIVE')}
      </Div>
      {Boolean(isNoCalls) && getText('TEXT_NO_CALLS')}
    </Div>
  )
}

export default VoiceAIBlockRowRight
