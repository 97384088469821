import React from 'react'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import LanguageItem from './LanguageItem'

const LanguagesSection = (props) => {
  const {
    listLanguage,
    defaultListLanguage,
    defaultLanguage,
    disabled,
    onSetAsDefaultClick,
    onChangeSwitch,
  } = props
  return (
    <MatBlockWithLabel className={'languages-wrapper'}>
      {defaultListLanguage.map((lng, index) => {
        return (
          <LanguageItem
            key={index}
            lng={lng}
            isFirst={index === 0}
            isLast={index === defaultListLanguage.length - 1}
            isSelected={listLanguage.includes(lng)}
            isDefault={defaultLanguage === lng}
            disabled={disabled}
            onSetAsDefaultClick={onSetAsDefaultClick}
            onChangeSwitch={onChangeSwitch}
          />
        )
      })}
    </MatBlockWithLabel>
  )
}

export default LanguagesSection
