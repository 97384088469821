import React from 'react'
import { Button, Form, message, List, Row, Tooltip, Divider } from 'antd'
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import InputFormTextSecondary from '../../../../../components/Form/InputFormText/InputFormTextSecondary'
import { getText } from '../../../../../lang'
import SelectFormSecondary from '../../../../../components/Form/SelectForm/SelectFormSecondary'
import MatButton from '../../../../../components/MatButton'

const UrlConditionManager = ({ urls, setUrls, hideAction = false }) => {
  const actionOptions = [
    { label: getText('TEXT_SHOW'), value: 'show' },
    { label: getText('TEXT_HIDE'), value: 'hide' },
  ]

  const conditionOptions = [
    { label: getText('TEXT_CONTAINS'), value: 'contains' },
    { label: getText('TEXT_STARTS_WITH'), value: 'starts_with' },
    { label: getText('TEXT_MATCHES'), value: 'matches' },
    { label: getText('TEXT_ENDS_WITH'), value: 'ends_with' },
  ]

  const [form] = Form.useForm()

  const conditionExists = (newCondition) => {
    return urls.some(
      (condition) =>
        condition.condition === newCondition.condition &&
        condition.link === newCondition.link
    )
  }

  const EnhancedTooltip = () => {
    const tooltipText = (
      <div>
        <p>{getText('TEXT_CONTENT_VISIBILITY_IS_DYNAMICALLY_CONTROLLED')}</p>
        <br />
        <p>
          <b>{getText('TEXT_SHOW')}:</b>{' '}
          {getText(
            'TEXT_OVERRIDES_HIDE_CONDITIONS_ENSURING_CONTENT_IS_VISIBLE_WHEN'
          )}
        </p>
        <p>
          <b>{getText('TEXT_HIDE')}:</b>{' '}
          {getText('TEXT_MAKES_CONTENT_INVISIBLE_WHEN_ITS_CONDITIONS_MATCH')}
        </p>
        <p>
          <b>{getText('TEXT_PRIORITIZATION')}:</b>{' '}
          {getText('TEXT_WHEN_BOTH_SHOW_AND_HIDE_CONDITIONS_MATCH_SHOW')}
        </p>
        <br />
        <p>
          <b>{getText('TEXT_CONTAINS')}:</b> {getText('TEXT_INCLUDES_SPECIFIC_TEXT')}
        </p>
        <p>
          <b>{getText('TEXT_STARTS_WITH')}:</b>{' '}
          {getText('TEXT_BEGINS_WITH_SPECIFIC_TEXT')}
        </p>
        <p>
          <b>{getText('TEXT_MATCHES')}:</b>{' '}
          {getText('TEXT_EXACTLY_MATCHES_SPECIFIC_TEXT')}
        </p>
        <p>
          <b>{getText('TEXT_ENDS_WITH')}:</b>{' '}
          {getText('TEXT_ENDS_WITH_SPECIFIC_TEXT')}
        </p>
        <p>{getText('TEXT_PROTOCOL_ARE_IGNORED_IN_COMPARISONS')}</p>
      </div>
    )

    return (
      <Tooltip title={tooltipText} overlayStyle={{ width: 250 }}>
        <InfoCircleOutlined style={{ marginLeft: 8, color: 'rgba(0,0,0,.45)' }} />
      </Tooltip>
    )
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (conditionExists(values)) {
          message.error('This condition has already been added.')
          return
        }
        values.action = hideAction ? 'show' : values.action
        setUrls([...urls, values])
        form.resetFields()
      })
      .catch((info) => {})
  }

  const removeCondition = (index) => {
    const newConditions = urls.filter((_, idx) => idx !== index)
    setUrls(newConditions)
  }

  const displayConditionLabel = (condition) => {
    let item = conditionOptions.find((x) => x.value === condition.condition)

    return `Condition: ${item.label}`
  }

  const renderConditionDescription = (item) => {
    return (
      <div>
        <span>
          Action: <b>{item.action}</b>
        </span>
        <br />
        <span>
          Link: <span style={{ color: '#0075ff', opacity: 0.8 }}>{item.link}</span>
        </span>
      </div>
    )
  }

  return (
    <div>
      <Divider />
      <span>
        {getText('TEXT_URL_TARGETING')}
        {EnhancedTooltip()}
      </span>

      <Form
        form={form}
        initialValues={{
          action: hideAction ? 'show' : null,
          condition: null,
          link: null,
        }}
        className='mt-3'
        layout='vertical'
      >
        <div className='d-flex' style={{ gap: 10 }}>
          {hideAction ? null : (
            <SelectFormSecondary
              label='Action'
              name='action'
              options={actionOptions}
              showSearch={false}
              required
            />
          )}
          <SelectFormSecondary
            label='Condition'
            name='condition'
            options={conditionOptions}
            showSearch={false}
            required
          />
          <InputFormTextSecondary
            label={getText('WORD_LINK')}
            name='link'
            required
            errorMessage={getText('ERR_VALIDATION_REQUIRED')}
          />
        </div>
        <Row>
          <div className='d-flex w-100 mb-3 justify-content-end'>
            <MatButton
              buttonText={getText('TEXT_ADD_CONDITION')}
              htmlType='button'
              onClick={handleSubmit}
            />
          </div>
        </Row>
      </Form>

      {urls && urls.length > 0 && (
        <div>
          <span style={{ fontSize: 15 }}>Added Conditions</span>
          <List
            itemLayout='horizontal'
            dataSource={urls}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <Button
                    size='small'
                    type='link'
                    onClick={() => removeCondition(index)}
                  >
                    {getText('WORD_REMOVE')}
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    item.action === 'show' ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )
                  }
                  title={displayConditionLabel(item)}
                  description={<div>{renderConditionDescription(item)}</div>}
                  style={{ wordBreak: 'break-word' }}
                />
              </List.Item>
            )}
          />
        </div>
      )}
      <Divider />
    </div>
  )
}

export default UrlConditionManager
