import React, { useCallback } from 'react'
import { Form, Select } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { getText } from '../../../lang'
import './SelectChecked.scss'

const SelectChecked = ({
  required = false,
  disabled,
  message = `${getText('WORD_PLEASE_SELECT_ITEM')}!`,
  formClassName,
  label = getText('WORD_SELECT'),
  name = 'select',
  hasFeedback,
  formStyle,
  initialValue,
  placeholder = getText('WORD_SELECT_ITEM'),
  className,
  multipleNewStyle,
  mode = '',
  option,
  loading,
  defaultValue,
  showArrow,
  onSelect,
  onSelectItem,
  onChange,
  onChangeItem,
  onSearch,
  onClear,
  labelInValue,
  getPopupContainer,
  filterOption,
  primary,
  dropdownClassName,
  dropdownStyle,
  showSearch = true,
  allowClear = true,
  popupMatchSelectWidth,
  maxTagCount,
  onPopupScroll,
  onLoadMore,
  hasOwnProperty,
}) => {
  const rules = useCallback(() => {
    const rules = []
    if (required && !disabled) {
      rules.push({ required: required, message: message })
    }
    return rules
  }, [required, disabled])
  return (
    <Form.Item
      className={`mat-form-item ${formClassName || ''}`}
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      rules={rules()}
      style={formStyle}
      initialValue={initialValue}
    >
      <Select
        popupMatchSelectWidth={popupMatchSelectWidth}
        placeholder={placeholder || label || 'Select a option '}
        dropdownStyle={dropdownStyle}
        mode={mode}
        className={`mat-select ${className || ''} ${
          multipleNewStyle ? 'mat-multiple-select' : ''
        }`}
        defaultValue={defaultValue}
        popupClassName={`mat-form-select-dropdown ${
          primary ? ' primary ' : ' '
        } ${dropdownClassName || ''}`}
        loading={loading}
        disabled={disabled}
        onSearch={onSearch}
        showSearch={showSearch}
        suffixIcon={showArrow || <DownOutlined />}
        allowClear={allowClear}
        onClear={onClear}
        labelInValue={labelInValue}
        getPopupContainer={
          getPopupContainer ? getPopupContainer : (trigger) => trigger.parentNode
        }
        filterOption={
          Boolean(filterOption)
            ? filterOption
            : (input, option) => {
                return (
                  option.label
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) > -1
                )
              }
        }
        onChange={(e) => {
          onChange && onChange(e)
          if (onChangeItem && option) {
            if (mode === 'multiple') {
              let find = option.filter((oo) => {
                return e.includes(oo.value)
              })
              if (find) {
                onChangeItem(e, find)
              }
            } else {
              let find = option.find((oo) => {
                return oo.value === e
              })
              if (find) {
                onChangeItem(e, find)
              }
            }
          }
        }}
        maxTagCount={maxTagCount}
        onSelect={(e, ee) => {
          onSelect && onSelect(e, ee)
          if (onSelectItem && option) {
            if (mode === 'multiple') {
              let find = option.filter((oo) => {
                return e.includes(oo.value)
              })
              if (find) {
                onSelectItem(e, find)
              }
            } else {
              let find = option.find((oo) => {
                return oo.value === e
              })
              if (find) {
                onSelectItem && onSelectItem(e, find)
              }
            }
          }
        }}
        onPopupScroll={(e) => {
          e.persist()
          onPopupScroll && onPopupScroll(e)
          if (
            Math.ceil(e.target.scrollTop + e.target.offsetHeight) ===
            e.target.scrollHeight
          ) {
            onLoadMore && onLoadMore()
          }
        }}
      >
        {option.map((option, index) => {
          return (
            <Select.Option
              key={option.value + '_' + index}
              value={option.value}
              label={option.label}
            >
              {' '}
              {option.label}{' '}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default SelectChecked
