import React, { useEffect, useMemo, useState } from 'react'
import { getText, getTextServerError } from '../../../../lang'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import organizationActions from '../../../../store/modules/organizationActions'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import SVGAccessControlIcon from '../../../../icons/SVG/SVGAccessControlIcon'
import SVGFeatureVisibilityIcon from '../../../../icons/SVG/SVGFeatureVisibilityIcon'
import MyDefaultInsidePageTabs from '../../../../components/MyDefaultInsidePageTabs/MyDefaultInsidePageTabs'
import SVGCommunicationAndBroadcastingIcon from '../../../../icons/SVG/SVGCommunicationAndBroadcastingIcon'
import authActions from '../../../../store/modules/authActions'
import SVGCalendarInvitationsIcon from '../../../../icons/SVG/SVGCalendarInvitationsIcon'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import {
  CANCEL_BTN,
  MANAGER,
  SUBMIT_BTN,
  SUPPORT_AGENT,
} from '../../../../devOptions'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import TabItems from './TabItems'
import './Restrictions.scss'

const RESTRICTION_TABS = {
  access_control: [
    'allowAccessToAllLocations',
    'allowSupportAgentsAccessToMainInboxFolder',
    'allowSupportAgentsAccessToUnassignedFolder',
    'allowSupportAgentsAccessToArchivedFolder',
  ],
  feature_visibility: [
    'allowDisplayingPushToCRMButtonToSupportAgents',
    'restrictDisableAIBot',
    'allowParseLeadsWithoutPhone',
    'restrictEditSuperHuman',
    'allowNextScheduledSequenceMessage',
    'allowSuperhumanActionInConversation',
    'allowFollowUp',
  ],
  communication_and_broadcasting: [
    'allowTextingCustomersInPendingStatus',
    'restrictSendingBroadcastsOverTheWeekend',
    'restrictUserVoiceCallsFromUsingOrganizationNumbers',
  ],
  calendar_invitations: [
    'calendarInvitationsToUsers',
    'allowAppointmentsOutsideWorkingHours',
  ],
}

const Restrictions = () => {
  const { selectedOrganization: organization } = useOrganizationLayout()
  const { setPageTitle, setFooterButtons } = useLayout()
  const { isTabletPortraitOrMobile } = useDeviceCheck()

  const [loading, setLoading] = useState(true)
  const [isChanged, setisChanged] = useState(false)
  const [restrictions, setRestrictions] = useState({
    allowTextingCustomersInPendingStatus: true,
    restrictSendingBroadcastsOverTheWeekend: false,
    allowAccessToAllLocations: { enabled: false, roles: [] },
    allowSupportAgentsAccessToMainInboxFolder: false,
    allowSupportAgentsAccessToUnassignedFolder: true,
    allowSupportAgentsAccessToArchivedFolder: true,
    calendarInvitationsToUsers: false,
    allowDisplayingPushToCRMButtonToSupportAgents: false,
    allowAppointmentsOutsideWorkingHours: false,
    restrictDisableAIBot: { enabled: false, roles: [] },
    allowParseLeadsWithoutPhone: false,
    restrictUserVoiceCallsFromUsingOrganizationNumbers: true,
    restrictEditSuperHuman: { enabled: false, roles: [] },
    allowNextScheduledSequenceMessage: { enabled: false, roles: [] },
    allowSuperhumanActionInConversation: { enabled: true, roles: [] },
    allowFollowUp: true,
  })

  useEffect(() => {
    setPageTitle(getText('WORD_SETTINGS_AND_PERMISSIONS'))
  }, [])

  const handleSave = async () => {
    if (
      (restrictions?.allowAccessToAllLocations?.enabled &&
        !restrictions?.allowAccessToAllLocations?.roles?.length) ||
      (restrictions?.restrictDisableAIBot?.enabled &&
        !restrictions?.restrictDisableAIBot?.roles?.length) ||
      (restrictions?.restrictEditSuperHuman?.enabled &&
        !restrictions?.restrictEditSuperHuman?.roles?.length) ||
      (restrictions?.allowSuperhumanActionInConversation?.enabled &&
        !restrictions?.allowSuperhumanActionInConversation?.roles?.length)
    ) {
      notifyError(getText('TEXT_PLEASE_SELECT_AT_LEAST_ONE_USER_ROLE'))
      return
    }
    try {
      const result = await organizationActions.setRestrictions(
        restrictions,
        organization?._id
      )
      if (result && result.success) {
        notifySuccess(getText('TEXT_RESTRICTION_WAS_CHANGED_SUCCESSFULLY'))
        const updatedUserData = authActions.getUserData()
        updatedUserData.organization.restrictions = result.data
        authActions.setUserData(updatedUserData)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    } catch (error) {
      notifyError(getTextServerError(error.message))
    }
  }

  const fetchData = async () => {
    try {
      const result = await organizationActions.getRestrictions(organization._id)
      if (result.success) {
        delete result.data.useEnterBtnToSendMessage
        setRestrictions(result.data)
      }
    } catch (error) {
      notifyError(getTextServerError(error.message))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
        action: () => {
          if (isChanged) {
            fetchData()
            setisChanged(false)
          }
        },
      },
      {
        type: SUBMIT_BTN,
        action: () => {
          handleSave()
        },
      },
    ])
  }, [restrictions])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await organizationActions.getRestrictions(organization?._id)
        if (result.success) {
          delete result.data.useEnterBtnToSendMessage
          setRestrictions(result.data)
        }
      } catch (error) {
        notifyError(getTextServerError(error.message))
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleSwitchChange = (key, value) => {
    setisChanged(true)
    setRestrictions((ov) => {
      if (typeof ov[key] === 'object') {
        ov[key].enabled = value
        if (!value) {
          ov[key].roles = []
        } else {
          if (key === 'allowSuperhumanActionInConversation') {
            ov[key].roles = [MANAGER, SUPPORT_AGENT]
          }
        }
      } else {
        ov[key] = value
      }
      return { ...ov }
    })
  }

  const handleCheckBoxPress = (key, role) => {
    setRestrictions((ov) => {
      if (ov[key].roles.includes(role)) {
        const newRoles = ov[key].roles.filter((userRole) => userRole !== role)
        ov[key].roles = [...newRoles]
      } else {
        ov[key].roles.push(role)
      }
      return { ...ov }
    })
  }

  const items = useMemo(() => {
    return [
      {
        key: 'access_control',
        label: !isTabletPortraitOrMobile && (
          <>
            <span>{getText('TEXT_ACCESS_CONTROL')}</span>
          </>
        ),
        icon: isTabletPortraitOrMobile && <SVGAccessControlIcon />,
        children: (
          <TabItems
            items={RESTRICTION_TABS.access_control}
            restrictions={restrictions}
            onSwitchChange={handleSwitchChange}
            onCheckBoxPress={handleCheckBoxPress}
          />
        ),
      },
      {
        key: 'feature_visibility',
        label: !isTabletPortraitOrMobile && (
          <>
            <span>{getText('TEXT_FEATURE_VISIBILITY_AND_CONTROL')}</span>
          </>
        ),
        icon: isTabletPortraitOrMobile && <SVGFeatureVisibilityIcon />,
        children: (
          <TabItems
            items={RESTRICTION_TABS.feature_visibility}
            restrictions={restrictions}
            onSwitchChange={handleSwitchChange}
            onCheckBoxPress={handleCheckBoxPress}
          />
        ),
      },
      {
        key: 'communication_and_broadcasting',
        label: !isTabletPortraitOrMobile && (
          <>
            <span>{getText('TEXT_COMMUNICATION_AND_BROADCASTING')}</span>
          </>
        ),
        icon: isTabletPortraitOrMobile && <SVGCommunicationAndBroadcastingIcon />,
        children: (
          <TabItems
            items={RESTRICTION_TABS.communication_and_broadcasting}
            restrictions={restrictions}
            onSwitchChange={handleSwitchChange}
            onCheckBoxPress={handleCheckBoxPress}
          />
        ),
      },
      {
        key: 'calendar_invitations',
        label: !isTabletPortraitOrMobile && (
          <>
            <span>{getText('TEXT_CALENDAR_INVITATIONS')}</span>
          </>
        ),
        icon: isTabletPortraitOrMobile && <SVGCalendarInvitationsIcon />,
        children: (
          <TabItems
            items={RESTRICTION_TABS.calendar_invitations}
            restrictions={restrictions}
            onSwitchChange={handleSwitchChange}
            onCheckBoxPress={handleCheckBoxPress}
          />
        ),
      },
    ]
  }, [handleSwitchChange, handleCheckBoxPress, restrictions])

  return !loading ? (
    <MyDefaultPageLayout disableHeader>
      <MyDefaultInsidePageTabs items={items} />
    </MyDefaultPageLayout>
  ) : (
    <LoadingSpinner />
  )
}

export default Restrictions
