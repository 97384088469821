import organizationActions from '../../../../store/modules/organizationActions'
import { formatDateAppoinment, getLongName } from '../../../../utils'

export const getTagListId = (tagList) => {
  let list = (tagList || [])?.map((tag) => {
    return {
      label: `#${tag.name}`,
      value: tag._id,
      color: tag.color,
      isSelected: false,
      _id: tag._id,
    }
  })
  return list
}

export const getLanguageList = (languages) => {
  let list = languages.map((lang) => {
    return {
      label: getLongName(lang),
      value: lang,
    }
  })
  return list
}

export const getSourceList = async (id) => {
  let result = await organizationActions.getSequenceSourceList(id, true, true)
  let list = []
  if (result.success) {
    list = result.data
      .filter((source) => {
        return source !== 'outgoing_call'
      })
      .map((src) => {
        return {
          label: src.name,
          value: src.source,
        }
      })
  }
  return list
}

export const getUsersListIds = (users) => {
  let list = users.map((item) => {
    return {
      label: item.fullName,
      value: item._id,
      ...item,
    }
  })

  return list
}

export const getBroadcastList = async (broadItem) => {
  let list = broadItem.map((item) => {
    return {
      label: item.name + '\r\n' + formatDateAppoinment(item.createdAt),
      value: item._id,
      ...item,
    }
  })

  return list
}
