import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getText } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { formatDateTime } from '../../../../utils'
import MyDefaultBlockHeader from '../../../../components/MyDefaultBlock/MyDefaultBlockHeader'
import MyDefaultBlockRow from '../../../../components/MyDefaultBlock/MyDefaultBlockRow'
import { userSelector } from '../../../../store/selectors/selectors'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import TouchpointsEditModal from './TouchpointsEditModal'

const TouchpointsItem = ({ item, organization, onSave }) => {
  const navigate = useNavigate()
  const user = useSelector(userSelector)

  const [visible, setVisible] = useState(false)

  const [consentedState, setConsentedState] = useState()
  const [forwardLead, setForwardLead] = useState()

  const isFromVoiceAI = item?.source && item.source.startsWith('Voice AI')

  useEffect(() => {
    setConsentedState(item.is_consented)
    setForwardLead(item.enabled_forward)
  }, [])

  const handleChangeConsented = async () => {
    const obj = {
      is_consented: !consentedState,
    }
    setConsentedState(!consentedState)
    let result = await organizationActions.saveUpdateTouchpoint_is_consented(
      obj,
      item._id
    )
    if (result.success) {
      result.data.users = item?.users || []
      result.data.sequence = item?.sequence || null
      notifySuccess(getText('TEXT_TOUCHPOINT_CHANGED_SUCCESSFULLY'))
      onSave && onSave(result.data)
    } else {
      setConsentedState(!consentedState)
      notifyError(result.errMsg)
    }
  }

  const handleChangeForwardLead = async () => {
    const obj = {
      enabled: !forwardLead,
    }
    setForwardLead(!forwardLead)
    let result = await organizationActions.updateForwardLead(obj, item._id)
    if (result.success) {
      result.data.users = item?.users || []
      result.data.sequence = item?.sequence || null
      notifySuccess(getText('TEXT_TOUCHPOINT_CHANGED_SUCCESSFULLY'))
      onSave && onSave(result.data)
    } else {
      setForwardLead(!forwardLead)
      notifyError(result.errMsg)
    }
  }

  return (
    <React.Fragment>
      <MyDefaultBlockHeader
        label={item.name}
        onClick={
          !isFromVoiceAI
            ? () => {
                setVisible(true)
              }
            : null
        }
      />
      <MyDefaultBlockRow label={getText('WORD_SOURCE')} value={item.source} />
      <MyDefaultBlockRow
        label={getText('TEXT_DATE_OF_CREATION')}
        value={formatDateTime(item.createdAt)}
      />
      <MyDefaultBlockRow label={getText('WORD_ID')} value={item._id} />
      <MyDefaultBlockRow
        label={getText('WORD_CONSENT')}
        value={consentedState}
        isSwitch
        disabled={
          item.source === 'inbound' ||
          item.source === 'chat' ||
          item.source === 'FB messenger' ||
          isFromVoiceAI
        }
        onChange={handleChangeConsented}
        titleTooltip={getText('WORD_TURN_ON_TOGGLE_WHEN')}
        tooltip={isFromVoiceAI}
        tooltipText={getText(
          'TEXT_YOU_CAN_CHANGE_THE_SETTINGS_ON_THE_ASSISTANT_PAGE'
        )}
      />
      <MyDefaultBlockRow
        label={getText('FORWARD_NEW_LEADS_TO_CRM')}
        value={forwardLead}
        isSwitch
        disabled={
          item.source === 'FB messenger' ||
          !Boolean(user.isSuperAdmin && user.isAdmin) ||
          isFromVoiceAI
        }
        onChange={handleChangeForwardLead}
        titleTooltip={getText('WORD_TURN_ON_TOGGLE_IF')}
        tooltip={!Boolean(user.isSuperAdmin && user.isAdmin) || isFromVoiceAI}
        tooltipText={getText(
          isFromVoiceAI
            ? 'TEXT_YOU_CAN_CHANGE_THE_SETTINGS_ON_THE_ASSISTANT_PAGE'
            : 'TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR'
        )}
      />
      <MyDefaultBlockRow
        label={getText('WORD_ASSIGNED_TO')}
        value={
          item.users.length === 0
            ? getText('WORD_UNASSIGNED')
            : item.users.length === 1
              ? item.users[0].fullName
              : getText('WORD_ASSIGNED_USERS_COUNT') + ' ' + item.users.length
        }
      />
      <MyDefaultBlockRow
        label={getText('WORD_SEQUENCE')}
        value={item.sequence ? item.sequence.name : getText('WORD_UNASSIGNED')}
        isLast
        onClickValue={
          item.sequence && !isFromVoiceAI
            ? async () => {
                const result = await organizationActions.getSingleSequence(
                  item.sequence._id
                )
                if (result.success) {
                  navigate(
                    `/organization/settings/${organization?._id}/dripsequence/edit`,
                    {
                      state: { sequenceData: result.data },
                    }
                  )
                }
              }
            : null
        }
      />
      {Boolean(!isFromVoiceAI) && (
        <TouchpointsEditModal
          item={item}
          onSave={onSave}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </React.Fragment>
  )
}

export default TouchpointsItem
