import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { debounce } from 'lodash'
import { getText } from '../../../../lang'
import { SEARCH, TABLE_DEFAULT_ROW_NUMBER } from '../../../../devOptions'
import MyDefaultGridList from '../../../../components/MyDefaultGridList/MyDefaultGridList'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import organizationActions from '../../../../store/modules/organizationActions'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'
import TouchpointsItem from './TouchpointsItem'
import TouchPointsItemNew from './TouchPointsItemNew'
import './Touchpoint.scss'

const Touchpoints = () => {
  const { selectedOrganization: organization } = useOrganizationLayout()
  const { setPageTitle } = useLayout()

  const [page, setPage] = useState(0)
  const [data, setData] = useState({
    list: [],
    total: 0,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [sequencesData, setsequencesData] = useState({
    night: [],
    default: [],
  })
  const [selectedRegularSequence, setselectedRegularSequence] = useState()
  const [selectedAfterHourSequence, setselectedAfterHourSequence] = useState()

  const [searchInput, setSearchInput] = useState({
    mainValue: '',
    debouncedValue: '',
  })

  const layoutRef = useRef(null)

  const NEW_TOUCHPOINT_ITEM = useMemo(() => {
    return {
      type: 'new',
      blockTitle: getText('TEXT_NEW_TOUCHPOINTS'),
      blockBackgroundColor: 'var(--mainColorLighter)',
    }
  }, [])

  useEffect(() => {
    setPageTitle(getText('WORD_TOUCHPOINTS'))
  }, [])

  useEffect(() => {
    if (organization?._id) {
      getListTouchpoints()
    }
  }, [organization, page, searchInput.debouncedValue])

  useEffect(() => {
    const getSequencesList = async () => {
      const result = await organizationActions.getPageListSequenceByGroupAll(
        organization?._id
      )

      if (result.success) {
        const newData = {
          night: [],
          default: [],
        }
        for (const sequence of result.data.sequences.night) {
          sequence.value = sequence._id
          sequence.label = sequence.name
          if (sequence.newSourceAutoAssignment) {
            setselectedAfterHourSequence(sequence)
          }
          newData.night.push(sequence)
        }
        for (const sequence of result.data.sequences.default) {
          sequence.value = sequence._id
          sequence.label = sequence.name
          if (sequence.newSourceAutoAssignment) {
            setselectedRegularSequence(sequence)
          }
          newData.default.push(sequence)
        }
        setsequencesData(newData)
      }
    }
    getSequencesList()
  }, [])

  const getListTouchpoints = async () => {
    let orgId = organization?._id
    let list = []
    let result = await organizationActions.getListTouchpoint(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      orgId,
      searchInput.debouncedValue
    )
    if (result.success) {
      for (const obj of result?.data?.list) {
        list = list.concat(obj.forms)
      }
      const newData = page === 0 ? list : [...data.list, ...list]
      setData({
        list: newData,
        total: result.data.total_items,
      })
      setIsLoading(false)
    }
  }

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchInput({
        debouncedValue: options,
        mainValue: options,
      })
    }, 500),
    []
  )

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      disableHeader={!searchInput.mainValue && !data?.list?.length && !isLoading}
      isAdvanced
      headerProps={{
        left: [
          {
            type: SEARCH,
            value: searchInput.mainValue,
            onChange: (value) => {
              setIsLoading(true)
              setSearchInput({
                ...searchInput,
                mainValue: value,
              })
              debouncedChange(value)
            },
          },
        ],
      }}
    >
      <MyDefaultGridList
        pageStart={page}
        loading={isLoading}
        data={
          !searchInput.mainValue && !isLoading
            ? [NEW_TOUCHPOINT_ITEM, ...data.list]
            : data.list
        }
        renderItem={(item, index) => {
          if (index === 0 && !searchInput.mainValue && !isLoading) {
            return (
              <TouchPointsItemNew
                sequencesData={sequencesData}
                selectedRegularSequence={selectedRegularSequence}
                selectedAfterHourSequence={selectedAfterHourSequence}
                setselectedRegularSequence={setselectedRegularSequence}
                setselectedAfterHourSequence={setselectedAfterHourSequence}
                organizationId={organization.id}
              />
            )
          }
          return (
            <TouchpointsItem
              item={item}
              key={index + '_' + item._id}
              organization={organization}
              onSave={(data) => {
                setData((ov) => {
                  const newList = ov.list.map((it) => {
                    if (it?._id === item?._id) return data
                    return it
                  })
                  return { list: [...newList], total: ov.total }
                })
              }}
            />
          )
        }}
        onLoadMore={() => {
          if (!isLoading && data?.list?.length < data?.total) {
            setIsLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={data.total || 0}
        getScrollParent={() => layoutRef?.current}
      />
    </MyDefaultPageLayout>
  )
}

export default Touchpoints
