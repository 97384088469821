import React from 'react'
import MatCheckbox from '../../../../components/MatCheckbox'
import { getText } from '../../../../lang'
import MatRow from '../../../../components/MatRow'
import { MANAGER, SUPPORT_AGENT } from '../../../../devOptions'

const RestrictionsCheckboxGroup = ({
  onCheckBoxPress,
  currentRestriction,
  currentRestrictionName,
}) => {
  return (
    <React.Fragment>
      <p>{getText(`${currentRestrictionName}_roles_description`)}</p>
      <MatRow>
        <MatCheckbox
          label={getText('WORD_ROLE_MANAGER')}
          value={currentRestriction.roles.includes(MANAGER)}
          onChange={() => {
            onCheckBoxPress(currentRestrictionName, MANAGER)
          }}
        />
        <MatCheckbox
          label={getText('WORD_ROLE_SUPPORT')}
          value={currentRestriction.roles.includes(SUPPORT_AGENT)}
          onChange={() => {
            onCheckBoxPress(currentRestrictionName, SUPPORT_AGENT)
          }}
        />
      </MatRow>
    </React.Fragment>
  )
}

export default RestrictionsCheckboxGroup
