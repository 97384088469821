import React, { useEffect, useState } from 'react'
import { Dropdown } from 'antd'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import messageActions from '../../../../../store/modules/messageActions'
import userActions from '../../../../../store/modules/userActions'
import { getText } from '../../../../../lang'
import SVGChecked from '../../../../../icons/SVG/SVGChecked'
import SVGSearch from '../../../../../icons/SVG/SVGSearch'
import { notifyError } from '../../../../../utils/Notify'
import InputText from '../../../../../components/InputText'
import ProfileLetters from '../../../../../components/ProfileLetters'
import { userSelector } from '../../../../../store/selectors/selectors'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'

const MenuItems = ({ items, label, itemClick, assigned, userId }) => {
  return (
    <div key={`${label}`} className='select-option'>
      <label className='disabled-option'>{label}</label>
      {items.map((item, index) => {
        return (
          <div
            className={`drop-item ${assigned ? 'activ-option' : ''} ${
              userId === item._id ? 'assignToMe' : ''
            }`}
            key={`${index}+${JSON.stringify(item)}`}
            onClick={() => {
              itemClick(item._id, item)
            }}
          >
            <div className='drop-item-text'>{item.fullName}</div>
            {assigned ? (
              <span className='option-icon'>
                <SVGChecked />
              </span>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

const DropMeuManagers = ({
  conversation,
  refreshConversation,
  addToList,
  className,
  showArrow,
}) => {
  let user = useSelector(userSelector)

  const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()

  const [assignedOtherLocation, setAssignedOtherLocation] = useState([])
  const [assignedMyLocation, setAssignedMyLocation] = useState([])
  const [unassignedList, setUnassignedList] = useState([])
  const [totalUnassignedList, setTotalUnassignedList] = useState(0)
  const [assignSearchKeyword, setՍssignSearchKeyword] = useState('')
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)

  useEffect(() => {
    const detectAssignedToMyLocation = (managers) => {
      let sameAsMyLocation = []
      let listManagers = []
      let isAssignedToMe = false
      for (let i = 0; i < managers.length; i++) {
        if (user?._id === managers[i]._id) {
          isAssignedToMe = true
          sameAsMyLocation.unshift({
            ...managers[i],
            fullName: getText('ACTION_ASSIGN_SELF'),
          })
          continue
        }
        if (user._location_id === managers[i]._location_id) {
          sameAsMyLocation.push(managers[i])
          continue
        }
        listManagers.push(managers[i])
      }
      if (isAssignedToMe) {
        sameAsMyLocation = sortingWithAssignedToMe(sameAsMyLocation)
      } else {
        sameAsMyLocation = sameAsMyLocation.sort(sortData)
      }

      listManagers = listManagers.sort(sortData)
      return { sameAsMyLocation, listManagers }
    }

    const fill = async () => {
      const resultAssignedManagers = await messageActions.retrieveUsers(
        conversation._id
      )

      if (resultAssignedManagers.success) {
        resultAssignedManagers.data.users = resultAssignedManagers.data.users || []
        let data = detectAssignedToMyLocation(resultAssignedManagers.data.users)

        let sortedAssignedManager =
          data.listManagers.length > 0 ? data.listManagers : []
        setAssignedOtherLocation(sortedAssignedManager)

        let sortedAssignedToMe =
          data.sameAsMyLocation.length > 0 ? data.sameAsMyLocation : []
        setAssignedMyLocation(sortedAssignedToMe)
        await getManagerList(resultAssignedManagers.data.users)
      } else {
        await getManagerList([])
      }
      setLoading(false)
    }
    fill()
  }, [conversation._id])

  useEffect(() => {
    let title = ''
    const concatedData = [...assignedMyLocation, ...assignedOtherLocation]
    if (concatedData?.length) {
      if (concatedData.length <= 3) {
        title = concatedData
      } else if (concatedData.length > 3) {
        const take = concatedData.slice(0, 2)
        const insertedObj = {
          fullName: `+${concatedData.length - take.length}`,
          customeName: true,
          _id: concatedData[2]._id,
        }
        title = [...take, insertedObj]
      }
    } else {
      title = [
        {
          fullName: getText('WORD_UNASSIGNED'),
          _id: new Date().getTime(),
          string: true,
        },
      ]
    }
    setTitle(title)
  }, [assignedOtherLocation, assignedMyLocation])

  const getAllManagers = async () => {
    if (!totalUnassignedList || unassignedList.length < totalUnassignedList) {
      if (user.isAdmin || user.isManager) {
        const result = await userActions.getUsersForAssignUnassign(
          page,
          50,
          user._organization_id,
          assignSearchKeyword
        )

        if (result.success) {
          result.data = result.data.filter((manager) => manager._id !== user?._id)
          let list = []
          if (page > 0) {
            list = [...unassignedList, ...result.data]
          } else {
            list = result.data
          }
          setUnassignedList(list)
          setTotalUnassignedList(result.max)
          setPage((ov) => ov + 1)
        }
      }
    }
  }

  const sortingWithAssignedToMe = (data) => {
    let firstItem = data.shift()
    data = data.sort(sortData)
    data.unshift(firstItem)
    return data
  }

  const sortData = (a, b) => {
    return ('' + a.fullName).localeCompare(b.fullName)
  }

  const handleAssignUser = async (userId, obj) => {
    setLoading(true)
    const result = await messageActions.assignUser(conversation._id, userId)
    setLoading(false)
    if (result.success) {
      let listManagers = assignedOtherLocation
      if (obj._location_id !== user._location_id) {
        listManagers.unshift(obj)
        if (listManagers.length > 1) {
          listManagers = listManagers.sort(sortData)
        }
        setAssignedOtherLocation([...listManagers])
      } else {
        if (user?._id !== userId) {
          listManagers = sortingWithAssignedToMe([...assignedMyLocation, obj])
          setAssignedMyLocation(listManagers)
        } else {
          setAssignedMyLocation([
            { ...obj, fullName: getText('ACTION_ASSIGN_SELF') },
            ...assignedMyLocation,
          ])
        }
      }
      let listUnAssignedManagers = unassignedList.filter(
        (item) => item._id !== userId
      )

      setUnassignedList(listUnAssignedManagers)
      result.data.conversation.unread = conversation.unread
      addToList && addToList(result.data.conversation, false)
      result.data.conversation.messages = conversation.messages
      refreshConversation && refreshConversation(result.data.conversation, false)
    } else {
      notifyError(result.errMsg)
    }
  }

  const handleUnAssignUser = async (userId, obj) => {
    setLoading(true)
    const result = await messageActions.unassignUser(conversation._id, userId)
    setLoading(false)
    if (result.success) {
      let listManagers = assignedOtherLocation
      if (userId === user?._id || obj._location_id === user._location_id) {
        let sameAsMyLocationlist = assignedMyLocation.filter(
          (item) => item._id !== userId
        )
        setAssignedMyLocation([...sameAsMyLocationlist])
      } else {
        listManagers = assignedOtherLocation.filter((item) => item._id !== userId)
        setAssignedOtherLocation([...listManagers])
      }

      let listUnAssignedManagers = unassignedList
      listUnAssignedManagers.unshift(obj)
      setUnassignedList(listUnAssignedManagers)
      result.data.conversation.unread = conversation.unread
      addToList && addToList(result.data.conversation, false)
      result.data.conversation.messages = conversation.messages
      refreshConversation && refreshConversation(result.data.conversation, false)
    } else {
      notifyError(result.errMsg)
    }
  }

  const handleOnChangeSearch = (value) => {
    setՍssignSearchKeyword(value)
    getManagerList([...assignedMyLocation, ...assignedOtherLocation], value)
  }

  const getManagerList = async (listAssignedManager, assignSearchKeyword = '') => {
    const resultAllMenegers = await userActions.getUsersForAssignUnassign(
      0,
      50,
      user._organization_id,
      assignSearchKeyword
    )

    let meObj = {
      ...user,
      fullName: getText('ACTION_ASSIGN_SELF'),
    }
    if (resultAllMenegers.success) {
      let list = resultAllMenegers.data
      if (listAssignedManager.length) {
        list = resultAllMenegers.data.filter(
          (managerListUser) =>
            !conversation.users.includes(managerListUser._id) &&
            user?._id !== managerListUser._id
        )
        if (!listAssignedManager.find((element) => element._id === user?._id)) {
          list.unshift(meObj)
        }
      } else {
        list = resultAllMenegers.data.filter((item) => {
          return item._id !== user?._id
        })
        list.unshift(meObj)
      }
      setPage(1)
      setUnassignedList(list)
      setTotalUnassignedList(resultAllMenegers.max)
    }
  }

  const items = [
    {
      label: (
        <div className='svg-with-input'>
          <SVGSearch />
          <InputText
            placeholder={getText('TEXT_USERS_SEARCH')}
            onChange={(e) => {
              handleOnChangeSearch(e.target.value)
            }}
            allowClear={() => {
              handleOnChangeSearch('')
            }}
          />
        </div>
      ),
      disabled: true,
      key: 'search',
      className: 'assign-search-input',
    },
    {
      label: (
        <MenuItems
          items={assignedMyLocation}
          label={getText('WORD_ASSIGNED_LOC')}
          itemClick={(itemId, obj) => {
            handleUnAssignUser(itemId, obj)
          }}
          assigned={true}
          userId={user?._id}
        />
      ),
      key: 'assigned_my_location',
    },
    {
      label: (
        <MenuItems
          items={assignedOtherLocation}
          label={getText('WORD_ASSIGNED_EXT')}
          itemClick={(itemId, obj) => {
            handleUnAssignUser(itemId, obj)
          }}
          assigned={true}
        />
      ),
      key: 'assigned_other_location',
    },
    {
      label: (
        <MenuItems
          assigned={false}
          items={unassignedList}
          label={getText('WORD_UNASSIGNED')}
          itemClick={(itemId, obj) => {
            handleAssignUser(itemId, obj)
          }}
          userId={user?._id}
        />
      ),
      key: 'unassigned',
    },
  ]
  return (
    <Dropdown
      menu={{ items }}
      placement={isMobileOrTabletOrTabletPortrait ? 'bottomRight' : 'bottom'}
      trigger='click'
      overlayClassName={`my-assing-drop-overlay ${className || ''}`}
      autoAdjustOverflow={false}
      getPopupContainer={(trigger) => trigger.parentNode}
      className={'message_page_assign_user_list--dropdown'}
      dropdownRender={(menu) => {
        return (
          <div
            style={{ maxHeight: '400px', overflowY: 'auto' }}
            className='my-assing-drop-overlay-wrapper'
            onScroll={(e) => {
              if (
                e.currentTarget.scrollHeight -
                  Math.ceil(e.currentTarget.scrollTop) <=
                e.currentTarget.clientHeight
              ) {
                getAllManagers && getAllManagers()
              }
            }}
          >
            {React.cloneElement(menu)}
          </div>
        )
      }}
    >
      <div className='drop-down-trigger-container'>
        <div className='message_page_assign_user_list--dropdown-title'>
          {!loading && title?.length ? (
            title.map((item, index) => {
              const myUser = user?._id === item._id
              return item.string ? (
                item.fullName
              ) : (
                <ProfileLetters
                  activeIcon={myUser}
                  key={index + item._id}
                  withoutIcon
                  customeName={item.customeName && item.fullName}
                  name={myUser ? user.fullName : item.fullName}
                />
              )
            })
          ) : (
            <LoadingOutlined style={{ fontSize: 12 }} />
          )}
        </div>
        {showArrow && <DownOutlined />}
      </div>
    </Dropdown>
  )
}

DropMeuManagers.defaultProps = {
  showArrow: true,
  placeholder: 'Please select',
  allowClear: false,
  renderItem: () => {},
  onChange: () => {},
  options: [],
}

export default React.memo(DropMeuManagers)
