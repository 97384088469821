import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.2917 9.76172H8.70837'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.4183 1.83203H6.58167C4.62917 1.83203 3.04333 3.42703 3.04333 5.37036V18.2862C3.04333 19.9362 4.22584 20.6329 5.67417 19.8354L10.1475 17.3512C10.6242 17.0854 11.3942 17.0854 11.8617 17.3512L16.335 19.8354C17.7833 20.642 18.9658 19.9454 18.9658 18.2862V5.37036C18.9567 3.42703 17.3708 1.83203 15.4183 1.83203Z'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGOrgTagsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgTagsIcon
