import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Iconly/Light/Chat'>
      <g id='vuesax/linear/calendar'>
        <g id='calendar'>
          <path
            id='Vector'
            d='M7.33325 1.83398V4.58398'
            stroke='#292D32'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_2'
            d='M14.6667 1.83398V4.58398'
            stroke='#292D32'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_3'
            d='M3.20825 8.33203H18.7916'
            stroke='#292D32'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_4'
            d='M19.25 7.79232V15.584C19.25 18.334 17.875 20.1673 14.6667 20.1673H7.33333C4.125 20.1673 2.75 18.334 2.75 15.584V7.79232C2.75 5.04232 4.125 3.20898 7.33333 3.20898H14.6667C17.875 3.20898 19.25 5.04232 19.25 7.79232Z'
            stroke='#292D32'
            stroke-width='1.5'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_5'
            d='M14.3869 12.5579H14.3951'
            stroke='#292D32'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_6'
            d='M14.3869 15.3079H14.3951'
            stroke='#292D32'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_7'
            d='M10.9958 12.5579H11.004'
            stroke='#292D32'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_8'
            d='M10.9958 15.3079H11.004'
            stroke='#292D32'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_9'
            d='M7.6032 12.5579H7.61143'
            stroke='#292D32'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            id='Vector_10'
            d='M7.6032 15.3079H7.61143'
            stroke='#292D32'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
      </g>
    </g>
  </svg>
)

const SVGCalendarInvitationsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGCalendarInvitationsIcon
