import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Layout, Menu, Drawer } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
// import userflow from 'userflow.js'
import authActions from '../../store/modules/authActions'
import appActions from '../../store/modules/appActions'
import {
  isMobileAppSelector,
  menuOpenSelector,
  organizationSelector,
  userSelector,
} from '../../store/selectors/selectors'
import userActions from '../../store/modules/userActions'
import { getText } from '../../lang'
import {
  getMobileOperatingSystem,
  moveToLoginPage,
  myWebViewPost,
} from '../../utils'
import SVGDrawerPayment from '../../icons/SVG/SVGDrawerPayment'
import SVGDrawerInvitations from '../../icons/SVG/SVGDrawerInvitations'
import SVGDrawerManageUsers from '../../icons/SVG/SVGDrawerManageUsers'
import SVGDrawerManageLocations from '../../icons/SVG/SVGDrawerManageLocations'
import SVGDrawerManageOrganizations from '../../icons/SVG/SVGDrawerManageOrganizations'
import SVGDrawerBigLogo from '../../icons/SVG/SVGDrawerBigLogo'
import SVGDrawerSmallLogo from '../../icons/SVG/SVGDrawerSmallLogo'
import SVGDrawerBroadcasting from '../../icons/SVG/SVGDrawerBroadcasting'
import SVGDrawerMessages from '../../icons/SVG/SVGDrawerMessages'
import SVGDrawerDashboard from '../../icons/SVG/SVGDrawerDashboard'
import SVGDrawerManageOrganizationsAdmin from '../../icons/SVG/SVGDrawerManageOrganizationsAdmin'
import SVGDrawerAccountSettings from '../../icons/SVG/SVGDrawerAccountSettings'
import SVGDrawerAppointments from '../../icons/SVG/SVGDrawerAppointments'
import SVGDrawerLogOut from '../../icons/SVG/SVGDrawerLogOut'
import SVGDrawerWorkflow from '../../icons/SVG/SVGDrawerWorkflow'
import SVGDrawerAutomation from '../../icons/SVG/SVGDrawerAutomation'
import './MainSideBar.scss'
// import SVGGetSupport from '../../icons/SVG/SVGGetSupport'
import SVGDrawerSequence from '../../icons/SVG/SVGDrawerSequence'
import useDeviceCheck from '../../utils/useDeviceCheck'

const aiBotSuffixes = [
  '/dripsequence/list',
  '/dripsequence/edit',
  '/dripsequence/create',
]
const workflowSuffixes = ['/workflow/list', '/workflow/edit', '/workflow/create']

const { Sider } = Layout

const MainMenu = ({ onCloseToggle }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { isPageDrawerSize } = useDeviceCheck()

  const user = useSelector(userSelector)
  const organization = useSelector(organizationSelector)
  const menuOpen = useSelector(organizationSelector)
  const isMobileApp = useSelector(isMobileAppSelector)

  const [selectedMenu, setSelectedMenu] = useState([])

  useEffect(() => {
    const url = location.pathname
    let search = location.search
    let newSelectedMenu = url

    if (search) {
      if (url.includes('/payments')) {
        search = ''
      }
      setSelectedMenu(url + search)
    } else {
      if (url.includes('dashboard')) {
        newSelectedMenu = '/dashboard'
      } else if (url.includes('/location/settings')) {
        newSelectedMenu = '/location/list'
      } else if (url.includes('/broadcasting')) {
        newSelectedMenu = '/broadcasting/list'
      } else if (url.includes('/leaderboard')) {
        newSelectedMenu = '/invitation/list'
      } else if (url.includes('/organization/settings')) {
        if (aiBotSuffixes.some((suffix) => url.includes(suffix))) {
          newSelectedMenu = `/organization/settings/${organization?._id}/dripsequence/list`
        } else if (workflowSuffixes.some((suffix) => url.includes(suffix))) {
          newSelectedMenu = `/organization/settings/${organization?._id}/workflow/list`
        } else if (user.isAdmin && !user.isSuperAdmin) {
          newSelectedMenu = `/organization/settings/${organization?._id}/general`
        } else {
          newSelectedMenu = '/organization/list'
        }
      } else if (url.includes('/profile')) {
        newSelectedMenu = '/profile/edit'
      }
      setSelectedMenu(newSelectedMenu)
    }
  }, [location.pathname])

  const redirectMenuAction = (path) => {
    navigate(path)
    if (isPageDrawerSize) {
      onCloseToggle && onCloseToggle()
    } else {
      !menuOpen && onCloseToggle && onCloseToggle()
    }
  }

  const checkVersion = (backendVersion) => {
    let version = localStorage.getItem('version')
    if (version) {
      version = JSON.parse(version)
    } else {
      version = { frontend: '', backend: '' }
    }

    if (
      authActions.isLoggedIn() &&
      (version.frontend !== backendVersion.frontend ||
        version.backend !== backendVersion.backend)
    ) {
      localStorage.setItem('version', JSON.stringify(backendVersion))
      if (isMobileApp) {
        myWebViewPost('HARD_RELOAD_MOBILE', 'HARD_RELOAD_MOBILE')
      } else {
        window.location.reload()
      }
    }
  }

  const MainMenuContent = useMemo(() => {
    const menuItems = [
      {
        key: userActions.getUserHasPermission(
          user,
          organization?.restrictions?.allowSupportAgentsAccessToMainInboxFolder,
          true
        )
          ? '/messages?type=all'
          : '/messages?type=my-messages',
        icon: <SVGDrawerMessages />,
        label: (
          <div className='title-with-count'>
            {getText('TITLE_MESSAGES')}{' '}
            {/* {unreadMessages ? (
              <div className='count-number'>{unreadMessages}</div>
            ) : (
              ''
            )} */}
          </div>
        ),
      },
      (user.isAdmin ||
        user.isManager ||
        (user.isSupportAgent &&
          user.accessibleOrganizations &&
          user.accessibleOrganizations.length > 1)) && {
        key: '/dashboard',
        icon: <SVGDrawerDashboard />,
        label: getText('TITLE_DASHBOARD'),
      },
      (user.isAdmin || user.isManager) &&
        organization.enable_broadcast && {
          key: '/broadcasting/list',
          icon: <SVGDrawerBroadcasting />,
          label: getText('WORD_BROADCASTING'),
        },
      (user.isAdmin || user.isManager) &&
        organization.enable_broadcast && {
          key: '/automation/list',
          icon: <SVGDrawerAutomation />,
          label: getText('WORD_AUTOMATIONS'),
        },
      user.isAdmin && {
        key: `/organization/settings/${organization?._id}/dripsequence/list`,
        icon: <SVGDrawerSequence />,
        label: getText('WORD_SEQUENCES'),
      },
      (user.isAdmin || user.isManager) && {
        key: `/organization/settings/${organization?._id}/workflow/list`,
        icon: <SVGDrawerWorkflow />,
        label: getText('WORD_WORKFLOWS'),
      },
      {
        key: '/invitation/list',
        icon: <SVGDrawerInvitations />,
        label: getText('TITLE_INVITATIONS'),
      },
      {
        key: '/appointments',
        icon: <SVGDrawerAppointments />,
        label: getText('WORD_APPOINTMENTS'),
      },
      !user.isSupportAgent &&
        organization &&
        organization.enable_payments && {
          key: '/payments',
          icon: <SVGDrawerPayment />,
          label: getText('WORD_PAYMENTS'),
        },
      {
        key: '',
        label: '',
        disabled: true,
        className: 'items-separation',
        title: '',
      },
      user.isSuperAdmin && {
        key: '/organization/list',
        icon: <SVGDrawerManageOrganizations />,
        label: getText('WORD_ORGANIZATIONS'),
      },
      user.isAdmin &&
        !user.isSuperAdmin && {
          key: `/organization/settings/${organization?._id}/general`,
          icon: <SVGDrawerManageOrganizationsAdmin />,
          label: getText('WORD_SETTINGS'),
        },
      userActions.getUserHasPermission(
        user,
        organization?.restrictions?.allowAccessToAllLocations,
        false,
        true
      ) && {
        key: '/location/list',
        icon: <SVGDrawerManageLocations />,
        label: getText('WORD_LOCATIONS'),
      },
      (user.isAdmin || user.isManager) && {
        key: '/users',
        icon: <SVGDrawerManageUsers />,
        label: getText('WORD_USERS'),
      },
      {
        key: 'line',
        label: '',
        disabled: true,
        className: 'line-between-item',
        title: '',
      },
      {
        key: '/profile/edit',
        icon: <SVGDrawerAccountSettings />,
        label: getText('WORD_PROFILE_SETTINGS'),
      },
      // {
      //   key: 'support',
      //   icon: <SVGGetSupport />,
      //   label: 'Get Support',
      // },
      {
        key: '/logout',
        icon: <SVGDrawerLogOut />,
        label: getText('TITLE_LOGOUT'),
      },
    ]

    return (
      <Menu
        className={`mat-sider-menu ${
          user.isSupportAgent ? 'mat-sider-menu-support-agent' : ''
        } `}
        selectedKeys={[selectedMenu]}
        items={menuItems}
        onClick={async (e) => {
          if (e.key === '/logout') {
            moveToLoginPage(navigate)
            await authActions.logout()
          }
          // else if (e.key === 'support') {
          //   userflow.toggleResourceCenter()
          // }
          else {
            setSelectedMenu(e.key)
            redirectMenuAction(e.key)
            const { data } = await appActions.getVersion()
            checkVersion(data)
          }
        }}
      />
    )
  }, [organization, user, menuOpen, selectedMenu])

  return MainMenuContent
}

const MainSideBar = ({ onCloseToggle }) => {
  const menuOpen = useSelector(menuOpenSelector)

  const { isPageDrawerSize } = useDeviceCheck()

  const handleClose = () => {
    // userflow.toggleResourceCenter()
    authActions.setMenuOpen(false)
    onCloseToggle && onCloseToggle()
  }

  const MainSideBarContent = useMemo(() => {
    return (
      <>
        <div className='sider-logo'>
          {menuOpen ? <SVGDrawerBigLogo /> : <SVGDrawerSmallLogo />}
        </div>
        <MainMenu onCloseToggle={handleClose} />
      </>
    )
  }, [menuOpen])

  const getDeviceOperatingSystem = getMobileOperatingSystem()

  return isPageDrawerSize ? (
    <Drawer
      rootClassName='mat-drawer-left-menu-mobile'
      placement={'left'}
      closable={false}
      onClose={handleClose}
      open={menuOpen}
      key={'left'}
      width={230}
    >
      {MainSideBarContent}
    </Drawer>
  ) : (
    <Sider
      trigger={null}
      collapsible
      collapsed={!menuOpen}
      collapsedWidth={70}
      width={230}
      className={`${getDeviceOperatingSystem === 'Windows' ? 'windows' : ''}`}
    >
      {MainSideBarContent}
    </Sider>
  )
}

export default MainSideBar
