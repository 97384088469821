import React, { useEffect, useState, useRef } from 'react'
import { Flex, Form, Spin } from 'antd'
import { getText, getTextServerError } from '../../../../lang'
import MatForm from '../../../../components/Form/MatForm'
import tagsActions from '../../../../store/modules/tagsActions'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { useSocket } from '../../../../utils/socket'
import MyDefaultBlockWithScroll from '../../../../components/MyDefaultBlock/MyDefaultBlockWithScroll'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import { TABLE_DEFAULT_ROW_HEIGHT } from '../../../../devOptions'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import MyDefaultBlockHeaderAdditionalButton from '../../../../components/MyDefaultBlock/MyDefaultBlockHeaderAdditionalButton'
import TagItem from './TagItem'

const Tags = () => {
  const { setPageTitle } = useLayout()
  const { selectedOrganization: organization } = useOrganizationLayout()
  const [tagsData, settagsData] = useState({ totalCount: 0, data: [] })
  const [page, setpage] = useState(0)
  const [isLoading, setisLoading] = useState(true)

  const [form] = Form.useForm()

  const addTagRef = useRef(null)

  useEffect(() => {
    setPageTitle(getText('WORD_TAGS'))
  }, [])

  const handleUpdateList = (data) => {
    setTimeout(() => {
      setisLoading(true)
      const oldData = form.getFieldsValue().tags_items
      const newList = oldData.map((tag) => {
        if (tag._id === data._id) {
          tag = data
          tag.key = data._id
        }
        return tag
      })
      settagsData({
        data: [...newList],
        totalCount: tagsData.totalCount,
      })
      form.setFieldsValue({ tags_items: [...newList] })
      setisLoading(false)
    }, 1000)
  }

  useSocket(
    `private-organization=${organization?._id}`,
    'TAG_UPDATED',
    handleUpdateList,
    !!organization?._id
  )

  useSocket(
    `private-organization=${organization?._id}`,
    'TAG_CREATED',
    (data) => {
      setTimeout(() => {
        const oldData = form.getFieldsValue().tags_items
        const find = oldData.find((tag) => tag._id === data._id)
        if (!find) {
          setisLoading(true)
          form.setFieldsValue({ tags_items: [] })
          if (page === 0) {
            fill()
          } else {
            setpage(0)
          }
        }
      }, 1500)
    },
    !!organization?._id
  )

  useSocket(
    `private-organization=${organization?._id}`,
    'TAG_DELETED',
    (data) => {
      setTimeout(() => {
        const oldData = form.getFieldsValue().tags_items
        const newList = oldData.filter((tag) => {
          return tag._id !== data._id
        })
        if (oldData.length !== newList.length) {
          settagsData({
            data: [...newList],
            totalCount: tagsData.totalCount,
          })
          form.setFieldsValue({ tags_items: [...newList] })
        }
      }, 1000)
    },
    !!organization?._id
  )

  const fill = async () => {
    setisLoading(true)
    const result = await tagsActions.getTagsList(page, TABLE_DEFAULT_ROW_HEIGHT, '')

    if (result.success) {
      const newData = page === 0 ? result.data : [...tagsData.data, ...result.data]
      settagsData({
        data: newData,
        totalCount: result.max,
      })
      form.setFieldsValue({ tags_items: newData })
    }
    setisLoading(false)
  }

  useEffect(() => {
    fill()
  }, [page])

  const handleDeleteTag = async (_id) => {
    const result = await tagsActions.delete(_id)
    if (result.success) {
      notifySuccess(getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    return result
  }

  if (!organization?._id) {
    return <LoadingSpinner />
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MyDefaultBlockWithScroll
        pageStart={page}
        disablePaddingTitle={!tagsData?.data?.length}
        hasMore={!isLoading && tagsData.data.length < tagsData.totalCount}
        loadMore={() => {
          if (!isLoading && tagsData.data.length < tagsData.totalCount) {
            setisLoading(true)
            setpage((ov) => ov + 1)
          }
        }}
        title={getText('WORD_TAGS')}
        additionalHeader={
          <MyDefaultBlockHeaderAdditionalButton
            buttonText={getText('WORD_ADD_TAG')}
            onClick={() => {
              addTagRef?.current?.add(
                {
                  name: '',
                  color: '#47A4FE',
                  tmpId: new Date(),
                },
                0
              )
            }}
          />
        }
      >
        <Spin spinning={isLoading}>
          <MatForm
            form={form}
            initialValues={{
              tags_items: tagsData.data,
            }}
          >
            <Form.List name='tags_items'>
              {(fields, { add, remove }) => {
                addTagRef.current = { add, remove }
                return (
                  <Flex vertical gap={8}>
                    {fields.map((field, index) => {
                      return (
                        <TagItem
                          key={index}
                          field={field}
                          form={form}
                          onDelete={async (item) => {
                            let success = true
                            if (item._id) {
                              const result = await handleDeleteTag(item._id)
                              success &= result.success
                            }
                            if (success) {
                              remove(index)
                            }
                          }}
                        />
                      )
                    })}
                  </Flex>
                )
              }}
            </Form.List>
          </MatForm>
        </Spin>
      </MyDefaultBlockWithScroll>
    </MyDefaultPageLayout>
  )
}

export default Tags
