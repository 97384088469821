import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import promotionsActions from '../../../../../store/modules/promotionsActions'
import announcementsActions from '../../../../../store/modules/announcementsActions'
import { getText } from '../../../../../lang'
import PageFull from '../../../../../components/PageFull'
import MatList from '../../../../../components/MatList'
import MatButton from '../../../../../components/MatButton'
import PageFix from '../../../../../components/PageFix'
import AnnouncementItem from './AnnouncementItem'
import './Announcements.scss'

const Announcements = () => {
  const { _id } = useParams()
  const navigate = useNavigate()
  const [isSpecialLoading, setIsSpecialLoading] = useState(false)
  const [specials, setSpecials] = useState({ list: [], total: 0 })
  const [currentPromPage, setCurrentPromPage] = useState(0)
  const [isAnnouncementLoading, setIsAnnouncementLoading] = useState(false)
  const [announcements, setAnnouncements] = useState({ list: [], total: 0 })
  const [currentAnnounPage, setCurrentAnnounPage] = useState(0)

  useEffect(() => {
    getSpecials()
    getAnnouncements()
  }, [])

  const getSpecials = async () => {
    setIsSpecialLoading(true)
    let resultPromotions = await promotionsActions.getAllList(_id)
    setSpecials({
      list: resultPromotions.data,
      total: resultPromotions.max,
    })
    setIsSpecialLoading(false)
  }
  const getAnnouncements = async () => {
    setIsAnnouncementLoading(true)
    const resultAnnouncement = await announcementsActions.getAllList(_id)

    setAnnouncements({
      list: resultAnnouncement.data,
      total: resultAnnouncement.max,
    })
    setIsAnnouncementLoading(false)
  }

  const redirectToEditSpecialScreen = (item) => {
    navigate(`/location/settings/${_id}/livechat-announcements/edit-special`, {
      state: item,
    })
  }

  const redirectToAddSpecialScreen = () => {
    navigate(`/location/settings/${_id}/livechat-announcements/add-special`)
  }

  const redirectToEditAnnouncementsScreen = (item) => {
    navigate(`/location/settings/${_id}/livechat-announcements/edit-announcement`, {
      state: item,
    })
  }

  const redirectToAddAnnouncementsScreen = () => {
    navigate(`/location/settings/${_id}/livechat-announcements/add-announcement`)
  }

  return (
    <PageFull className='livechat-announcements-wrapper'>
      <PageFix title={getText('WORD_SPECIALS')}>
        <div className='livechat-announcement-header'>
          <div className='left-content'>
            <p>{getText('WORD_TOTAL_SPECIALS')}</p>
            <span>{specials.total}</span>
          </div>
          <div className='right-content'>
            <MatButton
              buttonText={getText('ACTION_ADD_SPECIAL')}
              onClick={redirectToAddSpecialScreen}
            />
          </div>
        </div>
        <MatList
          loading={isSpecialLoading}
          data={specials.list}
          pagination
          paginationCurrentPage={currentPromPage}
          paginationTotalCount={specials.total}
          onPaginationChange={setCurrentPromPage}
          onPageChange={(pageNumber) => setCurrentPromPage(pageNumber)}
          paginationRange={5}
          totalItems={specials.total}
          renderItem={(special) => {
            return (
              <AnnouncementItem
                type='Special'
                key={special._id}
                item={special}
                actions={promotionsActions}
                onEdit={redirectToEditSpecialScreen}
                refreshList={special}
              />
            )
          }}
        />
      </PageFix>
      <PageFix title={getText('WORD_ANNOUNCEMENTS')}>
        <div className='livechat-announcement-header'>
          <div className='left-content'>
            <p>{getText('WORD_TOTAL_ANNOUNCEMENTS')}</p>
            <span>{announcements.total}</span>
          </div>
          <div className='right-content'>
            <MatButton
              buttonText={getText('ACTION_ADD_ANNOUNCEMENT')}
              onClick={redirectToAddAnnouncementsScreen}
            />
          </div>
        </div>
        <MatList
          loading={isAnnouncementLoading}
          data={announcements.list}
          pagination
          paginationCurrentPage={currentAnnounPage}
          paginationTotalCount={announcements.total}
          onPaginationChange={setCurrentAnnounPage}
          onPageChange={(pageNumber) => setCurrentAnnounPage(pageNumber)}
          paginationRange={5}
          totalItems={announcements.total}
          renderItem={(announcement) => {
            return (
              <AnnouncementItem
                type='Announcement'
                key={announcement._id}
                item={announcement}
                actions={announcementsActions}
                onEdit={redirectToEditAnnouncementsScreen}
                refreshList={getAnnouncements}
              />
            )
          }}
        />
      </PageFix>
    </PageFull>
  )
}

export default Announcements
