import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import statActions from '../../../store/modules/statActions'
import { locationSelector } from '../../../store/selectors/selectors'
import { getText, getTextServerError } from '../../../lang'
import PageFull from '../../../components/PageFull'
import { notifyError } from '../../../utils/Notify'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import MatList from '../../../components/MatList'
import { useLayout } from '../../../layout/LayoutProvider/LayoutProvider'
import LeaderboardHeader from './LeaderboardHeader'
import LeaderboardListItem from './LeaderboardListItem'
import './Leaderboard.scss'

const Leaderboard = () => {
  const { setPageTitle } = useLayout()

  const location = useSelector(locationSelector)

  const [page, setPage] = useState(0)
  const [loadingData, setLoadingData] = useState(false)
  const [leaderboardList, setLeaderboardList] = useState({
    list: [],
    total: 0,
  })
  const [leaderboardDate, setLeaderboardDate] = useState('')

  useEffect(() => {
    setPageTitle(getText('TITLE_LEADERBOARD'))
  }, [])

  useEffect(() => {
    getLeaderboardData()
  }, [location, page, leaderboardDate])

  const getLeaderboardData = async () => {
    setLoadingData(true)
    const result = await statActions.getLeaderboardStats(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      '',
      leaderboardDate
    )
    if (result.success) {
      setLeaderboardList({
        list: result.data,
        total: result.max,
      })
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoadingData(false)
  }

  return (
    <PageFull className={'leaderboard-page-wrapper'}>
      <LeaderboardHeader
        onChangeFilter={(option) => {
          setPage(0)
          setLeaderboardDate(option)
        }}
      />
      <MatList
        grid
        loading={loadingData}
        data={leaderboardList.list}
        pagination={leaderboardList.list.length ? true : false}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={page}
        paginationTotalCount={leaderboardList.total}
        onPaginationChange={setPage}
        renderItem={(item, index) => (
          <LeaderboardListItem key={index + '_' + item._id} item={item} />
        )}
      />
    </PageFull>
  )
}

export default Leaderboard
