import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatDateTime } from '../../../../utils'
import messageActions from '../../../../store/modules/messageActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import customerActions from '../../../../store/modules/customerActions'
import { manualConsentEditSelector } from '../../../../store/selectors/selectors'
import MatButton from '../../../../components/MatButton'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import { getText } from '../../../../lang'
import SVGAiBotSmsIcon from '../../../../icons/SVG/SVGAiBotSmsIcon'
import './MessageConsents.scss'

const MessageConsent = (props) => {
  const { conversation, onSend, disabled } = props
  const changeCustomerSelector = useSelector(manualConsentEditSelector)

  let { unsubscribed, receiver = {} } = conversation || {}
  let { status: consentInfo, _id: _receiver_id, phone } = receiver

  const [sending, setSending] = useState(false)
  const [statusToSend, setStatusToSend] = useState('')
  const [cl, setCl] = useState('')
  const [customerId, setCustomerId] = useState('')

  const canChangeConsent = !unsubscribed && consentInfo.state !== 'red'

  useEffect(() => {
    if (consentInfo.state === 'yellow') {
      setCl(' yellow')
    } else if (consentInfo.state === 'green') {
      setCl(' green')
    } else if (consentInfo.state === 'red') {
      setCl(' red')
    }
  }, [consentInfo.state])

  useEffect(() => {
    handleConsentChange()
  }, [changeCustomerSelector])

  const handleConsentChange = () => {
    let lastEditConsentByUser = customerActions.getManualConsentEdit()

    const { data = {} } = lastEditConsentByUser

    setCustomerId(_receiver_id)

    const { newStatus, previousStatus } = data

    if (consentInfo.state === 'red' && previousStatus && previousStatus !== 'red') {
      setStatusToSend(previousStatus)
    }

    if (statusToSend === 'red' && previousStatus && previousStatus === 'red') {
      setStatusToSend(newStatus)
    }

    if (consentInfo.state !== 'red') {
      setStatusToSend('red')
    }
  }

  const sendConsent = async () => {
    if (conversation) {
      phone = phone.replace('+', '')
      let obj = {}
      if (conversation && conversation.isNew) {
        obj.conversationId = conversation._id
      }
      setSending(true)
      let result = await messageActions.sendConsentByPhone(phone, obj, conversation)
      setSending(false)
      if (result.success) {
        const isArchived = conversation.is_archived
        if (isArchived) {
          props.unarchiveConversation()
        }
        notifySuccess(getText('TEXT_CONSENT_WAS_SENT_SUCCESSFULLY'))
        onSend && onSend(result.data)
      } else {
        notifyError(result.errMsg)
      }
    }
  }

  const getConsentInfo = () => {
    if (consentInfo.state === 'green') {
      return (
        <div
          className={`consent_info_buttons ${consentInfo.state === 'green' ? cl : ''}`}
        >
          <p>{getText('WORD_APPROVED')}</p>
        </div>
      )
    } else if (consentInfo.state === 'yellow') {
      return (
        <div
          className={`consent_info_buttons ${consentInfo.state === 'yellow' ? cl : ''}`}
        >
          <p>{getText('WORD_PENDING')}</p>
        </div>
      )
    } else if (consentInfo.state === 'red') {
      return (
        <div
          className={`consent_info_buttons ${consentInfo.state === 'red' ? cl : ''}`}
        >
          <p>{getText('WORD_OPTED_OUT')}</p>
        </div>
      )
    }
  }

  return (
    consentInfo && (
      <div className='message_contest_wrapper_block'>
        <div className='message_contest_wrapper_block--header'>
          {getText('WORD_CONSENT')}
        </div>
        {/* )} */}
        <div className='message_contest_wrapper_block--body'>
          <div className='block_right_and_left_sides'>
            <div className='block_right_and_left_sides--left-side'>
              <div className={'block_right_and_left_sides--left-side-icon green'}>
                <SVGAiBotSmsIcon />
              </div>
              <div className='block_right_and_left_sides--left-side-label'>
                <span className={'not_active'}>{getText('WORD_SMS_UC')}</span>
              </div>
            </div>
            <div className='arc-wrapper'>{getConsentInfo()}</div>
          </div>
          <>
            {
              <div
                className={`message_contest_wrapper_block--body-info ${
                  !canChangeConsent ? 'message-center' : ''
                }`}
              >
                <div>
                  <div className='message_contest_wrapper_block--body-info-text'>
                    {
                      consentInfo.state === 'yellow' ? (
                        consentInfo.consentSent ? (
                          <p>
                            {getText(
                              'TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT_AWAITING_CONSENT'
                            )}
                          </p>
                        ) : (
                          <p>
                            {getText(
                              'TEXT_WE_ARE_UNSURE_IF_THIS_CUSTOMER_IS_COMPLIANT_PLEASE_ASK_FOR_CONSENT'
                            )}
                          </p>
                        )
                      ) : consentInfo.state === 'green' ? (
                        <p>
                          {getText(
                            'TEXT_WE_HAVE_RECEIVED_CONSENT_FROM_THIS_CUSTOMER'
                          )}
                        </p>
                      ) : (
                        consentInfo.state === 'red' && (
                          // conversation.unsubscribed ? (
                          <>
                            <p>
                              {getText(
                                'TEXT_CUSTOMER_HAS_DECIDED_TO_OPT_OUT_FROM_UPCOMING_COMMUNICATIONS'
                              )}
                            </p>
                            {consentInfo.unsubscribedAt && (
                              <h6>
                                {`${getText('WORD_REFUSED_AT')}: ` +
                                  formatDateTime(consentInfo.unsubscribedAt)}
                              </h6>
                            )}
                          </>
                        )
                      )
                      // ) : (
                      //   <p>
                      //     {getText(
                      //       'TEXT_THE_CUSTOMER_DOES_NOT_WANT_TO_RECEIVE_BROADCASTED_MESSAGES'
                      //     )}
                      //   </p>
                      // )
                    }
                    {consentInfo.state === 'green' ? (
                      <h6>
                        {`${getText('WORD_APPROVED')} ${getText('WORD_AT')}: ` +
                          (consentInfo.activatedAt
                            ? formatDateTime(consentInfo.activatedAt)
                            : '')}
                      </h6>
                    ) : consentInfo.state === 'red' ? undefined : (
                      consentInfo.state === 'yellow' &&
                      consentInfo.consentSent &&
                      consentInfo.consentSentDate && (
                        <h6>
                          {`${getText('WORD_SENT')} ${getText('WORD_AT')}: ` +
                            formatDateTime(consentInfo.consentSentDate)}
                        </h6>
                      )
                    )}
                  </div>
                  {consentInfo.state === 'yellow' &&
                    !consentInfo.consentSent &&
                    consentInfo.consentSentDate && (
                      <div className='contest-info-message'>
                        <h6>
                          {`${getText('WORD_SENT')} ${getText('WORD_AT')}: ` +
                            formatDateTime(consentInfo.consentSentDate)}
                        </h6>
                      </div>
                    )}
                </div>
                <div className='consent-btns-wrappers'>
                  {(consentInfo.state === 'yellow' || consentInfo.state === 'red') &&
                  !consentInfo.consentSent &&
                  conversation.kind !== 'messenger' &&
                  conversation.kind !== 'gbm' &&
                  conversation.receiver_phone &&
                  !conversation.unsubscribed ? (
                    <MatButton
                      onClick={sendConsent}
                      disabled={disabled && !conversation.isNew}
                      loading={sending}
                      typeButton={'white'}
                      buttonText={getText('WORD_ASK_CONSENT')}
                      size={'small'}
                    />
                  ) : (
                    <div />
                  )}
                  {canChangeConsent ? (
                    <ConfirmPopup
                      title={getText(
                        'TEXT_ARE_YOU_SURE_YOU_WANT_TO_OPT_OUT_THIS_CONVERSATION'
                      )}
                      placement={'topRight'}
                      onConfirm={async () => {
                        const updatedCustomer =
                          await customerActions.changeConsentStatus(
                            statusToSend,
                            customerId
                          )
                        updatedCustomer && onSend && onSend(updatedCustomer.status)
                      }}
                      okText={getText('WORD_YES')}
                      cancelText={getText('WORD_NO')}
                      trigger={
                        <MatButton
                          buttonText={getText('WORD_OPT_OUT')}
                          size={'small'}
                          onClick={handleConsentChange}
                          disabled={
                            conversation.kind === 'messenger' ||
                            conversation.kind === 'gbm'
                          }
                          className={'opt-out-btn'}
                        />
                      }
                    />
                  ) : undefined}
                </div>
              </div>
            }
          </>
        </div>
      </div>
    )
  )
}

export default memo(MessageConsent)
