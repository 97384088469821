import React from 'react'
import { Collapse, Flex, Form } from 'antd'
import { getText } from '../../../lang'
import SVGSelectArrow from '../../../icons/SVG/SVGSelectArrow'
import Div from '../../Div/Div'
import MyDefaultSelect from '../../MyDefaultSelect/MyDefaultSelect'
import MyDefaultCustomTimePicker from '../MyDefaultCustomTimePicker/MyDefaultCustomTimePicker'
import './MyDefaultOrganizationOpeningHours.scss'

const MyDefaultOrganizationOpeningHoursResponsive = ({
  field,
  form,
  index,
  disabled,
  formListName,
}) => {
  const days = [
    getText('WORD_SUNDAY'),
    getText('WORD_MONDAY'),
    getText('WORD_TUESDAY'),
    getText('WORD_WEDNESDAY'),
    getText('WORD_THURSDAY'),
    getText('WORD_FRIDAY'),
    getText('WORD_SATURDAY'),
  ]

  const item = Form.useWatch([formListName, field.name], form)

  const [fromTime, fromPeriod] = item?.from?.split(' ') || ''
  const [toTime, toPeriod] = item?.to?.split(' ') || ''

  const [hoursFrom, minutesFrom] = fromTime?.split(':') || ''
  const [hoursTo, minutesTo] = toTime?.split(':') || ''

  const items = [
    {
      key: '1',
      label: days[index],
      className: 'my_default_organization_opening_hours_responsive-item',
      children: (
        <Div style={{ gap: 8, display: 'grid' }}>
          <MyDefaultSelect
            disabled={disabled}
            className={'store_open_state'}
            name={[field.name, 'isOpen']}
            options={[
              {
                label: getText('TEXT_STORE_IS_OPEN'),
                value: true,
              },
              {
                label: getText('TEXT_STORE_IS_CLOSED'),
                value: false,
              },
            ]}
            showSearch={false}
            isForm
            isLast
            label={null}
          />
          {Boolean(item?.isOpen) && (
            <Flex align='center' vertical gap={8}>
              <MyDefaultCustomTimePicker
                hourValueInit={hoursFrom}
                minuteValueInit={minutesFrom}
                systemHourInit={fromPeriod}
                onChange={(val) => {
                  form.setFieldValue([formListName, field.name, 'from'], val)
                }}
                index={`${index}0`}
                disabled={disabled}
              />
              <MyDefaultCustomTimePicker
                hourValueInit={hoursTo}
                minuteValueInit={minutesTo}
                systemHourInit={toPeriod}
                onChange={(val) => {
                  form.setFieldValue([formListName, field.name, 'to'], val)
                }}
                index={`${index}1`}
                disabled={disabled}
              />
            </Flex>
          )}
        </Div>
      ),
    },
  ]

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['0']}
      className='my_default_organization_opening_hours_responsive'
      expandIconPosition='end'
      expandIcon={(expanded) =>
        expanded.isActive ? (
          <Div style={{ transform: 'rotate(180deg)' }}>
            <SVGSelectArrow />
          </Div>
        ) : (
          <SVGSelectArrow />
        )
      }
      items={items}
    />
  )
}

export default MyDefaultOrganizationOpeningHoursResponsive
