import React, { memo, useEffect, useRef, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Col, Divider, Flex, Typography } from 'antd'
import Div from '../../../../../components/MatDiv/Div'
import { getText } from '../../../../../lang'
import MatSelect from '../../../../../components/MatSelect'
import utilNumber from '../../../../../utils/utilNumber'
import SVGDashSMSIcon from '../../../../../icons/SVG/SVGDashSMSIcon'
import SVGDashEmailIcon from '../../../../../icons/SVG/SVGDashEmailIcon'
import SVGDashFacebookIcon from '../../../../../icons/SVG/SVGDashFacebookIcon'
import SVGDashInstagramIcon from '../../../../../icons/SVG/SVGDashInstagramIcon'
import SVGDashMessageTotalSentIcon from '../../../../../icons/SVG/SVGDashMessageTotalSentIcon'
import DashboardInfoTooltip from '../../../DashboardComponents/DashboardInfoTooltip/DashboardInfoTooltip'
import { EMAIL, FACEBOOK, MESSENGER, SMS } from '../../../../../devOptions'
import { MONTH_MAP } from '../../../../../utils'
import './DashboardLeadsStatistics.scss'

const DashboardLeadsStatistics = ({
  chartData,
  number,
  leadsList,
  responsiveChartHeight,
  onSelectLeads,
}) => {
  const tooltipRef = useRef(null)
  const [selectedLead, setSelectedLead] = useState('total_message_sent')
  const [cursorPositionX, setcursorPositionX] = useState(0)
  const [tooltipWidth, setTooltipWidth] = useState(0)

  const CustomTooltip = ({ active, payload, coordinate }) => {
    useEffect(() => {
      setcursorPositionX(coordinate.x)
    }, [coordinate.x])
    useEffect(() => {
      if (tooltipRef?.current) {
        setTooltipWidth(tooltipRef.current.offsetWidth)
      }
    }, [payload])

    if (active && payload && payload?.length) {
      const name = payload[0].payload.name
      return (
        <Div ref={tooltipRef} className='chart_custom_tooltip'>
          <Div className='chart_custom_tooltip--content'>
            <Div className='chart_custom_tooltip--inner'>
              <p className='desc label'>
                {name?.length === 3 ? MONTH_MAP[name] : name}
              </p>
              {payload.map((item, index) => {
                return (
                  <Flex gap={4} align='center' key={index}>
                    <p className='desc label'>{item.name}:</p>
                    <p className='intro'>{item.value}</p>
                  </Flex>
                )
              })}
            </Div>
          </Div>
        </Div>
      )
    }
    return null
  }

  const transformedLeadData = !leadsList?.length
    ? []
    : leadsList
        .filter((item) => [SMS, FACEBOOK, EMAIL, MESSENGER].includes(item.channel))
        .map((item) => {
          const base = {
            count: item.count || 0,
            channel: item.channel,
          }

          switch (item.channel) {
            case SMS:
              return {
                ...base,
                name: getText('WORD_SMS_UC'),
                icon: <SVGDashSMSIcon />,
                tooltipText: getText('TOOLTIP_TOTAL_MESSAGES_SENT_SMS'),
              }
            case FACEBOOK:
              return {
                ...base,
                name: getText('WORD_SOCIAL_FB'),
                icon: <SVGDashFacebookIcon />,
                tooltipText: getText('TOOLTIP_TOTAL_MESSAGES_SENT_FACEBOOK'),
              }
            case EMAIL:
              return {
                ...base,
                name: getText('WORD_EMAIL'),
                icon: <SVGDashEmailIcon />,
                tooltipText: getText('TOOLTIP_TOTAL_MESSAGES_SENT_EMAIL'),
              }
            case MESSENGER:
              return {
                ...base,
                name: getText('WORD_SOCIAL_INSTAGRAM'),
                icon: <SVGDashInstagramIcon />,
                tooltipText: getText('TOOLTIP_TOTAL_MESSAGES_SENT_INSTAGRAM'),
              }
            default:
              return base
          }
        })
        .reduce((acc, item, index) => {
          acc.push(item)
          if (index === 0 || index === 2) {
            acc.push({ divider: true })
          }
          return acc
        }, [])

  const selectOption = [
    {
      label: getText('WORD_TOTAL_MESSAGES_SENT'),
      value: 'total_message_sent',
    },
    {
      label: getText('WORD_USER'),
      value: 'human',
    },
    {
      label: getText('WORD_BROADCAST_UPPER'),
      value: 'broadcast',
    },
    {
      label: getText('WORD_AUTOMATIONS'),
      value: 'automations',
    },
    {
      label: getText('AI_BOT_SUPERHUMAN'),
      value: 'superhuman',
    },
  ]

  const barsData = [
    {
      name: getText('WORD_USER'),
      dataKey: 'human',
      fill: 'url(#human)',
      radius: [10, 10, 0, 0],
      condition: 'human',
    },
    {
      name: getText('WORD_BROADCAST_UPPER'),
      dataKey: 'broadcast',
      fill: 'url(#broadcast)',
      radius: [10, 10, 0, 0],
      condition: 'broadcast',
    },
    {
      name: getText('WORD_AUTOMATIONS'),
      dataKey: 'automation',
      fill: 'url(#automation)',
      radius: [10, 10, 0, 0],
      condition: 'automations',
    },
    {
      name: getText('AI_BOT_SUPERHUMAN'),
      dataKey: 'superhuman',
      fill: 'url(#superhuman)',
      radius: [10, 10, 0, 0],
      condition: 'superhuman',
    },
  ]

  const displayedBars = barsData.filter(
    (bar) => selectedLead === 'total_message_sent' || bar.condition === selectedLead
  )

  return (
    <Div className={`dashboard_sent_statistics dashboard_sent_statistics_bar_chart`}>
      <Div className={'dashboard_sent_statistics-bg purple'} />
      <Flex className={'dashboard_sent_statistics_bar_chart--header'}>
        <Div className={'dashboard_sent_statistics_bar_chart--header-select-item'}>
          <MatSelect
            icon={<SVGDashMessageTotalSentIcon color={'#65D0C3'} />}
            secondary
            placeholder={getText('WORD_SELECT_ITEM')}
            options={selectOption}
            value={selectedLead}
            onChange={(value) => {
              setSelectedLead(value)
              onSelectLeads && onSelectLeads(value)
            }}
            getPopupContainer={() =>
              document.querySelector('.dashboard_sent_statistics_bar_chart')
            }
          />
          <Flex
            align='center'
            style={{ marginLeft: 0 }}
            className={'dashboard_sent_statistics--information'}
          >
            <Typography level={1}>{utilNumber.numberFractions(number)}</Typography>
          </Flex>
        </Div>
        <Div className={'dashboard_sent_statistics_bar_chart--header-leads-item'}>
          <Flex className={'dashboard_sent_statistics--information--hours'}>
            {transformedLeadData?.map((item, index) => {
              if (item?.divider) {
                return (
                  <Divider
                    key={index}
                    type='vertical'
                    style={{
                      borderInlineStart: '1px solid #38334D',
                      height: '100%',
                    }}
                  />
                )
              } else {
                return (
                  <Col key={index}>
                    <Flex
                      gap={6}
                      className='dashboard_sent_statistics--information--hours-text'
                    >
                      {item.icon}
                      {item.name}
                      <DashboardInfoTooltip title={item.tooltipText} />
                    </Flex>
                    <p>{utilNumber.numberFractions(item.count || 0)}</p>
                  </Col>
                )
              }
            })}
          </Flex>
        </Div>
      </Flex>
      <ResponsiveContainer height={responsiveChartHeight}>
        <Flex
          className='dashboard_sent_statistics-chart-info'
          style={{ marginBottom: '-10px' }}
          justify='flex-end'
        >
          {getText('TEXT_LAST_SIX_MONTH')}
        </Flex>
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
          }}
        >
          <defs>
            <linearGradient id='human' x1='0' y1='0' x2='0' y2='1'>
              <stop stopColor='#A9B0FF' />
              <stop offset='1' stopColor='#542BFA' />
            </linearGradient>
            <linearGradient id='broadcast' x1='0' y1='0' x2='0' y2='1'>
              <stop stopColor='#A9FFBC' />
              <stop offset='1' stopColor='#72FE6F' />
            </linearGradient>
            <linearGradient id='automation' x1='0' y1='0' x2='0' y2='1'>
              <stop stopColor='#A9FFFA' />
              <stop offset='1' stopColor='#2BA3FA' />
            </linearGradient>
            <linearGradient id='superhuman' x1='0' y1='0' x2='0' y2='1'>
              <stop stopColor='#FFBEA9' />
              <stop offset='1' stopColor='#FF9559' />
            </linearGradient>
          </defs>
          <XAxis dataKey='name' tickLine={false} />
          <YAxis tickLine={false} />
          <CartesianGrid strokeDasharray='' opacity={0.2} vertical={false} />
          <Tooltip
            radius={[10, 10, 0, 0]}
            cursor={{
              stroke: 'var(--secondaryTextColor)',
              opacity: 0.5,
              strokeWidth: 1,
              fill: 'transparent',
              radius: [10, 10, 0, 0],
            }}
            position={{
              y: displayedBars.length === 1 ? -50 : -120,
              x: cursorPositionX - tooltipWidth / 2,
            }}
            content={<CustomTooltip />}
          />
          <Legend iconSize={8} iconType='circle' />
          {displayedBars.map((bar, index) => {
            return (
              <Bar
                key={index}
                name={bar.name}
                width={32}
                dataKey={bar.dataKey}
                fill={bar.fill}
                stackId='a'
                shape={({
                  superhuman,
                  dataKey,
                  radius,
                  automation,
                  broadcast,
                  human,
                  ...props
                }) => {
                  if (selectedLead === 'total_message_sent') {
                    const conditionsMet =
                      (superhuman !== 0 && dataKey === 'superhuman') ||
                      (superhuman === 0 && dataKey === 'automation') ||
                      (automation === 0 && dataKey === 'superhuman') ||
                      (broadcast === 0 && dataKey === 'superhuman') ||
                      (human === 0 && dataKey === 'superhuman') ||
                      (superhuman === 0 &&
                        automation === 0 &&
                        dataKey === 'broadcast') ||
                      (superhuman === 0 &&
                        automation === 0 &&
                        broadcast === 0 &&
                        dataKey === 'human')

                    radius = conditionsMet ? [10, 10, 0, 0] : [0, 0, 0, 0]
                  } else {
                    radius = [10, 10, 0, 0]
                  }

                  return (
                    <Rectangle
                      superhuman={superhuman}
                      automation={automation}
                      broadcast={broadcast}
                      human={human}
                      dataKey={dataKey}
                      radius={radius}
                      {...props}
                    />
                  )
                }}
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
    </Div>
  )
}

export default memo(DashboardLeadsStatistics)
