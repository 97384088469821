import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText } from '../../../lang'
import { formatAmount, formatDateTime } from '../../../utils'
import SVGArrowLeft from '../../../icons/SVG/SVGArrowLeft'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import MatTable from '../../../components/MatTable'
import PaymentListItemTitle from '../PaymentListItemTitle'
import PaymentPageLayout from '../PaymentPageLayout'
import DepositDetailsListItems from './DepositDetailsListItems'

const DepositDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()
  let depositDetails = location.state

  const [page, setPage] = useState(0)

  const scheduledDepositsColumn = [
    {
      title: getText('WORD_TYPE'),
      dataIndex: 'type',
      render: (_, { type }) => {
        return type
      },
    },
    {
      title: getText('WORD_GROSS'),
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      render: (_, { amount }) => {
        return <span className='payment-amount'>{formatAmount(amount)}</span>
      },
    },
    {
      title: getText('WORD_FEE'),
      dataIndex: 'fee',
      sorter: (a, b) => a.fee - b.fee,
      render: (_, { fee }) => {
        return formatAmount(fee)
      },
    },
    {
      title: getText('WORD_NET'),
      dataIndex: 'net',
      sorter: (a, b) => a.net - b.net,
      render: (_, { net }) => {
        return formatAmount(net)
      },
    },
    {
      title: getText('WORD_DESCRIPTION'),
      dataIndex: 'reference',
      render: (_, { description, id }) => {
        return (
          <p className='payment-grey-item deposit-breakdown-reference'>
            <span>{description || '--'}</span>
            {id && <span>REF: {id}</span>}
          </p>
        )
      },
    },
    {
      title: getText('WORD_DATE_CREATED'),
      dataIndex: 'date',
      sorter: (a, b) => {
        if (a.created < b.created) {
          return -1
        }
        if (a.created > b.created) {
          return 1
        }
        return 0
      },
      render: (_, { created }) => {
        return (
          <span className='payment-grey-item'>
            {created ? formatDateTime(created * 1000) : ''}
          </span>
        )
      },
    },
  ]

  return (
    <PaymentPageLayout>
      <PaymentListItemTitle
        icon={
          <SVGArrowLeft
            color={'#474657'}
            onClick={() => {
              navigate('/payments?type=deposits')
            }}
          />
        }
        title={getText('WORD_DEPOSIT_DETAILS')}
      />
      <DepositDetailsListItems item={depositDetails} />
      <MatTable
        columns={scheduledDepositsColumn}
        dataSource={depositDetails?.transaction_list?.data || []}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={page}
        paginationTotalCount={4}
        onPaginationChange={setPage}
        withoutPagination
        title={() => getText('WORD_DEPOSIT_BREAKDOWN')}
        scroll={{
          x: 700,
        }}
      />
    </PaymentPageLayout>
  )
}

export default DepositDetails
