import React from 'react'
import { Switch, Tooltip } from 'antd'
import './MatSwitch.scss'
import { InfoCircleOutlined } from '@ant-design/icons'

const MatSwitch = (props) => {
  return (
    <div className={`mat-switch-wrapper ${props.parentClassName || ''}`}>
      {props.label && (
        <div className='switch-label'>
          {props.icon && props.icon} {props.label ? props.label : ''}
          {props?.toolTipText && (
            <Tooltip title={props.toolTipText}>
              <InfoCircleOutlined
                style={{
                  marginLeft: 6,
                  marginTop: 3,
                }}
              />
            </Tooltip>
          )}
        </div>
      )}

      <Switch
        ref={props.ref}
        defaultChecked={props.defaultValue}
        disabled={props.disabled}
        checked={props.value}
        onChange={props.onChange}
        loading={props.loading}
        className={`mat-switch ${props.className || ''}`}
      />
      {props.switchText && <span className='switch-text'>{props.switchText}</span>}
    </div>
  )
}

export default MatSwitch
