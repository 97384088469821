import userflow from 'userflow.js'
import authActions from '../../../store/modules/authActions'
import { getText } from '../../../lang'
import { emailFormater, phoneNumberFormater, setMixpanel } from '../../../utils'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import utilDate from '../../../utils/utilsDate'

export const setReduxAfterLogin = (result, mixpanel) => {
  authActions.storeAuthData(result.user, result.token, result.unread_messages_count)
  setMixpanel(
    mixpanel,
    'Log-in via credentials',
    result.user.createdAt,
    result.user.fullName,
    result.user.organization.name,
    result.user.location.name,
    null,
    result.user.organization?._id,
    result.user.location?._id,
    result.user?._id
  )
}

export const loginSuccess = (result, location, isMobileApp, email, values) => {
  userflow.identify(result.user?._id, {
    'Signed Up': utilDate.getDayJSDate(new Date()).format('MMM D, YYYY h:mm a'),
  })
  if (result.user.isTemporaryPassword) {
    return {
      path: '/reset',
      state: email
        ? {
            email: values.emailOrPhone,
            token: 'loginWithTemporaryPassword',
          }
        : {
            phone: values.emailOrPhone,
            token: 'loginWithTemporaryPassword',
          },
    }
  } else if (location.state && location.state.goBack) {
    return -1
  } else if (isMobileApp) {
    return '/messages'
  } else if (
    window.frames &&
    window.frames.location &&
    window.frames.location.ancestorOrigins &&
    window.frames.location.ancestorOrigins.length
  ) {
    return '/messages/extension'
  } else if (
    result.user.isAdmin ||
    result.user.isManager ||
    (result.user.isSupportAgent &&
      result.user.accessibleOrganizations &&
      result.user.accessibleOrganizations.length > 1)
  ) {
    return '/dashboard'
  } else {
    return '/messages'
  }
}

export const requestVerification = async (
  token,
  method,
  value,
  navigate,
  fromSecondPage,
  loginInputValues
) => {
  const resultCode = await authActions.requestVerification(token, method)
  if (resultCode.success) {
    navigate('/login/verification/code', {
      state: {
        fromSecondPage: fromSecondPage || false,
        token: token,
        method: method,
        value: value,
        loginInputValues: loginInputValues || '',
      },
    })
    notifySuccess(
      method === 'email'
        ? getText('TEXT_VERIFICATION_CODE_FOR_EMAIL').replace(
            '[email]',
            emailFormater(value)
          )
        : getText('TEXT_VERIFICATION_CODE_FOR_PHONE').replace(
            '[phone]',
            phoneNumberFormater(value)
          )
    )
  } else {
    notifyError(resultCode.errMsg)
  }
}
