import React from 'react'
import SVGChecked from '../../../../icons/SVG/SVGChecked'
import Div from '../../../../components/Div/Div'

const TouchpointAssignUserItem = ({
  className,
  index,
  onClick,
  name,
  checked,
  lastItem,
  style,
}) => {
  return (
    <Div
      className={`user_field ${lastItem ? 'not_border_bottom' : ''} ${
        className || ''
      }`}
      key={`${index} + "_" + ${name} `}
      onClick={() => {
        onClick && onClick()
      }}
      style={style}
    >
      <Div className='user_field_text'>{name}</Div>
      {checked && <SVGChecked />}
    </Div>
  )
}

export default TouchpointAssignUserItem
