/* eslint-disable array-callback-return */
import React from 'react'
import { Select, Form, Typography, Tabs } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import InputFormTextAreaSecondary from '../../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import { getText } from '../../../../../lang'
import { getLongName } from '../../../../../utils'

const { Option } = Select

const QuestionsLocationsSelector = ({
  step,
  stepIndex,
  organizationsData,
  organization,
  handleQuestionChange,
  handleLocationReorder,
  handleLocationUpdate,
  handleLocationChange,
}) => {
  const questionsAndLocations = step.questionsAndLocations || []

  const getTitle = (question) => {
    if (question && question.question && question.question.question) {
      return question.question.question.en
    } else {
      return question.question
    }
  }

  const getQuestionInitialValues = () => {
    if (questionsAndLocations) {
      const items = questionsAndLocations.map((item) => {
        let label = null

        if (item.organization && item.organization.name) {
          label = `${item.organization.name} - ${item.location.name} - ${item.question.question.en}`
        } else {
          label = item.question
        }

        return {
          label,
          value: JSON.stringify({
            locationId: item.locationId,
            organizationId: item.organizationId,
            questionId: item.questionId,
            question:
              item.question && item.question.question && item.question.question.en,
            label:
              item.question && item.question.question && item.question.question.en,
            selectedLocations: item.selectedLocations,
          }),
        }
      })
      return items
    }
  }

  const getLocationInitialValues = (questionId) => {
    let items = []
    if (questionsAndLocations) {
      const question = questionsAndLocations.find(
        (item) => item.questionId === questionId
      )

      if (question && question.selectedLocations) {
        items = question.selectedLocations.map((item) => {
          return {
            label: item.name,
            value: JSON.stringify({
              locationId: item.locationId,
              organizationId: item.organizationId,
              questionId: item.questionId,
              name: item.location && item.location.name,
            }),
          }
        })
      }
    }

    return items
  }

  return (
    <Form>
      <Form.Item label='Select questions that will be displayed on the initial screen.'>
        <Select
          style={{ width: '100%' }}
          placeholder='Select questions that will be displayed on the initial screen.'
          onChange={(value) => handleQuestionChange(value, stepIndex)}
          mode='multiple'
          defaultValue={getQuestionInitialValues()}
          name={`custom-questions`}
        >
          {organizationsData.map((organization) =>
            organization.locations.map((location) =>
              location.questions.map((question) => (
                <Option
                  key={question._id}
                  value={JSON.stringify({
                    question: question.question.en,
                    questionId: question._id,
                    organizationId: organization?._id,
                    locationId: location?._id,
                    selectedLocations: question.selectedLocations,
                  })}
                >
                  {`${organization.name} - ${location.name} - ${question.question.en}`}
                </Option>
              ))
            )
          )}
        </Select>
      </Form.Item>

      {questionsAndLocations.map((question, index) => (
        <div key={question.id}>
          <hr />
          <div className='pt-4 pb-4'>
            <Typography.Text style={{ fontSize: 20 }} strong>
              {index + 1}. QUESTION: {getTitle(question)}
            </Typography.Text>
            <Form.Item
              className='pl-md-4'
              label={`Select Locations for "${getTitle(question)}"`}
            >
              <Select
                style={{ width: '100%' }}
                label={`Select Locations`}
                onChange={(value) =>
                  handleLocationChange(value, question, stepIndex)
                }
                mode='multiple'
                defaultValue={getLocationInitialValues(question.questionId)}
              >
                {question.locations &&
                  question.locations.map((item) => {
                    if (item.location) {
                      const { location } = item

                      return (
                        <Option
                          key={`${item.location?._id}-${question._id}`}
                          value={JSON.stringify({
                            questionId: item._id,
                            organizationId: item.organization?._id,
                            locationId: location?._id,
                            name: location.name,
                          })}
                        >
                          {item.organization.name} - {item.location.name}
                        </Option>
                      )
                    }
                  })}
              </Select>
            </Form.Item>
            {question.selectedLocations && question.selectedLocations.length > 0 && (
              <Tabs
                className='mat-tabs pl-3'
                items={organization.languages.map((lng, yIndex) => {
                  return {
                    label: getLongName(lng),
                    key: yIndex,
                    forceRender: true,
                    children: (
                      <>
                        <DragDropContext
                          onDragEnd={handleLocationReorder(index, stepIndex)}
                        >
                          <Droppable droppableId='droppable' direction='vertical'>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {question.selectedLocations.map(
                                  (location, locationIndex) => {
                                    if (location) {
                                      return (
                                        <Draggable
                                          key={location.locationId}
                                          draggableId={location.locationId}
                                          index={locationIndex}
                                        >
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div className='d-flex align-items-center mb-2'>
                                                <div className='ml-2 d-flex flex-column'>
                                                  <Typography.Text
                                                    style={{ fontSize: 18 }}
                                                    strong
                                                  >
                                                    {location.name}
                                                  </Typography.Text>
                                                  <Typography.Text>
                                                    {location.name}
                                                  </Typography.Text>
                                                </div>
                                              </div>
                                              <InputFormTextAreaSecondary
                                                label='Custom Name'
                                                name={[
                                                  `customName${locationIndex}-${index}`,
                                                  lng,
                                                ]}
                                                onChange={(e) => {
                                                  e.persist()
                                                  handleLocationUpdate(
                                                    question.questionId,
                                                    location.locationId,
                                                    e.target.value,
                                                    lng,
                                                    stepIndex
                                                  )
                                                }}
                                                message={getText(
                                                  'ERR_VALIDATION_REQUIRED'
                                                )}
                                                autoSize={false}
                                                initialValue={
                                                  location.locationCustomName &&
                                                  location.locationCustomName[lng]
                                                }
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    }
                                  }
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </>
                    ),
                  }
                })}
              />
            )}
          </div>
        </div>
      ))}
    </Form>
  )
}

export default QuestionsLocationsSelector
