import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.1666 10.1737V6.3237C20.1666 3.11536 18.8833 1.83203 15.6749 1.83203H11.8249C8.61658 1.83203 7.33325 3.11536 7.33325 6.3237V7.33203H10.1749C13.3833 7.33203 14.6666 8.61536 14.6666 11.8237V14.6654H15.6749C18.8833 14.6654 20.1666 13.382 20.1666 10.1737Z'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6666 15.6737V11.8237C14.6666 8.61536 13.3833 7.33203 10.1749 7.33203H6.32492C3.11659 7.33203 1.83325 8.61536 1.83325 11.8237V15.6737C1.83325 18.882 3.11659 20.1654 6.32492 20.1654H10.1749C13.3833 20.1654 14.6666 18.882 14.6666 15.6737Z'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.57349 13.7484L7.36099 15.5359L10.9268 11.9609'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGSettingsTransferIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSettingsTransferIcon
