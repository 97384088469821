import React, { useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import { getText } from '../../../../lang'
import { getLongName } from '../../../../utils'
import AddVariableInMessage from '../../../../components/AddVariableInMessage'
import InputFormTextAreaSecondary from '../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'

const ReviewInvitePanel = (props) => {
  const { variables, languages, form, formName, formNameSubject, title } = props

  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [currentCursorPositionSubject, setCurrentCursorPositionSubject] = useState(0)
  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const textAreaRef = useRef()
  const inputRef = useRef()

  useEffect(() => {
    setSelectedLangTab(languages[0])
  }, [])

  useEffect(() => {
    const messageContent = form.getFieldValue(formName)[selectedLangTab]
    const messageLength = messageContent ? messageContent.length : 0
    setCurrentCursorPosition(messageLength)
    if (formNameSubject) {
      const subjectContent = form.getFieldValue(formNameSubject)[selectedLangTab]
      const subjectLength = subjectContent ? subjectContent.length : 0
      setCurrentCursorPositionSubject(subjectLength)
    }
  }, [selectedLangTab])

  const handleClickVariable = (variable) => {
    const currentContent = form.getFieldValue(formName)[selectedLangTab]
    const newContent = [
      currentContent && currentContent.slice(0, currentCursorPosition),
      variable,
      currentContent && currentContent.slice(currentCursorPosition),
    ].join('')

    form.setFieldsValue({
      [formName]: {
        [selectedLangTab]: newContent,
      },
    })
    setCurrentCursorPosition(currentCursorPosition + variable.length)
    textAreaRef.current &&
      textAreaRef.current.autoFocus(currentCursorPosition + variable.length)
  }
  const handleClickVariableSubject = (variable) => {
    const currentContent = form.getFieldValue(formNameSubject)[selectedLangTab]
    const newContent = [
      currentContent && currentContent.slice(0, currentCursorPositionSubject),
      variable,
      currentContent && currentContent.slice(currentCursorPositionSubject),
    ].join('')

    form.setFieldsValue({
      [formNameSubject]: {
        [selectedLangTab]: newContent,
      },
    })
    setCurrentCursorPositionSubject(currentCursorPositionSubject + variable.length)
    inputRef.current &&
      inputRef.current.autoFocus(currentCursorPositionSubject + variable.length)
  }

  return (
    <div>
      <h3 className='review-invites-title'>{title}</h3>
      <Tabs
        className='mat-tabs'
        onChange={(index) => {
          setSelectedLangTab(languages[index])
        }}
        items={languages.map((lng, index) => {
          return {
            label: getLongName(lng),
            key: index,
            forceRender: true,
            children: (
              <>
                {formNameSubject && (
                  <>
                    <InputFormTextAreaSecondary
                      ref={inputRef}
                      name={[formNameSubject, selectedLangTab]}
                      formClassName='with-variables'
                      allowClear={false}
                      onChange={(e) =>
                        setCurrentCursorPositionSubject(e.target.selectionStart)
                      }
                      onKeyUp={(e) =>
                        setCurrentCursorPositionSubject(e.target.selectionStart)
                      }
                      onClick={(e) =>
                        setCurrentCursorPositionSubject(e.target.selectionStart)
                      }
                      required
                      errorMessage={getText('ERR_VALIDATION_REQUIRED')}
                      minLength={3}
                      maxLength={1000}
                      autoSize={false}
                      placeholder={getText('WORD_SUBJECT')}
                    />
                    <AddVariableInMessage
                      onClick={handleClickVariableSubject}
                      data={variables}
                    />
                  </>
                )}
                <InputFormTextAreaSecondary
                  ref={textAreaRef}
                  maxLength={1000}
                  minLength={20}
                  message={getText('ERR_VALIDATION_REQUIRED')}
                  name={[formName, selectedLangTab]}
                  formClassName='with-variables'
                  placeholder={getText('WORD_BODY')}
                  allowClear={false}
                  onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                  onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                  onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
                  autoSize={false}
                  required
                />
                <AddVariableInMessage
                  onClick={handleClickVariable}
                  data={variables}
                />
              </>
            ),
          }
        })}
      />
    </div>
  )
}

export default ReviewInvitePanel
