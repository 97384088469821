import React, { memo, useState } from 'react'
import { Tooltip } from 'antd'
import SVGArchive from '../../../../../icons/SVG/SVGArchive'
import { getText } from '../../../../../lang'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatButton from '../../../../../components/MatButton'
import { BROADCAST } from '../../../utils/constants'
import './MessageArchiveAction.scss'

const MessageCenterArchiveButton = (props) => {
  const { conversation, messageType, removeConversationFromList } = props

  const [saving, setsaving] = useState(false)

  const handleArchive = async () => {
    setsaving(true)
    const result = await messageActions.archive(conversation._id)
    setsaving(false)
    if (result.success) {
      notifySuccess(getText('NTF_MSG_ARCHIVED'))
      removeConversationFromList(result.data.conversation, true, true)
    } else {
      notifyError(
        result.errMsg
          ? result.errMsg.startsWith('child')
            ? getText('ERR_GENERAL')
            : result.errMsg
          : getText('ERR_GENERAL')
      )
    }
  }

  const handleUnarchive = async () => {
    setsaving(true)
    const result = await messageActions.unarchive(conversation._id)
    setsaving(false)
    if (result.success) {
      notifySuccess(getText('NTF_MSG_UNARCHIVED'))
      removeConversationFromList(result.data.conversation, true, true)
    } else {
      notifyError(
        result.errMsg
          ? result.errMsg.startsWith('child')
            ? getText('ERR_GENERAL')
            : result.errMsg
          : getText('ERR_GENERAL')
      )
    }
  }

  let canArchive = false
  if (![BROADCAST].includes(messageType)) {
    canArchive = true
  }

  return (
    conversation &&
    !conversation.isNew &&
    canArchive && (
      <Tooltip
        destroyTooltipOnHide={true}
        trigger={['hover', 'click']}
        placement='top'
        title={
          Boolean(!conversation.is_archived)
            ? getText('ACTION_ARCHIVE')
            : getText('ACTION_UNARCHIVE')
        }
      >
        <MatButton
          loading={saving}
          disabled={!canArchive}
          onClick={
            Boolean(!conversation.is_archived) ? handleArchive : handleUnarchive
          }
          icon={<SVGArchive />}
          className={'archive_button'}
        />
      </Tooltip>
    )
  )
}

export default memo(MessageCenterArchiveButton)
