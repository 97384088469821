import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.75 5.83203C2.75 3.62289 4.54086 1.83203 6.75 1.83203H15.25C17.4591 1.83203 19.25 3.62289 19.25 5.83203V16.1654C19.25 18.3745 17.4591 20.1654 15.25 20.1654H6.75C4.54086 20.1654 2.75 18.3745 2.75 16.1654V5.83203Z'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M7.33337 6.41797L11 6.41797'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M7.33337 11H14.6667'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M7.33337 15.582H14.6667'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

const SVGOrgTemplateIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgTemplateIcon
