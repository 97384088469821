import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.3335 13.6667H4.66683C2.66683 13.6667 1.3335 12.6667 1.3335 10.3333V5.66666C1.3335 3.33333 2.66683 2.33333 4.66683 2.33333H11.3335C13.3335 2.33333 14.6668 3.33333 14.6668 5.66666V10.3333C14.6668 12.6667 13.3335 13.6667 11.3335 13.6667Z'
        stroke='#3F8CFF'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.3332 6L9.2465 7.66667C8.55983 8.21333 7.43317 8.21333 6.7465 7.66667L4.6665 6'
        stroke='#3F8CFF'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGDashEmailIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashEmailIcon
