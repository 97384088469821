import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './LoadingSpinner.scss'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function LoadingSpinner({ icon = antIcon, className, style, withoutHeight }) {
  return (
    <>
      {withoutHeight ? (
        <Spin
          className={`mat-spiner-loading ${className || ''}`}
          indicator={icon}
          style={style}
        />
      ) : (
        <div style={style} className='spin-loading-wrapper'>
          <Spin
            className={`mat-spiner-loading ${className || ''}`}
            indicator={icon}
          />
        </div>
      )}
    </>
  )
}

export default LoadingSpinner
