import React from 'react'
import _ from 'lodash'
import { getText } from '../../../lang'

const FeaturesConnectingData = ({ featuresConnectingData }) => {
  return !_.isEmpty(featuresConnectingData) ? (
    <div className='footer-connected-account'>
      {featuresConnectingData.userData.fullName === 'Automations' ? (
        <span style={{ color: '#474657', fontWeight: 'bold' }}>
          {getText('TEXT_DISCONNECTED_DUE_TO_TOKEN_EXPIRATION_OR_PASSWORD_CHANGE')}
        </span>
      ) : (
        <>
          <span>
            {getText(
              featuresConnectingData.isConnected
                ? 'ACTION_CONNECTED_BY'
                : 'ACTION_DISCONNECTED_BY'
            )}
          </span>
          <span>{featuresConnectingData.userData.fullName}</span>
        </>
      )}
    </div>
  ) : (
    <div />
  )
}

export default FeaturesConnectingData
