import React, { memo, useCallback, useEffect, useState } from 'react'
import organizationActions from '../../store/modules/organizationActions'
import { getText } from '../../lang'
import { GET_DATA_FETCH_SIZE } from '../../utils'
import MyDefaultSelect from './MyDefaultSelect'

const MyDefaultSelectOrganization = ({
  isForm,
  color = 'grey',
  label,
  dropdownClassName,
  dropdownStyle,
  style,
  onSelectItem,
  value,
  onDropdownVisibleChange,
  placeholder,
  mode,
  required,
  name,
  labelInValue,
  message,
  isLast,
}) => {
  const [data, setdata] = useState({ total: 0, list: [] })
  const [page, setPage] = useState(0)
  const [searchValue, setsearchValue] = useState('')

  const fill = useCallback(async () => {
    const result = await organizationActions.getPageList(
      page,
      GET_DATA_FETCH_SIZE,
      searchValue,
      {
        enabled: true,
      }
    )
    if (result.success) {
      const organizationsList = result.data.map((org) => ({
        value: org._id,
        label: org.name,
        ...org,
      }))

      const newListOrganizations =
        page > 0 ? [...data.list, ...organizationsList] : organizationsList

      setdata({
        list: newListOrganizations,
        total: result.max,
      })
    }
  }, [searchValue, page])

  useEffect(() => {
    fill()
  }, [fill])

  const debouncedChange = useCallback((options) => {
    setPage(0)
    setsearchValue(options)
  }, [])

  return (
    <MyDefaultSelect
      isForm={isForm}
      color={color}
      label={label}
      dropdownClassName={dropdownClassName}
      dropdownStyle={dropdownStyle}
      style={style}
      mode={mode}
      required={required}
      labelInValue={labelInValue}
      name={name}
      options={data.list}
      isLast={isLast}
      onLoadMore={() => {
        if (data.list.length < data.total) {
          setPage(page + 1)
        }
      }}
      message={message || getText('WORD_PLEASE_SELECT_ORGANIZATION')}
      showSearch={true}
      onSearch={debouncedChange}
      placeholder={placeholder || getText('WORD_SELECT_ORGANIZATION')}
      onSelectItem={onSelectItem}
      value={value}
      onDropdownVisibleChange={(e) => {
        if (!e) setsearchValue('')
        onDropdownVisibleChange && onDropdownVisibleChange(e)
      }}
      onClear={() => {
        setsearchValue('')
        setPage(0)
      }}
    />
  )
}

export default memo(MyDefaultSelectOrganization)
