import React, { useState } from 'react'
import { getLongName } from '../../../../utils'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'

const LanguageItem = (props) => {
  const {
    lng,
    isDefault,
    disabled,
    onSetAsDefaultClick,
    isSelected,
    onChangeSwitch,
    isFirst,
    isLast,
  } = props

  const [value, setValue] = useState(isSelected)

  return (
    <MyDefaultSwitch
      title={getLongName(lng)}
      isFirst={isFirst}
      isLast={isLast}
      titleStyle={{ fontSize: 14 }}
      value={value}
      onChangeSwitch={(e) => {
        setValue(e)
        onChangeSwitch(lng)
      }}
      onSetAsDefaultClick={() => {
        if (value) {
          onSetAsDefaultClick(lng)
        }
      }}
      disabled={disabled}
      isDefault={isDefault}
    />
  )
}

export default LanguageItem
