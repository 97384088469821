import React, { useState } from 'react'
import { Card, Col, Form, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import authActions from '../../../store/modules/authActions'
import { getText, getTextServerError } from '../../../lang'
import { formatPhone, moveToLoginPage } from '../../../utils'
import SVGLoginLogo from '../../../icons/SVG/SVGLoginLogo'
import LoginPageIcon from '../../../icons/login-page-icon.png'
import MatForm from '../../../components/Form/MatForm'
import PageFull from '../../../components/PageFull'
import { notifyError } from '../../../utils/Notify'
import MatRow from '../../../components/MatRow'
import MatButton from '../../../components/MatButton'
import InputFormTextSecondary from '../../../components/Form/InputFormText/InputFormTextSecondary'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import LoginPageHeader from './LoginPageHeader'

const PasswordRequest = () => {
  const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState('')

  const [form] = Form.useForm()

  const onFinish = async (values) => {
    setLoading(true)
    let phone
    let email
    if (values.emailOrPhone.includes('@')) {
      email = values.emailOrPhone
    } else {
      phone = formatPhone(values.emailOrPhone)
    }
    const result = await authActions.requestNewPassword(email, phone)
    if (result.success) {
      if (values.emailOrPhone.includes('@')) {
        setSubmitted(getText('WORD_EMAIL'))
      } else {
        setSubmitted(getText('WORD_PHONE'))
      }
    } else if (result.errMsg) {
      if (result.errMsg === 'Wrong phone format.') {
        result.errMsg = getText('MSG_ENTERED_PHONE_EMAIL_IS_NOT_VALID')
      }
      notifyError(getTextServerError(result.errMsg))
    }

    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <PageFull className='login-page-wrapper'>
      <Row className='login-page-main-row'>
        <Col
          className='login-page-main-col'
          style={{
            width: !isMobileOrTabletOrTabletPortrait ? '59%' : '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: !isMobileOrTabletOrTabletPortrait
                ? 'flex-start'
                : 'center',
              marginTop: !isMobileOrTabletOrTabletPortrait ? 0 : 20,
            }}
          >
            <SVGLoginLogo />
          </div>
          {!submitted ? (
            <Card bordered={false} className='login-page-left-card'>
              <LoginPageHeader
                customTitle={getText('WORD_FORGOT_PASSWORD')}
                customText={getText(
                  'TEXT_ENTER_YOUR_EMAIL_OR_PHONE_BELOW_AND_WE_WILL_SEND_YOU_PASSWORD_RESET_INSTRUCTIONS'
                )}
              />
              <MatForm
                form={form}
                name='formSaveResetPassword'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <InputFormTextSecondary
                  name='emailOrPhone'
                  label={getText('LABEL_PHONE_OR_EMAIL')}
                  required
                  placeholder={getText('WORD_PLEASE_ENTER_YOUR_PHONE_OR_EMAIL')}
                  message={getText('MSG_PLEASE_INPUT_PHONE_OR_EMAIL')}
                />
                <MatRow className='login-page-button-wrapper'>
                  <MatButton
                    buttonText={getText('ACTION_CANCEL')}
                    htmlType={''}
                    typeButton={'white'}
                    onClick={() => {
                      navigate(-1)
                    }}
                    className='magic-link-btn'
                  />
                  <MatButton
                    loading={loading}
                    formName={'formSaveResetPassword'}
                    buttonText={getText('WORD_RESET_PASSWORD')}
                    htmlType={'submit'}
                    className='login-link-btn'
                  />
                </MatRow>
              </MatForm>
            </Card>
          ) : (
            <div className='password-request-validate-wrapper'>
              <img
                style={{ marginBottom: '20px' }}
                className='password-request-validate-image'
                src={`${process.env.PUBLIC_URL}/images/email-confirm.svg`}
                alt='password subbmited'
              />
              <div className='password-request-info'>
                <h2>
                  {submitted} {getText('WORD_CONFIRMATION')}
                </h2>
                <h6 style={{ marginBottom: '0px' }}>
                  {getText('TEXT_MESSAGE_HAS_BEEN_SENT_PLEASE_CHECK_YOUR_INBOX')}
                </h6>
                <MatButton
                  style={{ margin: '20px auto 0', width: '60%' }}
                  buttonText={getText('ACTION_BACK')}
                  htmlType={''}
                  typeButton={'primary'}
                  onClick={() => {
                    moveToLoginPage(navigate)
                  }}
                />
              </div>
            </div>
          )}
        </Col>
        {!isMobileOrTabletOrTabletPortrait ? (
          <div className='login-page-right-card'>
            <img src={LoginPageIcon} alt='' />
          </div>
        ) : null}
      </Row>
    </PageFull>
  )
}

export default PasswordRequest
