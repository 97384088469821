import moment from 'moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import authActions from '../store/modules/authActions'

export const FORMAT_DATE = 'YYYY-MM-DD'
export const FORMAT_DATE_TIME = 'YYYY-MM-DD hh:mm:ss'
export const FORMAT_DATE_TIME_PM_AM = 'YYYY.MM.DD hh:mm a'
export const FORMAT_DATE_TIME_PM_AM_FIRST_MONTH = 'MM.DD.YYYY hh:mm a'
export const AUSTRALIAN_DATE_TIME_FORMAT = 'DD.MM.YYYY hh:mm a'
export const AUSTRALIAN_DATE_FORMAT = 'DD.MM.YYYY'
export const AUSTRALIAN_DATE_APPOINTMENT_FORMAT = 'DD MMM, YYYY'
export const AUSTRALIAN_DATE_TIME_APPOINTMENT_FORMAT = 'DD MMM, YYYY, hh:mm a'

const utilDate = {
  getDate: (date, format) => {
    return moment(date).format(format || FORMAT_DATE)
  },
  getTodayDate: (format) => {
    return moment().format(format || FORMAT_DATE)
  },
  getYesterday: (format) => {
    return moment()
      .subtract(1, 'days')
      .format(format || FORMAT_DATE)
  },
  getStartOfMonth: (date, format) => {
    if (!date) {
      date = moment()
    }
    return moment(date)
      .startOf('month')
      .format(format || FORMAT_DATE)
  },
  getEndOfMonth: (date, format) => {
    if (!date) {
      date = moment()
    }
    return moment(date)
      .endOf('month')
      .format(format || FORMAT_DATE)
  },
  getStartOfWeek: (date, format) => {
    if (!date) {
      date = moment()
    }
    return moment(date)
      .startOf('week')
      .format(format || FORMAT_DATE)
  },
  getEndOfWeek: (date, format) => {
    if (!date) {
      date = moment()
    }
    return moment(date)
      .endOf('week')
      .format(format || FORMAT_DATE)
  },
  addDays: (date, format, count) => {
    if (!date) {
      date = moment()
    }
    return moment(date)
      .add(count, 'days')
      .format(format || FORMAT_DATE)
  },
  beforeDays: (date, format, count) => {
    if (!date) {
      date = moment()
    }
    return moment(date)
      .subtract(count, 'days')
      .format(format || FORMAT_DATE)
  },
  addMinutes: (date, format, count) => {
    if (!date) {
      date = moment()
    }
    return moment(date)
      .add(count, 'minutes')
      .format(format || FORMAT_DATE_TIME)
  },
  getLocalizedFullDate: (date, format) => {
    if (!date) {
      date = moment()
    }
    return moment(date)
      .locale(authActions.getLanguage())
      .format(format || FORMAT_DATE_TIME_PM_AM)
  },
  getDateUTC: (date, format) => {
    const organization = authActions.getOrganization()
    if (!date) {
      date = moment().tz(organization.timezone)
    }
    const dateZone = moment(date)
    return format ? dateZone.format(format) : dateZone
  },
  getDateTimeUTC: (date, format) => {
    const organization = authActions.getOrganization()
    if (!date) {
      date = moment().tz(organization.timezone)
    }
    const dateZone = moment(date).tz(organization.timezone)
    return format
      ? dateZone.format(format)
      : dateZone.format(
          organization.twilio_number.includes('+61')
            ? AUSTRALIAN_DATE_TIME_FORMAT
            : FORMAT_DATE_TIME_PM_AM
        )
  },
  getDateByNewDateUTC: (date) => {
    const organization = authActions.getOrganization()
    if (!date) {
      date = new Date()
    }
    const localDateTime = date
    const targetTimeZone = organization.timezone // Replace with your target timezone
    const targetTime = new Date(
      localDateTime.toLocaleString('en-US', { timeZone: targetTimeZone })
    )
    return targetTime
  },
  getLastMonth: (format) => {
    return moment()
      .subtract(1, 'month')
      .format(format || FORMAT_DATE)
  },
  getDateTimeForPayment: (date, format) => {
    const organization = authActions.getOrganization()
    const parsedDate = moment(date, 'YYYY-MM-DD hh:mm:ss a').locale(
      authActions.getLanguage()
    )
    if (parsedDate.isValid()) {
      date = parsedDate
    }
    return date.format(
      Boolean(format)
        ? format
        : organization.twilio_number.includes('+61')
          ? AUSTRALIAN_DATE_TIME_FORMAT
          : FORMAT_DATE_TIME_PM_AM
    )
  },
  getConversationDate: (date) => {
    const now = moment()
    let messageDate = moment(date)

    if (!messageDate.isValid()) {
      messageDate = now
    }

    messageDate = messageDate.locale(authActions.getLanguage())

    if (now.isSame(messageDate, 'day')) {
      return messageDate.format('h:mm A')
    } else if (now.isSame(messageDate, 'year')) {
      return messageDate.format('D MMM')
    } else {
      return messageDate.format('D MMM YYYY')
    }
  },
  getDayJSDate: (date) => {
    if (!date) {
      date = dayjs()
    }
    return dayjs(date)
  },
  getDateByDayJS: (date, format) => {
    return dayjs(dayjs(date), format || FORMAT_DATE)
  },
  getCurrentDateByDayJS: (date, format) => {
    if (!date) {
      date = dayjs()
    }
    if (typeof date === 'string') {
      return dayjs(date).format(format || FORMAT_DATE)
    } else {
      return dayjs(date['$d']).format(format || FORMAT_DATE)
    }
  },
  getYesterdayByDayJS: (format) => {
    return dayjs()
      .subtract(1, 'days')
      .format(format || FORMAT_DATE)
  },
  getYesterdayByDayJSUTC: (format) => {
    const organization = authActions.getOrganization()
    dayjs.extend(utc)
    dayjs.extend(timezone)

    const dateZone = dayjs().tz(organization.timezone).subtract(1, 'days')
    return format ? dateZone.format(format) : dateZone
  },
  subtractDaysByDayJS: (number) => {
    return dayjs()
      .subtract(number || 1, 'days')
      .endOf('day')
  },
  endDateByDayJS: (when) => {
    return dayjs().endOf(when || 'day')
  },
  getDateDaysJSUTC: (date, format) => {
    const organization = authActions.getOrganization()
    dayjs.extend(utc)
    dayjs.extend(timezone)
    if (!date) {
      date = dayjs()
    }
    const dateZone = dayjs(date).tz(organization.timezone)
    return format ? dateZone.format(format) : dateZone
  },
  getStartOfMonthDaysJ: (date, format) => {
    if (!date) {
      date = dayjs()
    }
    return dayjs(date)
      .startOf('month')
      .format(format || FORMAT_DATE)
  },
  getEndOfMonthDaysJ: (date, format) => {
    if (!date) {
      date = dayjs()
    }
    return dayjs(date)
      .endOf('month')
      .format(format || FORMAT_DATE)
  },
  getLastMonthByDayJS: (format) => {
    return dayjs()
      .subtract(1, 'month')
      .format(format || FORMAT_DATE)
  },
  getStartOfDay: (date, format) => {
    if (!date) {
      date = dayjs()
    }
    return dayjs(date)
      .startOf('day')
      .format(format || FORMAT_DATE)
  },
  getEndOfDay: (date, format) => {
    if (!date) {
      date = dayjs()
    }
    return dayjs(date)
      .endOf('day')
      .format(format || FORMAT_DATE)
  },
  isWeekend: (date) => {
    const day = dayjs(date).day()
    return day === 0 || day === 6
  },
}

export default utilDate
