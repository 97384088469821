import React from 'react'
import { Flex } from 'antd'
import MyDefaultBlockSubtitle from '../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultTableText from '../../../../components/MyDefaultTable/MyDefaultTableText/MyDefaultTableText'
import MyDefaultTableTags from '../../../../components/MyDefaultTable/MyDefaultTableTags/MyDefaultTableTags'

const BrodcastListExpandedItem = ({ title, text, list }) => {
  return (
    <Flex vertical gap={5}>
      <MyDefaultBlockSubtitle subtitle={title} />
      {Boolean(text) ? (
        <MyDefaultTableText text={text} />
      ) : (
        <MyDefaultTableTags list={list} />
      )}
    </Flex>
  )
}

export default BrodcastListExpandedItem
