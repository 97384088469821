import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { Form, Tabs } from 'antd'
import locationActions from '../../../../../store/modules/locationActions'
import { getText, getTextServerError } from '../../../../../lang'
import SVGSwipeIcon from '../../../../../icons/SVG/SVGSwipeIcon'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import { APP_URL } from '../../../../../config'
import MatButton from '../../../../../components/MatButton'
import MatForm from '../../../../../components/Form/MatForm'
import InputFormText from '../../../../../components/Form/InputFormText'
import MatRow from '../../../../../components/MatRow'
import SwitchForm from '../../../../../components/Form/SwitchForm'
import SelectTrigger from '../../../../../components/api/SelectTrigger'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import InputTextarea from '../../../../../components/InputTextarea'
import SelectTheme from '../../../../../components/api/SelectThemeLightOnly'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import PopupPreview from '../../../../../components/Popup/PopupPreview'
import { getLongName } from '../../../../../utils'
import LocationCouponDescription from '../mobileCoupon/LocationCouponDescription'
import LocationCouponTitle from '../mobileCoupon/LocationCouponTitle'
import LocationsCouponPopover from '../mobileCoupon/LocationsCouponPopover'
import LocationCouponButton from '../mobileCoupon/LocationCouponButton'
import MatadorConnectLayout from '../../matadorConnect/matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../../matadorConnect/matadorConnectComponents/MatadorConnectListTitle'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import Dropzone from '../../fileUpload/DropzoneFile'
import UrlConditionManager from '../../matadorConnect/settings/UrlConditionManager'
import LocationPopupHeader from './LocationPopupHeader'
import './PopupPreview.scss'

const LocationsExitIntent = () => {
  const { locationObj } = useLocationLayout()
  const { setFooterButtons, setLoading } = useLayout()
  const organization = useSelector(organizationSelector)

  const [form] = Form.useForm()

  const [fieldsLang, setFieldsLang] = useState(organization.defaultLanguage)

  const [loading, setLoiading] = useState(true)
  const [popupData, setPopupData] = useState([])

  const [theme, setTheme] = useState('light')

  // popup props states here

  const [popupTrigger, setPopupTrigger] = useState('')

  const [headerEn, setHeaderEn] = useState('')
  const [headerFr, setHeaderFr] = useState('')
  const [headerSp, setHeaderSp] = useState('')
  const [headerColor, setHeaderColor] = useState('')
  const [visibleHeaderEdit, setVisibleHeaderEdit] = useState(false)

  const [titleEn, setTitleEn] = useState('')
  const [titleFr, setTitleFr] = useState('')
  const [titleSp, setTitleSp] = useState('')
  const [titleColor, setTitleColor] = useState('')
  const [visibleTitleEdit, setVisibleTitleEdit] = useState(false)

  const [descriptionEn, setDescriptionEn] = useState('')
  const [descriptionFr, setDescriptionFr] = useState('')
  const [descriptionSp, setDescriptionSp] = useState('')
  const [descriptionColor, setDescriptionColor] = useState('')
  const [visibleDescriptionEdit, setVisibleDescriptionEdit] = useState(false)

  const [buttonEn, setButtonEn] = useState('')
  const [buttonFr, setButtonFr] = useState('')
  const [buttonSp, setButtonSp] = useState('')
  const [buttonTextColor, setButtonTextColor] = useState('')
  const [visibleButtonTextEdit, setVisibleButtonTextEdit] = useState('')

  const [buttonColor, setButtonColor] = useState('')

  const [visibleButtonEdit, setVisibleButtonEdit] = useState(false)

  const [backgroundColor, setBackgroundColor] = useState('')
  const [visibleBackgroundEdit, setVisibleBackgroundEdit] = useState(false)
  const [termsConditionsLink, setTermsConditionsLink] = useState('')

  const [uploadFile, setUploadFile] = useState({
    fileUploadPage: true,
    file: '',
  })
  const [defaultImageUrl, setDefaultImageUrl] = useState('')

  const [copied, setCopied] = useState(false)
  const [urls, setUrls] = useState([])

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
  }, [])

  useEffect(() => {
    const fill = async () => {
      let result = await locationActions.getPopup(locationObj._id)
      if (result.success) {
        setPopupData(result.data)
        setLoiading(false)
      }
    }
    if (locationObj && locationObj._id) {
      fill()
    }
  }, [locationObj, locationObj._id])

  useEffect(() => {
    setPopupTrigger(popupData.popupTrigger)
    setHeaderColor(popupData.headerColor)
    setTitleColor(popupData.titleColor)
    setDescriptionColor(popupData.descriptionColor)
    setButtonColor(popupData.buttonColor)
    setButtonTextColor(popupData.buttonTextColor)
    setBackgroundColor(popupData.backgroundColor)
    setDefaultImageUrl(popupData.defaultImageUrl)
    setTermsConditionsLink(popupData.termsConditionsLink)
    if (popupData && popupData.conditions && popupData.conditions.url) {
      setUrls(popupData.conditions.url)
    }
    setUploadFile({
      fileUploadPage: true,
      file: popupData.photo,
    })
    if (popupData && popupData.header) {
      popupData.header.map((item) => {
        if (item.language === 'en') {
          setHeaderEn(item.text)
        }
        if (item.language === 'fr') {
          setHeaderFr(item.text)
        }
        if (item.language === 'sp') {
          setHeaderSp(item.text)
        }
        return item
      })
    }
    if (popupData && popupData.title) {
      popupData.title.map((item) => {
        if (item.language === 'en') {
          setTitleEn(item.text)
        }
        if (item.language === 'fr') {
          setTitleFr(item.text)
        }
        if (item.language === 'sp') {
          setTitleSp(item.text)
        }
        return item
      })
    }
    if (popupData && popupData.description) {
      popupData.description.map((item) => {
        if (item.language === 'en') {
          setDescriptionEn(item.text)
        }
        if (item.language === 'fr') {
          setDescriptionFr(item.text)
        }
        if (item.language === 'sp') {
          setDescriptionSp(item.text)
        }
        return item
      })
    }
    if (popupData && popupData.buttonTexts) {
      popupData.buttonTexts.map((item) => {
        if (item.language === 'en') {
          setButtonEn(item.text)
        }
        if (item.language === 'fr') {
          setButtonFr(item.text)
        }
        if (item.language === 'sp') {
          setButtonSp(item.text)
        }
        return item
      })
    }

    const initialValues = {
      photo: popupData.photo,
      theme: 'light',
      showOncePerDayOption: popupData.showOncePerDayOption,
      backgroundColor: popupData.backgroundColor,
      buttonTextColor: popupData.buttonTextColor,
      buttonColor: popupData.buttonColor,
      titleColor: popupData.titleColor,
      headerColor: popupData.headerColor,
      descriptionColor: popupData.descriptionColor,
      termsConditionsLink: popupData.termsConditionsLink,
      popupTrigger: popupData.popupTrigger,
      status: popupData.status === 'DISABLED' ? false : true,
    }
    form.setFieldsValue(initialValues)
  }, [popupData])

  const onFinish = async (values) => {
    setLoading(true)
    delete values.numberValue
    delete values.text
    values.photo = uploadFile.file || null
    values.buttonTextColor = buttonTextColor
    values.buttonColor = buttonColor
    values.backgroundColor = backgroundColor
    values.titleColor = titleColor
    values.headerColor = headerColor
    values.popupTrigger = popupTrigger
    values.descriptionColor = descriptionColor
    values.title = []
    values.conditions = {
      url: urls,
    }
    if (values.status) {
      values.status = 'ENABLED'
    } else {
      values.status = 'DISABLED'
    }
    if (organization.languages.includes('en')) {
      values.title.push({
        language: 'en',
        text: titleEn,
      })
    }
    if (organization.languages.includes('fr')) {
      values.title.push({
        language: 'fr',
        text: titleFr,
      })
    }
    if (organization.languages.includes('sp')) {
      values.title.push({
        language: 'sp',
        text: titleSp,
      })
    }
    values.header = []
    if (organization.languages.includes('en')) {
      values.header.push({
        language: 'en',
        text: headerEn,
      })
    }
    if (organization.languages.includes('fr')) {
      values.header.push({
        language: 'fr',
        text: headerFr,
      })
    }
    if (organization.languages.includes('sp')) {
      values.header.push({
        language: 'sp',
        text: headerSp,
      })
    }
    values.description = []
    if (organization.languages.includes('en')) {
      values.description.push({
        language: 'en',
        text: descriptionEn,
      })
    }
    if (organization.languages.includes('fr')) {
      values.description.push({
        language: 'fr',
        text: descriptionFr,
      })
    }
    if (organization.languages.includes('sp')) {
      values.description.push({
        language: 'sp',
        text: descriptionSp,
      })
    }
    values.buttonTexts = []
    if (organization.languages.includes('en')) {
      values.buttonTexts.push({
        language: 'en',
        text: buttonEn,
      })
    }
    if (organization.languages.includes('fr')) {
      values.buttonTexts.push({
        language: 'fr',
        text: buttonFr,
      })
    }
    if (organization.languages.includes('sp')) {
      values.buttonTexts.push({
        language: 'sp',
        text: buttonSp,
      })
    }
    let titleItems = values.title.filter((it) => {
      return it.text
    })
    let headerItems = values.header.filter((it) => {
      return it.text
    })
    let descriptionItems = values.description.filter((it) => {
      return it.text
    })
    let buttonItems = values.buttonTexts.filter((it) => {
      return it.text
    })
    if (titleItems) {
      values.title = titleItems
    }
    if (headerItems) {
      values.header = headerItems
    }
    if (descriptionItems) {
      values.description = descriptionItems
    }
    if (buttonItems) {
      values.buttonTexts = buttonItems
    }
    let result = await locationActions.savePopup(values, locationObj._id)
    if (result.success) {
      notifySuccess(getText('TEXT_YOUR_POPUP_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const getCode = () => {
    let defaultCode = `<!-- Start of Matador Exit Intent Script --> 
<script>
  (function(d,t) {
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=src="${APP_URL}/_coupon.js";
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
        matadorCouponWidget("${locationObj._id}");
    }
  })(document,"script");
</script>
<!-- End of Matador Exit Intent Script -->
`

    return defaultCode
  }

  const handleChange = (file, name) => {
    if (file.status !== 'done') return
    if (name === 'thumbnail') {
      setUploadFile({
        fileUploadPage: false,
        file: file.mediaUrl,
      })
    }
  }

  return !loading ? (
    <MatadorConnectLayout className={'location-coupon-wrapper'}>
      <MatadorConnectListTitle title={getText('TEXT_CREATE_NEW_EXIT_INTENT')} />
      <div className='sub-secondary-menu'>
        <div className='left-content'>
          <div className='left-content-block'>
            <MatForm
              form={form}
              formName='formCreateCoupon'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <MatRow flexStart>
                <SelectTrigger
                  name='popupTrigger'
                  label={getText('LABEL_POPUP_TRIGGER')}
                  location={locationObj}
                  required={false}
                  onChange={(trigger) => {
                    setPopupTrigger(trigger)
                  }}
                />
                <SelectTheme
                  name='theme'
                  label={getText('LABEL_THEME')}
                  required
                  onChange={(theme) => {
                    setTheme(theme)
                  }}
                />
              </MatRow>
              <SwitchForm name='status' text={getText('WORD_TURN_ON')} />
              <SwitchForm
                name='showOncePerDayOption'
                text={getText('TEXT_SHOW_ONCE_PER_DAY_TO_EACH_USER')}
              />
              <div className='popup-language-switch'>
                <Tabs
                  onChange={(item) => {
                    setFieldsLang(organization.languages[item])
                  }}
                  defaultActiveKey={`${fieldsLang}`}
                  items={organization.languages.map((lng, index) => {
                    return {
                      label: getLongName(lng),
                      key: index,
                    }
                  })}
                />
              </div>
              <div
                className={`coupon-widget-wrapper popup-widget-wrapper ${
                  theme === 'dark' ? 'coupon-widget-wrapper-dark' : ''
                }`}
                style={{
                  backgroundColor: backgroundColor,
                }}
              >
                <div className='bg-color-edit-icon-wrapper'>
                  <LocationsCouponPopover
                    onlyBackground
                    color={backgroundColor}
                    setColor={setBackgroundColor}
                    visibleEdit={visibleBackgroundEdit}
                    setVisibleEdit={setVisibleBackgroundEdit}
                  />
                </div>
                <div className='position-relative popup-upload-container'>
                  <Dropzone
                    mediaState={uploadFile}
                    mediaSetState={setUploadFile}
                    title={getText('WORD_THUMBNAIL')}
                    mediaTypes={'image/jpeg, image/jpg, image/png, image/gif'}
                    onChange={(file) => {
                      handleChange(file, 'thumbnail')
                    }}
                    placeholder={getText('TEXT_RECOMMENDED_FORMATS_JPEG')}
                  />
                </div>
                <LocationPopupHeader
                  headerEn={headerEn}
                  onChangeHeaderEn={(e) => {
                    setHeaderEn(e.target.value)
                  }}
                  headerFr={headerFr}
                  onChangeHeaderFr={(e) => {
                    setHeaderFr(e.target.value)
                  }}
                  headerSp={headerSp}
                  onChangeHeaderSp={(e) => {
                    setHeaderSp(e.target.value)
                  }}
                  onChangeColor={(color) => {
                    setHeaderColor(color)
                  }}
                  headerColor={headerColor}
                  visibleHeaderEdit={visibleHeaderEdit}
                  setVisibleHeaderEdit={setVisibleHeaderEdit}
                  lang={fieldsLang}
                  organization={organization}
                  withoutSelect
                  headerFontSize={14}
                />
                <LocationCouponTitle
                  titleEn={titleEn}
                  onChangeTitleEn={(e) => {
                    setTitleEn(e.target.value)
                  }}
                  titleFr={titleFr}
                  onChangeTitleFr={(e) => {
                    setTitleFr(e.target.value)
                  }}
                  titleSp={titleSp}
                  onChangeTitleSp={(e) => {
                    setTitleSp(e.target.value)
                  }}
                  onChangeColor={(color) => {
                    setTitleColor(color)
                  }}
                  titleColor={titleColor}
                  visibleTitleEdit={visibleTitleEdit}
                  setVisibleTitleEdit={setVisibleTitleEdit}
                  lang={fieldsLang}
                  organization={organization}
                  withoutSelect
                  titleFontSize={21}
                />
                <LocationCouponDescription
                  descriptionEn={descriptionEn}
                  onChangedescriptionEn={(e) => {
                    setDescriptionEn(e.target.value)
                  }}
                  descriptionFr={descriptionFr}
                  onChangedescriptionFr={(e) => {
                    setDescriptionFr(e.target.value)
                  }}
                  descriptionSp={descriptionSp}
                  onChangedescriptionSp={(e) => {
                    setDescriptionSp(e.target.value)
                  }}
                  onChangeDescriptionColor={(color) => {
                    setDescriptionColor(color)
                  }}
                  descriptionColor={descriptionColor}
                  visibleDescriptionEdit={visibleDescriptionEdit}
                  setVisibleDescriptionEdit={setVisibleDescriptionEdit}
                  lang={fieldsLang}
                  organization={organization}
                  withoutSelect
                />
                <div className='button-with-edit-icon'>
                  <div
                    className='swipe-to-start-button'
                    style={{ backgroundColor: buttonColor }}
                  >
                    <div className='icon-wrapper'>
                      <SVGSwipeIcon color={buttonColor} />
                    </div>
                    <div className='input-text-with-edit-icon'>
                      <LocationCouponButton
                        buttonEn={buttonEn}
                        onChangeButtonEn={(e) => {
                          setButtonEn(e.target.value)
                        }}
                        buttonFr={buttonFr}
                        onChangeButtonFr={(e) => {
                          setButtonFr(e.target.value)
                        }}
                        buttonSp={buttonSp}
                        onChangeButtonSp={(e) => {
                          setButtonSp(e.target.value)
                        }}
                        onChangeButtonColor={(color) => {
                          setButtonTextColor(color)
                        }}
                        buttonColor={buttonTextColor}
                        visibleButtonTextEdit={visibleButtonTextEdit}
                        setVisibleButtonTextEdit={setVisibleButtonTextEdit}
                        lang={fieldsLang}
                        organization={organization}
                        withoutSelect={true}
                      />
                    </div>
                  </div>
                  <LocationsCouponPopover
                    onlyBackground
                    color={buttonColor}
                    setColor={setButtonColor}
                    visibleEdit={visibleButtonEdit}
                    setVisibleEdit={setVisibleButtonEdit}
                  />
                </div>
              </div>
              <MatRow>
                <InputFormText
                  name='termsConditionsLink'
                  label={getText('WORD_TERMS_LINK')}
                  required
                />
              </MatRow>
              <MatRow>
                <MatBlockWithLabel
                  label={getText('WORD_WIDGET_CODE')}
                  className={'create-coupon-textarea-with-clipboard'}
                >
                  <div className='create-coupon-textarea-container'>
                    <InputTextarea
                      className='create-coupon-textarea-autosize'
                      value={getCode()}
                      disabled={true}
                      placeholder=''
                      minRows={10}
                      maxRows={50}
                    />
                    <CopyToClipboard text={getCode()} onCopy={() => setCopied(true)}>
                      <MatButton
                        htmlType='button'
                        className='copy-button'
                        size='small'
                        buttonText={
                          copied ? getText('WORD_COPIED') : getText('WORD_COPY')
                        }
                      />
                    </CopyToClipboard>
                  </div>
                </MatBlockWithLabel>
              </MatRow>
              <UrlConditionManager urls={urls} setUrls={setUrls} />
            </MatForm>
            <br />
          </div>
        </div>
        <div className='right-content'>
          <div className='matador-connect-preview-default'>
            <PopupPreview
              uploadFile={uploadFile}
              titleEn={titleEn}
              titleFr={titleFr}
              titleSp={titleSp}
              titleColor={titleColor}
              headerEn={headerEn}
              headerFr={headerFr}
              headerSp={headerSp}
              headerColor={headerColor}
              descriptionEn={descriptionEn}
              descriptionFr={descriptionFr}
              descriptionSp={descriptionSp}
              descriptionColor={descriptionColor}
              buttonTextColor={buttonTextColor}
              termsLink={termsConditionsLink}
              buttonColor={buttonColor}
              buttonEn={buttonEn}
              buttonFr={buttonFr}
              buttonSp={buttonSp}
              defaultImageUrl={defaultImageUrl}
              backgroundColor={backgroundColor}
              fieldsLang={fieldsLang}
            />
          </div>
        </div>
      </div>
    </MatadorConnectLayout>
  ) : (
    <LoadingSpinner />
  )
}

export default LocationsExitIntent
