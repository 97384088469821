import React from 'react'
import { Form } from 'antd'
import './MatForm.scss'

const MatForm = ({
  form,
  size = 'middle',
  name = 'formSubmit',
  initialValues,
  onFinish,
  onFinishFailed,
  validateTrigger,
  className,
  onValuesChange,
  children,
}) => {
  return (
    <Form
      form={form}
      size={size}
      name={name}
      initialValues={initialValues || {}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError
      validateTrigger={validateTrigger || ['onBlur']}
      layout='vertical'
      className={`mat-form ${className || ''}`}
      onValuesChange={onValuesChange}
    >
      {children}
    </Form>
  )
}

export default MatForm
