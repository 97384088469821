import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import { isMobileAppSelector } from '../../store/selectors/selectors'
import authActions from '../../store/modules/authActions'
import MainSideBar from '../MainSideBar'
import MainFooter from '../MainFooter/MainFooter'
import MainHeader from '../MainHeader/MainHeader'
import { LayoutProvider } from '../LayoutProvider/LayoutProvider'
import './MainLayout.scss'

const { Content } = Layout

const MainLayout = ({ children, nofooter, isExtension, containerStyle }) => {
  const isMobileApp = useSelector(isMobileAppSelector)

  const locationPath = useLocation()

  useEffect(() => {
    if (authActions.getAppTheme()) {
      document.body.setAttribute('data-theme', authActions.getAppTheme())
    }
  }, [locationPath.pathname])

  return (
    <LayoutProvider>
      <Layout
        hasSider
        className={`mat_main_layout ${
          isExtension ? 'mat_layout_content_for_extension' : ''
        }`}
      >
        {!isExtension && !isMobileApp && <MainSideBar />}
        <Layout className='mat_layout' style={containerStyle}>
          <MainHeader isExtension={isExtension} />
          <Content
            className={`mat_layout_content ${
              isMobileApp ? 'mat_mobile_layout_content' : ''
            }`}
          >
            {children}
          </Content>
          {!nofooter && <MainFooter />}
        </Layout>
      </Layout>
    </LayoutProvider>
  )
}

export default memo(MainLayout)
