import React from 'react'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'

const MyDefaultBlockHeaderAdditionalButton = ({ onClick, buttonText }) => {
  return (
    <MyDefaultButton
      className={'my_default_block_header_additional_button'}
      typeButton={'white'}
      buttonText={buttonText}
      onClick={(e) => {
        e.preventDefault()
        onClick && onClick()
      }}
    />
  )
}

export default MyDefaultBlockHeaderAdditionalButton
