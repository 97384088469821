import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_12911_440729)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_12911_440729)'>
          <circle cx='18' cy='17' r='13' fill='#3B48E6' />
        </g>
        <path
          d='M16.1067 17.2466C16.04 17.24 15.96 17.24 15.8867 17.2466C14.3 17.1933 13.04 15.8933 13.04 14.2933C13.04 12.66 14.36 11.3333 16 11.3333C17.6334 11.3333 18.96 12.66 18.96 14.2933C18.9534 15.8933 17.6934 17.1933 16.1067 17.2466Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.9402 12.6667C22.2335 12.6667 23.2735 13.7134 23.2735 15C23.2735 16.26 22.2735 17.2867 21.0268 17.3334C20.9735 17.3267 20.9135 17.3267 20.8535 17.3334'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.7735 19.7067C11.1601 20.7867 11.1601 22.5467 12.7735 23.62C14.6068 24.8467 17.6135 24.8467 19.4468 23.62C21.0601 22.54 21.0601 20.78 19.4468 19.7067C17.6201 18.4867 14.6135 18.4867 12.7735 19.7067Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.2266 23.3333C22.7066 23.2333 23.1599 23.04 23.5332 22.7533C24.5732 21.9733 24.5732 20.6866 23.5332 19.9066C23.1666 19.6266 22.7199 19.44 22.2466 19.3333'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_12911_440729)'
      />
      <defs>
        <filter
          id='filter0_f_12911_440729'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_12911_440729'
          />
        </filter>
        <linearGradient
          id='paint0_linear_12911_440729'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_12911_440729'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashInfluencedWalkInsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashInfluencedWalkInsIcon
