import React, { useEffect, useState } from 'react'
import { Flex } from 'antd'
import { getText, getTextServerError } from '../../../../lang'
import tagsActions from '../../../../store/modules/tagsActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import SVGMyDefaultDeleteIcon from '../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import Div from '../../../../components/Div/Div'
import SVGTagIcon from '../../../../icons/SVG/SVGTagIcon'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import './TagItem.scss'

const listColor = [
  '#47A4FE',
  '#9676E5',
  '#CE36D3',
  '#FF5B72',
  '#F8A326',
  '#FFE120',
  '#87CF48',
  '#46BFBE',
  '#9AAAB3',
]

const TagItem = (props) => {
  const { field, form, onDelete, onInitialValuesUpdate } = props

  const [isEdit, setIsEdit] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const item = (form.getFieldValue('tags_items') &&
    form.getFieldValue('tags_items')[field.name]) || { color: '#47A4FE' }

  useEffect(() => {
    if (item.name === '') {
      setIsEdit(true)
    }
  }, [item])

  const handleEditTag = async () => {
    setIsEdit(false)

    let resultTag
    let isNew = false

    if (!item.name) {
      form.resetFields()
      return
    }

    const obj = {
      name: item.name,
      color: item.color,
    }

    if (!item.tmpId) {
      resultTag = await tagsActions.saveUpdate(obj, item._id)
    } else {
      resultTag = await tagsActions.saveNew(obj)
      isNew = true
      if (resultTag.success) {
        let list = form.getFieldsValue().tags_items
        const index = list.findIndex((tag) => tag.tmpId === item.tmpId)
        list[index] = resultTag.data
        form.setFieldsValue({ tags_items: list })
      }
    }

    if (resultTag.success) {
      notifySuccess(getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY'))
      onInitialValuesUpdate && onInitialValuesUpdate(isNew)
    } else {
      form.resetFields()
      notifyError(getTextServerError(resultTag.errMsg, { organization: true }))
    }
    setInputValue('')
  }

  return (
    <Flex align='center' justify='space-between' gap={4} className='tag_item'>
      <Flex align='center' className='tag_item--left' gap={10}>
        <Div
          className={'tag_item--left-pin'}
          style={{
            backgroundColor: item.color,
          }}
        />
        <SVGTagIcon color={item.color} />
        {isEdit ? (
          <MyDefaultInputText
            isForm
            isLast
            color={'white'}
            name={[field.name, 'name']}
            allowClear={false}
            autoFocus={true}
            maxLength={30}
            placeholder={getText('WORD_TYPE_HERE')}
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            onPressEnter={() => {
              handleEditTag()
            }}
            onBlur={() => {
              handleEditTag()
            }}
            onFocus={() => {
              setInputValue(item.name)
            }}
          />
        ) : (
          <span onClick={() => setIsEdit(true)}>
            {inputValue || (item && item.name) || getText('WORD_TAG_NAME_HERE')}
          </span>
        )}
      </Flex>
      <Flex align='center' gap={12} className='tag_item--right'>
        <Flex gap={6} className='tag_item--right--colors'>
          {listColor.map((col, index) => {
            return (
              <Div
                key={index}
                className={`tag_item--right--colors-item ${
                  item.color === col ? 'selected' : ''
                }`}
                style={{ backgroundColor: col }}
                onClick={() => {
                  let list = form.getFieldsValue().tags_items
                  const index = list.findIndex((tag) => {
                    if (item._id) {
                      return tag._id === item._id
                    } else {
                      return tag._tmpId === item.tmpId
                    }
                  })
                  if (index > -1) {
                    list[index].color = col
                    form.setFieldsValue({ tags_items: list })
                    handleEditTag()
                  }
                }}
              />
            )
          })}
        </Flex>
        <SVGMyDefaultDeleteIcon onClick={() => onDelete && onDelete(item)} />
      </Flex>
    </Flex>
  )
}

export default TagItem
