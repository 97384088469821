import React from 'react'
import { getText } from '../../../lang'
import { formatAmount } from '../../../utils'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import MatTable from '../../../components/MatTable'

const DepositDetailsListItemDepositSummary = ({ item }) => {
  const depositSummary = (item?.summary || []).map((item) => {
    return {
      key: item.category,
      ...item,
    }
  })

  const scheduledDepositSummaryColumn = [
    {
      key: 'empty',
      title: '',
      render: (_, { category }) => {
        return category ? category.charAt(0).toUpperCase() + category.slice(1) : '--'
      },
    },
    {
      key: 'count',
      title: getText('WORD_COUNT'),
      sorter: (a, b) => a.count - b.count,
      render: (_, { count }) => {
        return <span>{count}</span>
      },
    },
    {
      key: 'gross',
      title: getText('WORD_GROSS'),
      sorter: (a, b) => a.gross - b.gross,
      render: (_, { gross }) => {
        return <span className='payment-amount'>{formatAmount(gross)}</span>
      },
    },
    {
      key: 'fees',
      title: getText('WORD_FEE'),
      sorter: (a, b) => a.fees - b.fees,
      render: (_, { fees }) => {
        return formatAmount(fees)
      },
    },
    {
      key: 'total',
      title: getText('WORD_NET'),
      sorter: (a, b) => a.total - b.total,
      render: (_, { total }) => {
        return formatAmount(total)
      },
    },
  ]

  return (
    <div className='deposit-details-top-info'>
      <MatTable
        columns={scheduledDepositSummaryColumn}
        dataSource={depositSummary}
        // Weird bug where the table keeps growing in width forever when there are no rows
        tableLayout={depositSummary.length > 0 ? 'auto' : 'fixed'}
        loading={false}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={0}
        paginationTotalCount={0}
        onPaginationChange={12}
        withoutPagination
        title={() => getText('WORD_DEPOSIT_SUMMARY')}
        scroll={{
          x: 479,
        }}
      />
    </div>
  )
}

export default DepositDetailsListItemDepositSummary
