import React from 'react'
import { Flex } from 'antd'
import './MyDefaultRow.scss'

const MyDefaultRow = ({ leftSide, rightSide, gap, fitContent, width }) => {
  return (
    <Flex
      align='center'
      gap={gap}
      style={{ width: fitContent ? 'fit-content' : width }}
      justify='space-between'
      className='my_default_row'
    >
      <span>{leftSide}</span>
      <span>{rightSide}</span>
    </Flex>
  )
}

export default MyDefaultRow
