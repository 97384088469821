import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '14'}
      height={props.height || '14'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.41797 1.75H7.58464C10.4841 1.75 12.8346 4.10051 12.8346 7C12.8346 9.8995 10.4841 12.25 7.58464 12.25H3.5013C2.21264 12.25 1.16797 11.2053 1.16797 9.91667V7C1.16797 4.10051 3.51847 1.75 6.41797 1.75ZM7.0013 7.58333C7.32347 7.58333 7.58464 7.32217 7.58464 7C7.58464 6.67783 7.32347 6.41667 7.0013 6.41667C6.67914 6.41667 6.41797 6.67783 6.41797 7C6.41797 7.32217 6.67914 7.58333 7.0013 7.58333ZM9.91797 7C9.91797 7.32217 9.6568 7.58333 9.33463 7.58333C9.01247 7.58333 8.7513 7.32217 8.7513 7C8.7513 6.67783 9.01247 6.41667 9.33463 6.41667C9.6568 6.41667 9.91797 6.67783 9.91797 7ZM4.66797 7.58333C4.99013 7.58333 5.2513 7.32217 5.2513 7C5.2513 6.67783 4.99013 6.41667 4.66797 6.41667C4.3458 6.41667 4.08464 6.67783 4.08464 7C4.08464 7.32217 4.3458 7.58333 4.66797 7.58333Z'
        fill='#48AF80'
      />
    </svg>
  )
}

const SVGAiBotSmsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAiBotSmsIcon
