import React from 'react'
import { Divider, Flex, Form } from 'antd'
import { getText } from '../../../lang'
import SVGMyDefaultDeleteIcon from '../../../icons/SVG/SVGMyDefaultDeleteIcon'
import MyDefaultSelect from '../../MyDefaultSelect/MyDefaultSelect'
import MyDefaultCustomTimePicker from '../MyDefaultCustomTimePicker/MyDefaultCustomTimePicker'
import MyDefaultDatePicker from '../../MyDefaultDatePicker/MyDefaultDatePicker'
import utilDate from '../../../utils/utilsDate'
import MyDefaultBlockSubtitle from '../../MyDefaultBlock/MyDefaultBlockSubtitle'
import ConfirmPopup from '../../ConfirmPopup'

const MyDefaultOrganizationCustomHours = ({
  field,
  form,
  index,
  formListName,
  disabled,
  onDelete,
}) => {
  const item = Form.useWatch([formListName, field.name], form)

  const [fromTime, fromPeriod] = item?.from?.split(' ') || ''
  const [toTime, toPeriod] = item?.to?.split(' ') || ''

  const [hoursFrom, minutesFrom] = fromTime?.split(':') || ''
  const [hoursTo, minutesTo] = toTime?.split(':') || ''

  if (item) {
    form.setFieldValue(
      [formListName, field.name, 'date'],
      item?.date ? utilDate.getDateByDayJS(item?.date) : utilDate.getDateByDayJS()
    )
  }

  if (!item) return null

  return (
    <Flex vertical>
      <MyDefaultBlockSubtitle
        subtitle={
          <Flex align='center' gap={12}>
            <MyDefaultDatePicker
              isForm
              name={[field.name, 'date']}
              isLast
              style={{ maxWidth: 166 }}
            />
            <ConfirmPopup
              title={getText('MSG_DELETE_CUSTOM_OPENING_HOURS')}
              placement={'top'}
              onConfirm={(e) => {
                e.stopPropagation()
                onDelete(item)
              }}
              okText={getText('WORD_YES')}
              cancelText={getText('WORD_NO')}
              trigger={<SVGMyDefaultDeleteIcon />}
            />
          </Flex>
        }
      />

      <Flex align='center' gap={12}>
        <MyDefaultSelect
          selectStyle={{ minWidth: 166 }}
          disabled={disabled}
          className={''}
          name={[field.name, 'isOpen']}
          options={[
            {
              label: getText('TEXT_STORE_IS_OPEN'),
              value: true,
            },
            {
              label: getText('TEXT_STORE_IS_CLOSED'),
              value: false,
            },
          ]}
          showSearch={false}
          isForm
          isLast
        />
        {Boolean(item?.isOpen) && (
          <Flex align='center' gap={8}>
            <MyDefaultCustomTimePicker
              hourValueInit={hoursFrom}
              minuteValueInit={minutesFrom}
              systemHourInit={fromPeriod}
              onChange={(val) => {
                form.setFieldValue([formListName, field.name, 'from'], val)
              }}
              index={`${index}0`}
              disabled={disabled}
            />
            <Divider className='my_default_opening_hours_divider' />
            <MyDefaultCustomTimePicker
              hourValueInit={hoursTo}
              minuteValueInit={minutesTo}
              systemHourInit={toPeriod}
              onChange={(val) => {
                form.setFieldValue([formListName, field.name, 'to'], val)
              }}
              index={`${index}1`}
              disabled={disabled}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default MyDefaultOrganizationCustomHours
