import useWindowSize from './useWindowSize'

export default function useDeviceCheck() {
  const windowSize = useWindowSize()

  return {
    isDesktop: windowSize.isDesktop,
    isMobile: windowSize.isMobile,
    isSmallDesktop: windowSize.isSmallDesktop,
    isTabletPortrait: windowSize.isTabletPortrait,
    isPageDrawerSize:
      windowSize.isMobile ||
      windowSize.isTablet ||
      windowSize.isTabletPortrait ||
      windowSize.isTabletLandscape,
    isMobileOrTabletOrTabletPortrait:
      windowSize.isMobile || windowSize.isTablet || windowSize.isTabletPortrait,
    isTabletPortraitOrMobile: windowSize.isTabletPortrait || windowSize.isMobile,
  }
}
