import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import greetingsActions from '../../../../../store/modules/greetingsActions'
import { getText, getTextServerError } from '../../../../../lang'
import { CANCEL_BTN, TABLE_DEFAULT_ROW_NUMBER } from '../../../../../devOptions'
import MatList from '../../../../../components/MatList'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import MatadorConnectLayout from '../matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnectComponents/MatadorConnectListTitle'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import MatadorConnectListItem from '../matadorConnectComponents/MatadorConnectListItem'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import './Greetings.scss'

const Greetings = () => {
  const { _id } = useParams()
  const navigate = useNavigate()
  const { setFooterButtons } = useLayout()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [greetingsData, setGreetingsData] = useState({
    list: [],
    total: 0,
  })

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
    ])
  }, [])

  useEffect(() => {
    setIsLoading(true)
    getGreetings()
  }, [currentPage])

  const getGreetings = async () => {
    const options = { _location_id: _id }
    const result = await greetingsActions.getPageList(
      currentPage,
      TABLE_DEFAULT_ROW_NUMBER,
      '',
      options
    )
    if (result.success) {
      setGreetingsData({
        list: result.data,
        total: result.max,
      })
    }
    setIsLoading(false)
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  const handleGreetingsSwitch = async (checked, item) => {
    const param = {
      enabled: checked,
    }
    const result = await greetingsActions.updatePartial(param, item._id)
    if (result.success) {
      notifySuccess(getText('SUCCESS_GREETING_UPDATE'))
      await getGreetings()
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleSetDefault = async (item) => {
    const param = {
      isDefault: true,
    }
    const result = await greetingsActions.setAsDefault(param, item._id)
    if (result.success) {
      notifySuccess(getText('SUCCESS_GREETING_SET_DEFAULT'))
      await getGreetings()
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleChangeGreeting = (val, item) => {
    if (val === 'edit') {
      navigate(`/location/settings/${_id}/livechat-greetings/edit`, {
        state: { greetingData: item },
      })
    } else if (val === 'delete') {
      Modal.confirm({
        title: getText('CONFIRMATION_DELETE_GREETING'),
        async onOk() {
          const result = await greetingsActions.delete(item._id)
          if (result.success) {
            notifySuccess(getText('SUCCESS_GREETING_DELETE'))
            await getGreetings()
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        },
        okText: getText('WORD_YES'),
        cancelText: getText('ACTION_CANCEL'),
        okButtonProps: {
          type: 'primary',
          className: 'mat-btn mat-btn-primary',
        },
        cancelButtonProps: {
          type: 'cancel',
          className: 'mat-btn',
        },
      })
    }
  }

  return (
    <MatadorConnectLayout className='livechat-greetings-wrapper'>
      <MatadorConnectListTitle
        title={getText('WORD_GREETINGS')}
        buttonTitle={'+ ' + getText('ACTION_ADD_GREETING')}
        url={`/location/settings/${_id}/livechat-greetings/add`}
      />
      <MatList
        loading={isLoading}
        data={greetingsData.list}
        pagination
        paginationCurrentPage={currentPage}
        paginationTotalCount={greetingsData.total}
        onPaginationChange={setCurrentPage}
        onPageChange={(pageNumber, totalItems) => {
          setCurrentPage(pageNumber)
        }}
        paginationRange={5}
        totalItems={greetingsData.total}
        containerClassName='matador-connect-mat-list-wrapper'
        header={
          <div className='matador-connect-mat-list-header'>
            <div className='matador-connect-mat-list-header-item'>
              {getText('WORD_TITLE')}
            </div>
            <div className='matador-connect-mat-list-header-item'>
              {getText('WORD_DATE')}
            </div>
            <div className='matador-connect-mat-list-header-item' />
          </div>
        }
        renderItem={(greetingsData, index) => (
          <MatadorConnectListItem
            key={greetingsData.id}
            style={{
              backgroundColor: index % 2 === 0 ? '#F8F9F9' : '#ffffff',
            }}
            item={greetingsData}
            handleSwitch={handleGreetingsSwitch}
            hasSwitch
            greetingDefault
            handleSetDefault={handleSetDefault}
            handleEdit={handleChangeGreeting}
          />
        )}
      />
    </MatadorConnectLayout>
  )
}

export default Greetings
