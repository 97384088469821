import React from 'react'
import Div from '../Div/Div'

const MyDefaultBlockTitle = ({ title, additionalHeader, disableMargin }) => {
  return (
    <Div
      className={'my_default_block_title'}
      style={{ marginBottom: disableMargin ? 0 : 20 }}
    >
      {title}
      {additionalHeader && additionalHeader}
    </Div>
  )
}

export default MyDefaultBlockTitle
