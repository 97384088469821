import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20.973'
    height='15.584'
    viewBox='0 0 20.973 15.584'
  >
    <g id='menu-icon' transform='translate(-0.001 0)'>
      <path
        id='Path_2561'
        data-name='Path 2561'
        d='M50.66,30.02H69.113a1.252,1.252,0,0,0,1.259-1.259h0a1.252,1.252,0,0,0-1.259-1.26H50.66A1.252,1.252,0,0,0,49.4,28.759h0a1.24,1.24,0,0,0,1.259,1.26Z'
        transform='translate(-49.4 -27.5)'
        fill={props.color}
      />
      <path
        id='Path_2562'
        data-name='Path 2562'
        d='M50.66,58.22H59.6a1.252,1.252,0,0,0,1.259-1.259h0A1.252,1.252,0,0,0,59.6,55.7H50.66A1.252,1.252,0,0,0,49.4,56.96h0A1.24,1.24,0,0,0,50.66,58.22Z'
        transform='translate(-39.882 -49.122)'
        fill={props.color}
      />
      <path
        id='Path_2563'
        data-name='Path 2563'
        d='M50.638,86.02h13.6A1.252,1.252,0,0,0,65.5,84.76h0A1.252,1.252,0,0,0,64.238,83.5H50.66A1.252,1.252,0,0,0,49.4,84.76h0a1.221,1.221,0,0,0,1.237,1.259Z'
        transform='translate(-44.524 -70.436)'
        fill={props.color}
      />
    </g>
  </svg>
)

const SVGDrawerOpenIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDrawerOpenIcon
