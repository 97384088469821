import React, { memo, useRef, useState } from 'react'
import { Col, Divider, Flex, Row, Typography } from 'antd'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { getText } from '../../../../lang'
import Div from '../../../../components/MatDiv/Div'
import utilNumber from '../../../../utils/utilNumber'
import { MONTH_MAP } from '../../../../utils'
import './DashboardStatistics.scss'

const Circle = ({ color }) => {
  return (
    <span
      style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: color }}
    />
  )
}

const DashboardMultiStatistics = ({
  leftNumber,
  rightNumber,
  data,
  leftIcon,
  rightIcon,
  leftTitle,
  rightTitle,
  className,
  responsiveChartHeight,
}) => {
  const tooltipRef = useRef(null)
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })
  const [isLastPoint, setIsLastPoint] = useState(false)

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Div ref={tooltipRef} className='chart_custom_tooltip'>
          <Div className='chart_custom_tooltip--content'>
            <Div className='chart_custom_tooltip--inner'>
              <p className='title'>{getText('WORD_CURRENT_MONTH')}</p>
              {payload.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <p
                      className='intro'
                      style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                    >
                      <Circle color={item.color} /> {item.value}
                    </p>
                  </React.Fragment>
                )
              })}
              <p className='desc label'>
                {label.length === 3 ? MONTH_MAP[label] : label}
              </p>
            </Div>
          </Div>
        </Div>
      )
    }
    return null
  }

  return (
    <Div
      className={`dashboard_sent_statistics dashboard_sent_statistics_multiple ${className || ''}`}
    >
      <Div className={'dashboard_sent_statistics-bg purple'}></Div>
      <Row className='dashboard_sent_statistics_headers'>
        <Col flex={2}>
          <Flex gap={8} align='center' className='dashboard_sent_statistics--header'>
            {leftIcon}
            <span>{leftTitle}</span>
          </Flex>
          <Flex align='center' className='dashboard_sent_statistics--information'>
            <Typography level={1}>
              {utilNumber.numberFractions(leftNumber)}
            </Typography>
          </Flex>
        </Col>
        <Divider
          type='vertical'
          style={{
            borderInlineStart: '1px solid #38334D',
          }}
        />
        <Col flex={2}>
          <Flex gap={8} align='center' className='dashboard_sent_statistics--header'>
            {rightIcon}
            <span>{rightTitle}</span>
          </Flex>
          <Flex align='center' className='dashboard_sent_statistics--information'>
            <Typography level={1}>
              {utilNumber.numberFractions(rightNumber)}
            </Typography>
          </Flex>
        </Col>
      </Row>
      <ResponsiveContainer height={responsiveChartHeight}>
        <Flex className='dashboard_sent_statistics-chart-info' justify='flex-end'>
          {getText('TEXT_LAST_SIX_MONTH')}
        </Flex>
        <LineChart
          data={data}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
          onMouseMove={(e) => {
            if (e?.activeCoordinate) {
              const isLast = e.activeLabel === data[data.length - 1].name
              setIsLastPoint(isLast)
              setTooltipPosition({
                x: e?.activeCoordinate.x,
                y: e?.activeCoordinate.y,
              })
            }
          }}
        >
          <XAxis dataKey='name' tickLine={false} />
          <YAxis tickLine={false} />
          <CartesianGrid strokeDasharray='' opacity={0.2} vertical={false} />
          <Tooltip
            content={<CustomTooltip />}
            position={{
              x: tooltipPosition.x - (isLastPoint ? 100 : 50),
              y: tooltipPosition.y - 100,
            }}
          />
          <Legend iconSize={8} iconType='circle' />
          <Line
            name={getText('WORD_MISSED_CALLS_SAVED')}
            type='monotone'
            dataKey='calls_count'
            stroke='#818BFF'
            fillOpacity={1}
            strokeWidth={2}
            dot={false}
          />
          <Line
            name={getText('WORD_SMS_CONVERSATIONS_STARTED')}
            type='monotone'
            dataKey='conversation_started_count'
            stroke='#48af80'
            fillOpacity={1}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Div>
  )
}

export default memo(DashboardMultiStatistics)
