import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.00944 0.333008C3.32744 0.333008 0.342773 3.31767 0.342773 6.99967C0.342773 10.6817 3.32744 13.6663 7.00944 13.6663C10.6914 13.6663 13.6761 10.6817 13.6761 6.99967C13.6761 3.31767 10.6914 0.333008 7.00944 0.333008ZM7.00944 3.66634C7.37744 3.66634 7.67611 3.96501 7.67611 4.33301V7.66634C7.67611 8.03434 7.37744 8.33301 7.00944 8.33301C6.64144 8.33301 6.34277 8.03434 6.34277 7.66634V4.33301C6.34277 3.96501 6.64144 3.66634 7.00944 3.66634ZM7.00944 8.99967C7.37744 8.99967 7.67611 9.29834 7.67611 9.66634C7.67611 10.0343 7.37744 10.333 7.00944 10.333C6.64144 10.333 6.34277 10.0343 6.34277 9.66634C6.34277 9.29834 6.64144 8.99967 7.00944 8.99967Z'
      fill={props.color || '#E05D4C'}
    />
  </svg>
)

const SVGCustomerRefusedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGCustomerRefusedIcon
