import React from 'react'
import Title from 'antd/es/typography/Title'
import { getText } from '../../lang'
import SVGEmptyIcon from '../../icons/SVG/SVGEmptyIcon'
import Div from '../Div/Div'
import MyDefaultBlock from '../MyDefaultBlock/MyDefaultBlock'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'
import './MyDefaultEmpty.scss'

const MyDefaultEmpty = ({
  title = getText('TEXT_NO_RESULTS_FOUND'),
  buttonText,
  onClick,
}) => {
  return (
    <Div className={'my_default_empty'}>
      <MyDefaultBlock>
        <SVGEmptyIcon />
        {title && <Title level={2}>{title}</Title>}
        {buttonText && <MyDefaultButton buttonText={buttonText} onClick={onClick} />}
      </MyDefaultBlock>
    </Div>
  )
}

export default MyDefaultEmpty
