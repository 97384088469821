import React, {
  forwardRef,
  memo,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
} from 'react'
import { Badge, Drawer } from 'antd'
import { useSelector } from 'react-redux'
import userActions from '../../../store/modules/userActions'
import {
  hasManyOrganizationsSelector,
  userSelector,
} from '../../../store/selectors/selectors'
import { getText } from '../../../lang'
import SVGChatClose from '../../../icons/SVG/SVGChatClose'
import SVGHamburgerFilterIcon from '../../../icons/SVG/SVGHamburgerFilterIcon'
import { CUSTOM } from '../../../devOptions'
import MatSwitchSecondary from '../../../components/MatSwitch/MatSwitchSecondary'
import MatButton from '../../../components/MatButton'
import TouchpointsFilter from './TouchpointsFilter'
import TagsFilter from './TagsFilter'
import AssignUserFilter from './AssignUserFilter'
import DateFilter from './DateFilter'
import BroadcastsFilter from './BroadcastsFilter'
import LocationFilter from './LocationFilter'
import NpsScoreFilter from './NpsScoreFilter'
import './MessageFilters.scss'

const LeftFillterDrawer = forwardRef(
  ({ countOfFilter, filterToggleStatuses, onChangeFilterToggleStatuses }, ref) => {
    const user = useSelector(userSelector)
    const organization = user?.organization
    const isSupportAgent = user?.isSupportAgent
    const hasManyOrganizations = useSelector(hasManyOrganizationsSelector)

    const [visibleLeftDrawer, setvisibleLeftDrawer] = useState(false)

    const showDrawer = () => setvisibleLeftDrawer(true)
    const closeDrawer = () => setvisibleLeftDrawer(false)

    const selectSources = useRef()
    const selectTags = useRef()
    const selectAssignedUser = useRef()
    const selectDate = useRef()
    const selectBroadcast = useRef()
    const selectLocation = useRef()
    const selectNpsScore = useRef()

    useImperativeHandle(ref, () => ({
      resetFilters() {
        clearAll()
      },
      resetSuperhumanFilter() {
        selectDate.current &&
          selectDate.current.resetDate &&
          selectDate.current.resetDate()
        selectBroadcast.current && selectBroadcast.current.resetBroadcast()
        onChangeFilterToggleStatuses({
          ...filterToggleStatuses,
          dateFilter: [],
          opportunities: false,
          fromBroadcastPage: false,
          campaign_responds: false,
          dashboardClickableStats: false,
          filterOutgoingCalls: false,
        })
      },
    }))

    const clearAll = () => {
      selectSources.current &&
        selectSources.current.resetSource &&
        selectSources.current.resetSource()
      selectTags.current &&
        selectTags.current.resetTags &&
        selectTags.current.resetTags()
      selectNpsScore.current &&
        selectNpsScore.current.resetNpsScore &&
        selectNpsScore.current.resetNpsScore()
      selectAssignedUser.current &&
        selectAssignedUser.current.resetAssignedUser &&
        selectAssignedUser.current.resetAssignedUser()
      selectDate.current &&
        selectDate.current.resetDate &&
        selectDate.current.resetDate()
      selectBroadcast &&
        selectBroadcast.current &&
        selectBroadcast.current.resetBroadcast()
      selectLocation.current &&
        selectLocation.current.resetLocation &&
        selectLocation.current.resetLocation()
      onChangeFilterToggleStatuses &&
        onChangeFilterToggleStatuses({
          fingerprinting: false,
          superhuman: false,
          incomingCalls: false,
          opportunities: false,
          touchpoints: [],
          users: [],
          dateFilter: [],
          tagsFilter: [],
          locationFilter: [],
          npsScoreFilter: '',
          broadcastFilter: '',
          fromBroadcastPage: false,
          campaign_responds: false,
          dashboardClickableStats: false,
          filterOutgoingCalls: false,
        })
    }

    const actionsOfFilterState = (key, value) => {
      onChangeFilterToggleStatuses &&
        onChangeFilterToggleStatuses({
          ...filterToggleStatuses,
          dashboardClickableStats:
            key === 'dateFilter' &&
            filterToggleStatuses.dashboardClickableStats.range
              ? { ...filterToggleStatuses.dashboardClickableStats, range: CUSTOM }
              : filterToggleStatuses.dashboardClickableStats,
          [key]: value,
        })
    }

    const FilterContent = useMemo(() => {
      return (
        <>
          <MatButton
            className='settings_button'
            onClick={showDrawer}
            icon={
              <Badge
                placement={'end'}
                count={countOfFilter}
                color={'var(--mainColor)'}
              >
                <SVGHamburgerFilterIcon
                  width={16}
                  height={16}
                  color={'var(--black)'}
                />
              </Badge>
            }
          />
          <Drawer
            placement={'left'}
            closable={false}
            onClose={closeDrawer}
            open={visibleLeftDrawer}
            width={285}
            rootClassName='message-filter-drawer'
            forceRender={filterToggleStatuses.broadcastFilter || false}
          >
            <div className='filter-drawer-header'>
              {getText('WORD_FILTER')}
              <div className='clear-all-button'>
                <MatButton
                  typeButton={'radius'}
                  buttonText={getText('WORD_CLEAR_ALL')}
                  style={{ width: '90px' }}
                  onClick={() => clearAll()}
                />
                <SVGChatClose color={'#A3A6BE'} stroke={2} onClick={closeDrawer} />
              </div>
            </div>
            <TouchpointsFilter
              onChangeSource={(list) => actionsOfFilterState('touchpoints', list)}
              ref={selectSources}
            />
            <AssignUserFilter
              onChangeUser={(list) => actionsOfFilterState('users', list)}
              ref={selectAssignedUser}
            />
            <DateFilter
              selectedDate={filterToggleStatuses.dateFilter}
              onChangeDate={(date) => actionsOfFilterState('dateFilter', date)}
              ref={selectDate}
            />
            <TagsFilter
              onChangeTags={(tags) => actionsOfFilterState('tagsFilter', tags)}
              ref={selectTags}
            />
            {!isSupportAgent && (
              <BroadcastsFilter
                selectedBroadcastItem={filterToggleStatuses.broadcastFilter}
                onChangeBroadcast={(val) =>
                  actionsOfFilterState('broadcastFilter', val)
                }
                ref={selectBroadcast}
              />
            )}
            {filterToggleStatuses.broadcastFilter._id && (
              <div className='dropdown-with-selected-item'>
                <MatSwitchSecondary
                  value={filterToggleStatuses.opportunities}
                  label={getText('WORD_OPPORTUNITIES')}
                  onChange={(value) => actionsOfFilterState('opportunities', value)}
                />
              </div>
            )}
            {(hasManyOrganizations ||
              userActions.getUserHasPermission(
                user,
                organization?.restrictions?.allowAccessToAllLocations
              )) && (
              <LocationFilter
                ref={selectLocation}
                mode={
                  filterToggleStatuses?.dashboardClickableStats?.locations
                    ? ''
                    : 'multiple'
                }
                locationFilter={filterToggleStatuses.locationFilter}
                onChangeLocation={(value) =>
                  actionsOfFilterState('locationFilter', value)
                }
              />
            )}
            <NpsScoreFilter
              onChangeNpsScore={(score) =>
                actionsOfFilterState('npsScoreFilter', score)
              }
              ref={selectNpsScore}
            />
            <div style={{ marginTop: 15 }}>
              {/* <MatSwitchSecondary
              value={filterToggleStatuses.fingerprinting}
              label={getText('FINGERPRINTING_AUTOMATION')}
              onChange={(value) => actionsOfFilterState('fingerprinting', value)}
            /> */}
              <MatSwitchSecondary
                value={filterToggleStatuses.superhuman}
                label={getText('WORD_SUPERHUMAN_MESSAGES')}
                onChange={(value) => actionsOfFilterState('superhuman', value)}
              />
              <MatSwitchSecondary
                value={filterToggleStatuses.incomingCalls}
                label={getText('TEXT_INCOMING_CALLS')}
                onChange={(value) => actionsOfFilterState('incomingCalls', value)}
              />
              <MatSwitchSecondary
                value={filterToggleStatuses.filterOutgoingCalls}
                label={getText('WORD_OUTGOING_CALLS')}
                onChange={(value) =>
                  actionsOfFilterState('filterOutgoingCalls', value)
                }
              />
            </div>
          </Drawer>
        </>
      )
    }, [visibleLeftDrawer, countOfFilter, filterToggleStatuses])

    return FilterContent
  }
)

export default memo(LeftFillterDrawer)
