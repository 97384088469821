import React, { memo, useContext } from 'react'
import { getText } from '../../../lang'
import MatSelect from '../../../components/MatSelect'
import { DashboardContext } from '../Dashboard'

const DashboardSelectRange = () => {
  const {
    selectedRange,
    setSelectCustomRange,
    setSelectedRange,
    paginationChangeRef,
  } = useContext(DashboardContext)
  let list_periods = [
    {
      label: getText('WORD_ALL_TIME'),
      value: 'all_time',
    },
    {
      label: getText('WORD_YESTERDAY'),
      value: 'ytd',
    },
    {
      label: getText('WORD_TODAY'),
      value: 'today',
    },
    {
      label: getText('WORD_MONTH_TO_DAY'),
      value: 'mtd',
    },
    {
      label: getText('WORD_LAST_MONTH'),
      value: 'last_month',
    },
    {
      label: getText('WORD_CUSTOM'),
      value: 'custom',
    },
  ]
  const selectedRangeLabel = list_periods.find((obj) => obj.value === selectedRange)

  return (
    <div className='dashboard-filter-select'>
      <MatSelect
        secondary
        value={selectedRangeLabel.label}
        options={list_periods}
        onSelect={(value) => {
          if (value !== 'custom') {
            setSelectCustomRange('')
          }
          setSelectedRange(value)
          paginationChangeRef?.current?.resetPage()
        }}
      />
    </div>
  )
}

export default memo(DashboardSelectRange)
