import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '14'}
      height={props.height || '14'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2005_12814)'>
        <path
          d='M12.635 0.875H1.365C1.09397 0.875 0.875 1.09397 0.875 1.365V12.635C0.875 12.906 1.09397 13.125 1.365 13.125H12.635C12.906 13.125 13.125 12.906 13.125 12.635V1.365C13.125 1.09397 12.906 0.875 12.635 0.875ZM11.2201 4.45047H10.2417C9.4745 4.45047 9.32597 4.81491 9.32597 5.35084V6.53144H11.1573L10.9185 8.37966H9.32597V13.125H7.4165V8.38119H5.81941V6.53144H7.4165V5.16862C7.4165 3.58684 8.38272 2.72475 9.79453 2.72475C10.4713 2.72475 11.0517 2.77528 11.2217 2.79825V4.45047H11.2201Z'
          fill={props.color || '#0866FF'}
        />
      </g>
      <defs>
        <clipPath id='clip0_2005_12814'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGAiBotFacebooklcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGAiBotFacebooklcon
