import React, { memo, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import organizationActions from '../../store/modules/organizationActions'
import {
  isMobileAppSelector,
  loadingOrgSelector,
  organizationSelector,
} from '../../store/selectors/selectors'
import authActions from '../../store/modules/authActions'
import { getText } from '../../lang'
import SVGOrganization from '../../icons/SVG/SVGOrganization'
import MatSelect from '../MatSelect'

const organizationFilters = {
  filters: { enabled: true },
}
const PER_PAGE = 50
const SelectOrganization = memo((props) => {
  const organization = useSelector(organizationSelector)
  const isMobileApp = useSelector(isMobileAppSelector)
  const organizationLoading = useSelector(loadingOrgSelector)

  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [organizations, setOrganizations] = useState([])
  const [totalOrg, setTotalOrg] = useState(0)
  const [open, setOpen] = useState(false)

  const fill = async () => {
    setIsLoading(true)
    const result = await organizationActions.getPageList(
      page,
      PER_PAGE,
      searchValue,
      organizationFilters
    )
    if (result.success) {
      const organizationsList = result.data.map((org) => ({
        value: org._id,
        label: org.name,
        ...org,
      }))

      const newListOrganizations =
        page > 0 ? [...organizations, ...organizationsList] : organizationsList
      setOrganizations(newListOrganizations)
      setTotalOrg(result.max)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (open) {
      fill()
    }
  }, [page, searchValue, open])

  const handleSearchOrganization = useCallback(
    (searchValue) => {
      setSearchValue(searchValue)
      setPage(0)
    },
    [searchValue]
  )

  return (
    <MatSelect
      primary={props.primary}
      dropdownClassName={props.dropdownClassName}
      mainSelectClassName={props.mainSelectClassName}
      dropdownStyle={props.dropdownStyle}
      icon={<SVGOrganization />}
      showSearch={props.showSearch}
      options={organizations}
      loading={isLoading}
      onSearch={handleSearchOrganization}
      onLoadMore={() => {
        if (organizations.length < totalOrg && !isLoading) {
          setPage(page + 1)
        }
      }}
      open={props.disableOpen ? false : undefined}
      disableSuffix={props.disableSuffix}
      onSelectItem={async (organization) => {
        if (isMobileApp) {
          authActions.setLoadingOrganization(true)
        }
        localStorage.removeItem('selectedConversation')
        await authActions.setOrganization(organization)
        setSearchValue('')
        props.onChangeOrganization && props.onChangeOrganization(organization)
      }}
      value={organization ? organization.name : getText('WORD_SELECT_ORGANIZATION')}
      onDropdownVisibleChange={(e) => {
        if (!props.disableOpen) {
          setOpen(e)
          props.onDropdownVisibleChange && props.onDropdownVisibleChange(e)
        }
      }}
      disabled={organizationLoading}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onClick={props.onClick}
    />
  )
})

export default SelectOrganization
