import React from 'react'
import { Form } from 'antd'
import './MyDefaultFormItem.scss'

const MyDefaultFormItem = ({
  children,
  formClassName,
  formStyle,
  label,
  name,
  hasFeedback,
  initialValue,
  validateStatus,
  hidden,
  rules,
  isFirst,
  isLast,
  disableMargins,
  noStyle,
  valuePropName,
  getValueFromEvent,
  dependencies,
}) => {
  return (
    <Form.Item
      className={`my_default_form_item ${isFirst ? 'first' : ''} ${isLast ? 'last' : ''} ${disableMargins ? 'disable_margins' : ''} ${formClassName || ''}`}
      style={formStyle}
      label={label}
      name={name}
      hasFeedback={hasFeedback}
      initialValue={initialValue}
      validateStatus={validateStatus}
      hidden={hidden}
      rules={rules}
      noStyle={noStyle}
      valuePropName={valuePropName}
      getValueFromEvent={getValueFromEvent}
      dependencies={dependencies}
    >
      {children}
    </Form.Item>
  )
}

export default MyDefaultFormItem
