import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { organizationSelector } from '../../../store/selectors/selectors'
import paymentsActions from '../../../store/modules/paymentsAction'
import { getText, getTextServerError } from '../../../lang'
import { formatAmount } from '../../../utils'
import SVGAmountIcon from '../../../icons/SVG/SVGAmountIcon'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../devOptions'
import utilDate from '../../../utils/utilsDate'
import { notifyError } from '../../../utils/Notify'
import { getEmptyResult } from '../PaymentPageUtils'
import MatTable from '../../../components/MatTable'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import DepositsListItemMobileView from './DepositsListItemMobileView'
import './Deposit.scss'

const Deposits = ({ options }) => {
  const { isMobile } = useDeviceCheck()

  let organization = useSelector(organizationSelector)

  const navigate = useNavigate()

  const [page, setPage] = useState(0)
  const [loadingData, setLoadingData] = useState(false)
  const [depositsList, setDepositList] = useState([])

  const fill = useCallback(async () => {
    setLoadingData(true)
    let filterOptions = {
      status: options.status,
      group: options.group,
    }
    const result = await paymentsActions.getDepositsList(
      page,
      TABLE_DEFAULT_ROW_NUMBER
    )
    if (result.success) {
      setDepositList({
        list: result.data.deposits.map((item, index) => {
          Object.assign(item, {
            key: index,
          })
          return item
        }),
        total: result.data.totalPages,
        status: filterOptions.status,
      })
    } else {
      getEmptyResult(result.errMsg, setDepositList, {
        list: [],
        total: 0,
        status: undefined,
      })
      notifyError(getTextServerError(result.errMsg))
    }
    setLoadingData(false)
  }, [options, page, organization?._id])

  useEffect(() => {
    fill()
  }, [fill])

  const scheduledDepositsColumn = [
    {
      title: getText('WORD_AMOUNT'),
      dataIndex: 'amount',
      sorter: (a, b) => a.amount - b.amount,
      render: (_, { amount }) => {
        return (
          <span className='payment-amount'>
            <SVGAmountIcon />
            {formatAmount(amount)}
          </span>
        )
      },
    },
    {
      title: getText('WORD_DATE'),
      dataIndex: 'updatedAt',
      sorter: (a, b) => {
        if (a._id < b._id) {
          return -1
        }
        if (a._id > b._id) {
          return 1
        }
        return 0
      },
      render: (_, { updatedAt }) => {
        return (
          <span className='payment-grey-item'>
            {updatedAt ? utilDate.getDateTimeForPayment(updatedAt) : ''}
          </span>
        )
      },
    },
  ]

  return isMobile ? (
    <>
      {depositsList.list &&
        depositsList.list.map((item, index) => {
          return (
            <DepositsListItemMobileView
              key={index}
              item={item}
              onClick={() => {
                navigate(`/payment/${item._id}/deposit-details`, {
                  state: item,
                })
              }}
            />
          )
        })}
    </>
  ) : (
    <>
      <MatTable
        columns={scheduledDepositsColumn}
        dataSource={
          (depositsList.list &&
            depositsList.list.filter((item) => item.status !== 'paid')) ||
          []
        }
        loading={loadingData}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={page}
        paginationTotalCount={0}
        onPaginationChange={setPage}
        title={() => getText('WORD_SCHEDULED_DEPOSITS')}
      />
      <MatTable
        columns={scheduledDepositsColumn}
        dataSource={
          (depositsList.list &&
            depositsList.list.filter((item) => item.status === 'paid')) ||
          []
        }
        rowSelection={{
          type: 'radio',
          onChange: (selectedRowKeys, selectedRows) => {
            navigate(`/payment/${selectedRows[0]._id}/deposit-details`, {
              state: selectedRows[0],
            })
          },
        }}
        loading={loadingData}
        defaultPageSize={TABLE_DEFAULT_ROW_NUMBER}
        paginationCurrentPage={page}
        paginationTotalCount={depositsList.totalPages}
        onPaginationChange={setPage}
        title={() => getText('WORD_COMPLETED_DEPOSITS')}
      />
    </>
  )
}

export default Deposits
