import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_17881_109682)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_17881_109682)'>
          <circle cx='18' cy='17' r='13' fill='#65D0C3' />
        </g>
        <path
          d='M15.6668 22.6666H15.3335C12.6668 22.6666 11.3335 22 11.3335 18.6666V15.3333C11.3335 12.6666 12.6668 11.3333 15.3335 11.3333H20.6668C23.3335 11.3333 24.6668 12.6666 24.6668 15.3333V18.6666C24.6668 21.3333 23.3335 22.6666 20.6668 22.6666H20.3335C20.1268 22.6666 19.9268 22.7666 19.8002 22.9333L18.8002 24.2666C18.3602 24.8533 17.6402 24.8533 17.2002 24.2666L16.2002 22.9333C16.0935 22.7866 15.8468 22.6666 15.6668 22.6666Z'
          stroke='white'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.6665 15.3333H21.3332'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.6665 18.6667H18.6665'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_17881_109682)'
      />
      <defs>
        <filter
          id='filter0_f_17881_109682'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_17881_109682'
          />
        </filter>
        <linearGradient
          id='paint0_linear_17881_109682'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_17881_109682'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashTotalTimeSavedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashTotalTimeSavedIcon
