import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='24'
        height='24'
        rx='4'
        fill={props.backgroundcolor || '#EBF3FF'}
      />
      <rect
        x='0.5'
        y='0.5'
        width='23'
        height='23'
        rx='3.5'
        stroke={props.color || '#3F8CFF'}
        strokeOpacity='0.2'
      />
      <path
        d='M17.8337 13.7498V10.2498C17.8337 7.33317 16.667 6.1665 13.7503 6.1665H10.2503C7.33366 6.1665 6.16699 7.33317 6.16699 10.2498V13.7498C6.16699 16.6665 7.33366 17.8332 10.2503 17.8332H13.7503C16.667 17.8332 17.8337 16.6665 17.8337 13.7498Z'
        stroke={props.color || '#3F8CFF'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.46973 9.14746H17.5297'
        stroke={props.color || '#3F8CFF'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.96973 6.23096V9.06596'
        stroke={props.color || '#3F8CFF'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0303 6.23096V8.80346'
        stroke={props.color || '#3F8CFF'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6875 13.4291V12.7291C10.6875 11.8307 11.3233 11.4632 12.0992 11.9124L12.7058 12.2624L13.3125 12.6124C14.0883 13.0616 14.0883 13.7966 13.3125 14.2457L12.7058 14.5957L12.0992 14.9457C11.3233 15.3949 10.6875 15.0274 10.6875 14.1291V13.4291V13.4291Z'
        stroke={props.color || '#3F8CFF'}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGShowVideoIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGShowVideoIcon
