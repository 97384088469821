import React, { useState } from 'react'
import { getText } from '../../../../../lang'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError } from '../../../../../utils/Notify'
import MyDefaultAIReplyButton from '../../../../../components/MyDefaultButton/MyDefaultAIReplyButton/MyDefaultAIReplyButton'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'

const AIGeneratedButton = ({
  conversation,
  newMessageValue,
  setnewMessageValue,
  currentCursorPosition,
  setcurrentCursorPosition,
  disabled,
  tooltip,
  tooltipTitle,
}) => {
  const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()

  const [loading, setloading] = useState(false)

  const subStringMessageText = (message, oldMessage) => {
    let text = oldMessage.substring(0, currentCursorPosition)
    if (oldMessage.length) {
      text = `${text} `
    }
    text = `${text}${message} ${oldMessage.substring(currentCursorPosition)}`
    return text
  }

  const disabledButton = disabled || tooltip

  return (
    <MyDefaultAIReplyButton
      style={{
        marginRight: 4,
        background: disabledButton ? '#a3a6be' : '',
        padding: isMobileOrTabletOrTabletPortrait ? '7px 10px 7px 7px' : '15px',
      }}
      tooltip={tooltip}
      tooltipTitle={tooltipTitle}
      disabled={disabledButton}
      loading={loading}
      buttonText={getText('WORD_AI_REPLY')}
      onClick={async () => {
        setloading(true)
        const result = await messageActions.getAIReplyMessage(conversation._id)
        if (result.success) {
          if (result.data === '') {
            notifyError(getText('TEXT_THERE_IS_NO_GENERATED_MESSAGE'))
          } else {
            const letterForNewValue = subStringMessageText(
              result.data.message,
              newMessageValue.inputMessageForTags
            )
            const letterForPlainText = subStringMessageText(
              result.data.message,
              newMessageValue.inputMessageForSending
            )
            setnewMessageValue({
              inputMessageForTags: letterForNewValue,
              inputMessageForSending: letterForPlainText,
            })
            setcurrentCursorPosition(letterForPlainText.length)
          }
        } else {
          notifyError(result.errMsg)
        }
        setloading(false)
      }}
    />
  )
}

export default AIGeneratedButton
