import React, { useEffect, useRef, useState } from 'react'
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Flex, Typography } from 'antd'
import Div from '../../../../components/MatDiv/Div'
import SVGDashValueGeneratedIcon from '../../../../icons/SVG/SVGDashValueGeneratedIcon'
import utilNumber from '../../../../utils/utilNumber'
import { getText } from '../../../../lang'

const ValueGeneratedStatistic = ({ number, data, responsiveChartHeight }) => {
  const tooltipRef = useRef(null)
  const [tooltipWidth, setTooltipWidth] = useState(0)
  const [barGraphData, setBarGraphData] = useState({})

  const keys = ['service', 'sales', 'parts', 'timeSaved']
  const bgColors = [
    'linear-gradient(45deg, #FFD301, #FF912B)',
    'linear-gradient(45deg, #6CD8A7, #299765)',
    'linear-gradient(45deg, #6EA8FF, #084EB7)',
    'linear-gradient(45deg, #9B7FFF, #5336B6)',
  ]

  const CustomTooltip = ({ active, payload }) => {
    useEffect(() => {
      if (tooltipRef.current) {
        setTooltipWidth(tooltipRef.current.offsetWidth)
      }
    }, [payload])

    if (active && payload && payload.length) {
      if (payload[0].dataKey === 'gap') {
        return null
      }
      return (
        <Div ref={tooltipRef} className='chart_custom_tooltip'>
          <Div className='chart_custom_tooltip--content'>
            <Div className='chart_custom_tooltip--inner'>
              <p className='desc label'>{payload[0].payload.name}</p>
              {payload.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <p className='desc label'>{item.name}</p>
                    <p className='intro'>
                      {utilNumber.getNumberWithDollars(item.value)}
                    </p>
                    <p className='title'>{getText('WORD_CURRENT_MONTH')}</p>
                  </React.Fragment>
                )
              })}
            </Div>
          </Div>
        </Div>
      )
    }
    return null
  }

  const CustomLegend = () => {
    return keys.map((item, index) => {
      return (
        <Flex align='center' gap={4} key={index}>
          <Div
            style={{
              background: bgColors[index],
              width: 8,
              height: 8,
              borderRadius: '50%',
            }}
          ></Div>
          {getText(`WORD_${item.toUpperCase()}`)}{' '}
          {utilNumber.getNumberWithDollars(data?.[0][item])}
        </Flex>
      )
    })
  }

  return (
    <Div className={`dashboard_sent_statistics value_generated_statistics`}>
      <Div className={'dashboard_sent_statistics-bg green'} />
      <Flex gap={8} align='center' className='dashboard_sent_statistics--header'>
        <SVGDashValueGeneratedIcon />
        <span>{getText('WORD_VALUE_GENERATED')}</span>
      </Flex>
      <Flex align='center' className='dashboard_sent_statistics--information'>
        <Typography level={1}>{utilNumber.getNumberWithDollars(number)}</Typography>
      </Flex>
      <ResponsiveContainer height={responsiveChartHeight}>
        <BarChart
          height={100}
          data={data}
          margin={{
            left: 10,
          }}
          layout='vertical'
          barSize={32}
        >
          <defs>
            <linearGradient id='service' x1='0' y1='0' x2='1' y2='0'>
              <stop stopColor='#FFD301' />
              <stop offset='1' stopColor='#FF912B' />
            </linearGradient>
            <linearGradient id='sales' x1='0' y1='0' x2='1' y2='0'>
              <stop stopColor='#6CD8A7' />
              <stop offset='1' stopColor='#299765' />
            </linearGradient>
            <linearGradient id='parts' x1='0' y1='0' x2='1' y2='0'>
              <stop stopColor='#6EA8FF' />
              <stop offset='1' stopColor='#084EB7' />
            </linearGradient>
            <linearGradient id='timeSaved' x1='0' y1='0' x2='1' y2='0'>
              <stop stopColor='#9B7FFF' />
              <stop offset='1' stopColor='#5336B6' />
            </linearGradient>
          </defs>
          <XAxis type='number' tickLine={false} hide={true} domain={[0, 0]} />
          <YAxis dataKey='name' type='category' tickLine={false} hide={true} />
          <Legend iconSize={8} iconType='circle' content={<CustomLegend />} />
          <Tooltip
            cursor={{
              opacity: 0,
            }}
            position={{
              x: barGraphData.x + barGraphData.width / 2 - tooltipWidth / 2,
              y: -100,
            }}
            content={<CustomTooltip />}
            shared={false}
          />
          {keys.map((name, index) => {
            if (data[0][name] === 0) return null
            const bars = []
            bars.push(
              <Bar
                key={index}
                name={getText(`WORD_${name.toUpperCase()}`)}
                dataKey={name}
                stackId='a'
                fill={`url(#${name})`}
                radius={[8, 8, 8, 8]}
                onMouseOver={(data) => {
                  setBarGraphData(data)
                }}
              />
            )
            bars.push(<Bar dataKey='gap' stackId='a' fill='transparent' />)
            return bars
          })}
        </BarChart>
      </ResponsiveContainer>
    </Div>
  )
}

export default ValueGeneratedStatistic
