import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='153'
      height='146'
      viewBox='0 0 153 146'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M132.442 75.0318C131.927 74.8152 131.333 75.0579 131.116 75.5737C130.9 76.0895 131.142 76.6832 131.658 76.8998C132.174 77.1164 132.768 76.8738 132.984 76.3579C133.201 75.8421 132.958 75.2484 132.442 75.0318ZM131.14 78.1335C130.787 77.9853 130.381 78.1513 130.233 78.5043C130.084 78.8574 130.25 79.2637 130.604 79.4119C130.957 79.5602 131.363 79.3941 131.511 79.0411C131.659 78.688 131.493 78.2817 131.14 78.1335ZM130.036 80.7637C129.693 80.6196 129.298 80.781 129.154 81.1242C129.01 81.4673 129.171 81.8623 129.514 82.0064C129.857 82.1504 130.252 81.9891 130.396 81.6459C130.541 81.3027 130.379 80.9078 130.036 80.7637Z'
        fill='white'
      />
      <path
        d='M134.3 70.6174L133.473 71.577L132.304 71.0865L132.961 72.1701L132.134 73.1297L133.367 72.8398L134.024 73.9234L134.13 72.6606L135.363 72.3707L134.195 71.8802L134.3 70.6174Z'
        fill='white'
      />
      <path
        d='M115.358 82.013C114.842 81.7964 114.248 82.039 114.032 82.5548C113.815 83.0706 114.058 83.6644 114.574 83.8809C115.09 84.0975 115.683 83.8549 115.9 83.3391C116.116 82.8232 115.874 82.2295 115.358 82.013Z'
        fill='white'
      />
      <path
        d='M118.348 84.511C118.561 84.1928 118.476 83.7622 118.158 83.5493C117.839 83.3364 117.409 83.4218 117.196 83.7401C116.983 84.0583 117.069 84.4889 117.387 84.7018C117.705 84.9147 118.136 84.8293 118.348 84.511Z'
        fill='white'
      />
      <path
        d='M119.766 84.9622C119.622 85.3054 119.783 85.7003 120.127 85.8444C120.47 85.9885 120.865 85.8271 121.009 85.4839C121.153 85.1408 120.991 84.7458 120.648 84.6017C120.305 84.4577 119.91 84.619 119.766 84.9622Z'
        fill='white'
      />
      <path
        d='M111.664 80.8736L111.374 79.6401L110.883 80.8085L109.621 80.703L110.58 81.5306L110.09 82.6989L111.173 82.042L112.133 82.8696L111.843 81.636L112.927 80.9791L111.664 80.8736Z'
        fill='white'
      />
      <path
        d='M122.341 99.0995C121.825 98.8829 121.231 99.1255 121.015 99.6413C120.798 100.157 121.041 100.751 121.557 100.967C122.072 101.184 122.666 100.941 122.883 100.426C123.099 99.9098 122.857 99.316 122.341 99.0995ZM123.396 96.5873C123.043 96.4391 122.636 96.6052 122.488 96.9582C122.34 97.3112 122.506 97.7176 122.859 97.8658C123.212 98.014 123.618 97.848 123.766 97.4949C123.915 97.1419 123.749 96.7356 123.396 96.5873ZM124.485 93.9929C124.142 93.8488 123.747 94.0102 123.603 94.3534C123.459 94.6965 123.62 95.0915 123.963 95.2356C124.306 95.3796 124.701 95.2182 124.845 94.8751C124.989 94.532 124.828 94.137 124.485 93.9929Z'
        fill='white'
      />
      <path
        d='M120.636 103.159L119.979 102.075L119.874 103.338L118.64 103.628L119.809 104.119L119.703 105.381L120.531 104.422L121.699 104.912L121.042 103.829L121.87 102.869L120.636 103.159Z'
        fill='white'
      />
      <path
        d='M139.429 92.1185C138.913 91.902 138.32 92.1446 138.103 92.6604C137.887 93.1762 138.129 93.77 138.645 93.9865C139.161 94.2031 139.755 93.9605 139.971 93.4447C140.188 92.9288 139.945 92.3351 139.429 92.1185Z'
        fill='white'
      />
      <path
        d='M136.879 92.1048C137.006 91.7438 136.817 91.3477 136.456 91.2201C136.095 91.0925 135.699 91.2817 135.571 91.6427C135.444 92.0037 135.633 92.3998 135.994 92.5274C136.355 92.655 136.751 92.4658 136.879 92.1048Z'
        fill='white'
      />
      <path
        d='M133.877 90.1556C133.534 90.0116 133.139 90.1729 132.995 90.5161C132.851 90.8592 133.012 91.2542 133.355 91.3983C133.698 91.5424 134.093 91.381 134.237 91.0378C134.381 90.6947 134.22 90.2997 133.877 90.1556Z'
        fill='white'
      />
      <path
        d='M143.912 93.3011L142.829 93.958L141.869 93.1304L142.159 94.3639L141.075 95.0208L142.338 95.1263L142.628 96.3599L143.118 95.1915L144.381 95.297L143.422 94.4694L143.912 93.3011Z'
        fill='white'
      />
      <path
        d='M127.733 78.5742C127.733 78.1702 127.405 77.8427 127.001 77.8427C126.597 77.8427 126.27 78.1702 126.27 78.5742C126.27 78.9782 126.597 79.3058 127.001 79.3058C127.405 79.3058 127.733 78.9782 127.733 78.5742Z'
        fill='#FF9154'
      />
      <path
        d='M127.003 81.2734C127.279 81.2734 127.503 81.0493 127.503 80.7728C127.503 80.4963 127.279 80.2721 127.003 80.2721C126.726 80.2721 126.502 80.4963 126.502 80.7728C126.502 81.0493 126.726 81.2734 127.003 81.2734Z'
        fill='#FF9154'
      />
      <path
        d='M127.002 83.3047C127.271 83.3047 127.488 83.0868 127.488 82.818C127.488 82.5493 127.271 82.3314 127.002 82.3314C126.733 82.3314 126.515 82.5493 126.515 82.818C126.515 83.0868 126.733 83.3047 127.002 83.3047Z'
        fill='#FF9154'
      />
      <path
        d='M127.002 74.3853L126.719 75.2556H125.804L126.544 75.7935L126.261 76.6639L127.002 76.126L127.742 76.6639L127.459 75.7935L128.2 75.2556H127.284L127.002 74.3853Z'
        fill='#FF9154'
      />
      <path
        d='M117.689 88.7225C118.088 88.6578 118.359 88.282 118.294 87.8832C118.23 87.4844 117.854 87.2135 117.455 87.2783C117.056 87.343 116.785 87.7187 116.85 88.1176C116.915 88.5164 117.291 88.7872 117.689 88.7225Z'
        fill='#FF9154'
      />
      <path
        d='M119.775 88.5002C120.051 88.5002 120.275 88.2761 120.275 87.9996C120.275 87.7231 120.051 87.4989 119.775 87.4989C119.498 87.4989 119.274 87.7231 119.274 87.9996C119.274 88.2761 119.498 88.5002 119.775 88.5002Z'
        fill='#FF9154'
      />
      <path
        d='M121.816 87.5135C121.547 87.5135 121.33 87.7314 121.33 88.0002C121.33 88.2689 121.547 88.4868 121.816 88.4868C122.085 88.4868 122.303 88.2689 122.303 88.0002C122.303 87.7314 122.085 87.5135 121.816 87.5135Z'
        fill='#FF9154'
      />
      <path
        d='M114.254 89.1981L114.792 88.4578L115.662 88.7406L115.125 88.0002L115.662 87.2598L114.792 87.5426L114.254 86.8022V87.7174L113.384 88.0002L114.254 88.283V89.1981Z'
        fill='#FF9154'
      />
      <path
        d='M127.001 98.1577C127.405 98.1577 127.733 97.8301 127.733 97.4261C127.733 97.0221 127.405 96.6945 127.001 96.6945C126.597 96.6945 126.27 97.0221 126.27 97.4261C126.27 97.8301 126.597 98.1577 127.001 98.1577Z'
        fill='#FF9154'
      />
      <path
        d='M127.003 95.7287C127.279 95.7287 127.503 95.5045 127.503 95.228C127.503 94.9515 127.279 94.7274 127.003 94.7274C126.726 94.7274 126.502 94.9515 126.502 95.228C126.502 95.5045 126.726 95.7287 127.003 95.7287Z'
        fill='#FF9154'
      />
      <path
        d='M127.002 93.6673C127.271 93.6673 127.488 93.4494 127.488 93.1807C127.488 92.9119 127.271 92.694 127.002 92.694C126.733 92.694 126.515 92.9119 126.515 93.1807C126.515 93.4494 126.733 93.6673 127.002 93.6673Z'
        fill='#FF9154'
      />
      <path
        d='M127.742 99.3357L127.002 99.8736L126.261 99.3357L126.544 100.206L125.804 100.744H126.719L127.002 101.614L127.284 100.744H128.2L127.459 100.206L127.742 99.3357Z'
        fill='#FF9154'
      />
      <path
        d='M136.543 88.7225C136.942 88.6578 137.213 88.282 137.148 87.8832C137.083 87.4844 136.707 87.2135 136.309 87.2783C135.91 87.343 135.639 87.7187 135.704 88.1176C135.768 88.5164 136.144 88.7872 136.543 88.7225Z'
        fill='#FF9154'
      />
      <path
        d='M133.726 87.9996C133.726 88.2761 133.95 88.5003 134.227 88.5003C134.503 88.5003 134.727 88.2761 134.727 87.9996C134.727 87.7231 134.503 87.4989 134.227 87.4989C133.95 87.4989 133.726 87.7231 133.726 87.9996ZM132.181 87.5129C131.912 87.5129 131.694 87.7308 131.694 87.9996C131.694 88.2684 131.912 88.4863 132.181 88.4863C132.449 88.4863 132.667 88.2684 132.667 87.9996C132.667 87.7308 132.449 87.5129 132.181 87.5129Z'
        fill='#FF9154'
      />
      <path
        d='M139.742 86.8022L139.204 87.5426L138.334 87.2598L138.872 88.0002L138.334 88.7406L139.204 88.4578L139.742 89.1981V88.283L140.613 88.0002L139.742 87.7174V86.8022Z'
        fill='#FF9154'
      />
      <path
        d='M123.003 75.5342C122.791 75.0163 122.2 74.768 121.682 74.9796C121.164 75.1912 120.916 75.7826 121.127 76.3005C121.339 76.8184 121.93 77.0667 122.448 76.8551C122.966 76.6434 123.214 76.0521 123.003 75.5342ZM123.858 78.4732C123.713 78.1187 123.309 77.9488 122.954 78.0937C122.6 78.2385 122.43 78.6432 122.575 78.9977C122.72 79.3521 123.124 79.522 123.479 79.3772C123.833 79.2324 124.003 78.8276 123.858 78.4732ZM124.033 80.7343C123.689 80.8751 123.524 81.2685 123.664 81.613C123.805 81.9576 124.199 82.1227 124.543 81.982C124.888 81.8412 125.053 81.4478 124.912 81.1033C124.771 80.7587 124.378 80.5936 124.033 80.7343Z'
        fill='#FF9154'
      />
      <path
        d='M120.113 73.8565L120.78 72.7792L122.011 73.0809L121.192 72.1134L121.86 71.0362L120.687 71.5155L119.868 70.548L119.962 71.8117L118.789 72.291L120.019 72.5927L120.113 73.8565Z'
        fill='#FF9154'
      />
      <path
        d='M115.288 93.8811C115.809 93.6785 116.068 93.0915 115.865 92.5701C115.663 92.0486 115.076 91.79 114.554 91.9926C114.033 92.1952 113.774 92.7821 113.977 93.3036C114.179 93.8251 114.766 94.0836 115.288 93.8811Z'
        fill='#FF9154'
      />
      <path
        d='M117.474 91.1438C117.12 91.2886 116.95 91.6934 117.095 92.0478C117.239 92.4022 117.644 92.5722 117.999 92.4273C118.353 92.2825 118.523 91.8778 118.378 91.5233C118.233 91.1689 117.829 90.999 117.474 91.1438ZM120.104 90.0901C119.76 90.2309 119.595 90.6243 119.735 90.9688C119.876 91.3133 120.27 91.4785 120.614 91.3377C120.959 91.1969 121.124 90.8035 120.983 90.459C120.842 90.1145 120.449 89.9493 120.104 90.0901Z'
        fill='#FF9154'
      />
      <path
        d='M112.083 92.9882L111.115 93.8065L110.038 93.1392L110.517 94.3123L109.55 95.1306L110.814 95.0372L111.293 96.2102L111.595 94.9795L112.858 94.8861L111.781 94.2189L112.083 92.9882Z'
        fill='#FF9154'
      />
      <path
        d='M132.544 100.892C132.991 100.556 133.081 99.921 132.745 99.4739C132.409 99.0268 131.774 98.9369 131.327 99.2732C130.88 99.6094 130.79 100.244 131.126 100.692C131.462 101.139 132.097 101.229 132.544 100.892Z'
        fill='#FF9154'
      />
      <path
        d='M131.424 97.0022C131.279 96.6478 130.875 96.4778 130.52 96.6227C130.166 96.7675 129.996 97.1722 130.141 97.5267C130.285 97.8811 130.69 98.051 131.045 97.9062C131.399 97.7614 131.569 97.3566 131.424 97.0022ZM130.334 94.3868C130.194 94.0423 129.8 93.8771 129.456 94.0179C129.111 94.1587 128.946 94.5521 129.087 94.8966C129.228 95.2411 129.621 95.4063 129.966 95.2655C130.31 95.1247 130.475 94.7313 130.334 94.3868Z'
        fill='#FF9154'
      />
      <path
        d='M133.983 103.407L133.889 102.143L133.222 103.221L131.991 102.919L132.81 103.886L132.142 104.964L133.315 104.484L134.134 105.452L134.04 104.188L135.213 103.709L133.983 103.407Z'
        fill='#FF9154'
      />
      <path
        d='M139.396 84.0261C139.928 83.8522 140.218 83.2801 140.044 82.7484C139.87 82.2167 139.298 81.9266 138.766 82.1005C138.235 82.2744 137.945 82.8465 138.119 83.3782C138.293 83.9099 138.865 84.2 139.396 84.0261Z'
        fill='#FF9154'
      />
      <path
        d='M136.527 84.8562C136.881 84.7114 137.051 84.3067 136.906 83.9522C136.761 83.5978 136.357 83.4279 136.002 83.5727C135.648 83.7175 135.478 84.1222 135.623 84.4767C135.768 84.8311 136.172 85.001 136.527 84.8562ZM133.387 84.6623C133.042 84.8031 132.877 85.1965 133.018 85.541C133.159 85.8855 133.552 86.0507 133.897 85.9099C134.241 85.7691 134.406 85.3757 134.266 85.0312C134.125 84.6867 133.731 84.5215 133.387 84.6623Z'
        fill='#FF9154'
      />
      <path
        d='M143.185 80.9618L142.706 79.7888L142.404 81.0195L141.14 81.1129L142.217 81.7802L141.916 83.0109L142.883 82.1926L143.96 82.8598L143.481 81.6868L144.449 80.8685L143.185 80.9618Z'
        fill='#FF9154'
      />
      <path
        d='M119.817 80.8175C119.532 81.1032 119.532 81.5664 119.817 81.8521C120.103 82.1378 120.566 82.1378 120.852 81.8521C121.138 81.5664 121.138 81.1032 120.852 80.8175C120.566 80.5318 120.103 80.5318 119.817 80.8175ZM121.535 82.5354C121.34 82.731 121.34 83.048 121.535 83.2435C121.731 83.439 122.048 83.439 122.243 83.2435C122.439 83.048 122.439 82.731 122.243 82.5354C122.048 82.3399 121.731 82.3399 121.535 82.5354ZM122.992 83.9922C122.802 84.1823 122.802 84.4904 122.992 84.6805C123.182 84.8705 123.49 84.8705 123.68 84.6805C123.87 84.4904 123.87 84.1823 123.68 83.9922C123.49 83.8021 123.182 83.8021 122.992 83.9922Z'
        fill='#48AF80'
      />
      <path
        d='M118.459 80.5081L118.603 79.6043L119.507 79.4611L118.691 79.0456L118.834 78.1417L118.187 78.7888L117.372 78.3734L117.787 79.1888L117.14 79.8359L118.044 79.6927L118.459 80.5081Z'
        fill='#48AF80'
      />
      <path
        d='M119.818 94.1487C119.532 94.4344 119.532 94.8976 119.818 95.1832C120.103 95.469 120.567 95.469 120.852 95.1832C121.138 94.8976 121.138 94.4344 120.852 94.1487C120.567 93.863 120.103 93.863 119.818 94.1487ZM121.536 92.7573C121.34 92.9528 121.34 93.2698 121.536 93.4654C121.731 93.6609 122.048 93.6609 122.244 93.4654C122.439 93.2698 122.439 92.9528 122.244 92.7573C122.048 92.5618 121.731 92.5618 121.536 92.7573ZM122.992 91.3203C122.802 91.5104 122.802 91.8185 122.992 92.0086C123.182 92.1987 123.491 92.1987 123.681 92.0086C123.871 91.8185 123.871 91.5104 123.681 91.3203C123.491 91.1303 123.182 91.1303 122.992 91.3203Z'
        fill='#48AF80'
      />
      <path
        d='M118.603 96.3958L118.459 95.4919L118.044 96.3073L117.14 96.1642L117.787 96.8113L117.372 97.6267L118.187 97.2112L118.834 97.8583L118.691 96.9544L119.507 96.539L118.603 96.3958Z'
        fill='#48AF80'
      />
      <path
        d='M133.146 94.1487C132.86 94.4344 132.86 94.8976 133.146 95.1832C133.431 95.469 133.895 95.469 134.18 95.1832C134.466 94.8976 134.466 94.4344 134.18 94.1487C133.895 93.863 133.431 93.863 133.146 94.1487ZM131.754 92.7573C131.559 92.9528 131.559 93.2698 131.754 93.4654C131.95 93.6609 132.267 93.6609 132.462 93.4654C132.658 93.2698 132.658 92.9528 132.462 92.7573C132.267 92.5618 131.95 92.5618 131.754 92.7573ZM130.317 91.3203C130.127 91.5104 130.127 91.8185 130.317 92.0086C130.507 92.1987 130.816 92.1987 131.006 92.0086C131.196 91.8185 131.196 91.5104 131.006 91.3203C130.816 91.1303 130.507 91.1303 130.317 91.3203Z'
        fill='#48AF80'
      />
      <path
        d='M135.956 96.3073L135.541 95.4919L135.398 96.3958L134.494 96.539L135.309 96.9544L135.166 97.8583L135.813 97.2112L136.628 97.6267L136.213 96.8113L136.86 96.1642L135.956 96.3073Z'
        fill='#48AF80'
      />
      <path
        d='M133.146 80.8177C132.86 81.1034 132.86 81.5666 133.146 81.8523C133.431 82.138 133.895 82.138 134.18 81.8523C134.466 81.5666 134.466 81.1034 134.18 80.8177C133.895 80.532 133.431 80.532 133.146 80.8177ZM131.754 82.5356C131.559 82.7311 131.559 83.0482 131.754 83.2437C131.95 83.4392 132.267 83.4392 132.462 83.2437C132.658 83.0482 132.658 82.7311 132.462 82.5356C132.267 82.3401 131.95 82.3401 131.754 82.5356ZM130.317 83.9924C130.127 84.1824 130.127 84.4906 130.317 84.6806C130.507 84.8707 130.816 84.8707 131.006 84.6806C131.196 84.4906 131.196 84.1824 131.006 83.9924C130.816 83.8023 130.507 83.8023 130.317 83.9924Z'
        fill='#48AF80'
      />
      <path
        d='M136.628 78.3734L135.813 78.7888L135.166 78.1417L135.309 79.0456L134.494 79.4611L135.398 79.6043L135.541 80.5081L135.956 79.6927L136.86 79.8359L136.213 79.1888L136.628 78.3734Z'
        fill='#48AF80'
      />
      <path
        d='M74.9293 75.0918L74.4726 76.4261L73.0625 76.404L74.1904 77.2507L73.7336 78.585L74.8874 77.774L76.0152 78.6207L75.6005 77.2728L76.7542 76.4618L75.3441 76.4397L74.9293 75.0918Z'
        fill='white'
      />
      <path
        d='M93.9146 34.2132L93.9966 32.8054L95.3609 32.4483L94.0472 31.9352L94.1292 30.5273L93.2354 31.6182L91.9217 31.1051L92.6829 32.2923L91.7891 33.3831L93.1534 33.0261L93.9146 34.2132Z'
        fill='white'
      />
      <path
        d='M62.4212 45.1581L62.4432 43.748L61.5965 44.8759L60.2622 44.4192L61.0732 45.5729L60.2266 46.7008L61.5745 46.286L62.3855 47.4397L62.4075 46.0297L63.7554 45.6149L62.4212 45.1581Z'
        fill='white'
      />
      <path
        d='M107.741 62.6112L106.554 63.3724L105.463 62.4785L105.82 63.8429L104.633 64.6041L106.041 64.686L106.398 66.0504L106.911 64.7367L108.319 64.8187L107.228 63.9248L107.741 62.6112Z'
        fill='white'
      />
      <path
        d='M91.6681 35.2234C90.8881 34.9209 90.0198 35.3721 89.8189 36.1842L87.2166 46.7033L92.3862 37.1797C92.7853 36.4445 92.4481 35.5259 91.6681 35.2234ZM76.3333 74.7702C77.1133 75.0727 77.9817 74.6215 78.1826 73.8094L80.7848 63.2903L75.6152 72.8139C75.2161 73.5491 75.5533 74.4678 76.3333 74.7702ZM101.818 63.3823C102.553 63.7814 103.472 63.4442 103.774 62.6642C104.077 61.8842 103.625 61.0159 102.813 60.815L92.2943 58.2127L101.818 63.3823ZM65.1881 49.1786L75.7072 51.7809L66.1836 46.6113C65.4484 46.2122 64.5298 46.5494 64.2273 47.3294C63.9248 48.1094 64.376 48.9777 65.1881 49.1786Z'
        fill='white'
      />
      <path
        d='M94.5785 76.4397L94.1637 75.0918L93.707 76.4261L92.2969 76.404L93.4247 77.2507L92.968 78.585L94.1218 77.774L95.2497 78.6207L94.8348 77.2728L95.9886 76.4618L94.5785 76.4397Z'
        fill='#FF9154'
      />
      <path
        d='M73.936 32.1669L72.5625 32.4868L73.8617 33.0353L73.7416 34.4405L74.6647 33.3743L75.964 33.9228L75.2353 32.7154L76.1584 31.6492L74.7849 31.9692L74.0562 30.7617L73.936 32.1669Z'
        fill='#FF9154'
      />
      <path
        d='M61.5745 65.5204L62.3855 66.6742L62.4075 65.264L63.7554 64.8492L62.4212 64.3925L62.4432 62.9824L61.5965 64.1103L60.2622 63.6536L61.0732 64.8073L60.2266 65.9352L61.5745 65.5204Z'
        fill='#FF9154'
      />
      <path
        d='M106.68 44.6216L106.36 43.248L105.811 44.5473L104.406 44.4271L105.472 45.3503L104.924 46.6495L106.131 45.9208L107.198 46.8439L106.878 45.4704L108.085 44.7417L106.68 44.6216Z'
        fill='#FF9154'
      />
      <path
        d='M84.8191 41.3228L84.5874 39.9316L83.9571 41.1933L82.5625 40.9838L83.5676 41.973L82.9374 43.2347L84.1889 42.5844L85.194 43.5737L84.9623 42.1825L86.2137 41.5323L84.8191 41.3228Z'
        fill='#FF9154'
      />
      <path
        d='M97.4324 54.1321L97.2443 52.7344L96.5749 53.9757L95.1875 53.7227L96.1612 54.7429L95.4919 55.9842L96.7631 55.3734L97.7368 56.3936L97.5486 54.9959L98.8198 54.3851L97.4324 54.1321Z'
        fill='#FF9154'
      />
      <path
        d='M70.3093 55.6092L70.4974 57.0068L71.1667 55.7655L72.5542 56.0185L71.5804 54.9983L72.2498 53.757L70.9786 54.3678L70.0049 53.3477L70.193 54.7453L68.9219 55.3562L70.3093 55.6092Z'
        fill='#FF9154'
      />
      <path
        d='M84.1773 67.7686L84.8076 66.507L83.5561 67.1572L82.551 66.168L82.7827 67.5591L81.5312 68.2093L82.9259 68.4188L83.1576 69.8099L83.7878 68.5483L85.1825 68.7578L84.1773 67.7686Z'
        fill='#FF9154'
      />
      <path
        d='M83.9988 44.3947C83.6385 44.3947 83.3601 44.711 83.4059 45.0684L83.9988 49.6976L84.5918 45.0684C84.6376 44.711 84.3591 44.3947 83.9988 44.3947ZM103.402 46.4366C103.065 45.6712 102.132 45.3762 101.415 45.8084L92.1373 51.4065L102.527 48.3277C103.329 48.09 103.74 47.2021 103.402 46.4366ZM64.5952 63.5571C64.9329 64.3225 65.8659 64.6175 66.5823 64.1853L75.8604 58.5873L65.4708 61.666C64.6687 61.9037 64.2576 62.7917 64.5952 63.5571ZM92.5591 74.4004C93.3245 74.0628 93.6195 73.1297 93.1873 72.4134L87.5892 63.1353L90.668 73.5249C90.9057 74.327 91.7936 74.7381 92.5591 74.4004ZM77.3297 36.4689C77.092 35.6668 76.204 35.2556 75.4386 35.5933C74.6732 35.931 74.3782 36.864 74.8104 37.5803L80.4084 46.8584L77.3297 36.4689ZM83.9988 60.3033L83.4059 64.9324C83.3601 65.2898 83.6385 65.6062 83.9988 65.6062C84.3591 65.6062 84.6376 65.2898 84.5918 64.9324L83.9988 60.3033ZM94.6046 55.0004C94.6046 54.6401 94.2882 54.3617 93.9308 54.4075L89.3017 55.0004L93.9308 55.5934C94.2882 55.6392 94.6046 55.3607 94.6046 55.0004ZM73.3931 55.0004C73.3931 55.3607 73.7094 55.6392 74.0668 55.5934L78.696 55.0004L74.0668 54.4075C73.7094 54.3617 73.3931 54.6401 73.3931 55.0004Z'
        fill='#FF9154'
      />
      <path
        d='M74.4726 46.0384L74.9293 47.3726L75.3441 46.0247L76.7542 46.0027L75.6005 45.1917L76.0152 43.8438L74.8874 44.6904L73.7336 43.8794L74.1904 45.2137L73.0625 46.0604L74.4726 46.0384Z'
        fill='#48AF80'
      />
      <path
        d='M92.859 44.6904L91.7312 43.8438L92.146 45.1917L90.9922 46.0027L92.4023 46.0247L92.8171 47.3726L93.2738 46.0384L94.6839 46.0604L93.5561 45.2137L94.0128 43.8794L92.859 44.6904Z'
        fill='#48AF80'
      />
      <path
        d='M75.3441 63.7171L74.9293 62.3691L74.4726 63.7034L73.0625 63.6814L74.1904 64.5281L73.7336 65.8624L74.8874 65.0513L76.0152 65.898L75.6005 64.5501L76.7542 63.7391L75.3441 63.7171Z'
        fill='#48AF80'
      />
      <path
        d='M93.2738 63.7034L92.8171 62.3691L92.4023 63.7171L90.9922 63.7391L92.146 64.5501L91.7312 65.898L92.859 65.0513L94.0128 65.8624L93.5561 64.5281L94.6839 63.6814L93.2738 63.7034Z'
        fill='#48AF80'
      />
      <path
        d='M90.6063 62.4429C90.8266 62.728 91.2472 62.7548 91.502 62.5001C91.7568 62.2453 91.73 61.8247 91.4449 61.6044L87.7523 58.7504L90.6063 62.4429ZM77.3989 47.5584C77.1786 47.2733 76.758 47.2465 76.5032 47.5013C76.2485 47.756 76.2753 48.1766 76.5604 48.397L80.2529 51.251L77.3989 47.5584ZM77.3989 62.4429L80.2529 58.7504L76.5604 61.6044C76.2753 61.8247 76.2485 62.2453 76.5032 62.5001C76.758 62.7548 77.1786 62.728 77.3989 62.4429ZM91.502 47.5013C91.2472 47.2465 90.8266 47.2733 90.6063 47.5584L87.7523 51.251L91.4449 48.397C91.73 48.1766 91.7568 47.756 91.502 47.5013Z'
        fill='#48AF80'
      />
      <path
        d='M15.8873 73.2591C16.2264 73.2591 16.5012 72.9842 16.5012 72.6452C16.5012 72.3061 16.2264 72.0312 15.8873 72.0312C15.5483 72.0312 15.2734 72.3061 15.2734 72.6452C15.2734 72.9842 15.5483 73.2591 15.8873 73.2591Z'
        fill='#FF9154'
      />
      <path
        d='M97.6139 119.228C97.9529 119.228 98.2278 118.953 98.2278 118.614C98.2278 118.275 97.9529 118 97.6139 118C97.2749 118 97 118.275 97 118.614C97 118.953 97.2749 119.228 97.6139 119.228Z'
        fill='#FF9154'
      />
      <path
        d='M73.6139 119.228C73.9529 119.228 74.2278 118.953 74.2278 118.614C74.2278 118.275 73.9529 118 73.6139 118C73.2749 118 73 118.275 73 118.614C73 118.953 73.2749 119.228 73.6139 119.228Z'
        fill='#FF9154'
      />
      <path
        d='M116.614 116.228C116.953 116.228 117.228 115.953 117.228 115.614C117.228 115.275 116.953 115 116.614 115C116.275 115 116 115.275 116 115.614C116 115.953 116.275 116.228 116.614 116.228Z'
        fill='#FF9154'
      />
      <path
        d='M106.614 104.228C106.953 104.228 107.228 103.953 107.228 103.614C107.228 103.275 106.953 103 106.614 103C106.275 103 106 103.275 106 103.614C106 103.953 106.275 104.228 106.614 104.228Z'
        fill='#FF9154'
      />
      <path
        d='M37.7158 73.4569C37.9395 73.4569 38.121 73.2755 38.121 73.0517C38.121 72.8279 37.9395 72.6465 37.7158 72.6465C37.4919 72.6465 37.3105 72.8279 37.3105 73.0517C37.3105 73.2755 37.4919 73.4569 37.7158 73.4569ZM48.1558 81.0716C47.9697 81.0716 47.8188 81.2225 47.8188 81.4087C47.8188 81.5948 47.9697 81.7457 48.1558 81.7457C48.342 81.7457 48.4928 81.5948 48.4928 81.4087C48.4928 81.2225 48.342 81.0716 48.1558 81.0716ZM50.5246 92.5872C50.0451 92.5872 49.6563 92.976 49.6563 93.4555C49.6563 93.935 50.0451 94.3238 50.5246 94.3238C51.0042 94.3238 51.3929 93.935 51.3929 93.4555C51.3929 92.976 51.0042 92.5872 50.5246 92.5872ZM37.9882 106.54C37.9882 106.166 37.6848 105.863 37.3105 105.863C36.9362 105.863 36.6328 106.166 36.6328 106.54C36.6328 106.915 36.9362 107.218 37.3105 107.218C37.6848 107.218 37.9882 106.915 37.9882 106.54Z'
        fill='#FF9154'
      />
      <path
        d='M60.0829 85.8105C60.3067 85.8105 60.4882 85.629 60.4882 85.4052C60.4882 85.1814 60.3067 85 60.0829 85C59.8591 85 59.6777 85.1814 59.6777 85.4052C59.6777 85.629 59.8591 85.8105 60.0829 85.8105ZM70.523 93.4252C70.3369 93.4252 70.186 93.576 70.186 93.7622C70.186 93.9483 70.3369 94.0992 70.523 94.0992C70.7091 94.0992 70.86 93.9483 70.86 93.7622C70.86 93.576 70.7091 93.4252 70.523 93.4252ZM72.8918 104.941C72.4123 104.941 72.0235 105.329 72.0235 105.809C72.0235 106.289 72.4123 106.677 72.8918 106.677C73.3713 106.677 73.7601 106.289 73.7601 105.809C73.7601 105.329 73.3713 104.941 72.8918 104.941ZM60.3554 118.894C60.3554 118.52 60.052 118.216 59.6777 118.216C59.3034 118.216 59 118.52 59 118.894C59 119.268 59.3034 119.572 59.6777 119.572C60.052 119.572 60.3554 119.268 60.3554 118.894Z'
        fill='#FF9154'
      />
      <path
        d='M16.149 82.0186C16.4858 82.0186 16.7589 81.7456 16.7589 81.4087C16.7589 81.0719 16.4858 80.7988 16.149 80.7988C15.8121 80.7988 15.5391 81.0719 15.5391 81.4087C15.5391 81.7456 15.8121 82.0186 16.149 82.0186Z'
        fill='white'
      />
      <path
        d='M29.0995 73.4549C29.5471 73.4549 29.91 73.0921 29.91 72.6445C29.91 72.1968 29.5471 71.834 29.0995 71.834C28.6519 71.834 28.2891 72.1968 28.2891 72.6445C28.2891 73.0921 28.6519 73.4549 29.0995 73.4549Z'
        fill='white'
      />
      <path
        d='M23.4207 105.146C23.6315 105.146 23.8024 104.975 23.8024 104.764C23.8024 104.554 23.6315 104.383 23.4207 104.383C23.2099 104.383 23.0391 104.554 23.0391 104.764C23.0391 104.975 23.2099 105.146 23.4207 105.146Z'
        fill='white'
      />
      <path
        d='M15.5475 95.6426C15.2106 95.6426 14.9375 95.9157 14.9375 96.2525C14.9375 96.5894 15.2106 96.8625 15.5475 96.8625C15.8843 96.8625 16.1574 96.5894 16.1574 96.2525C16.1574 95.9157 15.8843 95.6426 15.5475 95.6426Z'
        fill='white'
      />
      <path
        d='M16.149 86.1397C16.4858 86.1397 16.7589 85.8667 16.7589 85.5298C16.7589 85.193 16.4858 84.9199 16.149 84.9199C15.8121 84.9199 15.5391 85.193 15.5391 85.5298C15.5391 85.8667 15.8121 86.1397 16.149 86.1397Z'
        fill='#48AF80'
      />
      <path
        d='M44.1441 73.0519C44.7069 73.0519 45.1632 72.5956 45.1632 72.0328C45.1632 71.4699 44.7069 71.0137 44.1441 71.0137C43.5813 71.0137 43.125 71.4699 43.125 72.0328C43.125 72.5956 43.5813 73.0519 44.1441 73.0519Z'
        fill='#48AF80'
      />
      <path
        d='M60.8683 103.737C61.3478 103.737 61.7366 103.348 61.7366 102.868C61.7366 102.389 61.3478 102 60.8683 102C60.3888 102 60 102.389 60 102.868C60 103.348 60.3888 103.737 60.8683 103.737Z'
        fill='#48AF80'
      />
      <path
        d='M101.868 92.7366C102.348 92.7366 102.737 92.3478 102.737 91.8683C102.737 91.3887 102.348 91 101.868 91C101.389 91 101 91.3887 101 91.8683C101 92.3478 101.389 92.7366 101.868 92.7366Z'
        fill='#48AF80'
      />
      <path
        d='M92.8683 104.737C93.3478 104.737 93.7366 104.348 93.7366 103.868C93.7366 103.389 93.3478 103 92.8683 103C92.3888 103 92 103.389 92 103.868C92 104.348 92.3888 104.737 92.8683 104.737Z'
        fill='#48AF80'
      />
      <path
        d='M110.868 119.737C111.348 119.737 111.737 119.348 111.737 118.868C111.737 118.389 111.348 118 110.868 118C110.389 118 110 118.389 110 118.868C110 119.348 110.389 119.737 110.868 119.737Z'
        fill='#48AF80'
      />
      <path
        d='M122.868 65.7366C123.348 65.7366 123.737 65.3478 123.737 64.8683C123.737 64.3887 123.348 64 122.868 64C122.389 64 122 64.3887 122 64.8683C122 65.3478 122.389 65.7366 122.868 65.7366Z'
        fill='#48AF80'
      />
      <path
        d='M140.868 115.737C141.348 115.737 141.737 115.348 141.737 114.868C141.737 114.389 141.348 114 140.868 114C140.389 114 140 114.389 140 114.868C140 115.348 140.389 115.737 140.868 115.737Z'
        fill='#48AF80'
      />
      <path
        d='M51.1972 103.003C51.2347 102.624 50.9581 102.287 50.5794 102.249C50.2006 102.212 49.8632 102.488 49.8257 102.867C49.7882 103.246 50.0648 103.583 50.4436 103.621C50.8223 103.658 51.1597 103.382 51.1972 103.003Z'
        fill='#48AF80'
      />
      <path
        d='M29.1032 107.277C29.2854 107.277 29.433 107.129 29.433 106.947C29.433 106.765 29.2854 106.617 29.1032 106.617C28.9211 106.617 28.7734 106.765 28.7734 106.947C28.7734 107.129 28.9211 107.277 29.1032 107.277Z'
        fill='#48AF80'
      />
      <path
        d='M14.1869 102.673C14.6008 102.673 14.9363 102.337 14.9363 101.923C14.9363 101.509 14.6008 101.174 14.1869 101.174C13.773 101.174 13.4375 101.509 13.4375 101.923C13.4375 102.337 13.773 102.673 14.1869 102.673Z'
        fill='#48AF80'
      />
      <path
        d='M84.8916 81.5087C84.9583 77.9023 85 74.9631 85 74.9631C85.0009 74.4282 84.7789 73.9983 84.5027 74C84.2265 74.0017 84.0018 74.4368 84 74.9734C84 74.9734 84.0258 77.9126 84.0728 81.519C84.0702 81.5551 84.0675 81.5913 84.0666 81.6308C84.0666 81.6308 84.2087 98 84.4378 98C84.667 97.9966 84.897 81.6222 84.897 81.6222C84.897 81.5844 84.8943 81.5448 84.8916 81.5087Z'
        fill='white'
      />
      <path
        d='M127.892 112.509C127.958 108.902 128 105.963 128 105.963C128.001 105.428 127.779 104.998 127.503 105C127.226 105.002 127.002 105.437 127 105.973C127 105.973 127.026 108.913 127.073 112.519C127.07 112.555 127.067 112.591 127.067 112.631C127.067 112.631 127.209 129 127.438 129C127.667 128.997 127.897 112.622 127.897 112.622C127.897 112.584 127.894 112.545 127.892 112.509Z'
        fill='white'
      />
      <path
        d='M32.8916 121.509C32.9583 117.902 33 114.963 33 114.963C33.0009 114.428 32.7789 113.998 32.5027 114C32.2265 114.002 32.0018 114.437 32 114.973C32 114.973 32.0258 117.913 32.0728 121.519C32.0702 121.555 32.0675 121.591 32.0666 121.631C32.0666 121.631 32.2087 138 32.4378 138C32.667 137.997 32.897 121.622 32.897 121.622C32.897 121.584 32.8943 121.545 32.8916 121.509Z'
        fill='white'
      />
      <path
        d='M84.8916 108.509C84.9583 104.902 85 101.963 85 101.963C85.0009 101.428 84.7789 100.998 84.5027 101C84.2265 101.002 84.0018 101.437 84 101.973C84 101.973 84.0258 104.913 84.0728 108.519C84.0702 108.555 84.0675 108.591 84.0666 108.631C84.0666 108.631 84.2087 125 84.4378 125C84.667 124.997 84.897 108.622 84.897 108.622C84.897 108.584 84.8943 108.545 84.8916 108.509Z'
        fill='white'
      />
      <path
        d='M127.892 139.509C127.958 135.902 128 132.963 128 132.963C128.001 132.428 127.779 131.998 127.503 132C127.226 132.002 127.002 132.437 127 132.973C127 132.973 127.026 135.913 127.073 139.519C127.07 139.555 127.067 139.591 127.067 139.631C127.067 139.631 127.209 156 127.438 156C127.667 155.997 127.897 139.622 127.897 139.622C127.897 139.584 127.894 139.545 127.892 139.509Z'
        fill='white'
      />
      <path
        d='M39.0766 79.3301C38.4533 79.0684 37.7359 79.3616 37.4742 79.9849C37.2125 80.6082 37.5057 81.3256 38.1289 81.5873C38.7522 81.8489 39.4696 81.5558 39.7313 80.9325C39.993 80.3092 39.6999 79.5918 39.0766 79.3301ZM37.5031 83.078C37.0765 82.8989 36.5855 83.0995 36.4064 83.5261C36.2273 83.9527 36.4279 84.4437 36.8545 84.6228C37.2811 84.8019 37.7721 84.6012 37.9512 84.1747C38.1303 83.7481 37.9297 83.2571 37.5031 83.078ZM36.1687 86.2562C35.7541 86.0821 35.2768 86.2771 35.1028 86.6917C34.9287 87.1064 35.1237 87.5836 35.5383 87.7577C35.953 87.9318 36.4302 87.7368 36.6043 87.3221C36.7784 86.9075 36.5834 86.4302 36.1687 86.2562Z'
        fill='white'
      />
      <path
        d='M41.3211 73.9961L40.3211 75.1556L38.9094 74.5629L39.7031 75.8722L38.7031 77.0317L40.1937 76.6815L40.9875 77.9908L41.1149 76.465L42.6055 76.1147L41.1937 75.522L41.3211 73.9961Z'
        fill='white'
      />
      <path
        d='M18.4325 87.7657C17.8092 87.504 17.0918 87.7971 16.8301 88.4204C16.5684 89.0437 16.8616 89.7611 17.4849 90.0228C18.1082 90.2845 18.8256 89.9914 19.0873 89.3681C19.3489 88.7448 19.0558 88.0274 18.4325 87.7657Z'
        fill='white'
      />
      <path
        d='M22.0458 90.7841C22.3031 90.3996 22.1999 89.8793 21.8154 89.6221C21.4308 89.3648 20.9105 89.468 20.6533 89.8525C20.396 90.2371 20.4992 90.7574 20.8838 91.0146C21.2683 91.2719 21.7886 91.1687 22.0458 90.7841Z'
        fill='white'
      />
      <path
        d='M23.759 91.3293C23.5849 91.744 23.7799 92.2212 24.1946 92.3953C24.6092 92.5694 25.0865 92.3744 25.2606 91.9598C25.4346 91.5451 25.2396 91.0679 24.825 90.8938C24.4103 90.7197 23.9331 90.9147 23.759 91.3293Z'
        fill='white'
      />
      <path
        d='M13.9689 86.389L13.6186 84.8984L13.0258 86.3102L11.5 86.1828L12.6595 87.1828L12.0668 88.5945L13.3761 87.8008L14.5356 88.8008L14.1854 87.3102L15.4947 86.5165L13.9689 86.389Z'
        fill='white'
      />
      <path
        d='M26.87 108.412C26.2467 108.15 25.5293 108.443 25.2676 109.067C25.0059 109.69 25.2991 110.407 25.9224 110.669C26.5457 110.931 27.2631 110.638 27.5248 110.014C27.7864 109.391 27.4933 108.674 26.87 108.412ZM28.1444 105.376C27.7179 105.197 27.2269 105.398 27.0478 105.824C26.8687 106.251 27.0693 106.742 27.4959 106.921C27.9225 107.1 28.4135 106.9 28.5926 106.473C28.7717 106.046 28.571 105.555 28.1444 105.376ZM29.4606 102.241C29.046 102.067 28.5687 102.262 28.3946 102.677C28.2206 103.092 28.4156 103.569 28.8302 103.743C29.2449 103.917 29.7221 103.722 29.8962 103.307C30.0703 102.893 29.8753 102.416 29.4606 102.241Z'
        fill='white'
      />
      <path
        d='M24.8102 113.317L24.0164 112.008L23.889 113.534L22.3984 113.884L23.8102 114.477L23.6828 116.003L24.6828 114.843L26.0945 115.436L25.3008 114.126L26.3008 112.967L24.8102 113.317Z'
        fill='white'
      />
      <path
        d='M47.5185 99.9766C46.8952 99.7149 46.1777 100.008 45.9161 100.631C45.6544 101.255 45.9475 101.972 46.5708 102.234C47.1941 102.495 47.9115 102.202 48.1732 101.579C48.4349 100.956 48.1417 100.238 47.5185 99.9766Z'
        fill='white'
      />
      <path
        d='M44.437 99.96C44.5912 99.5238 44.3626 99.0452 43.9264 98.891C43.4902 98.7368 43.0115 98.9654 42.8573 99.4016C42.7031 99.8378 42.9317 100.316 43.3679 100.471C43.8041 100.625 44.2828 100.396 44.437 99.96Z'
        fill='white'
      />
      <path
        d='M40.8094 97.6047C40.3947 97.4306 39.9175 97.6256 39.7434 98.0403C39.5693 98.4549 39.7643 98.9322 40.179 99.1063C40.5936 99.2804 41.0709 99.0853 41.2449 98.6707C41.419 98.2561 41.224 97.7788 40.8094 97.6047Z'
        fill='white'
      />
      <path
        d='M52.9358 101.405L51.6264 102.199L50.4669 101.199L50.8172 102.69L49.5078 103.484L51.0337 103.611L51.384 105.102L51.9767 103.69L53.5025 103.817L52.343 102.817L52.9358 101.405Z'
        fill='white'
      />
      <path
        d='M33.3851 83.6105C33.3851 83.1223 32.9894 82.7266 32.5012 82.7266C32.013 82.7266 31.6172 83.1223 31.6172 83.6105C31.6172 84.0987 32.013 84.4945 32.5012 84.4945C32.9894 84.4945 33.3851 84.0987 33.3851 83.6105Z'
        fill='#FF9154'
      />
      <path
        d='M32.5034 86.8721C32.8375 86.8721 33.1084 86.6012 33.1084 86.2671C33.1084 85.933 32.8375 85.6621 32.5034 85.6621C32.1693 85.6621 31.8984 85.933 31.8984 86.2671C31.8984 86.6012 32.1693 86.8721 32.5034 86.8721Z'
        fill='#FF9154'
      />
      <path
        d='M32.5021 89.3265C32.8269 89.3265 33.0902 89.0632 33.0902 88.7385C33.0902 88.4137 32.8269 88.1504 32.5021 88.1504C32.1773 88.1504 31.9141 88.4137 31.9141 88.7385C31.9141 89.0632 32.1773 89.3265 32.5021 89.3265Z'
        fill='#FF9154'
      />
      <path
        d='M32.5022 78.5488L32.1605 79.6005H31.0547L31.9493 80.2505L31.6076 81.3022L32.5022 80.6522L33.3968 81.3022L33.0551 80.2505L33.9497 79.6005H32.8439L32.5022 78.5488Z'
        fill='#FF9154'
      />
      <path
        d='M21.2495 95.873C21.7314 95.7948 22.0587 95.3408 21.9805 94.8589C21.9023 94.377 21.4482 94.0497 20.9663 94.1279C20.4844 94.2061 20.1571 94.6602 20.2353 95.1421C20.3135 95.624 20.7676 95.9512 21.2495 95.873Z'
        fill='#FF9154'
      />
      <path
        d='M23.769 95.6045C24.1032 95.6045 24.374 95.3336 24.374 94.9995C24.374 94.6654 24.1032 94.3945 23.769 94.3945C23.4349 94.3945 23.1641 94.6654 23.1641 94.9995C23.1641 95.3336 23.4349 95.6045 23.769 95.6045Z'
        fill='#FF9154'
      />
      <path
        d='M26.2365 94.4121C25.9117 94.4121 25.6484 94.6754 25.6484 95.0002C25.6484 95.3249 25.9117 95.5882 26.2365 95.5882C26.5613 95.5882 26.8246 95.3249 26.8246 95.0002C26.8246 94.6754 26.5613 94.4121 26.2365 94.4121Z'
        fill='#FF9154'
      />
      <path
        d='M17.0986 96.4478L17.7486 95.5532L18.8003 95.8949L18.1503 95.0003L18.8003 94.1056L17.7486 94.4474L17.0986 93.5527V94.6586L16.0469 95.0003L17.0986 95.342V96.4478Z'
        fill='#FF9154'
      />
      <path
        d='M32.5012 107.274C32.9894 107.274 33.3851 106.878 33.3851 106.39C33.3851 105.902 32.9894 105.506 32.5012 105.506C32.013 105.506 31.6172 105.902 31.6172 106.39C31.6172 106.878 32.013 107.274 32.5012 107.274Z'
        fill='#FF9154'
      />
      <path
        d='M32.5034 104.339C32.8375 104.339 33.1084 104.068 33.1084 103.734C33.1084 103.4 32.8375 103.129 32.5034 103.129C32.1693 103.129 31.8984 103.4 31.8984 103.734C31.8984 104.068 32.1693 104.339 32.5034 104.339Z'
        fill='#FF9154'
      />
      <path
        d='M32.5021 101.848C32.8269 101.848 33.0902 101.585 33.0902 101.26C33.0902 100.935 32.8269 100.672 32.5021 100.672C32.1773 100.672 31.9141 100.935 31.9141 101.26C31.9141 101.585 32.1773 101.848 32.5021 101.848Z'
        fill='#FF9154'
      />
      <path
        d='M33.3968 108.697L32.5022 109.347L31.6076 108.697L31.9493 109.749L31.0547 110.399H32.1605L32.5022 111.451L32.8439 110.399H33.9497L33.0551 109.749L33.3968 108.697Z'
        fill='#FF9154'
      />
      <path
        d='M44.0308 95.873C44.5127 95.7948 44.8399 95.3408 44.7618 94.8589C44.6836 94.377 44.2295 94.0497 43.7476 94.1279C43.2657 94.2061 42.9384 94.6602 43.0166 95.1421C43.0948 95.624 43.5488 95.9512 44.0308 95.873Z'
        fill='#FF9154'
      />
      <path
        d='M40.6274 94.9995C40.6274 95.3337 40.8983 95.6045 41.2324 95.6045C41.5665 95.6045 41.8374 95.3337 41.8374 94.9995C41.8374 94.6654 41.5665 94.3945 41.2324 94.3945C40.8983 94.3945 40.6274 94.6654 40.6274 94.9995ZM38.7599 94.4115C38.4352 94.4115 38.1719 94.6748 38.1719 94.9995C38.1719 95.3243 38.4352 95.5876 38.7599 95.5876C39.0847 95.5876 39.348 95.3243 39.348 94.9995C39.348 94.6748 39.0847 94.4115 38.7599 94.4115Z'
        fill='#FF9154'
      />
      <path
        d='M47.897 93.5527L47.247 94.4474L46.1953 94.1056L46.8453 95.0003L46.1953 95.8949L47.247 95.5532L47.897 96.4478V95.342L48.9487 95.0003L47.897 94.6586V93.5527Z'
        fill='#FF9154'
      />
      <path
        d='M27.6699 79.9371C27.4142 79.3114 26.6996 79.0113 26.0738 79.267C25.4481 79.5227 25.1481 80.2373 25.4038 80.8631C25.6595 81.4889 26.374 81.7889 26.9998 81.5332C27.6256 81.2775 27.9256 80.5629 27.6699 79.9371ZM28.7037 83.4884C28.5287 83.0601 28.0396 82.8548 27.6113 83.0298C27.1831 83.2048 26.9777 83.6939 27.1527 84.1221C27.3277 84.5504 27.8168 84.7557 28.2451 84.5807C28.6734 84.4057 28.8787 83.9167 28.7037 83.4884ZM28.9151 86.2206C28.4988 86.3908 28.2993 86.8661 28.4694 87.2824C28.6395 87.6987 29.1148 87.8983 29.5311 87.7282C29.9474 87.5581 30.147 87.0827 29.9769 86.6664C29.8068 86.2501 29.3314 86.0506 28.9151 86.2206Z'
        fill='#FF9154'
      />
      <path
        d='M24.178 77.9099L24.9844 76.6082L26.4715 76.9727L25.4826 75.8037L26.2889 74.502L24.8715 75.0812L23.8827 73.9121L23.9955 75.4391L22.5781 76.0182L24.0652 76.3828L24.178 77.9099Z'
        fill='#FF9154'
      />
      <path
        d='M18.3476 102.106C18.9777 101.862 19.2901 101.152 19.0454 100.522C18.8006 99.892 18.0914 99.5796 17.4613 99.8244C16.8311 100.069 16.5187 100.778 16.7635 101.409C17.0083 102.039 17.7175 102.351 18.3476 102.106Z'
        fill='#FF9154'
      />
      <path
        d='M20.9898 98.7988C20.5615 98.9738 20.3562 99.4628 20.5312 99.8911C20.7062 100.319 21.1953 100.525 21.6235 100.35C22.0518 100.175 22.2571 99.6857 22.0822 99.2574C21.9072 98.8291 21.4181 98.6238 20.9898 98.7988ZM24.1678 97.5256C23.7515 97.6957 23.5519 98.171 23.722 98.5873C23.8921 99.0036 24.3675 99.2032 24.7838 99.0331C25.2001 98.863 25.3997 98.3876 25.2296 97.9713C25.0595 97.555 24.5841 97.3555 24.1678 97.5256Z'
        fill='#FF9154'
      />
      <path
        d='M14.4747 101.027L13.3056 102.016L12.004 101.21L12.5831 102.627L11.4141 103.616L12.941 103.503L13.5202 104.921L13.8848 103.434L15.4118 103.321L14.1101 102.514L14.4747 101.027Z'
        fill='#FF9154'
      />
      <path
        d='M39.1996 110.578C39.7399 110.172 39.8484 109.405 39.4421 108.864C39.0358 108.324 38.2685 108.215 37.7282 108.622C37.188 109.028 37.0794 109.795 37.4857 110.336C37.892 110.876 38.6594 110.984 39.1996 110.578Z'
        fill='#FF9154'
      />
      <path
        d='M37.8458 105.878C37.6708 105.449 37.1818 105.244 36.7535 105.419C36.3252 105.594 36.1199 106.083 36.2949 106.511C36.4699 106.94 36.9589 107.145 37.3872 106.97C37.8155 106.795 38.0208 106.306 37.8458 105.878ZM36.5292 102.717C36.3591 102.301 35.8837 102.102 35.4674 102.272C35.0511 102.442 34.8516 102.917 35.0216 103.333C35.1918 103.75 35.6671 103.949 36.0834 103.779C36.4997 103.609 36.6993 103.134 36.5292 102.717Z'
        fill='#FF9154'
      />
      <path
        d='M40.9375 113.617L40.8247 112.09L40.0184 113.392L38.5312 113.027L39.5201 114.196L38.7138 115.498L40.1312 114.919L41.12 116.088L41.0072 114.561L42.4246 113.981L40.9375 113.617Z'
        fill='#FF9154'
      />
      <path
        d='M47.479 90.1982C48.1215 89.988 48.472 89.2968 48.2619 88.6543C48.0517 88.0118 47.3605 87.6613 46.718 87.8715C46.0755 88.0816 45.725 88.7728 45.9352 89.4153C46.1453 90.0578 46.8365 90.4083 47.479 90.1982Z'
        fill='#FF9154'
      />
      <path
        d='M44.0114 91.2013C44.4397 91.0263 44.645 90.5372 44.47 90.109C44.295 89.6807 43.806 89.4754 43.3777 89.6504C42.9494 89.8253 42.7441 90.3144 42.9191 90.7427C43.0941 91.1709 43.5831 91.3763 44.0114 91.2013ZM40.2174 90.967C39.8011 91.1371 39.6015 91.6124 39.7716 92.0287C39.9417 92.445 40.4171 92.6446 40.8334 92.4745C41.2497 92.3044 41.4493 91.829 41.2792 91.4127C41.1091 90.9965 40.6337 90.7969 40.2174 90.967Z'
        fill='#FF9154'
      />
      <path
        d='M52.0567 86.4955L51.4776 85.0781L51.113 86.5652L49.5859 86.6781L50.8876 87.4844L50.5231 88.9715L51.6921 87.9826L52.9938 88.7889L52.4146 87.3715L53.5837 86.3827L52.0567 86.4955Z'
        fill='#FF9154'
      />
      <path
        d='M23.8214 86.3212C23.4762 86.6664 23.4762 87.2261 23.8214 87.5713C24.1666 87.9165 24.7263 87.9165 25.0716 87.5713C25.4168 87.2261 25.4168 86.6664 25.0716 86.3212C24.7263 85.9759 24.1666 85.9759 23.8214 86.3212ZM25.8972 88.3969C25.6609 88.6332 25.6609 89.0163 25.8972 89.2525C26.1334 89.4888 26.5165 89.4888 26.7528 89.2525C26.989 89.0163 26.989 88.6332 26.7528 88.3969C26.5165 88.1607 26.1334 88.1607 25.8972 88.3969ZM27.6574 90.1572C27.4278 90.3869 27.4278 90.7592 27.6574 90.9889C27.8871 91.2185 28.2594 91.2185 28.4891 90.9889C28.7188 90.7592 28.7188 90.3869 28.4891 90.1572C28.2594 89.9276 27.8871 89.9276 27.6574 90.1572Z'
        fill='#48AF80'
      />
      <path
        d='M22.1802 85.9473L22.3532 84.8551L23.4453 84.6821L22.4601 84.1801L22.6331 83.0879L21.8511 83.8698L20.8658 83.3678L21.3679 84.3531L20.5859 85.135L21.6781 84.962L22.1802 85.9473Z'
        fill='#48AF80'
      />
      <path
        d='M23.8214 102.43C23.4762 102.775 23.4762 103.335 23.8214 103.68C24.1666 104.025 24.7263 104.025 25.0716 103.68C25.4168 103.335 25.4168 102.775 25.0716 102.43C24.7263 102.084 24.1666 102.084 23.8214 102.43ZM25.8972 100.748C25.6609 100.985 25.6609 101.368 25.8972 101.604C26.1334 101.84 26.5165 101.84 26.7528 101.604C26.989 101.368 26.989 100.985 26.7528 100.748C26.5165 100.512 26.1334 100.512 25.8972 100.748ZM27.6574 99.0121C27.4278 99.2417 27.4278 99.6141 27.6574 99.8437C27.8871 100.073 28.2594 100.073 28.4891 99.8437C28.7188 99.6141 28.7188 99.2417 28.4891 99.0121C28.2594 98.7824 27.8871 98.7824 27.6574 99.0121Z'
        fill='#48AF80'
      />
      <path
        d='M22.3532 105.145L22.1802 104.053L21.6782 105.038L20.5859 104.865L21.3679 105.647L20.8658 106.632L21.8511 106.13L22.6331 106.912L22.4601 105.82L23.4453 105.318L22.3532 105.145Z'
        fill='#48AF80'
      />
      <path
        d='M39.9257 102.43C39.5805 102.775 39.5805 103.335 39.9257 103.68C40.271 104.025 40.8307 104.025 41.1759 103.68C41.5211 103.335 41.5211 102.775 41.1759 102.43C40.8307 102.084 40.271 102.084 39.9257 102.43ZM38.2445 100.748C38.0082 100.985 38.0082 101.368 38.2445 101.604C38.4808 101.84 38.8638 101.84 39.1001 101.604C39.3364 101.368 39.3364 100.985 39.1001 100.748C38.8638 100.512 38.4808 100.512 38.2445 100.748ZM36.5082 99.0121C36.2785 99.2417 36.2785 99.6141 36.5082 99.8437C36.7378 100.073 37.1102 100.073 37.3398 99.8437C37.5695 99.6141 37.5695 99.2417 37.3398 99.0121C37.1102 98.7824 36.7378 98.7824 36.5082 99.0121Z'
        fill='#48AF80'
      />
      <path
        d='M43.3219 105.038L42.8199 104.053L42.6469 105.145L41.5547 105.318L42.54 105.82L42.367 106.912L43.1489 106.13L44.1342 106.632L43.6322 105.647L44.4141 104.865L43.3219 105.038Z'
        fill='#48AF80'
      />
      <path
        d='M39.9257 86.3214C39.5805 86.6666 39.5805 87.2263 39.9257 87.5716C40.271 87.9168 40.8307 87.9168 41.1759 87.5716C41.5211 87.2263 41.5211 86.6666 41.1759 86.3214C40.8307 85.9762 40.271 85.9762 39.9257 86.3214ZM38.2445 88.3972C38.0082 88.6335 38.0082 89.0165 38.2445 89.2528C38.4808 89.489 38.8638 89.489 39.1001 89.2528C39.3364 89.0165 39.3364 88.6335 39.1001 88.3972C38.8638 88.1609 38.4808 88.1609 38.2445 88.3972ZM36.5082 90.1574C36.2785 90.3871 36.2785 90.7594 36.5082 90.9891C36.7378 91.2188 37.1102 91.2188 37.3398 90.9891C37.5695 90.7594 37.5695 90.3871 37.3398 90.1574C37.1102 89.9278 36.7378 89.9278 36.5082 90.1574Z'
        fill='#48AF80'
      />
      <path
        d='M44.1342 83.3678L43.1489 83.8698L42.367 83.0879L42.54 84.1801L41.5547 84.6821L42.6469 84.8551L42.8199 85.9473L43.3219 84.962L44.4141 85.135L43.6322 84.3531L44.1342 83.3678Z'
        fill='#48AF80'
      />
    </svg>
  )
}

const SVGDashInfluencedWalkInsTreeIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashInfluencedWalkInsTreeIcon
