import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import organizationActions from '../../store/modules/organizationActions'
import { userSelector } from '../../store/selectors/selectors'
import TreeSelectForm from '../Form/TreeSelectForm'
import TreeSelectFormSecondary from '../Form/TreeSelectForm/TreeSelectFormSecondary'

export function transformData(data, enabledOnlyOrganizations) {
  return data.map((item) => {
    const newItem = {}
    Object.keys(item).forEach((key) => {
      if (enabledOnlyOrganizations) {
        newItem.disabled = true
      }
      if (key === 'name') {
        newItem['title'] = item[key]
      } else if (key === '_id') {
        newItem['value'] = item[key]
      } else if (key === 'locations') {
        newItem['children'] = transformData(item[key])
      } else {
        newItem[key] = item[key]
      }
    })
    return newItem
  })
}

const SelectTreeFormOrganizationLocation = (props) => {
  const user = useSelector(userSelector)

  const hasAccessToMultipleOrganizations =
    user.accessibleOrganizations && user.accessibleOrganizations.length > 0

  const [isDataTree, setIsDataTree] = useState(true)
  const [treeData, setTreeData] = useState({
    list: [],
    total: 0,
  })
  const [treePage, setTreePage] = useState(0)

  useEffect(() => {
    if (user.isSuperAdmin || hasAccessToMultipleOrganizations) {
      const fill = async () => {
        const result = await organizationActions.getOrganizationsLocationsList(
          treePage,
          100,
          '',
          {
            enabled: true,
          }
        )
        if (result.success) {
          const list = transformData(result.data)
          const newTreeData = treePage > 0 ? [...treeData.list, ...list] : list
          setTreeData({
            list: newTreeData,
            total: result.max,
          })
          props.gettingTreeData && props.gettingTreeData(newTreeData)
          if (newTreeData.length < result.max) {
            setTreePage(treePage + 1)
          }
        }
        setIsDataTree(false)
      }
      fill()
    }
  }, [treePage])

  return props.secondary ? (
    <TreeSelectFormSecondary
      loading={isDataTree}
      name={props.name}
      label={props.label}
      treeData={treeData.list || []}
      treeCheckable={true}
      multipleNewStyle
      placeholder={props.placeholder}
      message={props.message}
      required={props.required}
      disabled={props.disabled}
    />
  ) : (
    <TreeSelectForm
      loading={isDataTree}
      name={props.name}
      label={props.label}
      treeData={treeData.list || []}
      treeCheckable={true}
      multipleNewStyle
      placeholder={props.placeholder}
      message={props.message}
      required={props.required}
      disabled={props.disabled}
    />
  )
}

export default SelectTreeFormOrganizationLocation
