import React, { useEffect, useRef, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import SVGWorkflowSMSIcon from '../../../../icons/SVG/SVGWorkflowSMSIcon'
import { getText } from '../../../../lang'
import AddVariableInMessage from '../../../../components/AddVariableInMessage'
import InputFormTextArea from '../../../../components/Form/InputFormTextArea'
import MatRow from '../../../../components/MatRow'

const WorkflowActionSmsItem = ({ form, onRemove, field, fieldItemName }) => {
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [wrongDynamicTags, setWrongDynamicTags] = useState(false)
  const textAreaInputRef = useRef()

  const listVariables = [
    {
      name: 'customer_first_name',
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
    },
    {
      name: 'customer_last_name',
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
    },
    {
      name: 'organization_name',
      label: 'organization_name',
      value: '{{organization_name}}',
    },
    {
      name: 'location_name',
      label: 'location_name',
      value: '{{location_name}}',
    },
    {
      name: 'review_invite_url',
      label: 'review_invite_url',
      value: '{{review_invite_url}}',
    },
  ]

  const addNameInMessenger = (variable) => {
    const name = [...fieldItemName, 'config', 'message']
    const currentContent = form.getFieldValue(name)
    const newContent = [
      currentContent && currentContent.slice(0, currentCursorPosition),
      variable,
      currentContent && currentContent.slice(currentCursorPosition),
    ].join('')
    form.setFieldValue(name, newContent)
    setCurrentCursorPosition(currentCursorPosition + variable.length)
    textAreaInputRef.current &&
      textAreaInputRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  const smsValue = Form.useWatch([...fieldItemName, 'config', 'message'], form)

  const checkListValues = () => {
    const regex = /\{\{([^{}]+)\}\}/g
    let match = regex.exec(smsValue)
    while (match != null) {
      const value = match[0]
      const name = match[1]
      const listItem = listVariables.find((item) => item.value === value)
      if (!listItem || listItem.name !== name) {
        return false
      }
      match = regex.exec(smsValue)
    }

    return true
  }

  useEffect(() => {
    const existingValue = checkListValues()
    if (existingValue) {
      setWrongDynamicTags(true)
    } else {
      setWrongDynamicTags(false)
    }
  }, [smsValue])

  return (
    <div className='workflow-action-item-wrapper'>
      <MatRow flexStart className='workflow-action-item-row'>
        <div className='workflow-action-item-icon' style={{ color: '#5CB7D2' }}>
          <SVGWorkflowSMSIcon width={48} height={48} />
          {getText('WORD_SMS_UC')}
        </div>
        <div className='workflow-action-item-textarea-wrapper'>
          <InputFormTextArea
            ref={textAreaInputRef}
            name={[field.name, 'config', 'message']}
            allowClear={false}
            maxRows={2}
            placeholder={getText('WORD_YOUR_SMS')}
            onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
            onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
            onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
            required
            maxLength={!wrongDynamicTags ? 1 : false}
            maxLenghthMsg={
              !wrongDynamicTags ? getText('PLEASE_USE_CORRECT_DYNAMIC_TAGS') : false
            }
          />
        </div>
      </MatRow>
      <AddVariableInMessage onClick={addNameInMessenger} data={listVariables} />
      <CloseOutlined
        style={{ color: '#77808d', fontSize: '10px' }}
        className='workflow-remove-icon'
        onClick={() => {
          onRemove && onRemove()
        }}
      />
    </div>
  )
}

export default WorkflowActionSmsItem
