import React, { useEffect, memo } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from 'antd'
import { useSelector } from 'react-redux'
import authActions from '../../store/modules/authActions'
import { isMobileAppSelector } from '../../store/selectors/selectors'

const { Content } = Layout

const OuterLayout = ({ isBlueExtension, children }) => {
  const location = useLocation()

  const isMobileApp = useSelector(isMobileAppSelector)

  useEffect(() => {
    if (authActions.getAppTheme()) {
      document.body.setAttribute('data-theme', authActions.getAppTheme())
    }
  }, [location.pathname])

  return (
    <Layout
      hasSider
      className={`mat_main_layout mat-outer-layout ${
        isMobileApp ? 'mat_mobile_outer_layout' : ''
      } ${isBlueExtension ? 'mat_blue_extension_outer_layout' : ''}`}
    >
      <Layout className='mat_layout'>
        <Content className='mat_layout_content'>{children}</Content>
      </Layout>
    </Layout>
  )
}

export default memo(OuterLayout)
