import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Iconly/Light/Chat'>
        <path
          id='Vector'
          d='M3.2208 16.4017H10.3382V17.8014H8.89248C8.22069 17.8014 7.96258 18.4552 8.38648 18.9706L10.5134 21.5764C10.7896 21.917 11.25 21.917 11.5352 21.5764L13.6441 18.9706C14.0397 18.4642 13.7914 17.8014 13.1467 17.8014H11.6825V16.4017H18.7815C20.1907 16.4017 20.8994 15.7205 20.8994 14.3023V11.0706C20.8994 9.66183 20.1907 8.98062 18.7819 8.98062H11.6829V7.51644H18.7819C20.1907 7.51644 20.8994 6.82579 20.8994 5.41701V2.17594C20.8994 0.767152 20.1907 0.0859375 18.7819 0.0859375H3.22041C1.81162 0.0859375 1.10291 0.767152 1.10291 2.17594V5.41701C1.10291 6.82579 1.81162 7.51644 3.22041 7.51644H10.3378V8.98022H3.2208C1.81201 8.98022 1.1033 9.66183 1.1033 11.0702V14.3023C1.1033 15.7205 1.81201 16.4017 3.2208 16.4017ZM3.33119 6.12612C2.78826 6.12612 2.49362 5.84051 2.49362 5.26969V2.33269C2.49362 1.75244 2.78826 1.47626 3.33119 1.47626H18.6711C19.2144 1.47626 19.509 1.75244 19.509 2.33269V5.26969C19.509 5.84051 19.2144 6.12612 18.6711 6.12612H3.33119ZM3.33119 15.0204C2.78826 15.0204 2.49362 14.7352 2.49362 14.164V11.227C2.49362 10.6471 2.78826 10.3705 3.33119 10.3705H18.6711C19.2144 10.3705 19.509 10.6471 19.509 11.227V14.1644C19.509 14.7352 19.2144 15.0208 18.6711 15.0208L3.33119 15.0204Z'
          fill='black'
        />
      </g>
    </svg>
  )
}

const SVGOrgSequenceIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgSequenceIcon
