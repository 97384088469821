import React from 'react'
import { Divider, Flex, Form } from 'antd'
import { getText } from '../../../../lang'
import MatRow from '../../../../components/MatRow'
// import SelectForm from '../../../../components/Form/SelectForm'
import CustomTimePicker from '../../../../components/CustomTimePicker/CustomTimePicker'
import MyDefaultSelect from '../../../../components/MyDefaultSelect/MyDefaultSelect'

const SingleWorkingDayVoiceAI = (props) => {
  const { field, form, index } = props

  const days = [
    getText('WORD_SUNDAY'),
    getText('WORD_MONDAY'),
    getText('WORD_TUESDAY'),
    getText('WORD_WEDNESDAY'),
    getText('WORD_THURSDAY'),
    getText('WORD_FRIDAY'),
    getText('WORD_SATURDAY'),
  ]

  const item = Form.useWatch(['workingHours', field.name], form)

  const [fromTime, fromPeriod] = item?.from?.split(' ') || ''
  const [toTime, toPeriod] = item?.to?.split(' ') || ''

  const [hoursFrom, minutesFrom] = fromTime?.split(':') || ''
  const [hoursTo, minutesTo] = toTime?.split(':') || ''

  // const timeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$/

  return (
    <MatRow className='single-working-day'>
      <MatRow className='single-working-day-row'>
        <span className='days'>
          {days.find((day, index) => {
            return field.key === index
          })}
        </span>
        <MyDefaultSelect
          disabled={props?.disabled}
          className={'store_open_state'}
          name={[field.name, 'isOpen']}
          options={[
            {
              label: getText('TEXT_STORE_IS_OPEN'),
              value: true,
            },
            {
              label: getText('TEXT_STORE_IS_CLOSED'),
              value: false,
            },
          ]}
          showSearch={false}
          isForm
          isLast
          label={null}
        />
      </MatRow>
      {Boolean(item?.isOpen) && (
        <Flex align='center'>
          <CustomTimePicker
            hourValueInit={hoursFrom}
            minuteValueInit={minutesFrom}
            systemHourInit={fromPeriod}
            onChange={(val) => {
              form.setFieldValue(['workingHours', field.name, 'from'], val)
            }}
            index={`${index}0`}
            disabled={props?.disabled}
          />
          <Divider />
          <CustomTimePicker
            hourValueInit={hoursTo}
            minuteValueInit={minutesTo}
            systemHourInit={toPeriod}
            onChange={(val) => {
              form.setFieldValue(['workingHours', field.name, 'to'], val)
            }}
            index={`${index}1`}
            disabled={props?.disabled}
          />
        </Flex>
      )}
    </MatRow>
  )
}

export default SingleWorkingDayVoiceAI
