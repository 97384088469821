import React, { createContext, useContext, useState } from 'react'

const LayoutContext = createContext()

export const LayoutProvider = ({ children }) => {
  const [footerButtons, setFooterButtons] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageTitle, setPageTitle] = useState('')

  return (
    <LayoutContext.Provider
      value={{
        footerButtons,
        setFooterButtons,
        loading,
        setLoading,
        pageTitle,
        setPageTitle,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export const useLayout = () => useContext(LayoutContext)
