import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.479 9.9987C18.479 14.681 14.683 18.4779 9.9998 18.4779C5.31746 18.4779 1.52063 14.681 1.52063 9.9987C1.52063 5.31545 5.31746 1.51953 9.9998 1.51953C14.683 1.51953 18.479 5.31545 18.479 9.9987Z'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.8415 10.7023L9.68896 10.6345V6.19141'
        stroke='#0E0E0E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGOrgOpeningHoursIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgOpeningHoursIcon
