import React from 'react'
import { getText } from '../../lang'
import SVGSearch from '../../icons/SVG/SVGSearch'
import Div from '../Div/Div'
import MyDefaultInputText from '../MyDefaultInputText/MyDefaultInputText'
import './MyDefaultSearchInput.scss'

const MyDefaultSearchInput = ({
  placeholder,
  value,
  onChange,
  className,
  fullWidth,
  color = 'white',
}) => {
  return (
    <Div
      className={`my_default_input_search ${color} ${className || ''}`}
      style={{ maxWidth: fullWidth ? '100%' : 327 }}
    >
      <SVGSearch />
      <MyDefaultInputText
        className='my_default_input_search--input'
        placeholder={placeholder || getText('WORD_SEARCH')}
        value={value}
        color={color}
        onChange={(e) => onChange(e.target.value)}
      />
    </Div>
  )
}

export default MyDefaultSearchInput
