import messageActions from '../../../store/modules/messageActions'
import meetActions from '../../../store/modules/meetActions'
import authActions from '../../../store/modules/authActions'
import { getText, getTextServerError } from '../../../lang'
import { myWebViewPost } from '../../../utils'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import {
  ACTIVIX,
  DEALER_SOCKET,
  ELEADS,
  VIN_SOLUTION,
} from '../../../config/constants'

export const MULTIPLE_CONVERSATIONS_TYPE_OBJECT = {
  selectAll: false,
  convEdit: false,
  selectButtonActionsType: '',
  unreadIds: [],
  archiveConversationsIds: [],
  unArchiveConversationIds: [],
  exceptConversationsIds: [],
  archivedConversationsTotalCount: 0,
  unreadConversationsTotalCount: 0,
  unArchivedConversationsTotalCount: 0,
}

export const EMAIL_DEFAULT_OBJECT = {
  newValue: '',
  newPlainTextValue: '',
}

export const SMS_DEFAULT_OBJECT = {
  inputMessageForTags: '',
  inputMessageForSending: '',
}

export const sendPhoneCall = async (phone, conversation) => {
  const phoneNumber = phone.replace('+', '')
  const result = await messageActions.setVoiceCall(phoneNumber, conversation)
  if (!result.success) {
    notifyError(getTextServerError(result.errMsg))
  }
}
export const sendCustomPhone = (conversation, isMobileApp) => {
  if (isMobileApp) {
    myWebViewPost(conversation.receiver_phone, 'phone')
  } else {
    window.location.href = `tel:${conversation.receiver_phone}`
  }
}

export function toggleArrayItem(array, item) {
  return array.includes(item) ? array.filter((oo) => oo !== item) : [...array, item]
}

export function toggleUnreadItem(array, item, isUnread) {
  return !array.includes(item) && isUnread
    ? [...array, item]
    : array.filter((oo) => oo !== item)
}

export function toggleExceptItem(array, item, condition) {
  return !array.includes(item) && condition
    ? [...array, item]
    : array.filter((oo) => oo !== item)
}

export function updateCountArchive(array, item, total) {
  return !array.includes(item) ? total + 1 : total - 1
}

export function updateCountUnread(array, item, unread, total) {
  return !array.includes(item) && unread
    ? total + 1
    : array.includes(item) && unread
      ? total - 1
      : total
}

export const handleArchiveadReadActions = (
  selectMultipleConversationsType,
  setselectMultipleConversationsType,
  item
) => {
  const updatedSelections = {
    ...selectMultipleConversationsType,
    archiveConversationsIds: toggleArrayItem(
      selectMultipleConversationsType.archiveConversationsIds,
      item._id
    ),
    unreadIds: toggleUnreadItem(
      selectMultipleConversationsType.unreadIds,
      item._id,
      item.is_unread
    ),
    exceptConversationsIds: toggleExceptItem(
      selectMultipleConversationsType.exceptConversationsIds,
      item._id,
      selectMultipleConversationsType.selectAll
    ),
    archivedConversationsTotalCount: updateCountArchive(
      selectMultipleConversationsType.archiveConversationsIds,
      item._id,
      selectMultipleConversationsType.archivedConversationsTotalCount
    ),
    unreadConversationsTotalCount: updateCountUnread(
      selectMultipleConversationsType.unreadIds,
      item._id,
      item.is_unread,
      selectMultipleConversationsType.unreadConversationsTotalCount
    ),
  }
  setselectMultipleConversationsType(updatedSelections)
}

export const MESSAGE_BLOCK_ANIMATIONS = {
  initial: { opacity: 0, scale: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.3, // duration of the animation
      ease: 'easeOut', // easing function
      delay: 0.2,
    },
  },
  exit: { opacity: 0, scale: 0 },
}

export const PROFILE_LETTERS_ANIMATION = {
  initial: { opacity: 0, scale: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.3, // duration of the animation
      ease: 'easeOut', // easing function
      delay: 0.2,
    },
  },
  exit: { opacity: 0, scale: 0 },
}

export const handleUnArchivedAction = (
  selectMultipleConversationsType,
  setselectMultipleConversationsType,
  item
) => {
  const updatedSelections = {
    ...selectMultipleConversationsType,
    unArchiveConversationIds: toggleArrayItem(
      selectMultipleConversationsType.unArchiveConversationIds,
      item._id
    ),
    unArchivedConversationsTotalCount: updateCountArchive(
      selectMultipleConversationsType.unArchiveConversationIds,
      item._id,
      selectMultipleConversationsType.unArchivedConversationsTotalCount
    ),
    exceptConversationsIds: toggleExceptItem(
      selectMultipleConversationsType.exceptConversationsIds,
      item._id,
      selectMultipleConversationsType.selectAll
    ),
  }
  setselectMultipleConversationsType(updatedSelections)
}
export const handleSendVideoCallRequest = async (
  conversation,
  setSaving,
  refreshConversation
) => {
  let obj = {}
  if (conversation.kind === 'messenger') {
    obj.messengerId = conversation.receiver.messengerId
  } else {
    obj.phone = conversation.receiver.phone.replace('+', '')
  }
  setSaving(true)
  let result = await meetActions.createRoom(obj, conversation)
  setSaving(false)
  if (result.success) {
    refreshConversation && refreshConversation(result.data.conversation)
  }
}

export const handleArchive = async (
  conversation,
  setsaving,
  setArchiveForExtension,
  removeConversationFromList
) => {
  setsaving(true)
  const result = await messageActions.archive(conversation._id)
  setsaving(false)
  if (result.success) {
    notifySuccess(getText('NTF_MSG_ARCHIVED'))
    setArchiveForExtension(result.data.conversation.is_archived)
    if (result.data.conversation.receiver.email) {
      removeConversationFromList &&
        removeConversationFromList(result.data.conversation, true, true)
    }
  } else {
    notifyError(
      result.errMsg
        ? result.errMsg.startsWith('child')
          ? getText('ERR_GENERAL')
          : result.errMsg
        : getText('ERR_GENERAL')
    )
  }
}

export const handleUnarchive = async (
  conversation,
  setsaving,
  setArchiveForExtension
) => {
  setsaving(true)
  const result = await messageActions.unarchive(conversation._id)
  setsaving(false)
  if (result.success) {
    notifySuccess(getText('NTF_MSG_UNARCHIVED'))
    setArchiveForExtension(result.data.conversation.is_archived)
  } else {
    notifyError(
      result.errMsg
        ? result.errMsg.startsWith('child')
          ? getText('ERR_GENERAL')
          : result.errMsg
        : getText('ERR_GENERAL')
    )
  }
}

export const handleMarkAsRead = async (conversation, setsaving) => {
  const unread = conversation.is_unread
  if (!unread) {
    return
  }
  setsaving(true)
  const result = await messageActions.markRead(conversation._id)
  setsaving(false)
  if (!result.success) {
    notifyError(
      result.errMsg
        ? result.errMsg.startsWith('child')
          ? getText('ERR_GENERAL')
          : result.errMsg
        : getText('ERR_GENERAL')
    )
  }
}

export const getCRMItem = (conversation, location) => {
  if (!location) {
    return false
  }

  const crmInfo = conversation?.crmInfo

  if (location.crmType === DEALER_SOCKET) {
    const entityId = crmInfo?.dealerSocket?.entityId || crmInfo?.crmCustomerId
    const eventId = crmInfo?.dealerSocket?.eventId || crmInfo?.crmLeadId

    // // V1
    // if (entityId) {
    //   return `https://bb.dealersocket.com/#/crm/view/contact/${entityId}`
    // }

    // v2
    if (entityId && eventId) {
      const dealerNumber = location.crmConfigs.dealerSocket.dealerNumber || ''
      const [siteId] = dealerNumber.split('_') // 7260_12 => 7260

      // https://inoxoft.atlassian.net/browse/MAT-3212
      return `https://bb.dealersocket.com/#/crm/sales/view/${entityId}/${eventId}?siteId=${siteId}&fullscreen`
    }

    // // V3
    // if (entityId) {
    //   return `https://bb.dealersocket.com/#/crm/customers/${entityId}`
    // }
  }

  if (location.crmType === ELEADS) {
    const customerId = crmInfo?.eLeads?.customer_id || crmInfo?.crmCustomerId
    if (customerId) {
      return `https://www.eleadcrm.com/evo2/fresh/eLead-V45/elead_track/newprospects/OpptyDetails.aspx?lPID=${customerId}`
    }
  }

  if (location.crmType === ACTIVIX) {
    const leadId = crmInfo?.activix?.lead_id || crmInfo?.crmLeadId
    if (leadId) {
      return `https://crm.activix.ca/leads/${leadId}`
    }
  }

  if (location.crmType === VIN_SOLUTION) {
    const customerId =
      crmInfo?.vinSolution?.contact?.ContactId || crmInfo?.crmCustomerId
    if (customerId) {
      return `https://apps.vinmanager.com/vinconnect/#/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=HIDE&rightpaneframe=/CarDashboard/Pages/CRM/CustomerDashboard.aspx?GlobalCustomerID=${customerId}`
    }
  }
}

export const sendCRM = async (
  conversation,
  selectedLocation,
  addToList,
  refreshConversation,
  setsaving
) => {
  setsaving && setsaving(true)
  if (conversation._id && selectedLocation._id) {
    let result = await messageActions.sendTranscriptionCRM(
      conversation._id,
      selectedLocation._id
    )
    if (result.success) {
      result.data.lastMessage._conversation_id = conversation._id
      addToList && addToList(result.data.lastMessage, true)
      refreshConversation && refreshConversation(conversation, false)
      notifySuccess(getText('NTF_SUCCESS_SEND_TO_CRM'))
    } else {
      notifyError(result.errMsg || getText('ERR_GENERAL'))
    }
  }
  setsaving && setsaving(false)
}

export const handleUploadByMobileApp = (conversation) => {
  myWebViewPost(
    {
      accessToken: authActions.getTokenData().accessToken,
      targetId: conversation._id,
      isNew: conversation.isNew,
    },
    'UPLOAD_BUTTON_CLICKED'
  )
}

export const msgTooltipText = (conversation, isAwaitingConsent) => {
  if (isAwaitingConsent) {
    return getText('TEXT_YOU_CANT_TEXT_CUSTOMERS_WHILE_AWAITING_CONSENT')
  }
  if (conversation.kind === 'gbm') {
    return getText(
      'MSG_THIS_ACTION_IS_NOT_SUPPORTED_FOR_GOOGLE_BUSINESS_CONVERSATIONS_JUST_YET'
    )
  } else if (conversation.last_source === 'IG messenger') {
    return getText('TOOLTIP_TEXT').replace('[messanger]', 'Instagram')
  } else if (conversation.last_source === 'FB messenger') {
    return getText('TOOLTIP_TEXT').replace('[messanger]', 'Facebook')
  }
}

export const templatesConverter = (
  templatesList,
  conversation,
  organization,
  user,
  vehicleOfInterest
) => {
  let list = templatesList?.map((item) => {
    const { receiver } = conversation || {}
    const { text, subject } = item
    const replaceValues = {
      '{{organization_name}}': organization.name,
      '{{customer_first_name}}': receiver
        ? receiver.firstName
        : conversation
          ? conversation.receiver_phone
          : '',
      '{{customer_last_name}}': receiver ? receiver.lastName : '',
      '{{user_first_name}}': user ? user.firstName : '',
      '{{user_last_name}}': user ? user.lastName : '',
      '{{user_phone}}': user ? user.phone : '',
      '{{user_email}}': user ? user.email : '',
      '{{make}}': vehicleOfInterest ? vehicleOfInterest.make : '',
      '{{model}}': vehicleOfInterest ? vehicleOfInterest.model : '',
      '{{year}}': vehicleOfInterest ? vehicleOfInterest.year : '',
      '{{VIN}}': vehicleOfInterest ? vehicleOfInterest.vin : '',
      '{{trade_make}}': vehicleOfInterest ? vehicleOfInterest.trade_make : '',
      '{{trade_model}}': vehicleOfInterest ? vehicleOfInterest.trade_model : '',
      '{{trade_year}}': vehicleOfInterest ? vehicleOfInterest.trade_year : '',
      '{{trade_VIN}}': vehicleOfInterest ? vehicleOfInterest.trade_vin : '',
      '{{offer_amount}}': vehicleOfInterest ? vehicleOfInterest.offer_amount : '',
      '{{review_invite_url}}': '{{review_invite_url}}',
    }

    item.getValue = () => {
      let templateText = text ? text[receiver?.language || 'en'] : ''
      return templateText
    }
    item.getSubject = () => {
      let subjectText = subject ? subject[receiver?.language || 'en'] : ''
      return subjectText
    }
    Object.assign(item, {
      display: item.name,
    })
    item.replacementItem = () => {
      let templateText = text ? text[receiver?.language || 'en'] : ''

      templateText =
        templateText &&
        templateText.replace(/{{\w+}}/g, (match) => {
          return replaceValues[match] || ''
        })
      return templateText
    }
    item.replacementSubject = () => {
      let templateText = subject ? subject[receiver?.language || 'en'] : ''

      templateText =
        templateText &&
        templateText.replace(/{{\w+}}/g, (match) => {
          return replaceValues[match] || ''
        })
      return templateText
    }
    return item
  })
  return list
}
