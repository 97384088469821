import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Form, Input } from 'antd'
import { getText } from '../../../lang'
import useDeviceCheck from '../../../utils/useDeviceCheck'

const InputFormTextArea = forwardRef((props, ref) => {
  const textAreaRef = useRef()
  const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()

  useImperativeHandle(ref, () => ({
    autoFocus(position) {
      if (!isMobileOrTabletOrTabletPortrait) {
        setTimeout(() => {
          textAreaRef.current.focus()
          if (position) {
            textAreaRef.current.resizableTextArea.textArea.selectionStart = position
            textAreaRef.current.resizableTextArea.textArea.selectionEnd = position
          }
        }, 10)
      }
    },
  }))

  return (
    <Form.Item
      className={`mat-form-item ${props.formClassName || ''}`}
      label={props.label}
      name={props.name}
      hasFeedback={props.hasFeedback}
      initialValue={props.initialValue}
      getValueFromEvent={props.getValueFromEvent}
      validateStatus={props.validateStatus}
      hidden={props.hidden}
      rules={[
        {
          required: props.required,
          message: props.message
            ? props.message
            : `${getText('WORD_PLEASE_INPUT')} ${props.label ? props.label : props.placeholder}!`,
          whitespace: props.required,
        },
        {
          max: props.maxLength,
          message:
            props.maxLength &&
            (props.maxLenghthMsg ||
              `${props.label ? props.label : props.placeholder} ${getText('TEXT_MUST_BE_MAXIMUM')} ${
                props.maxLength
              } ${getText('WORD_CHARACTERS')}.`),
        },
        {
          min: props.minLength,
          message:
            props.minLength &&
            `${props.label ? props.label : props.placeholder} ${getText('TEXT_MUST_BE_MINIMUM')} ${
              props.minLength
            } ${getText('WORD_CHARACTERS')}.`,
        },
      ]}
      layout='vertical'
    >
      <Input.TextArea
        className={`mat-textarea ${props.inputClassName || ''}`}
        placeholder={props.placeholder || props.label}
        autoSize={props.autoSize}
        disabled={props.disabled}
        onChange={props.onChange}
        ref={textAreaRef}
        value={props.value}
        allowClear={props.allowClear}
        showCount={props.showCount}
        onClick={props.onClick}
        onPressEnter={props.onPressEnter}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        style={props.style}
      />
    </Form.Item>
  )
})

InputFormTextArea.defaultProps = {
  name: 'comment',
  placeholder: 'Comment',
  required: false,
  hasFeedback: false,
  allowClear: true,
  autoSize: { minRows: 5, maxRows: 5 },
}

export default InputFormTextArea
