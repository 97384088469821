import React from 'react'
import { Modal } from 'antd'
import { getText } from '../../lang'
import MatButton from '../MatButton'
import './MatModal.scss'

const MatModal = ({
  noTrigger,
  dataIndex,
  triggerClassName,
  visible,
  trigger,
  id,
  onTrrigerClick,
  className,
  rootClassName,
  title,
  width,
  bodyStyle,
  maskStyle,
  destroyOnClose = true,
  onCancel,
  onCancelBtn,
  hideFooter,
  cancelText,
  hideSubmit,
  onOk,
  confirmLoading,
  okText,
  formName,
  submitButtonDisabled,
  centered = true,
  forceRender,
  closable,
  children,
}) => {
  return (
    <>
      {!noTrigger && (
        <div
          dataindex={dataIndex}
          className={`modal-btn ${triggerClassName || ''} ${visible ? 'modal-btn-active' : ''}`}
          onClick={() => {
            onTrrigerClick && onTrrigerClick()
          }}
          id={id}
        >
          {trigger ? trigger : 'Open Modal'}
        </div>
      )}
      <Modal
        className={`mat-modal ${className || ''}`}
        rootClassName={rootClassName}
        title={title}
        open={visible}
        width={width || 500}
        styles={{ body: bodyStyle, mask: maskStyle }}
        destroyOnClose={destroyOnClose}
        footer={
          !hideFooter
            ? [
                <MatButton
                  key='back'
                  onClick={onCancelBtn || onCancel}
                  buttonText={cancelText || getText('ACTION_CANCEL')}
                  typeButton={'white'}
                  htmlType={'button'}
                  style={{ minWidth: 90, height: 50 }}
                />,
                !hideSubmit ? (
                  <MatButton
                    key='submit'
                    onClick={onOk}
                    loading={confirmLoading}
                    buttonText={okText}
                    formName={formName}
                    disabled={submitButtonDisabled}
                    style={{ minWidth: 90, height: 50 }}
                  />
                ) : null,
              ]
            : null
        }
        centered={centered}
        forceRender={forceRender}
        okText={okText}
        cancelText={cancelText}
        confirmLoading={confirmLoading}
        onOk={onOk}
        onCancel={onCancel}
        closable={closable}
      >
        <div className='modal-content-wrapper'>{children}</div>
      </Modal>
    </>
  )
}

export default MatModal
