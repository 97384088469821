import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '18'}
    height={props.height || '18'}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.0294 6.12957C15.0739 7.04771 16.5 9.12086 16.5 11.5312V14.0625C16.5 14.9945 15.7538 15.75 14.8333 15.75H9.83333C7.6563 15.75 5.80424 14.3413 5.11784 12.375M13.0294 6.12957C12.5033 3.90424 10.5258 2.25 8.16667 2.25H7.33333C4.11167 2.25 1.5 4.89432 1.5 8.15625V10.6875C1.5 11.6195 2.24619 12.375 3.16667 12.375H5.11784M13.0294 6.12957C13.1192 6.50906 13.1667 6.90516 13.1667 7.3125C13.1667 10.1084 10.9281 12.375 8.16667 12.375H5.11784'
      stroke={props.color || '#77808D'}
      strokeWidth='1.5'
      strokeLinejoin='round'
    />
  </svg>
)

const SVGSMSSelect = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGSMSSelect
