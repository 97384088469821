import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import SVGStripe from '../../../../icons/SVG/SVGStripe'
import { getText } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import MyDefaultButton from '../../../../components/MyDefaultButton/MyDefaultButton'
import MyDefaultIntegrationBlock from '../../../../components/MyDefaultIntegrationBlock/MyDefaultIntegrationBlock'
import { userSelector } from '../../../../store/selectors/selectors'

const StripeButton = ({ organization }) => {
  const [stripeLogged, setStripeLogged] = useState(organization.stripeConnected)
  const [loading, setLoading] = useState(false)

  const user = useSelector(userSelector)

  useEffect(() => {
    window.addEventListener('message', stripeConnectHandler)
  }, [])

  const stripeConnectHandler = async (event) => {
    if (event.data.type === 'stripeAuth') {
      const data = event.data.data
      if (data) {
        organization.featuresConnectingData = data.featuresConnectingData
        setStripeLogged(data.stripeConnected)
      }
    }
  }

  const connectStripe = async () => {
    setLoading(true)
    if (!stripeLogged) {
      const url = await organizationActions.createStripeAccount(organization?._id)
      if (url) {
        window.open(url, 'stripeAuth')
      }
    } else {
      const result = await organizationActions.disconnectPayment(organization?._id)
      if (result) {
        organization.featuresConnectingData = result.featuresConnectingData
        setStripeLogged(result.stripeConnected)
      }
    }
    setLoading(false)
  }

  return (
    <MyDefaultIntegrationBlock
      enableMarginTop
      icon={<SVGStripe />}
      title={getText('TEXT_CONNECT_STRIPE_ACCOUNT')}
      description={getText('TEXT_INTEGRATE_YOUR_PAYMENT_ACCOUNT')}
      enabled={organization?.featuresConnectingData?.stripe?.isConnected}
      connectedBy={organization?.featuresConnectingData?.stripe?.userData?.fullName}
      customButton={
        <React.Fragment>
          {!stripeLogged ? (
            <MyDefaultButton
              loading={loading}
              buttonText={getText('WORD_CONNECT_STRIPE')}
              disabled={
                !user.isSuperAdmin && !organization.enable_payments && !user.isAdmin
              }
              onClick={connectStripe}
              htmlType='button'
            />
          ) : !Boolean(user.isSuperAdmin && user.isAdmin && stripeLogged) ? (
            <Tooltip
              placement='top'
              title={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
            >
              <MyDefaultButton
                loading={loading}
                buttonText={getText('WORD_DISCONNECT_STRIPE')}
                disabled={!user.isSuperAdmin && !stripeLogged && !user.isAdmin}
                htmlType='button'
              />
            </Tooltip>
          ) : (
            <ConfirmPopup
              title={getText('TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT_PAYMENTS')}
              okText={getText('WORD_DISCONNECT')}
              onConfirm={connectStripe}
              cancelText={getText('WORD_NO')}
              trigger={
                <MyDefaultButton
                  loading={loading}
                  buttonText={getText('WORD_DISCONNECT_STRIPE')}
                  disabled={!user.isSuperAdmin && !stripeLogged && !user.isAdmin}
                  htmlType='button'
                />
              }
            />
          )}
        </React.Fragment>
      }
    />
  )
}

export default StripeButton
