import React, { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import locationActions from '../../store/modules/locationActions'
import { idOrganizationSelector } from '../../store/selectors/selectors'
import { getText } from '../../lang'
import MyDefaultSelect from './MyDefaultSelect'

const MyDefaultSelectLocation = ({
  isForm,
  color = 'grey',
  label,
  dropdownClassName,
  dropdownStyle,
  style,
  onSelectItem,
  value,
  onDropdownVisibleChange,
  placeholder,
  required,
  name,
}) => {
  const _idOrganization = useSelector(idOrganizationSelector)

  const [listData, setListData] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(0)
  const [perPage] = useState(50)

  const fill = useCallback(async () => {
    if (_idOrganization) {
      setLoadingData(true)
      let result = await locationActions.getPageList(page, perPage, '', {}, true)
      if (result.success) {
        let list = result.data.map((oo) => {
          return { value: oo._id, label: oo.name, ...oo }
        })
        let oldValue = listData
        if (page > 0) {
          list = oldValue.concat(list)
        }
        setListData(list)
        setTotalRows(result.max)
      }
      setLoadingData(false)
    }
  }, [page, perPage, _idOrganization])

  useEffect(() => {
    fill()
  }, [fill])

  return (
    <MyDefaultSelect
      isForm={isForm}
      color={color}
      label={label}
      dropdownClassName={dropdownClassName}
      dropdownStyle={dropdownStyle}
      style={style}
      options={listData}
      loading={loadingData}
      onLoadMore={() => {
        if (listData.length < totalRows && !loadingData) {
          setPage(page + 1)
        }
      }}
      required={required}
      name={name}
      placeholder={placeholder || getText('WORD_SELECT_LOCATION')}
      onSelectItem={onSelectItem}
      value={value}
      onDropdownVisibleChange={onDropdownVisibleChange}
    />
  )
}

export default memo(MyDefaultSelectLocation)
