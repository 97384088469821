import React from 'react'
import { Form, Select } from 'antd'
import { SELECT_UNSELECT_NONE } from '../../../utils'

const SelectFormSecondary = (props) => {
  const errorMessage = `Please Select ${
    props.label ? props.label : props.placeholder
  }!`

  return (
    <Form.Item
      className={`mat-form-item mat-form-item-black ${props.formClassName || ''}`}
      style={props.formStyle}
      name={props.name}
      label={props.label}
      hasFeedback={props.hasFeedback}
      initialValue={props.initialValue}
      rules={[
        { required: props.required, message: errorMessage },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve()
            }
            if (getFieldValue(props.name) !== SELECT_UNSELECT_NONE) {
              return Promise.resolve()
            }
            return Promise.reject(errorMessage)
          },
        }),
      ]}
    >
      <Select
        className={`mat-select mat-select-secondary ${props.className || ''}`}
        popupClassName={`mat-select-dropdown ${props.primary ? ' primary ' : ' '} `}
        dropdownStyle={props.dropdownStyle}
        placeholder={props.label ? props.label : props.placeholder}
        showSearch={props.showSearch}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={props.disabled}
        loading={props.loading}
        onSearch={props.onSearch}
        defaultValue={props.defaultValue}
        mode={props.mode}
        onChange={(e, ee) => {
          let find = props.options.find((oo) => {
            return oo.value === e
          })
          props.onChangeItem && props.onChangeItem(find)
          props.onChange && props.onChange(e, ee)
        }}
        onPopupScroll={(e) => {
          e.persist()
          props.onPopupScroll && props.onPopupScroll(e)
          if (
            e.target.scrollTop + e.target.offsetHeight >=
            e.target.scrollHeight - 10
          ) {
            props.onLoadMore && props.onLoadMore()
          }
        }}
        options={props.options}
        allowClear={props.allowClear}
      />
    </Form.Item>
  )
}

SelectFormSecondary.defaultProps = {
  placeholder: 'Select One',
  hasFeedback: false,
  showSearch: true,
  required: false,
  options: [],
  mode: undefined,
}

export default SelectFormSecondary
