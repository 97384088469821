import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import chatSettingsMainActions from '../../../store/modules/chatSettingsMainActions'
import locationActions from '../../../store/modules/locationActions'
import { getText } from '../../../lang'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { useLayout } from '../../../layout/LayoutProvider/LayoutProvider'
import LocationMenu from '../navigation/LocationMenu'
import Div from '../../../components/Div/Div'

const LocationContext = createContext()

export const LocationProvider = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { setPageTitle } = useLayout()
  const { _id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [locationObj, setLocationObj] = useState({})
  const [settings, setSettings] = useState(null)

  const isLiveChatRoute = location.pathname.includes('livechat')
  const isNewLocation = location.pathname.includes('new')

  useEffect(() => {
    setPageTitle(
      isNewLocation
        ? getText('ACTION_CREATE_LOCATION')
        : getText('ACTION_EDIT_LOCATION')
    )
  }, [])

  useEffect(() => {
    const getLocationFromParamsId = async () => {
      setIsLoading(true)
      const result = await locationActions.getSingle(_id, true)
      if (result.success) {
        setLocationObj(result.data)
      } else {
        navigate('/location/list')
      }
    }
    if (!isNewLocation) {
      getLocationFromParamsId()
    }
    setIsLoading(false)
  }, [_id])

  useEffect(() => {
    // this is used only in matador connect settings, appearance, and greeting edit
    const getChatSettings = async () => {
      setIsLoading(true)
      const result = await chatSettingsMainActions.getSingle(_id)
      if (result.success) {
        setSettings(result.data.settings)
      }
      setIsLoading(false)
    }
    if (isLiveChatRoute) {
      getChatSettings()
    }
  }, [_id, isLiveChatRoute])

  return (
    <LocationContext.Provider
      value={{
        locationObj,
        setLocationObj,
        ...(isLiveChatRoute && { settings, setSettings }),
        isNewLocation,
      }}
    >
      <LocationMenu locationObj={locationObj} isNewLocation={isNewLocation} />
      {(!isLoading && (!isLiveChatRoute || settings)) || isNewLocation ? (
        <Div className='loc-navigation-page-container'>{children}</Div>
      ) : (
        <LoadingSpinner />
      )}
    </LocationContext.Provider>
  )
}

export const useLocationLayout = () => useContext(LocationContext)
