import React, { forwardRef } from 'react'
import Div from '../Div/Div'
import MyDefaultPageHeader from '../MyDefaultPageHeader/MyDefaultPageHeader'
import MyDefaultPageHeaderAdvanced from '../MyDefaultPageHeader/MyDefaultPageHeaderAdvanced'
import './MyDefaultPageLayout.scss'

const MyDefaultPageLayout = forwardRef(
  ({ children, customHeader, headerProps, disableHeader, isAdvanced }, ref) => {
    return (
      <Div ref={ref} className={'my_default_page_layout'}>
        {Boolean(!disableHeader) &&
          (customHeader || isAdvanced ? (
            <MyDefaultPageHeaderAdvanced {...headerProps} />
          ) : (
            <MyDefaultPageHeader {...headerProps} />
          ))}
        {children}
      </Div>
    )
  }
)

export default MyDefaultPageLayout
