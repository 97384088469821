import React, { useState, useEffect } from 'react'
import { getContentMessage } from '../../utils'
import SVGClearCircle from '../../../../../../icons/SVG/SVGClearCircle'
import { getText } from '../../../../../../lang'
import AddVariableInMessage from '../../../../../../components/AddVariableInMessage'
import InputFormTextArea from '../../../../../../components/Form/InputFormTextArea'
import UploadFiles from '../../../../../../components/UploadFiles'
import { isAustralianOrg } from '../../../../../../utils'

const BuildContent = (props) => {
  const {
    listVariables,
    textMessage,
    settextMessage,
    onStateChange,
    setCurrentCursorPosition,
    addNameInMessenger,
    textAreRef,
    hideDynamicTags,
    showTextarea,
    hideUploadFile,
    tabValue,
  } = props

  const [uploadFiles, setUploadFiles] = useState([])

  useEffect(() => {
    if (tabValue === 'ai_generated') {
      onStateChange && onStateChange('image', ...uploadFiles)
    } else {
      onStateChange && onStateChange('image', ...uploadFiles)
    }
  }, [tabValue, uploadFiles])

  return (
    <>
      <div className='content-mat-textarea-wrapper'>
        {((textMessage &&
          textMessage.aiGeneratedText &&
          textMessage.aiGeneratedText.length > 160) ||
          (textMessage &&
            textMessage.buildYourOwnText &&
            textMessage.buildYourOwnText.length > 160)) && (
          <label className='label-mat-text-area'>
            {' '}
            {getText('TEXT_IF_THE_BROADCAST_MESSAGE_CONTAINS_MORE_THAN_SYMBOLS')}
          </label>
        )}
        {!hideDynamicTags && (
          <AddVariableInMessage onClick={addNameInMessenger} data={listVariables} />
        )}
        {showTextarea && (
          <div className='textarea-with-upload-message'>
            <InputFormTextArea
              ref={textAreRef}
              name={[
                'inputMessage',
                tabValue === 'ai_generated' ? 'aiGeneratedText' : 'buildYourOwnText',
              ]}
              placeholder={getText('TEXT_WRITE_YOUR_MESSAGE')}
              autoSize={{ minRows: 6, maxRows: 6 }}
              onClick={(e) => {
                setCurrentCursorPosition &&
                  setCurrentCursorPosition(e.target.selectionStart)
              }}
              onChange={(e) => {
                setCurrentCursorPosition &&
                  setCurrentCursorPosition(e.target.selectionStart)
                getContentMessage(
                  tabValue,
                  textMessage,
                  settextMessage,
                  onStateChange,
                  e.target.value
                )
              }}
              onKeyUp={(e) => {
                setCurrentCursorPosition &&
                  setCurrentCursorPosition(e.target.selectionStart)
              }}
              required={true}
              message={getText('ERROR_PLEASE_FILL_OUT_THE_MESSAGE_TEXT_FIELD')}
              maxLenghthMsg={getText(
                'MSG_BROADCAST_MESSAGE_LENGTH_MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS'
              )}
              maxLength={1400}
              showCount={true}
            />
            {!hideUploadFile && !isAustralianOrg() && (
              <UploadFiles
                onRemoveItem={(uploadFiles) => {
                  setUploadFiles(uploadFiles)
                  onStateChange && onStateChange('image', '')
                }}
                mediaData={uploadFiles}
                mediaCount={1}
                size={10}
                mediaTypes={'image/jpeg, image/jpg, image/png, image/gif'}
                beforeUploadProps
                showUploadList={{
                  removeIcon: <SVGClearCircle />,
                  showPreviewIcon: null,
                }}
                onUpload={(url, file = null, id = null) => {
                  let list = uploadFiles.map((obj) => {
                    if (obj._id === id) {
                      obj.mediaUrl = url
                      obj.mediaContentType = file.type
                      obj.status = 'done'
                    }
                    return obj
                  })
                  setUploadFiles(list)
                }}
                onStartUpload={(file) => {
                  setUploadFiles((oldList) => {
                    oldList.push(file)
                    return oldList
                  })
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default BuildContent
