import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.8196 3.18022C15.9126 2.27326 14.4422 2.27326 13.5352 3.18022L12.4527 4.26276L15.7371 7.54716L16.8196 6.46461C17.7266 5.55765 17.7266 4.08718 16.8196 3.18022ZM14.6764 8.60782L11.392 5.32342L3.98896 12.7265C3.52184 13.1936 3.20599 13.7904 3.08246 14.4394L2.77477 16.0557C2.64281 16.7489 3.25094 17.357 3.94413 17.2251L5.56047 16.9174C6.20943 16.7938 6.80623 16.478 7.27335 16.0109L14.6764 8.60782Z'
      fill={props.color || '#A3A1B7'}
    />
  </svg>
)

const SVGMyDefaultEditIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGMyDefaultEditIcon
