import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.99968 0.333008C3.31768 0.333008 0.333008 3.31767 0.333008 6.99967C0.333008 10.6817 3.31768 13.6663 6.99968 13.6663C10.6817 13.6663 13.6664 10.6817 13.6664 6.99967C13.6664 3.31767 10.6817 0.333008 6.99968 0.333008ZM9.66636 4.99967C9.83702 4.99967 10.015 5.05701 10.1457 5.18701C10.4057 5.44768 10.4057 5.885 10.1457 6.14567L7.64568 8.625C6.87835 9.39167 5.70569 9.27702 5.10369 8.37435L4.43702 7.37435C4.23302 7.06835 4.31834 6.64168 4.62501 6.43701C4.93101 6.23301 5.35768 6.31833 5.56235 6.625L6.22901 7.625C6.36368 7.827 6.53636 7.85901 6.70769 7.68701L9.18703 5.18701C9.31769 5.05701 9.49569 4.99967 9.66636 4.99967Z'
      fill={props.color || '#48AF80'}
    />
  </svg>
)

const SVGCustomerApprovedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGCustomerApprovedIcon
