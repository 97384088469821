import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_18735_102960)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_18735_102960)'>
          <circle cx='18' cy='17' r='13' fill='#5B66EA' />
        </g>
        <path
          d='M15.3335 11.3335V13.3335'
          stroke='white'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.6665 11.3335V13.3335'
          stroke='white'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.3335 16.0601H23.6668'
          stroke='white'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24 15.6668V21.3335C24 23.3335 23 24.6668 20.6667 24.6668H15.3333C13 24.6668 12 23.3335 12 21.3335V15.6668C12 13.6668 13 12.3335 15.3333 12.3335H20.6667C23 12.3335 24 13.6668 24 15.6668Z'
          stroke='white'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.9972 19.1331H18.0031'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.5294 19.1331H15.5354'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.5294 21.1331H15.5354'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_18735_102960)'
      />
      <defs>
        <filter
          id='filter0_f_18735_102960'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_18735_102960'
          />
        </filter>
        <linearGradient
          id='paint0_linear_18735_102960'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_18735_102960'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashVoiceAiCreatedAppointmentsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashVoiceAiCreatedAppointmentsIcon
