import React from 'react'
import MyDefaultSwitch from '../../MyDefaultSwitch/MyDefaultSwitch'
import './MyDefaultTableSwitch.scss'

const MyDefaultTableSwitch = ({ value, text, onChange }) => {
  return (
    <MyDefaultSwitch
      justify={'start'}
      noText
      isFirst
      isLast
      value={value}
      textAfterSwitch={text}
      onChangeSwitch={onChange}
      className={'my_default_table_switch'}
    />
  )
}

export default MyDefaultTableSwitch
