import React from 'react'
import Icon from '@ant-design/icons'
import { getText } from '../../lang'
import Div from '../../components/Div/Div'
import utilNumber from '../../utils/utilNumber'

const multipleLineText = (
  title,
  x,
  y,
  maxWidth = 100,
  lineHeight = '18px',
  textAlign = 'center',
  fontSize = 14
) => {
  return (
    <foreignObject x={x} y={y} width='110' height='50'>
      <Div
        xmlns='http://www.w3.org/1999/xhtml'
        style={{
          maxWidth: maxWidth,
          lineHeight: lineHeight,
          textAlign: textAlign,
          fontSize: fontSize,
        }}
      >
        {title}
      </Div>
    </foreignObject>
  )
}

const SVGIcon = ({
  profiles_messaged,
  avg_per_user_count,
  engaged_clients,
  ai_bot_messages,
  ai_bot_percentage,
}) => {
  return (
    <svg
      width='311'
      height='624'
      viewBox='0 0 311 624'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M164 122L164 165.066L164 184.104L164 473'
        stroke='url(#paint0_linear_22179_97667)'
        strokeWidth='42'
      />
      <path
        opacity='0.2'
        d='M164 122L164 165.437L164 185.096L164 468'
        stroke='white'
        strokeWidth='1.5'
      />
      <rect
        x='85.416'
        width='158'
        height='158'
        rx='79'
        fill='#24AADF'
        fillOpacity='0.12'
      />
      <circle cx='164.416' cy='79' r='64.1875' fill='#24AADF' />
      <path
        d='M223.257 79C223.257 111.497 196.913 137.841 164.416 137.841C131.919 137.841 105.575 111.497 105.575 79C105.575 46.5031 131.919 20.1591 164.416 20.1591C196.913 20.1591 223.257 46.5031 223.257 79Z'
        stroke='#24AADF'
        strokeWidth='0.818182'
      />
      <path
        d='M153.582 65.0608H175.249'
        stroke='white'
        strokeWidth='1.22727'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M161.979 45.561V65.061H166.854V45.561C166.854 44.3693 166.366 43.3943 164.904 43.3943H163.929C162.466 43.3943 161.979 44.3693 161.979 45.561Z'
        stroke='white'
        strokeWidth='1.22727'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M154.666 52.0607V65.0607H158.999V52.0607C158.999 50.869 158.566 49.894 157.266 49.894H156.399C155.099 49.894 154.666 50.869 154.666 52.0607Z'
        stroke='white'
        strokeWidth='1.22727'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.833 57.4772V65.0605H174.166V57.4772C174.166 56.2855 173.733 55.3105 172.433 55.3105H171.566C170.266 55.3105 169.833 56.2855 169.833 57.4772Z'
        stroke='white'
        strokeWidth='1.22727'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <text fill='white' space='preserve' fontSize='12' letterSpacing='0em'>
        <tspan x='130.916' y='81.7656'>
          {getText('WORD_LEAD_VOLUME')}
        </tspan>
      </text>
      <text fill='white' space='preserve' fontSize='24' letterSpacing='-0.01em'>
        <tspan x='135.416' y='110.804'>
          {utilNumber.numberFractions(profiles_messaged)}
        </tspan>
      </text>
      {multipleLineText(getText('WORD_REPLY_SUCCSESS_RATE'), 6, 513)}
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M184 510H59L59 422H184L184 510Z'
        fill='#B4EAFF'
      />
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M185.068 488H56.9443L56.9443 444H185.068L185.068 488Z'
        fill='#B4EAFF'
      />
      {multipleLineText(getText('WORD_ENGAGED_CLIENTS'), 123, 382, 80)}
      <text
        x='218'
        dominantBaseline='middle'
        textAnchor='start'
        fontSize={16}
        fontWeight={500}
        y='468'
        fill='white'
      >
        {utilNumber.numberFractions(engaged_clients)}
      </text>
      <rect
        x='121.553'
        y='422'
        width='88'
        height='88'
        rx='44'
        fill='#86FFDE'
        fillOpacity='0.12'
      />
      <circle cx='165.553' cy='466' r='35.75' fill='#19BE83' />
      <path
        d='M198.053 466C198.053 483.949 183.502 498.5 165.553 498.5C147.603 498.5 133.053 483.949 133.053 466C133.053 448.051 147.603 433.5 165.553 433.5C183.502 433.5 198.053 448.051 198.053 466Z'
        stroke='#2FE6B4'
      />
      <path
        d='M161.766 464.493C161.633 464.48 161.473 464.48 161.326 464.493C158.153 464.387 155.633 461.787 155.633 458.587C155.633 455.32 158.273 452.667 161.553 452.667C164.819 452.667 167.473 455.32 167.473 458.587C167.459 461.787 164.939 464.387 161.766 464.493Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M171.433 455.333C174.02 455.333 176.1 457.427 176.1 460C176.1 462.52 174.1 464.573 171.606 464.667C171.5 464.653 171.38 464.653 171.26 464.667'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M155.1 469.413C151.873 471.573 151.873 475.093 155.1 477.24C158.766 479.693 164.78 479.693 168.446 477.24C171.673 475.08 171.673 471.56 168.446 469.413C164.793 466.973 158.78 466.973 155.1 469.413Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M174.006 476.667C174.966 476.467 175.873 476.08 176.619 475.507C178.699 473.947 178.699 471.373 176.619 469.813C175.886 469.253 174.993 468.88 174.046 468.667'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='61.5205' cy='466' r='44' fill='#3B3F6A' />
      <circle cx='61.5205' cy='466' r='43.5' stroke='#5C97AD' strokeOpacity='0.3' />
      <path
        d='M71.7401 440.577C68.4303 439.206 64.883 438.5 61.3005 438.5C57.718 438.5 54.1707 439.206 50.8609 440.577C47.5512 441.948 44.5438 443.957 42.0106 446.49C39.4775 449.023 37.468 452.031 36.0971 455.34C34.7261 458.65 34.0205 462.198 34.0205 465.78C34.0205 469.362 34.7261 472.91 36.0971 476.22C37.468 479.529 39.4774 482.537 42.0106 485.07C44.5438 487.603 47.5511 489.612 50.8609 490.983C54.1707 492.354 57.718 493.06 61.3005 493.06C64.883 493.06 68.4303 492.354 71.7401 490.983C75.0499 489.612 78.0572 487.603 80.5904 485.07C83.1236 482.537 85.133 479.529 86.5039 476.22C87.8749 472.91 88.5805 469.362 88.5805 465.78C88.5805 462.198 87.8749 458.65 86.5039 455.34C85.133 452.031 83.1236 449.023 80.5904 446.49C78.0572 443.957 75.0499 441.948 71.7401 440.577Z'
        fill='#19BE83'
      />
      <path
        d='M61.3005 438.5C64.883 438.5 68.4303 439.206 71.7401 440.577C75.0499 441.948 78.0572 443.957 80.5904 446.49C83.1236 449.023 85.133 452.031 86.5039 455.34C87.8749 458.65 88.5805 462.198 88.5805 465.78C88.5805 469.362 87.8749 472.91 86.5039 476.22C85.133 479.529 83.1236 482.537 80.5904 485.07C78.0572 487.603 75.0499 489.612 71.7401 490.983C68.4303 492.354 64.883 493.06 61.3005 493.06C57.718 493.06 54.1707 492.354 50.8609 490.983C47.5511 489.612 44.5438 487.603 42.0106 485.07C39.4774 482.537 37.468 479.529 36.0971 476.22C34.7261 472.91 34.0205 469.362 34.0205 465.78C34.0205 462.198 34.7261 458.65 36.0971 455.34C37.468 452.031 39.4775 449.023 42.0106 446.49C44.5438 443.957 47.5512 441.948 50.8609 440.577C54.1707 439.206 57.718 438.5 61.3005 438.5Z'
        fill='#19BE83'
      />
      <text fill='white' space='preserve' fontSize='16' letterSpacing='0em'>
        <tspan x='44.9887' y='471.688'>
          {ai_bot_percentage}%
        </tspan>
      </text>
      <path
        d='M61.5203 430.8C66.1428 430.8 70.7201 431.711 74.9908 433.479C79.2614 435.248 83.1419 437.841 86.4105 441.11C89.6791 444.379 92.2719 448.259 94.0409 452.53C95.8098 456.8 96.7203 461.378 96.7203 466C96.7203 470.623 95.8098 475.2 94.0409 479.471C92.2719 483.741 89.6791 487.622 86.4105 490.89C83.1418 494.159 79.2614 496.752 74.9908 498.521C70.7201 500.29 66.1428 501.2 61.5203 501.2C56.8978 501.2 52.3205 500.29 48.0498 498.521C43.7792 496.752 39.8988 494.159 36.6301 490.89C33.3615 487.622 30.7687 483.741 28.9997 479.47C27.2308 475.2 26.3203 470.623 26.3203 466C26.3203 461.378 27.2308 456.8 28.9998 452.53C30.7687 448.259 33.3615 444.378 36.6302 441.11C39.8988 437.841 43.7792 435.248 48.0499 433.479C52.3205 431.711 56.8978 430.8 61.5203 430.8L61.5203 430.8Z'
        stroke='#48547A'
        strokeWidth='10'
      />
      {/* <path
        d='M61.5197 430.8C67.8237 430.8 74.0119 432.493 79.438 435.702C84.864 438.911 89.3288 443.518 92.3657 449.042C95.4027 454.566 96.9004 460.805 96.7024 467.106C96.5043 473.407 94.6179 479.539 91.2401 484.861'
        stroke='url(#paint1_linear_22179_97667)'
        strokeWidth='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      /> */}
      <defs>
        <linearGradient id='progressBarGradient' x1='1' y1='1' x2='0' y2='0'>
          <stop stopColor='#19BE83' />
          <stop offset='1' stopColor='#00E4C9' />
        </linearGradient>
      </defs>
      <circle
        stroke='url(#progressBarGradient)'
        fill='none'
        strokeWidth={8}
        strokeDasharray={'219.912'}
        strokeLinecap={'round'}
        strokeDashoffset={`calc(219.912 * (1 - (${ai_bot_percentage} / 100)))`}
        cx='31.221'
        transform='rotate(-90 61.221 435.221)'
        cy='435.221'
        r='35'
      ></circle>
      <g filter='url(#filter0_b_22179_97667)'>
        <rect
          x='6.57031'
          y='562'
          width='109.507'
          height='62'
          rx='6'
          fill='url(#paint2_linear_22179_97667)'
          fillOpacity='0.4'
        />
        <rect
          x='7.07031'
          y='562.5'
          width='108.507'
          height='61'
          rx='5.5'
          stroke='#EAECF0'
          strokeOpacity='0.2'
        />
        {multipleLineText(
          getText('WORD_INDUSTRY_AVERAGE_ENGAGEMENT'),
          15,
          565,
          90,
          '16px',
          undefined,
          10
        )}
        <text fill='white' space='preserve' fontSize='14' letterSpacing='0px'>
          <tspan x='46.3242' y='615.977'>
            44%
          </tspan>
        </text>
      </g>
      {multipleLineText(getText('TEXT_MESSAGES_SENT'), 123, 173, 80)}
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M184 301H59L59 213L184 213L184 301Z'
        fill='#B4EAFF'
      />
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M185.068 279H56.9443L56.9443 235L185.068 235L185.068 279Z'
        fill='#B4EAFF'
      />
      <rect
        x='121.553'
        y='213'
        width='88'
        height='88'
        rx='44'
        fill='#B4EAFF'
        fillOpacity='0.12'
      />
      <circle cx='165.553' cy='257' r='35.75' fill='#24AADF' />
      <path
        d='M198.053 257C198.053 274.949 183.502 289.5 165.553 289.5C147.603 289.5 133.053 274.949 133.053 257C133.053 239.051 147.603 224.5 165.553 224.5C183.502 224.5 198.053 239.051 198.053 257Z'
        stroke='#36BAEE'
      />
      <path
        d='M159.419 249.427L170.739 245.653C175.819 243.96 178.579 246.733 176.899 251.813L173.126 263.133C170.592 270.747 166.432 270.747 163.899 263.133L162.779 259.773L159.419 258.653C151.806 256.12 151.806 251.973 159.419 249.427Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M163.033 259.2L167.807 254.413'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {multipleLineText(getText('TEXT_AVG_MESSAGE_SENT_PER_CUSTOMER'), 11, 305, 110)}
      <circle cx='63.7119' cy='257' r='44' fill='#3B3F6A' />
      <circle cx='63.7119' cy='257' r='43.5' stroke='#5C97AD' strokeOpacity='0.3' />
      <path
        d='M74.1522 231.797C70.8425 230.426 67.2951 229.72 63.7126 229.72C60.1302 229.72 56.5828 230.426 53.273 231.797C49.9633 233.167 46.9559 235.177 44.4228 237.71C41.8896 240.243 39.8801 243.251 38.5092 246.56C37.1382 249.87 36.4326 253.417 36.4326 257C36.4326 260.582 37.1382 264.13 38.5092 267.44C39.8801 270.749 41.8896 273.757 44.4227 276.29C46.9559 278.823 49.9632 280.832 53.273 282.203C56.5828 283.574 60.1301 284.28 63.7126 284.28C67.2951 284.28 70.8424 283.574 74.1522 282.203C77.462 280.832 80.4693 278.823 83.0025 276.29C85.5357 273.757 87.5451 270.749 88.916 267.44C90.287 264.13 90.9926 260.582 90.9926 257C90.9926 253.418 90.287 249.87 88.9161 246.56C87.5451 243.251 85.5357 240.243 83.0025 237.71C80.4693 235.177 77.462 233.167 74.1522 231.797Z'
        fill='#24AADF'
      />
      <path
        d='M63.7126 229.72C67.2951 229.72 70.8425 230.426 74.1522 231.797C77.462 233.167 80.4693 235.177 83.0025 237.71C85.5357 240.243 87.5451 243.251 88.9161 246.56C90.287 249.87 90.9926 253.418 90.9926 257C90.9926 260.582 90.287 264.13 88.916 267.44C87.5451 270.749 85.5357 273.757 83.0025 276.29C80.4693 278.823 77.462 280.832 74.1522 282.203C70.8424 283.574 67.2951 284.28 63.7126 284.28C60.1301 284.28 56.5828 283.574 53.273 282.203C49.9632 280.832 46.9559 278.823 44.4227 276.29C41.8896 273.757 39.8801 270.749 38.5092 267.44C37.1382 264.13 36.4326 260.582 36.4326 257C36.4326 253.417 37.1382 249.87 38.5092 246.56C39.8801 243.251 41.8896 240.243 44.4228 237.71C46.9559 235.177 49.9633 233.167 53.273 231.797C56.5828 230.426 60.1302 229.72 63.7126 229.72Z'
        fill='#24AADF'
      />
      <text fill='white' space='preserve' fontSize='16' letterSpacing='0em'>
        <tspan x='49.1103' y='262.688'>
          {avg_per_user_count}
        </tspan>
      </text>
      <path
        d='M63.7117 221.8C68.3343 221.8 72.9115 222.711 77.1822 224.479C81.4528 226.248 85.3333 228.841 88.6019 232.11C91.8705 235.379 94.4633 239.259 96.2323 243.53C98.0012 247.8 98.9117 252.378 98.9117 257C98.9117 261.623 98.0012 266.2 96.2323 270.471C94.4633 274.741 91.8705 278.622 88.6019 281.89C85.3332 285.159 81.4528 287.752 77.1822 289.521C72.9115 291.29 68.3342 292.2 63.7117 292.2C59.0892 292.2 54.5119 291.29 50.2413 289.521C45.9706 287.752 42.0902 285.159 38.8215 281.89C35.5529 278.622 32.9601 274.741 31.1912 270.47C29.4222 266.2 28.5117 261.623 28.5117 257C28.5117 252.378 29.4222 247.8 31.1912 243.53C32.9601 239.259 35.553 235.378 38.8216 232.11C42.0902 228.841 45.9706 226.248 50.2413 224.479C54.5119 222.711 59.0892 221.8 63.7117 221.8L63.7117 221.8Z'
        stroke='#48547A'
        strokeWidth='10'
      />
      <text fill='white' space='preserve' fontSize='16' letterSpacing='0em'>
        <tspan x='217.919' y='235.188'>
          {utilNumber.numberFractions(ai_bot_messages)}
        </tspan>
      </text>
      <text fill='white' space='preserve' fontSize='14' letterSpacing='0em'>
        <tspan x='217.919' y='252.977'>
          ({getText('WORD_SMS_UC')},{getText('WORD_EMAIL')},&#x2028;
        </tspan>
        <tspan x='217.919' y='270.977'>
          {getText('WORD_SOCIAL_FB')},{' '}
        </tspan>
        <tspan x='217.919' y='288.977'>
          {getText('WORD_SOCIAL_INSTAGRAM')})
        </tspan>
      </text>
      <defs>
        <filter
          id='filter0_b_22179_97667'
          x='-33.4297'
          y='522'
          width='189.507'
          height='142'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='20' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_22179_97667'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_22179_97667'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_22179_97667'
          x1='163.26'
          y1='122'
          x2='163.26'
          y2='586.088'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5B66EA' />
          <stop offset='0.341725' stopColor='#16B9DC' />
          <stop offset='0.675' stopColor='#19BE83' />
          <stop offset='1' stopColor='#FFA51F' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_22179_97667'
          x1='61.5197'
          y1='430.8'
          x2='90.1197'
          y2='489.32'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#19BE83' />
          <stop offset='1' stopColor='#00E4C9' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_22179_97667'
          x1='110.793'
          y1='564.287'
          x2='8.25737'
          y2='583.653'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#443F91' />
          <stop offset='1' stopColor='#3E3DAE' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const SVGDashSequenceMobileBarIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashSequenceMobileBarIcon
