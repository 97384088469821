import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_12911_441022)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_12911_441022)'>
          <circle cx='18' cy='17' r='13' fill='#EED030' />
        </g>
        <path
          d='M24.6665 14V15.6133C24.6665 16.6667 23.9998 17.3333 22.9465 17.3333H20.6665V12.6733C20.6665 11.9333 21.2732 11.3333 22.0132 11.3333C22.7399 11.34 23.4065 11.6333 23.8865 12.1133C24.3665 12.6 24.6665 13.2667 24.6665 14Z'
          stroke='white'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.3335 14.6667V24C11.3335 24.5533 11.9601 24.8667 12.4001 24.5333L13.5402 23.68C13.8068 23.48 14.1802 23.5067 14.4202 23.7467L15.5268 24.86C15.7868 25.12 16.2135 25.12 16.4735 24.86L17.5935 23.74C17.8268 23.5067 18.2002 23.48 18.4602 23.68L19.6002 24.5333C20.0402 24.86 20.6668 24.5467 20.6668 24V12.6667C20.6668 11.9333 21.2668 11.3333 22.0002 11.3333H14.6668H14.0002C12.0002 11.3333 11.3335 12.5267 11.3335 14V14.6667Z'
          stroke='white'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14 16H18'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5 18.6667H17.5'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_12911_441022)'
      />
      <defs>
        <filter
          id='filter0_f_12911_441022'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_12911_441022'
          />
        </filter>
        <linearGradient
          id='paint0_linear_12911_441022'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_12911_441022'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashSalesHandoffsIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashSalesHandoffsIcon
