import React, { memo, useState } from 'react'
import { Form, Modal } from 'antd'
import { useMixpanel } from 'react-mixpanel-browser'
import { useSelector } from 'react-redux'
import { getText, getTextServerError } from '../../../../../lang'
import notesActions from '../../../../../store/modules/notesActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatForm from '../../../../../components/Form/MatForm'
import InputFormTextArea from '../../../../../components/Form/InputFormTextArea'
import MatModal from '../../../../../components/MatModal'
import { formatDateTime, setMixpanel } from '../../../../../utils'
import { userSelector } from '../../../../../store/selectors/selectors'

const AddNoteModal = ({ id, trigger, conversation, note, onSuccess, minRows }) => {
  const user = useSelector(userSelector)

  const isNewNote = Boolean(!note)

  const [form] = Form.useForm()
  const myRef = React.useRef()
  const mixpanel = useMixpanel()

  const [visible, setVisible] = useState(false)
  const [saving, setSaving] = useState(false)
  const [noteValue, setNoteValue] = useState('')

  const onFinish = async (values) => {
    if (conversation.isNew) {
      values.createdAt = new Date()
      conversation.notes.push(values)
      notifySuccess(getText('MSG_NOTE_WAS_CREATED_SUCCESSFULLY'))
      setVisible(false)
    } else {
      if (conversation) {
        setSaving(true)
        let result = await notesActions.saveNew(values, conversation._id)
        if (result.success) {
          setMixpanel(
            mixpanel,
            'Notes created',
            user.createdAt,
            user.fullName,
            user.organization.name,
            user.location.name,
            null,
            user.organization?._id,
            user.location?._id,
            user?._id
          )

          onSuccess && onSuccess(result.data, false)
          notifySuccess(getText('MSG_NOTE_WAS_CREATED_SUCCESSFULLY'))
        } else {
          if (result.errCode === 409 && result.errors) {
            let errStack = []
            result.errors.forEach((errObj) => {
              errObj.messages.forEach((message) => {
                errStack.push(result.errMsg + ': ' + message)
              })
            })
            notifyError(errStack.length > 0 ? errStack.join('. ') : result.errMsg)
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        }
      }
      setVisible(false)
      setSaving(false)
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <MatModal
      title={isNewNote ? getText('TEXT_NEW_NOTE') : note.user_name}
      trigger={trigger}
      confirmLoading={saving}
      formName={'formAddNote' + id}
      visible={visible}
      hideSubmit={!isNewNote}
      onTrrigerClick={() => {
        myRef.current && myRef.current.autoFocus()
        setVisible(true)
        form.resetFields()
      }}
      onCancel={() => {
        if (noteValue) {
          Modal.confirm({
            title: getText(
              'ARE_YOU_SURE_YOU_WANT_TO_CLOSE_THE_NOTE_THE_NOTE_WILL_NOT_BE_SAVED'
            ),
            okText: getText('WORD_YES'),
            cancelText: getText('WORD_NO'),
            onOk: () => {
              setVisible(false)
              setNoteValue('')
            },
            okButtonProps: {
              type: 'primary',
              className: 'mat-btn mat-btn-primary',
            },
            cancelButtonProps: {
              type: 'cancel',
              className: 'mat-btn',
            },
          })
        } else {
          setVisible(false)
        }
      }}
      okText={getText('ACTION_SAVE')}
      submitButtonDisabled={!noteValue || !noteValue?.trim()?.length}
      className='my-note-modal'
      onCancelBtn={() => {
        setVisible(false)
        setNoteValue('')
      }}
    >
      <MatForm
        id={id}
        form={form}
        name={'formAddNote' + id}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ text: note ? note.text : '' }}
      >
        <InputFormTextArea
          name='text'
          inputClassName={`${isNewNote ? '' : 'note-show-text'}`}
          label={
            !isNewNote
              ? formatDateTime(note.createdAt)
              : getText('TEXT_ADD_NEW_NOTES')
          }
          disabled={!isNewNote}
          required={false}
          onChange={(e) => setNoteValue(e.target.value)}
          maxLength={1000}
          minRows={minRows}
          ref={myRef}
        />
      </MatForm>
    </MatModal>
  )
}

export default memo(AddNoteModal)
