import React, { useEffect, useState } from 'react'
import { Flex } from 'antd'
import dayjs from 'dayjs'
import { getText } from '../../../../lang'

const AIReplyBlock = ({ time }) => {
  const specificDate = dayjs(time)
  const [currentTime, setCurrentTime] = useState(dayjs())
  const [message, setMessage] = useState('')

  useEffect(() => {
    const updateTime = () => setCurrentTime(dayjs())
    const interval = setInterval(updateTime, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const differenceInMinutes = specificDate.diff(currentTime, 'minute')

    if (differenceInMinutes > 60) {
      const hours = Math.floor(differenceInMinutes / 60)
      setMessage(`${getText('WORD_AI_REPLY_IN')} ${hours}h`)
    } else if (differenceInMinutes > 0) {
      setMessage(`${getText('WORD_AI_REPLY_IN')} ${differenceInMinutes}min`)
    } else {
      setMessage(getText('WORD_AI_REPLYING'))
    }
  }, [currentTime, specificDate])

  return (
    <Flex align='center' className='message_conversation_item--aiReply'>
      {message}
    </Flex>
  )
}

export default AIReplyBlock
