import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  memo,
  useCallback,
  useContext,
} from 'react'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { useMixpanel } from 'react-mixpanel-browser'
import { getText, getTextServerError } from '../../../../../lang'
import messageActions from '../../../../../store/modules/messageActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import MatRow from '../../../../../components/MatRow'
import { getCustomerPhoneInvlid, setMixpanel } from '../../../../../utils'
import MessagesPicker from '../MessageScheduledAction/MessagesPicker'
import {
  EMAIL_DEFAULT_OBJECT,
  SMS_DEFAULT_OBJECT,
  templatesConverter,
} from '../../../utils/messageUtils'
import { MessageContext } from '../../../main/MessageMain'
import { uploadAndFetchPOStFile } from '../../../../../utils/UrlHelper'
import { EMAIL, MESSENGER, PHONE, TAG, UNASSIGNED } from '../../../utils/constants'
import SendMessageInput from '../MessageSMSAction/SendMessageInput'
import SendEmailInput from '../MessageEmailMessageAction/SendEmailInput'
import SelectSwitchSMSEmail from '../MessageInputSwitchAction/SelectSwitchSMSEmail'
import UploadFileMessage from '../MessageUploadFile/UploadFileMessage'
import RenderMediaPreview from '../RenderMediaPreview/RenderMediaPreview'
import AIGeneratedButton from '../MessageAIGeneratedButton/AIGeneratedButton'
import SendMessageButton from '../MessageSendButton/SendMessageButton'
import MessageEmoji from '../MessageEmoji/MessageEmoji'
import MessageFooterDesktopSize from '../MessageFooterDesktopSize/MessageFooterDesktopSize'
import { GBM } from '../../../../../devOptions'
import MessageFooterMobileSize from '../MessageFooterMobileSize/MessageFooterMobileSize'
import {
  isMobileAppSelector,
  organizationSelector,
  userSelector,
  vehicleOfInterestSelector,
} from '../../../../../store/selectors/selectors'
import './MessageFooterMain.scss'
import useDeviceCheck from '../../../../../utils/useDeviceCheck'

const MessageCenterFooter = forwardRef((props, ref) => {
  const {
    messageType,
    conversation,
    onSendSuccess,
    disabled,
    onChangeEmailSMSType,
    msgChangeEmailSMSType,
    addToMessageList,
    isMessageScheduled,
    unarchiveConversation,
    unAssignConversation,
    isExtension,
    draftedMessage,
    isLastMessageConsent,
    smsTemplatesList,
    setsmsTemplatesList,
    emailTemplatesList,
    setemailTemplatesList,
    usersTagList,
    refreshConversation,
    isBlueExtension,
  } = props

  const { isMobile } = useDeviceCheck()

  let organization = useSelector(organizationSelector)
  let user = useSelector(userSelector)
  const isMobileApp = useSelector(isMobileAppSelector)
  let vehicleOfInterest = useSelector(vehicleOfInterestSelector)

  const [showEmojiModal, setshowEmojiModal] = useState(false)
  const [newMessageValue, setnewMessageValue] = useState(SMS_DEFAULT_OBJECT)
  const [newEmailSubject, setnewEmailSubject] = useState(EMAIL_DEFAULT_OBJECT)
  const [currentCursorPosition, setcurrentCursorPosition] = useState(0)
  const [currentCursorPositionSubject, setcurrentCursorPositionSubject] = useState(0)
  const [sendingMsg, setsendingMsg] = useState(false)
  const [sendingMedia, setsendingMedia] = useState(false)
  const [uploadFiles, setuploadFiles] = useState([])

  const [msgTypeDefault, setmsgTypeDefault] = useState(PHONE)
  const [replyMessageId, setReplyMessageId] = useState('')
  const [visibleAppointment, setvisibleAppointment] = useState(false)
  const [isTemplate, setIsTemplate] = useState(false)
  const [videoUpload, setVideoUpload] = useState([])
  const [videoStatusUpload, setVideoStatusUpload] = useState('')
  const [selectedUserTagList, setSelectedUserTagList] = useState([])
  const [debouncedNewMessageValue, setDebouncedNewMessageValue] = useState('')
  const [focusOverInput, setFocusOverInput] = useState(false)
  const [tabletActionsPopupVisible, setTabletActionsPopupVisible] = useState(false)
  const [listItemsOfResponsiveMode, setListItemsOfResponsiveMode] = useState([])
  const [countOfFooterActions, setCountOfFooterActions] = useState(0)
  const [showMobileFooterDrawer, setShowMobileFooterDrawer] = useState(false)
  const [sendMessageLaterVisible, setSendMessageLaterVisible] = useState(false)

  const mentionItem = useRef()
  const emailRef = useRef()
  const isMountedRef = useRef(true)
  const mixpanel = useMixpanel()
  const messageContext = useContext(MessageContext)

  const isCustomerRedStatus =
    conversation.receiver && conversation.receiver.status.state === 'red'

  const isCustomerYellowStatusAndRestricted =
    conversation.receiver &&
    conversation.receiver.status.state === 'yellow' &&
    !user?.organization?.restrictions?.allowTextingCustomersInPendingStatus

  const isCustomerPhoneNumberValid =
    (conversation.receiver &&
      conversation.receiver.phoneStatus &&
      !conversation.receiver.phoneStatus.valid) ||
    false

  const isCustomerPhoneTemporaryBlocked = getCustomerPhoneInvlid(conversation)

  const isCustomerRedStatusEmail =
    conversation.receiver &&
    conversation.receiver.status &&
    conversation.receiver.status.email &&
    conversation.receiver.status.email.state === 'red'

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const debouncedSetNewMessageValue = useCallback(
    debounce(async (value, upload, userIds, subValue) => {
      const obj = {
        ...debouncedNewMessageValue,
        message: value,
        media: upload.map((item) => ({
          mediaUrl: item.mediaUrl,
          mediaContentType: item.mediaContentType,
        })),
        userIds: userIds.map((item) => item._id),
        subMessage: subValue,
      }
      Object.keys(obj).forEach((key) => {
        if (key === '_id' || key === 'isDraft') {
          delete obj[key]
        }
      })
      if (focusOverInput && !conversation.isNew) {
        await messageActions.saveDraft(obj, conversation._id)
        setDebouncedNewMessageValue(obj)
      }
    }, 500),
    [conversation._id, focusOverInput]
  )

  useEffect(() => {
    debouncedSetNewMessageValue(
      newMessageValue.inputMessageForSending,
      uploadFiles,
      selectedUserTagList,
      newMessageValue.inputMessageForTags
    )
  }, [
    newMessageValue,
    uploadFiles,
    selectedUserTagList,
    newMessageValue.inputMessageForTags,
  ])

  useEffect(() => {
    if (smsTemplatesList?.length && conversation?._id) {
      setsmsTemplatesList((ov) => [
        ...templatesConverter(
          ov,
          conversation,
          organization,
          user,
          vehicleOfInterest
        ),
      ])
    }
  }, [
    smsTemplatesList?.length,
    conversation.isNew,
    vehicleOfInterest,
    conversation?.receiver?.language,
  ])

  useEffect(() => {
    if (emailTemplatesList?.length && conversation?._id) {
      setemailTemplatesList((ov) => [
        ...templatesConverter(
          ov,
          conversation,
          organization,
          user,
          vehicleOfInterest
        ),
      ])
    }
  }, [
    emailTemplatesList?.length,
    conversation?.isNew,
    vehicleOfInterest,
    conversation?.receiver?.language,
  ])

  useEffect(() => {
    setnewEmailSubject(EMAIL_DEFAULT_OBJECT)
    setnewMessageValue({
      inputMessageForTags: draftedMessage
        ? draftedMessage.subMessage === null
          ? ''
          : draftedMessage.subMessage
        : '',
      inputMessageForSending: draftedMessage ? draftedMessage.message : '',
    })
    setReplyMessageId('')
    setuploadFiles(draftedMessage ? draftedMessage.media : [])
    setIsTemplate(false)
    setDebouncedNewMessageValue(draftedMessage)
    setFocusOverInput(false)
    setVideoUpload([])
    setSelectedUserTagList(
      draftedMessage?.userIds?.length
        ? draftedMessage.userIds.map((item) => ({ _id: item }))
        : []
    )
    if (draftedMessage?.userIds?.length) {
      onChangeEmailSMSType && onChangeEmailSMSType(TAG)
    }
  }, [organization && organization?._id, conversation._id, draftedMessage])

  useEffect(() => {
    let isSendMedia = uploadFiles.find((fil) => {
      return fil.isLoading
    })
    setsendingMedia(isSendMedia)
  }, [uploadFiles, conversation])

  const messageFromWebView = async (e) => {
    const messageData = JSON.parse(e.data)
    if (messageData.type === 'RESULT_UPLOAD') {
      const filesData = [...uploadFiles]
      const videoData = [...videoUpload]
      for (const file of messageData.result) {
        if (file.success) {
          if (file.imgData) {
            file.imgData._id = `${new Date().getTime()}_${file.imgData._id}`
            filesData.push(file.imgData)
          }
          if (file.videoData) {
            videoData.push(file.videoData)
          }
        } else {
          notifyError(
            file.errMsg === 'Request failed with status code 413'
              ? getText('TEXT_PLEASE_TRY_TO_UPLOAD_THE_FILE_LESS_THAN_1_MB')
              : file.errMsg
          )
        }
      }
      if (filesData.length) {
        setFocusOverInput(true)
      }
      setuploadFiles(filesData)
      setVideoUpload(videoData)
      setShowMobileFooterDrawer(false)
    } else if (messageData.type === 'START_UPLOADING_VIDEO') {
      setVideoStatusUpload('uploading')
    } else if (messageData.type === 'END_VIDEO_UPLOAD') {
      setVideoStatusUpload('done')
      setShowMobileFooterDrawer(false)
      if (messageData.errMsg) {
        notifyError(messageData.errMsg)
      }
    } else if (messageData.type === 'RESULT_VIDEO_UPLOAD') {
      if (messageData.result.success) {
        setVideoUpload((oldList) => {
          oldList.push(messageData.result.videoData)
          return [...oldList]
        })
      } else {
        notifyError(messageData.errMsg)
      }
      setShowMobileFooterDrawer(false)
      setVideoStatusUpload('done')
    }
  }

  useEffect(() => {
    if (window.platform === 'android') {
      document.addEventListener('message', messageFromWebView)
    } else if (window.platform === 'ios') {
      window.addEventListener('message', messageFromWebView)
    }
    return () => {
      if (window.platform === 'android') {
        document.removeEventListener('message', messageFromWebView)
      } else if (window.platform === 'ios') {
        window.removeEventListener('message', messageFromWebView)
      }
    }
  }, [videoUpload, uploadFiles])

  const canSubmit = () => {
    if (msgChangeEmailSMSType === EMAIL) {
    } else {
      if (!newMessageValue.inputMessageForSending.trim() && !uploadFiles.length) {
        return getText('ERR_EMPTY_MESSAGE')
      }
    }
    return ''
  }

  useImperativeHandle(ref, () => ({
    addSubject(item) {
      if (item) {
        const subject =
          item.subject && item.subject.startsWith('Re:')
            ? item.subject
            : `Re: ${item.subject}`

        setnewEmailSubject({
          newValue: subject,
          newPlainTextValue: subject,
        })
        setReplyMessageId(item._id)
        onChangeEmailSMSType(EMAIL)
        emailRef.current && emailRef.current.focus()
      }
    },
    changeMsgType(type) {
      onChangeEmailSMSType(type)
    },
    setDefaultType(type) {
      setmsgTypeDefault(type)
      onChangeEmailSMSType(type)
    },
    addNewMessage(text) {
      setnewMessageValue({
        inputMessageForTags: text,
        inputMessageForSending: text,
      })
      setnewMessageValue(text)
    },
  }))

  const handleSendMsg = async (scheduleDate) => {
    if (msgChangeEmailSMSType === TAG && !selectedUserTagList.length) {
      notifyError(
        getText('TEXT_PLEASE_TAG_A_USER_TO_WHOM_THIS_MESSAGE_IS_ADDRESSED')
      )
      return
    }

    if (videoUpload.length > 0) {
      setsendingMsg(true)
      let resultProcessVideo
      let obj
      for (let videoIndex = 0; videoIndex < videoUpload.length; videoIndex++) {
        const element = videoUpload[videoIndex]
        if (videoIndex === 0) {
          obj = {
            videoUploadId: element._id,
            status: 'succeeded',
            textMessage: newMessageValue.inputMessageForSending,
          }
        } else {
          obj = {
            videoUploadId: element._id,
            status: 'succeeded',
          }
        }
        resultProcessVideo = await uploadAndFetchPOStFile(
          '/videos/process-video',
          obj
        )
        if (resultProcessVideo.success) {
          setVideoUpload([])
          setnewMessageValue(SMS_DEFAULT_OBJECT)
          setFocusOverInput(true)
        }
      }
      const isArchived = conversation.is_archived
      const unassigned = conversation.unassigned
      if (isArchived) {
        unarchiveConversation()
      }
      if (unassigned) {
        await messageActions.assignUser(conversation._id, user?._id)
      }
      setsendingMsg(false)
    }
    if (
      uploadFiles.length > 0 ||
      (newMessageValue.inputMessageForSending && videoUpload.length === 0) ||
      newEmailSubject.newValue
    ) {
      setshowEmojiModal(false)
      let phone = ''
      if (conversation.receiver && conversation.receiver.phone) {
        phone = conversation.receiver.phone
      } else if (conversation.receiver_phone) {
        phone = conversation.receiver_phone
      }
      if (phone) {
        phone = phone.replace('+', '')
      }
      if (!conversation) {
        return
      }
      const errorMsg = canSubmit()
      if (errorMsg) {
        notifyError(errorMsg)
        return
      }
      let sendFilesData = []
      if (uploadFiles.length) {
        sendFilesData = uploadFiles.map((file) => {
          return {
            mediaUrl: file.url || file.mediaUrl,
            url: file.mediaUrl,
            mediaContentType: file.mediaContentType,
          }
        })
      }
      let result
      setsendingMsg(true)
      if (selectedUserTagList.length && !scheduleDate) {
        result = await messageActions.tagUsersInConversation(
          conversation._id,
          newMessageValue.inputMessageForSending,
          selectedUserTagList.map((item) => item._id)
        )
      } else if (conversation.kind === MESSENGER && !scheduleDate) {
        result = await messageActions.sendToMessenger(
          conversation._id,
          newMessageValue.inputMessageForSending,
          sendFilesData,
          conversation
        )
      } else if (msgChangeEmailSMSType === EMAIL && !scheduleDate) {
        result = await messageActions.sendViaEmail({
          email: conversation.receiver.email,
          subject: newEmailSubject.newPlainTextValue,
          message: newMessageValue.inputMessageForSending,
          files: sendFilesData,
          replyMessageId,
          receiver_id: conversation.receiver._id,
          conversation,
        })
      } else if (scheduleDate) {
        if (msgChangeEmailSMSType === EMAIL) {
          result = await messageActions.sendScheduleByEmail(
            conversation._id,
            conversation.receiver.email,
            newEmailSubject.newPlainTextValue,
            replyMessageId,
            newMessageValue.inputMessageForSending,
            sendFilesData,
            scheduleDate,
            conversation
          )
        } else if (conversation.kind === MESSENGER) {
          result = await messageActions.sendScheduleByMessenger(
            conversation._id,
            newMessageValue.inputMessageForSending,
            sendFilesData,
            scheduleDate
          )
        } else {
          if (
            isLastMessageConsent ||
            conversation?.receiver?.status?.isWaitingAnswerToConsent
          ) {
            notifyError(
              getText('TEXT_YOU_CANT_TEXT_CUSTOMERS_WHILE_AWAITING_CONSENT')
            )
            setsendingMsg(false)
            return
          }
          result = await messageActions.sendScheduleByPhone(
            conversation._id,
            phone,
            newMessageValue.inputMessageForSending,
            sendFilesData,
            scheduleDate,
            conversation
          )
        }
      } else {
        const isArchived = conversation.is_archived
        const unassigned = conversation.unassigned
        if (isArchived) {
          unarchiveConversation()
        }
        if (unassigned && !selectedUserTagList.length) {
          await messageActions.assignUser(conversation._id, user?._id)
        }
        if (selectedUserTagList.length) {
          result = await messageActions.tagUsersInConversation(
            conversation._id,
            newMessageValue.inputMessageForSending,
            selectedUserTagList.map((item) => item._id)
          )
        } else {
          if (
            isLastMessageConsent ||
            conversation?.receiver?.status?.isWaitingAnswerToConsent
          ) {
            notifyError(
              getText('TEXT_YOU_CANT_TEXT_CUSTOMERS_WHILE_AWAITING_CONSENT')
            )
            setsendingMsg(false)
            return
          }
          result = await messageActions.send(
            phone,
            newMessageValue.inputMessageForSending,
            sendFilesData,
            conversation
          )
        }
      }

      setsendingMsg(false)
      if (result.success) {
        setFocusOverInput(true)
        if (messageType && messageType === UNASSIGNED && conversation.unassigned) {
          notifySuccess(
            getText('TEXT_THE_CONVERSATION_MOVED_TO_MY_MESSAGES_SUCCESSFULLY')
          )
        }
        if (scheduleDate) {
          result.data.isScheduled = true
          addToMessageList(result.data)
        } else {
          if (result.data.conversation && result.data.conversation.kind !== GBM) {
            if (isMountedRef.current) {
              onSendSuccess && onSendSuccess(result.data.conversation)
            }
          }
        }
        setnewMessageValue(SMS_DEFAULT_OBJECT)
        setnewEmailSubject(EMAIL_DEFAULT_OBJECT)
        setReplyMessageId('')
        setuploadFiles([])
        setIsTemplate(false)
        setSelectedUserTagList([])

        if (mixpanel.config && mixpanel.config.token) {
          if (isTemplate) {
            setMixpanel(
              mixpanel,
              'Outbound Message with templates',
              user.createdAt,
              user.fullName,
              user.organization.name,
              user.location.name,
              null,
              user.organization?._id,
              user.location?._id,
              user?._id
            )
          } else {
            setMixpanel(
              mixpanel,
              'Outbound Message',
              user.createdAt,
              user.fullName,
              user.organization.name,
              user.location.name,
              null,
              user.organization?._id,
              user.location?._id,
              user?._id
            )
          }
        }
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
    }
  }

  useEffect(() => {
    //This we need for mobile backswipe
    if (isMobileApp) {
      if (!messageContext.centerBoxVisible) {
        setTabletActionsPopupVisible(false)
        setvisibleAppointment(false)
        setshowEmojiModal(false)
        setShowMobileFooterDrawer(false)
        setSendMessageLaterVisible(false)
      }
    }
  }, [messageContext.centerBoxVisible])

  useEffect(() => {
    const handleResize = () => {
      const footerLeftActions = document.querySelector(
        '.message_page_footer--left-actions'
      )
      const footerRightActions = document.querySelector(
        '.message_page_footer--right-actions'
      )
      const leftRect = footerLeftActions?.getBoundingClientRect()
      const rigthRect = footerRightActions?.getBoundingClientRect()
      const distance = rigthRect?.left - leftRect?.right
      const sameClassNamesList = footerLeftActions?.querySelectorAll('.footerAction')
      const getLastElement = sameClassNamesList?.[sameClassNamesList?.length - 1]
      const lastItemIndex = getLastElement?.getAttribute('dataIndex')
      if (distance < 30 && !listItemsOfResponsiveMode?.includes(lastItemIndex)) {
        setListItemsOfResponsiveMode((list) => {
          if (Boolean(lastItemIndex)) {
            list.push(lastItemIndex)
          }
          return [...list]
        })
        setCountOfFooterActions((count) => count + 1)
      }
      if (distance > 100) {
        setListItemsOfResponsiveMode([])
        setCountOfFooterActions(0)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [countOfFooterActions])

  if (conversation.is_archived)
    return (
      <div className='conversation-archived-note-wrapper'>
        <p>{getText('CONVERSATION_ARCHIVED_NOTE')}</p>
      </div>
    )

  return (
    <div className={'message-center-type-wrapper'}>
      <div
        style={window.platform ? { paddingBottom: '24px' } : {}}
        className='message-center-text-input-wrapper'
      >
        <RenderMediaPreview
          files={uploadFiles}
          videoUpload={videoUpload}
          handleDeleteFile={(file) => {
            setFocusOverInput(true)
            if (file.mediaContentType.includes('video')) {
              let list = videoUpload.filter((img) => {
                return img._id !== file._id
              })
              setVideoUpload(list)
            } else {
              let list = uploadFiles.filter((img) => {
                return img._id !== file._id
              })
              setuploadFiles(list)
            }
          }}
        />
        {msgChangeEmailSMSType === EMAIL &&
        Boolean(conversation?.receiver?.email) ? (
          <SendEmailInput
            emailRef={emailRef}
            sendingMsg={sendingMsg}
            emailTemplatesList={emailTemplatesList}
            setIsTemplate={setIsTemplate}
            newEmailSubject={newEmailSubject}
            setshowEmojiModal={setshowEmojiModal}
            newMessageValue={newMessageValue}
            setnewEmailSubject={setnewEmailSubject}
            setnewMessageValue={setnewMessageValue}
            isCustomerRedStatus={isCustomerRedStatusEmail}
            setSelectedUserTagList={setSelectedUserTagList}
            setcurrentCursorPosition={setcurrentCursorPosition}
            setcurrentCursorPositionSubject={setcurrentCursorPositionSubject}
            conversation={conversation}
            usersTagList={usersTagList}
            msgChangeEmailSMSType={msgChangeEmailSMSType}
            onChangeEmailSMSType={onChangeEmailSMSType}
          />
        ) : (
          <SendMessageInput
            sendingMsg={sendingMsg}
            usersTagList={usersTagList}
            smsTemplatesList={smsTemplatesList}
            conversation={conversation}
            setIsTemplate={setIsTemplate}
            handleSendMsg={handleSendMsg}
            setuploadFiles={setuploadFiles}
            setFocusOverInput={setFocusOverInput}
            setshowEmojiModal={setshowEmojiModal}
            newMessageValue={newMessageValue}
            setnewMessageValue={setnewMessageValue}
            isCustomerRedStatus={isCustomerRedStatus}
            setSelectedUserTagList={setSelectedUserTagList}
            messageInputRef={messageContext.messageInputRef}
            setcurrentCursorPosition={setcurrentCursorPosition}
            isCustomerPhoneTemporaryBlocked={isCustomerPhoneTemporaryBlocked}
            isCustomerPhoneNumberValid={
              isCustomerPhoneNumberValid || Boolean(isCustomerPhoneTemporaryBlocked)
            }
            isCustomerYellowStatusAndRestricted={isCustomerYellowStatusAndRestricted}
            msgChangeEmailSMSType={msgChangeEmailSMSType}
            onChangeEmailSMSType={onChangeEmailSMSType}
          />
        )}
        <div className='message-center-button-wrapper'>
          <MatRow className={'message_page_footer--left-actions'}>
            {Boolean(conversation) && Boolean(conversation.receiver) && (
              <SelectSwitchSMSEmail
                msgTypeDefault={msgTypeDefault}
                msgChangeEmailSMSType={msgChangeEmailSMSType}
                setnewEmailSubject={setnewEmailSubject}
                setnewMessageValue={setnewMessageValue}
                setmsgTypeDefault={setmsgTypeDefault}
                onChangeEmailSMSType={onChangeEmailSMSType}
                receiver={conversation.receiver}
                disabled={
                  msgChangeEmailSMSType === TAG && selectedUserTagList.length
                }
                isMobile={isMobile}
                source={conversation.last_source}
              />
            )}
            {Boolean(msgChangeEmailSMSType !== TAG) && (
              <React.Fragment>
                <AIGeneratedButton
                  conversation={conversation}
                  newMessageValue={newMessageValue}
                  setnewMessageValue={setnewMessageValue}
                  currentCursorPosition={currentCursorPosition}
                  setcurrentCursorPosition={setcurrentCursorPosition}
                  disabled={
                    isCustomerPhoneNumberValid ||
                    isCustomerRedStatus ||
                    conversation.isNew ||
                    Boolean(isCustomerPhoneTemporaryBlocked) ||
                    conversation.kind === GBM
                  }
                  tooltip={
                    isLastMessageConsent ||
                    conversation?.receiver?.status?.isWaitingAnswerToConsent
                  }
                  tooltipTitle={getText(
                    'TEXT_YOU_CANT_TEXT_CUSTOMERS_WHILE_AWAITING_CONSENT'
                  )}
                />
                <MessageEmoji
                  newMessageValue={newMessageValue}
                  showEmojiModal={showEmojiModal}
                  currentCursorPosition={currentCursorPosition}
                  setnewMessageValue={setnewMessageValue}
                  conversation={conversation}
                  setshowEmojiModal={setshowEmojiModal}
                  isCustomerRedStatus={isCustomerRedStatus}
                  isCustomerYellowStatusAndRestricted={
                    isCustomerYellowStatusAndRestricted
                  }
                  isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
                  setFocusOverInput={setFocusOverInput}
                  setcurrentCursorPosition={setcurrentCursorPosition}
                />
              </React.Fragment>
            )}

            {Boolean(!isMobile && msgChangeEmailSMSType !== TAG) && (
              <UploadFileMessage
                conversation={conversation}
                videoStatusUpload={videoStatusUpload}
                setuploadFiles={setuploadFiles}
                uploadFiles={uploadFiles}
                setVideoUpload={setVideoUpload}
                videoUpload={videoUpload}
                setVideoStatusUpload={setVideoStatusUpload}
                msgChangeEmailSMSType={msgChangeEmailSMSType}
                isCustomerRedStatus={isCustomerRedStatus}
                isCustomerYellowStatusAndRestricted={
                  isCustomerYellowStatusAndRestricted
                }
                isCustomerPhoneNumberValid={
                  isCustomerPhoneNumberValid ||
                  Boolean(isCustomerPhoneTemporaryBlocked)
                }
                setFocusOverInput={setFocusOverInput}
              />
            )}
            {Boolean(!isMobile && msgChangeEmailSMSType !== TAG) && (
              <MessageFooterDesktopSize
                conversation={conversation}
                isLastMessageConsent={isLastMessageConsent}
                msgChangeEmailSMSType={msgChangeEmailSMSType}
                isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
                disabled={disabled}
                isCustomerRedStatus={isCustomerRedStatus}
                isCustomerPhoneTemporaryBlocked={isCustomerPhoneTemporaryBlocked}
                isCustomerYellowStatusAndRestricted={
                  isCustomerYellowStatusAndRestricted
                }
                smsTemplatesList={smsTemplatesList}
                emailTemplatesList={emailTemplatesList}
                newMessageValue={newMessageValue}
                setnewMessageValue={setnewMessageValue}
                currentCursorPosition={currentCursorPosition}
                setcurrentCursorPosition={setcurrentCursorPosition}
                currentCursorPositionSubject={currentCursorPositionSubject}
                setcurrentCursorPositionSubject={setcurrentCursorPositionSubject}
                mentionItem={mentionItem}
                newEmailSubject={newEmailSubject}
                setnewEmailSubject={setnewEmailSubject}
                setIsTemplate={setIsTemplate}
                setuploadFiles={setuploadFiles}
                setFocusOverInput={setFocusOverInput}
                visibleAppointment={visibleAppointment}
                setvisibleAppointment={setvisibleAppointment}
                unarchiveConversation={unarchiveConversation}
                unAssignConversation={unAssignConversation}
                addToMessageList={addToMessageList}
                refreshConversation={refreshConversation}
                listItemsOfResponsiveMode={listItemsOfResponsiveMode}
                tabletActionsPopupVisible={tabletActionsPopupVisible}
                setTabletActionsPopupVisible={setTabletActionsPopupVisible}
              />
            )}
            {Boolean(isMobile && msgChangeEmailSMSType !== TAG) && (
              <MessageFooterMobileSize
                conversation={conversation}
                isLastMessageConsent={isLastMessageConsent}
                msgChangeEmailSMSType={msgChangeEmailSMSType}
                isCustomerPhoneNumberValid={isCustomerPhoneNumberValid}
                isCustomerRedStatus={isCustomerRedStatus}
                isCustomerPhoneTemporaryBlocked={isCustomerPhoneTemporaryBlocked}
                isCustomerYellowStatusAndRestricted={
                  isCustomerYellowStatusAndRestricted
                }
                organization={organization}
                smsTemplatesList={smsTemplatesList}
                emailTemplatesList={emailTemplatesList}
                newMessageValue={newMessageValue}
                setnewMessageValue={setnewMessageValue}
                currentCursorPosition={currentCursorPosition}
                setcurrentCursorPosition={setcurrentCursorPosition}
                currentCursorPositionSubject={currentCursorPositionSubject}
                setcurrentCursorPositionSubject={setcurrentCursorPositionSubject}
                mentionItem={mentionItem}
                newEmailSubject={newEmailSubject}
                setnewEmailSubject={setnewEmailSubject}
                setIsTemplate={setIsTemplate}
                setuploadFiles={setuploadFiles}
                setFocusOverInput={setFocusOverInput}
                visibleAppointment={visibleAppointment}
                setvisibleAppointment={setvisibleAppointment}
                unarchiveConversation={unarchiveConversation}
                unAssignConversation={unAssignConversation}
                addToMessageList={addToMessageList}
                refreshConversation={refreshConversation}
                videoStatusUpload={videoStatusUpload}
                uploadFiles={uploadFiles}
                setVideoUpload={setVideoUpload}
                videoUpload={videoUpload}
                setVideoStatusUpload={setVideoStatusUpload}
                disabled={disabled}
                showMobileFooterDrawer={showMobileFooterDrawer}
                setShowMobileFooterDrawer={setShowMobileFooterDrawer}
                isExtension={isExtension || isBlueExtension}
              />
            )}
          </MatRow>
          <MatRow className={'message_page_footer--right-actions'}>
            {conversation.last_source !== 'IG messenger' &&
              conversation.kind !== GBM &&
              conversation.last_source !== 'FB messenger' &&
              msgChangeEmailSMSType !== EMAIL && (
                <div
                  className={`message-count ${
                    window.platform ? 'message-count-mobile' : ''
                  }`}
                  style={{ margin: '2px 4px 0 0' }}
                >
                  {
                    <>
                      <span
                        style={
                          newMessageValue.inputMessageForSending.length > 160
                            ? { color: 'orange' }
                            : {}
                        }
                      >
                        {newMessageValue.inputMessageForSending.length}
                      </span>{' '}
                      / 160
                    </>
                  }
                </div>
              )}
            <SendMessageButton
              videoStatusUpload={videoStatusUpload}
              onClick={handleSendMsg}
              sendingMsg={sendingMsg}
              sendingMedia={sendingMedia}
              disabled={disabled}
              uploadFiles={uploadFiles}
              videoUpload={videoUpload}
              newMessageValue={newMessageValue}
              newEmailSubject={newEmailSubject}
              msgChangeEmailSMSType={msgChangeEmailSMSType}
              setnewMessageValue={setnewMessageValue}
              isLoading={sendingMsg}
              isExtension={isExtension || isBlueExtension}
            />
            {msgChangeEmailSMSType === EMAIL ||
            conversation.kind === MESSENGER ||
            conversation.kind === GBM ||
            msgChangeEmailSMSType === TAG ? undefined : (
              <MessagesPicker
                setSendMessageLaterVisible={setSendMessageLaterVisible}
                sendMessageLaterVisible={sendMessageLaterVisible}
                loading={sendingMsg}
                active={
                  newMessageValue.inputMessageForTags || newEmailSubject.newValue
                }
                onClickSend={handleSendMsg}
                disabled={
                  !Boolean(
                    newMessageValue.inputMessageForTags || newEmailSubject.newValue
                  ) ||
                  isMessageScheduled ||
                  videoStatusUpload !== '' ||
                  selectedUserTagList.length > 0
                }
              />
            )}
          </MatRow>
        </div>
      </div>
    </div>
  )
})

export default memo(MessageCenterFooter)
