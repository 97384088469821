import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Form } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMixpanel } from 'react-mixpanel-browser'
import { useSelector } from 'react-redux'
import authActions from '../../../store/modules/authActions'
import appActions from '../../../store/modules/appActions'
import {
  isMobileAppSelector,
  userSelector,
} from '../../../store/selectors/selectors'
import { getText, getTextServerError } from '../../../lang'
import { formatPhone } from '../../../utils'
import SVGLoginLogo from '../../../icons/SVG/SVGLoginLogo'
import PageFull from '../../../components/PageFull'
import MatForm from '../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../utils/Notify'
import { removeTagManagerTracker } from '../../../utils/tagManager'
import useDeviceCheck from '../../../utils/useDeviceCheck'
import LoginPage from './LoginPage'
import LoginMagicLink from './LoginMagicLink'
import LoginPageButtons from './LoginPageButtons'
import { loginSuccess, setReduxAfterLogin } from './loginUtils'
import LoginPageDownloadButtons from './LoginPageDownloadButtons'
import LoginPageIcons from './LoginPageIcons'
import LoginPageHeader from './LoginPageHeader'
import './Login.scss'

const Login = () => {
  const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()

  const location = useLocation()
  const navigate = useNavigate()
  const mixpanel = useMixpanel()

  const isMobileApp = useSelector(isMobileAppSelector)
  const user = useSelector(userSelector)

  const [viewChange, setViewChange] = useState(false)
  const [loading, setLoading] = useState(false)
  const [magicLinkPhone, setMagicLinkPhone] = useState('')
  const [magicLinkEmail, setMagicLinkEmail] = useState('')

  const [form] = Form.useForm()

  useEffect(() => {
    if (authActions.isLoggedIn()) {
      if (
        user.isAdmin ||
        user.isManager ||
        (user.isSupportAgent &&
          user.accessibleOrganizations &&
          user.accessibleOrganizations.length > 1)
      ) {
        navigate('/dashboard')
      } else {
        navigate('/messages')
      }
    } else {
      authActions.logout()
    }
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    removeTagManagerTracker()
    if (values.emailOrPhone) {
      let phone
      let email
      let password = values.password
      if (values.emailOrPhone.includes('@')) {
        email = values.emailOrPhone
      } else {
        phone = formatPhone(values.emailOrPhone, false)
      }

      const result = await authActions.login(email, phone, password)

      if (result.success) {
        if (result.data.user) {
          let version = localStorage.getItem('version')
          if (!Boolean(version)) {
            const { data } = await appActions.getVersion()
            localStorage.setItem('version', JSON.stringify(data))
          }
          setReduxAfterLogin(result.data, mixpanel)
          const resultData = loginSuccess(
            result.data,
            location,
            isMobileApp,
            email,
            values
          )
          if (typeof resultData === 'object') {
            navigate(resultData.path, { state: resultData.state })
          } else {
            navigate(resultData)
          }
        } else {
          if (result.data.methods.length === 1) {
            navigate('/login/verification/code', {
              state: {
                token: result.data.token,
                method: result.data.methods[0].method,
                value: result.data.methods[0].value,
                loginInputValues: values,
              },
            })
          } else {
            navigate('/login/verification', {
              state: {
                token: result.data.token,
                methods: result.data.methods,
              },
            })
          }
        }
      } else {
        notifyError(
          result.errMsg
            ? result.errMsg.startsWith('child')
              ? getText('ERR_GENERAL')
              : result.errMsg
            : getText('ERR_GENERAL')
        )
      }
    }

    if (magicLinkEmail) {
      removeTagManagerTracker()
      const result = await authActions.loginMagicLinkEmail(values.email)
      if (result.success) {
        notifySuccess(getText('NTF_SUCCESS_MAGIC_SEND_EMAIL'))
      } else {
        notifyError(
          result.errMsg
            ? result.errMsg.startsWith('child')
              ? getText('ERR_GENERAL')
              : result.errMsg
            : getText('ERR_GENERAL')
        )
      }
    } else if (magicLinkPhone) {
      let phone = values.phone
      const result = await authActions.loginMagicLinkPhone(phone)
      if (result.success) {
        notifySuccess(getText('NTF_SUCCESS_MAGIC_SEND_SMS'))
      } else {
        notifyError(
          result.errMsg
            ? result.errMsg.startsWith('child')
              ? getText('ERR_GENERAL')
              : result.errMsg
            : getText('ERR_GENERAL')
        )
      }
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(
        errorInfo.errorFields.length === 2
          ? getText('MSG_PLEASE_INPUT_PHONE_OR_EMAIL')
          : getTextServerError(errorInfo.errorFields[0].errors[0])
      )
    }
  }

  return (
    <PageFull className='login-page-wrapper'>
      <Row className='login-page-main-row'>
        <Col
          className='login-page-main-col'
          style={{
            width: !isMobileOrTabletOrTabletPortrait ? '59%' : '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: !isMobileOrTabletOrTabletPortrait
                ? 'flex-start'
                : 'center',
              marginTop: !isMobileOrTabletOrTabletPortrait ? 0 : 20,
            }}
          >
            <SVGLoginLogo />
          </div>
          <Card bordered={false} className='login-page-left-card'>
            <LoginPageHeader viewChange={viewChange} />
            <MatForm form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              {viewChange ? (
                <LoginMagicLink
                  form={form}
                  setMagicLinkEmail={(e) => {
                    setMagicLinkEmail(e)
                  }}
                  setMagicLinkPhone={(e) => {
                    setMagicLinkPhone(e)
                  }}
                  magicLinkPhone={magicLinkPhone}
                  magicLinkEmail={magicLinkEmail}
                />
              ) : (
                <LoginPage />
              )}
              <LoginPageButtons
                loading={loading}
                viewChange={viewChange}
                setViewChange={(view) => {
                  setViewChange(view)
                }}
              />
              {viewChange || isMobileApp ? null : <LoginPageDownloadButtons />}
            </MatForm>
          </Card>
        </Col>
        {!isMobileOrTabletOrTabletPortrait ? <LoginPageIcons /> : null}
      </Row>
    </PageFull>
  )
}

export default Login
