import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getText } from '../../../../../../lang'
import MatForm from '../../../../../../components/Form/MatForm'
import BroadcastCampaignPage from '../../../../BroadcastCampaignPage'
import { notifyError } from '../../../../../../utils/Notify'
import MatButton from '../../../../../../components/MatButton'
import SelectCheckedSecondary from '../../../../../../components/Form/SelectChecked/SelectCheckedSecondary'
import workflowActions from '../../../../../../store/modules/workflowActions'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../../../../devOptions'
import FormButtons from '../FormButtons/FormButtons'
import './Workflows.scss'

const Workflows = (props) => {
  const { organization, onStateChange, onChangeStep, workflow, segmentState } = props

  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [page, setPage] = useState(0)
  const [workflowList, setWorkflowList] = useState({
    list: [],
    total: 0,
  })

  const getWorkflowList = async () => {
    const result = await workflowActions.getWorkflowList(
      page,
      TABLE_DEFAULT_ROW_NUMBER,
      organization?._id
    )
    if (result.success) {
      let list = result.data.workflows.map((item) => {
        return Object.assign(item, {
          label: item.name,
          value: item._id,
        })
      })
      let oldValue = workflowList.list
      if (page > 0) {
        list = oldValue.concat(list)
      }
      setWorkflowList({
        list: list,
        total: result.data.total_items,
      })
    } else {
      notifyError(result.errMsg)
    }
  }

  useEffect(() => {
    getWorkflowList()
  }, [page])

  const onFinish = () => onChangeStep(1)

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  return (
    <BroadcastCampaignPage title={getText('WORD_WORKFLOW')}>
      <div className='create-campaign-form-wrapper ant-form-vertical'>
        <MatForm
          form={form}
          name='formSaveWorkflow'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ workflow: workflow ? [workflow] : [] }}
        >
          <MatButton
            htmlType={'button'}
            buttonText={'+ ' + getText('WORD_BUILD_NEW_WORKFLOW')}
            typeButton={'radius'}
            className='broadcast-workflow-button'
            onClick={() => {
              navigate(
                `/organization/settings/${organization?._id}/workflow/create`,
                { state: { fromBroadcast: true, segmentState } }
              )
            }}
          />
          <SelectCheckedSecondary
            label={''}
            placeholder={getText('WORD_CHOOSE_EXISTING_WORKFLOW')}
            name={'workflow'}
            option={workflowList.list || []}
            onChangeItem={(val, item) => {
              onStateChange('workflow', item)
            }}
            loading={workflowList.list.length > 0 ? false : true}
            onLoadMore={() => {
              if (workflowList.list.length <= workflowList.total) {
                setPage(page + 1)
              }
            }}
            getPopupContainer={() => document.querySelector('.steps-wrapper')}
          />
          <FormButtons
            prevAction={onChangeStep}
            currentFormName='formSaveWorkflow'
            submitFormBtn={getText('WORD_NEXT')}
            cancelFormBtn={getText('WORD_PREVIOUS')}
          />
        </MatForm>
      </div>
    </BroadcastCampaignPage>
  )
}

export default Workflows
