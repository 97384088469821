import React from 'react'
import Div from '../../Div/Div'
import './MyDefaultTableText.scss'

const MyDefaultTableText = ({ text, color, fontWeight }) => {
  return (
    <Div
      style={{ color: color, fontWeight: fontWeight }}
      className={'my_default_table_text'}
    >
      {text}
    </Div>
  )
}

export default MyDefaultTableText
