import React, { memo, useRef, useState } from 'react'
import { Flex, Typography } from 'antd'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { getText } from '../../../../lang'
import Div from '../../../../components/MatDiv/Div'
import utilNumber from '../../../../utils/utilNumber'
import DashboardInfoTooltip from '../DashboardInfoTooltip/DashboardInfoTooltip'
import { MONTH_MAP } from '../../../../utils'
import './DashboardStatistics.scss'

const DashboardStatistics = ({
  number,
  data,
  icon,
  title,
  className,
  tooltipTitle,
  responsiveChartHeight,
}) => {
  const tooltipRef = useRef(null)
  const [tooltipPosition, setTooltipPosition] = useState(null)
  const [isLastPoint, setIsLastPoint] = useState(false)

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Div className='chart_custom_tooltip'>
          <Div className='chart_custom_tooltip--content'>
            <Div className='chart_custom_tooltip--inner'>
              <p className='title'>{getText('WORD_CURRENT_MONTH')}</p>
              <p className='intro'>{payload[0].value}</p>
              <p className='desc label'>
                {label.length === 3 ? MONTH_MAP[label] : label}
              </p>
            </Div>
          </Div>
        </Div>
      )
    }
    return null
  }

  return (
    <Div ref={tooltipRef} className={`dashboard_sent_statistics ${className || ''}`}>
      <Div className={'dashboard_sent_statistics-bg purple'}></Div>
      <Flex gap={8} align='center' className='dashboard_sent_statistics--header'>
        {icon}
        <span>
          {title}
          <DashboardInfoTooltip title={tooltipTitle} />
        </span>
      </Flex>
      <Flex align='center' className='dashboard_sent_statistics--information'>
        <Typography level={1}>{utilNumber.numberFractions(number)}</Typography>
      </Flex>
      <ResponsiveContainer height={responsiveChartHeight}>
        <Flex className='dashboard_sent_statistics-chart-info' justify='flex-end'>
          {getText('TEXT_LAST_SIX_MONTH')}
        </Flex>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
          onMouseMove={(e) => {
            if (e?.activeCoordinate && data.length) {
              const isLast = e.activeLabel === data[data.length - 1].name
              setIsLastPoint(isLast)
              setTooltipPosition({
                x: e?.activeCoordinate.x,
                y: e?.activeCoordinate.y,
              })
            }
          }}
        >
          <defs>
            <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#6B75EC' stopOpacity={0.4} />
              <stop offset='95%' stopColor='#3D4286' stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey='name' tickLine={false} />
          <YAxis tickLine={false} />
          <CartesianGrid strokeDasharray='' opacity={0.2} vertical={false} />
          <Tooltip
            content={<CustomTooltip />}
            position={
              Boolean(tooltipPosition)
                ? {
                    x: tooltipPosition.x - (isLastPoint ? 80 : 50),
                    y: tooltipPosition.y - 100,
                  }
                : null
            }
          />
          <Area
            type='monotone'
            dataKey='value'
            stroke='#818BFF'
            fillOpacity={1}
            fill='url(#colorUv)'
            strokeWidth={2}
            dot={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Div>
  )
}

export default memo(DashboardStatistics)
