import React from 'react'
import { Tooltip } from 'antd'
import { getText } from '../../lang'
import SVGInfoIconOrange from '../../icons/SVG/SVGInfoIconOrange'
import Div from '../Div/Div'
import './MyDefaultInactiveBlock.scss'

const MyDefaultInactiveBlock = ({ tooltipText, enableMargin }) => {
  if (tooltipText)
    return (
      <Tooltip title={tooltipText}>
        <Div
          className={`my_default_inactive_block_wrapper ${enableMargin ? 'margin' : ''}`}
        >
          {getText('WORD_INACTIVE')}
          <SVGInfoIconOrange />
        </Div>
      </Tooltip>
    )

  return (
    <Div
      className={`my_default_inactive_block_wrapper ${enableMargin ? 'margin' : ''}`}
    >
      <SVGInfoIconOrange />
    </Div>
  )
}

export default MyDefaultInactiveBlock
