import React from 'react'
import { Flex } from 'antd'

const MyDefaultBlockSubtitle = ({
  subtitle,
  rightContent,
  fontSize = 14,
  disableMargin,
}) => {
  return (
    <Flex
      className={'my_default_block_subtitle'}
      align='center'
      justify='space-between'
      style={{ fontSize: fontSize, marginBottom: disableMargin ? 0 : 12 }}
    >
      {subtitle}
      {rightContent && rightContent}
    </Flex>
  )
}

export default MyDefaultBlockSubtitle
