import React, { useEffect, useState } from 'react'
import querystring from 'querystring'
import { Navigate, useLocation } from 'react-router-dom'
import messageActions from '../../store/modules/messageActions'
import { DEALER_SOCKET, VIN_SOLUTION, ELEADS } from '../../config/constants'

const extractSearchParams = (string) => {
  return querystring.parse(string.substr(1))
}

const CreateConversation = () => {
  const [loading, setloading] = useState(true)

  const location = useLocation()

  const query = extractSearchParams(location.search)

  useEffect(() => {
    const { phone_number } = query
    if (phone_number) {
      const handleCreateNewConversationFromURL = async (phone_number) => {
        let newPhone = phone_number
          .replace(/\s/g, '')
          .replace(/[()]/g, '')
          .replace('+', '')
        const result = await messageActions.apply(newPhone)
        if (result.success) {
          result.data.conversation.isNew = result.data.isNew
          result.data.conversation.isSetDataReciver = true
          if (query.email) {
            result.data.conversation.receiver.email = query.email
          }
          if (query.first_name) {
            result.data.conversation.receiver.firstName = query.first_name
            result.data.conversation.receiver.fullName = query.first_name
          }
          if (query.last_name) {
            result.data.conversation.receiver.lastName = query.last_name
            result.data.conversation.receiver.fullName += ' ' + query.last_name
          }
          if (query.source) {
            result.data.conversation.receiver.source =
              (query.source.toLowerCase() === DEALER_SOCKET.toLowerCase() &&
                DEALER_SOCKET) ||
              (query.source.toLowerCase() === ELEADS.toLowerCase() && ELEADS) ||
              (query.source.toLowerCase() === VIN_SOLUTION.toLowerCase() &&
                VIN_SOLUTION) ||
              'other'
          }
          if (query.website) {
            result.data.conversation.receiver.website = query.website
          }
          localStorage.setItem(
            'createNewConversationFromURL',
            JSON.stringify(result.data.conversation)
          )
        }
        setloading(false)
      }
      handleCreateNewConversationFromURL(phone_number)
    }
  }, [])

  return (
    <div>
      Creating Conversation...
      {!loading && (
        <Navigate
          to={{
            pathname: '/messages',
          }}
        />
      )}
    </div>
  )
}

export default CreateConversation
