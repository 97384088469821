import React, { useState, useEffect } from 'react'
import { Form, Tabs } from 'antd'
import { useSelector } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import { getLongName, replaceWords } from '../../../../../utils'
import { getText, getTextServerError } from '../../../../../lang'
import { APP_URL } from '../../../../../config'
import MatForm from '../../../../../components/Form/MatForm'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import InputTextarea from '../../../../../components/InputTextarea'
import MatButton from '../../../../../components/MatButton'
import { organizationSelector } from '../../../../../store/selectors/selectors'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import chatSettingsMainActions from '../../../../../store/modules/chatSettingsMainActions'
import MatadorConnectLayout from '../matadorConnectComponents/MatadorConnectLayout'
import MatadorConnectListTitle from '../matadorConnectComponents/MatadorConnectListTitle'
import ThemePreview from '../preview/ThemePreview'
import InputFormTextSecondary from '../../../../../components/Form/InputFormText/InputFormTextSecondary'
import InputFormTextAreaSecondary from '../../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import SwtichFormSecondary from '../../../../../components/Form/SwitchForm/SwtichFormSecondary'
import MatadorConnectInstructions from '../instructions/OpenMatadorConnectInstructions'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../../devOptions'
import { useLocationLayout } from '../../../locationProvider/LocationProvider'
import { useLayout } from '../../../../../layout/LayoutProvider/LayoutProvider'
import MatadorConnectVehiclePropertiesInstructions from '../instructions/MatadorConnectVehiclePropertiesInstructions'
import UrlConditionManager from './UrlConditionManager'
import './Settings.scss'

const Settings = () => {
  const { locationObj, settings, setSettings } = useLocationLayout()
  const { setFooterButtons, setLoading } = useLayout()
  const organization = useSelector(organizationSelector)
  const languages = organization.languages
  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const [settingTextCopy, setSettingTextCopy] = useState(settings.texts)
  const [widgetBodyIsCopied, setWidgetBodyIsCopied] = useState(false)
  const [form] = Form.useForm()
  const [urls, setUrls] = useState([])

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
      },
    ])
    setSelectedLangTab(languages[0])
    setSettingTextCopy(settings.texts)
    setUrls(settings.conditions.url)
  }, [])

  const isTermLinksValid = (str) => {
    try {
      new URL(str)
    } catch (_) {
      return false
    }

    let pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator

    return !pattern.test(str)
  }

  const onFinish = async (values) => {
    setLoading(true)
    const allValues = form.getFieldsValue(true)

    if (allValues.termsLink && isTermLinksValid(allValues.termsLink)) {
      return notifyError(getText('TEXT_TERMS_LINK_INVALID'))
    }

    let deviceSetting
    if (allValues.showMobile && allValues.showDesktop) {
      deviceSetting = 'all'
    } else if (allValues.showDesktop) {
      deviceSetting = 'desktop'
    } else if (allValues.showMobile) {
      deviceSetting = 'mobile'
    } else {
      deviceSetting = 'none'
    }

    const payload = {
      device: deviceSetting,
      isTriggerOnNewSession: allValues.isTriggerOnNewSession,
      termsLink: allValues.termsLink,
      texts: allValues.texts,
      displayAllOrganizationAddresses: allValues.displayAllOrganizationAddresses,
      firstAndLastNameSeparately: allValues.firstAndLastNameSeparately,
      isCustomerNameRequired: allValues.isCustomerNameRequired,
      conditions: {
        url: urls,
      },
    }

    // the BE set on texts an object with 3 languages all being empty strings
    // We shouold remove the language not used by organization as it leads to an error
    for (const lng in payload.texts) {
      if (!languages.includes(lng)) {
        delete payload.texts[lng]
      }
    }
    let result
    if (settings._id) {
      result = await chatSettingsMainActions.update(payload, locationObj._id)
    } else {
      // TODO not sure in which case this is used
      result = await chatSettingsMainActions.insert(payload, locationObj._id)
    }
    if (result.success) {
      setSettings(result.data.settings)
      notifySuccess(getText('TEXT_MATADOR_CONNECT_SAVED_SUCCESSFULLY'))
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    for (const lng of languages) {
      if (!settingTextCopy[lng] || !settingTextCopy[lng].welcome_title) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('WORD_WELCOME_TITLE'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      } else if (!settingTextCopy[lng] || !settingTextCopy[lng].first_message) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('WORD_FIRST_MESSAGE'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      } else if (!settingTextCopy[lng] || !settingTextCopy[lng].second_message) {
        const errorText = replaceWords(
          getText('TEXT_VARIABLE_REQUIRED_BOTH_LANGUAGE'),
          {
            missingVariable: getText('WORD_SECOND_MESSAGE'),
            missingLanguage: getLongName(lng),
          }
        )
        return notifyError(errorText)
      }
    }
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const widgetBodyCode = `<!-- Start of Matador Live Chat Script --> 
  <script>
    (function(d,t) {
      var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
      g.src=src="${APP_URL}/_livechat.js";
      g.async = true;
      s.parentNode.insertBefore(g,s);
      g.onload=function(){
        matadorLiveChatWidget("${settings._token}");
      }
    })(document,"script");
  </script>
  <!-- End of Matador Live Chat Script -->`

  const getInitialText = () => {
    return {
      welcome_title: '',
      first_message: '',
      second_message: '',
    }
  }

  const settingsCopy = {
    ...settings,
    texts: settingTextCopy,
  }

  return (
    <MatadorConnectLayout className='livechat-settings-wrapper'>
      <MatadorConnectListTitle title={getText('WORD_SETTINGS')} />
      <div className='form-chatboxPreview-wrapper'>
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            texts: {
              en: settings.texts.en ? { ...settings.texts.en } : getInitialText(),
              fr: settings.texts.fr ? { ...settings.texts.fr } : getInitialText(),
              sp: settings.texts.sp ? { ...settings.texts.sp } : getInitialText(),
            },
            showMobile: settings.device === 'all' || settings.device === 'mobile',
            showDesktop: settings.device === 'all' || settings.device === 'desktop',
            isTriggerOnNewSession: settings.isTriggerOnNewSession,
            firstAndLastNameSeparately: settings.firstAndLastNameSeparately,
            isCustomerNameRequired: settings.isCustomerNameRequired,
            termsLink: settings.termsLink,
            displayAllOrganizationAddresses:
              settings.displayAllOrganizationAddresses,
          }}
        >
          <Tabs
            className='mat-tabs'
            onChange={(index) => {
              setSelectedLangTab(organization.languages[index])
            }}
            items={organization.languages.map((lng, index) => {
              return {
                label: getLongName(lng),
                key: index,
                forceRender: true,
                children: (
                  <>
                    <InputFormTextSecondary
                      label={getText('WORD_CHAT_SETTINGS_WELCOME_MESSAGE')}
                      name={['texts', lng, 'welcome_title']}
                      required
                      errorMessage={getText('ERR_VALIDATION_REQUIRED')}
                      onChange={(e) => {
                        e.persist()
                        setSettingTextCopy((oldText) => ({
                          ...oldText,
                          [lng]: {
                            ...oldText[lng],
                            welcome_title: e.target.value,
                          },
                        }))
                      }}
                    />
                    <InputFormTextAreaSecondary
                      label={getText('WORD_AUTO_REPLY_FIRST')}
                      name={['texts', lng, 'first_message']}
                      required
                      autoSize={false}
                      message={getText('ERR_VALIDATION_REQUIRED')}
                      onChange={(e) => {
                        e.persist()
                        setSettingTextCopy((oldText) => ({
                          ...oldText,
                          [lng]: {
                            ...oldText[lng],
                            first_message: e.target.value,
                          },
                        }))
                      }}
                    />
                    <InputFormTextAreaSecondary
                      label={getText('WORD_AUTO_REPLY_SECOND')}
                      name={['texts', lng, 'second_message']}
                      required
                      autoSize={false}
                      message={getText('ERR_VALIDATION_REQUIRED')}
                      onChange={(e) => {
                        e.persist()
                        setSettingTextCopy((oldText) => ({
                          ...oldText,
                          [lng]: {
                            ...oldText[lng],
                            second_message: e.target.value,
                          },
                        }))
                      }}
                    />
                  </>
                ),
              }
            })}
          />
          <MatBlockWithLabel black label={getText('WORD_VISIBILTY')}>
            <div className='location-settings-switchs-wrapper'>
              <SwtichFormSecondary
                name='showMobile'
                text={getText('LABEL_SHOW_WIDGET_MOBILE')}
              />
              <SwtichFormSecondary
                name='showDesktop'
                text={getText('LABEL_SHOW_WIDGET_DESKTOP')}
              />
              <SwtichFormSecondary
                name='isTriggerOnNewSession'
                text={getText('LABEL_TRIGGER_NEW_SESSION')}
              />
            </div>
          </MatBlockWithLabel>

          <MatBlockWithLabel black label={getText('TEXT_FORM_SETTINGS')}>
            <div className='location-settings-switchs-wrapper'>
              <SwtichFormSecondary
                name='firstAndLastNameSeparately'
                text={getText(
                  'TEXT_DISPLAY_THE_FIRST_AND_LAST_NAMES_SEPARATELY_IN_WIDGET_FORM'
                )}
              />

              <SwtichFormSecondary
                name='isCustomerNameRequired'
                text={getText('TEXT_NAME_IS_REQUIRED_IN_WIDGET_FORM')}
              />
            </div>
          </MatBlockWithLabel>

          <MatBlockWithLabel>
            <InputFormTextSecondary
              label={getText('WORD_TERMS_LINK')}
              name='termsLink'
            />
          </MatBlockWithLabel>
          <MatBlockWithLabel
            label={getText('WORD_WIDGET_CODE')}
            className='text-area-copy-wrapper'
            black
          >
            <InputTextarea autoSize={true} value={widgetBodyCode} disabled />
            <CopyToClipboard
              text={widgetBodyCode}
              onCopy={() => {
                setWidgetBodyIsCopied(true)
              }}
            >
              <MatButton
                size='small'
                htmlType='button'
                className='copy-button'
                buttonText={
                  widgetBodyIsCopied ? getText('WORD_COPIED') : getText('WORD_COPY')
                }
              />
            </CopyToClipboard>
          </MatBlockWithLabel>
          <UrlConditionManager urls={urls} setUrls={setUrls} />

          <div className='mt-4'>
            <MatadorConnectInstructions />
          </div>

          <div className='mt-4'>
            <MatadorConnectVehiclePropertiesInstructions />
          </div>
        </MatForm>
        <div className='matador-connect-settings-chat-wrapper'>
          <ThemePreview
            settings={settingsCopy}
            language={selectedLangTab}
            locationId={settings._token}
            theme={settings.theme}
          />
        </div>
      </div>
    </MatadorConnectLayout>
  )
}

export default Settings
