import React, { memo } from 'react'
import { motion } from 'framer-motion'
import MatButton from '../../../../../components/MatButton'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import {
  formatDateAppoinment,
  formatDateTime,
  formatTimeAppoinment,
} from '../../../../../utils'
import SVGTimeIcon from '../../../../../icons/SVG/SVGTimeIcon'
import SVGDateIcon from '../../../../../icons/SVG/SVGDateIcon'
import { getText } from '../../../../../lang'
import {
  CONVERSATION_CREATED,
  REACTIVATE,
  SKIP_SCHEDULED_MESSAGE,
  SNOOZE,
  SUBSCRIBE,
  UNSUBSCRIBE,
  BOT_CANCELLED,
  OUTGOING_CALL,
  OUTGOING_CALL_LINK,
  ARCHIVE_STATUS,
  CONSENT_EDIT,
  CALL,
} from '../../../utils/constants'
import utilDate from '../../../../../utils/utilsDate'
import { MESSAGE_BLOCK_ANIMATIONS } from '../../../utils/messageUtils'
import SVGSendMessageCheckedIcon from '../../../../../icons/SVG/SVGSendMessageCheckedIcon'
import utilsLocalizations from '../../../../../utils/utilsLocalizations'
import MessageMarkAsReadButton from '../../centerMessageHeader/MessageMarkAsReadButton/MessageMarkAsReadButton'
import './CenterMessageItem.scss'
import { AI_FOLLOW_UP } from '../../../../../devOptions'

const CenterMessageItemButton = ({
  hasButtonText,
  customButton,
  buttonText,
  onClick,
  title,
  conversation,
  onChange,
  buttonPlaceChanged,
}) => {
  return (
    hasButtonText && (
      <React.Fragment>
        {customButton ? (
          <MatButton
            buttonText={buttonText}
            size={'small'}
            onClick={(e) => {
              onClick && onClick(e)
            }}
            style={{ marginTop: 8 }}
          />
        ) : (
          <ConfirmPopup
            title={title}
            onConfirm={() => {
              onChange && onChange(conversation)
            }}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            trigger={
              <MatButton
                buttonText={buttonText || getText('ACTION_DELETE')}
                size={'small'}
                typeButton={'danger'}
                style={buttonPlaceChanged ? { marginTop: 8 } : {}}
              />
            }
          />
        )}
      </React.Fragment>
    )
  )
}

const CenterMessageItem = (props) => {
  const {
    message,
    index,
    icon,
    hasFooter,
    onChange,
    conversation,
    date,
    messageTitle,
    messageBody,
    withUserName,
    buttonText,
    hasButtonText,
    onClick,
    customButton,
    buttonPlaceChanged,
    title,
    messageListType,
    lastNotSentMessage,
  } = props

  let scheduledMessageName = message?.user?.firstName + ' ' + message?.user?.lastName

  const getSkippedTitle = (createdBy) => {
    let text = getText('TEXT_SKIPPED_SCHEDULED_MESSAGE_CREATED_DRIP_SEQUENCE')

    if (createdBy === AI_FOLLOW_UP) {
      text = getText('TEXT_SKIPPED_SCHEDULED_MESSAGE_CREATED_AI_FOLLOW_UP')
    }

    return text
  }

  const getLocalizedTitle = () => {
    let text = messageTitle
    switch (message?.type) {
      case SKIP_SCHEDULED_MESSAGE:
        text = `${message?.whoSentThis_name} ${getSkippedTitle(message?.additionalInfo?.createdByName || '')}`
        break
      case REACTIVATE:
        text = `${message?.whoSentThis_name} ${getText('TEXT_REACTIVATED_SUPERHUMAN_PARTICIPATION_CONVERSATION')}`
        break
      case SNOOZE:
        text = `${message?.whoSentThis_name} ${getText('TEXT_SNOOZED_SUPERHUMAN_FOR_HOURS')}`
        break
      case SUBSCRIBE:
        text = `${getText('TEXT_THIS_CUSTOMER_SUBSCRIBED_EMAIL_COMMUNICATION')}`
        break
      case UNSUBSCRIBE:
        text = `${getText('TEXT_THIS_CUSTOMER_UNSUBSCRIBED_EMAIL_COMMUNICATION')}`
        break
      case CONVERSATION_CREATED:
        text = `${getText('WORD_CONVERSATION_CREATED_AT')} ${utilDate.getLocalizedFullDate(message.createdAt)}`
        break
      case BOT_CANCELLED:
        text = `${getText('TEXT_DRIP_SEQUENCE_WAS_CANCELLED_BY')} ${message?.whoSentThis_name}`
        break
      case OUTGOING_CALL:
        text = `${getText('TEXT_VIDEO_CALL_END')}`
        break
      case OUTGOING_CALL_LINK:
        text = `${getText('WORD_JOIN_A_VIDEO_CALL')}`
        break
      case ARCHIVE_STATUS:
        text = `Conversation ${message.archiveType}d by ${message.fullName}`
        break
      case CALL:
        text = `${getText('TEXT_INCOMING_CALL_FROM')} ${message?.whoSentThis_name || getText('WORD_CUSTOMER').toLowerCase()}`
        break
      case CONSENT_EDIT:
        text = utilsLocalizations.localizedText(
          message.message,
          message.whoChangeConsent
        )
        break
      default:
        text = messageTitle || message?.body
        break
    }
    return text
  }

  const localizedTitle = getLocalizedTitle()

  const getDeliveryStatus = (status) => {
    if (status === 'queued' || status === 'accepted') {
      return <SVGSendMessageCheckedIcon color='#00CB85' />
    } else if (status === 'delivered' || status === 'sent') {
      return <SVGSendMessageCheckedIcon color='#00CB85' />
    } else if (status === 'failed' || status === 'undelivered') {
      return <SVGSendMessageCheckedIcon />
    }
  }

  return (
    <motion.div
      key={index}
      className='center_message_item_wrapper'
      {...MESSAGE_BLOCK_ANIMATIONS}
      layout
    >
      <div className='icon-wrapper'>{icon}</div>
      <div className='center_message_item_block'>
        <div className='center_message_item_block--body'>
          {message?.type === 'EMAIL' ? (
            <div className='center_message_item_block--body-text'>
              {`${localizedTitle} ${withUserName ? scheduledMessageName : ''}`}
              {localizedTitle && <br />}
              <span>
                {getText('WORD_SUBJECT')}: {message?.subject}
              </span>
              <br />
              {messageBody && <i>"{messageBody}"</i>}
            </div>
          ) : (
            <div className='center_message_item_block--body-text'>
              {`${localizedTitle} ${withUserName ? scheduledMessageName : ''}`}
              {localizedTitle && <br />}
              {messageBody && (
                <i dangerouslySetInnerHTML={{ __html: `"${messageBody}"` }} />
              )}
            </div>
          )}
        </div>
        {!buttonPlaceChanged && (
          <CenterMessageItemButton
            hasButtonText={hasButtonText}
            customButton={customButton}
            buttonText={buttonText}
            onClick={onClick}
            title={title}
            conversation={conversation}
            onChange={onChange}
          />
        )}
        {hasFooter && (
          <div className='center_message_item_block--footer'>
            <div className='center_message_item_block--footer-item'>
              <SVGDateIcon width={16} height={16} />
              {formatDateAppoinment(date)}
            </div>
            <div className='center_message_item_block--footer-item'>
              <SVGTimeIcon width={16} height={16} />
              {formatTimeAppoinment(date)}
            </div>
          </div>
        )}
        {buttonPlaceChanged && (
          <CenterMessageItemButton
            hasButtonText={hasButtonText}
            customButton={customButton}
            buttonText={buttonText}
            onClick={onClick}
            title={title}
            conversation={conversation}
            onChange={onChange}
            buttonPlaceChanged={buttonPlaceChanged}
          />
        )}
      </div>
      <div className='date'>
        {formatDateTime(message?.createdAt)}{' '}
        {message?.type === OUTGOING_CALL_LINK &&
          getDeliveryStatus(message.smsStatus)}{' '}
      </div>
      <div className='center_message_item_wrapper--button'>
        {lastNotSentMessage?._id === message._id && conversation?.is_unread && (
          <MessageMarkAsReadButton
            conversation={conversation}
            messageType={messageListType}
          />
        )}
      </div>
    </motion.div>
  )
}

export default memo(CenterMessageItem)
