import React from 'react'
import { Dropdown } from 'antd'
import SVGSelectArrow from '../../icons/SVG/SVGSelectArrow'
import './MyDefaultDropdownButton.scss'

export const MyDefaultDropdownButton = ({
  title,
  dropdownMenuItems,
  onItemClick,
  onClick,
  className,
}) => {
  return (
    <Dropdown.Button
      className={`my_default_dropdown_button ${className || ''}`}
      overlayClassName='my_default_dropdown_button_dropdown'
      type='primary'
      onClick={onClick}
      menu={{
        items: dropdownMenuItems || [],
        onClick: onItemClick,
      }}
      icon={
        <SVGSelectArrow
          style={{
            pointerEvents: 'none',
          }}
          color={'var(--white)'}
        />
      }
      trigger={['click']}
    >
      {title}
    </Dropdown.Button>
  )
}
