import React from 'react'
import { Flex } from 'antd'
import MyDefaultTagBlock from '../../MyDefaultTagBlock/MyDefaultTagBlock'
import MyDefaultSwitch from '../../MyDefaultSwitch/MyDefaultSwitch'
import './MyDefaultTableHeader.scss'

const MyDefaultTableHeader = ({
  title,
  textAfterSwitch,
  switchValue,
  onChangeSwitch,
  actionTitle,
  actionBy,
  totalCount,
  disableRight,
}) => {
  return (
    <Flex
      justify={'space-between'}
      className={'my_default_table_header'}
      wrap={'wrap'}
      gap={10}
    >
      <Flex gap={12} align='center' className='my_default_table_header--left'>
        {title}
        <MyDefaultTagBlock
          title={totalCount}
          titleColor='var(--white)'
          backgroundColor='var(--blue)'
          disableMargin
          height={20}
        />
      </Flex>
      {!disableRight && (
        <Flex
          gap={22}
          align='center'
          className='my_default_table_header--right'
          wrap={'wrap'}
        >
          {Boolean(actionTitle) && (
            <Flex gap={4}>
              <span className='my_default_table_header--right-action_title'>
                {actionTitle}
              </span>
              <span className='my_default_table_header--right-action_by'>
                {actionBy}
              </span>
            </Flex>
          )}
          <MyDefaultSwitch
            noText
            isFirst
            isLast
            value={switchValue}
            textAfterSwitch={textAfterSwitch}
            onChangeSwitch={onChangeSwitch}
            className={'my_default_table_header_switch'}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default MyDefaultTableHeader
