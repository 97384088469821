import React from 'react'
import Div from '../../../../components/Div/Div'
import MyDefaultButton from '../../../../components/MyDefaultButton/MyDefaultButton'
import { getText } from '../../../../lang'

const VoiceAIBlockCallTransfersAdditionalHeader = ({ onClick }) => {
  return (
    <Div className={'voice_ai_block_call_transfers_additional_header'}>
      <MyDefaultButton
        typeButton={'white'}
        buttonText={getText('TEXT_ADD_DEPARTMENT')}
        onClick={(e) => {
          e.preventDefault()
          onClick && onClick()
        }}
      />
    </Div>
  )
}

export default VoiceAIBlockCallTransfersAdditionalHeader
