import React, { memo } from 'react'
import { Card, Flex, Progress, Tooltip, Typography } from 'antd'
import Div from '../../../../components/MatDiv/Div'
import DashboardInfoTooltip from '../DashboardInfoTooltip/DashboardInfoTooltip'
import './DashboardProgressBlock.scss'

const DashboardProgressBlock = ({
  icon,
  title,
  content,
  color,
  description,
  strokeColor,
  percent,
  align,
  tooltipTitle,
  maxWidth,
}) => {
  return (
    <Card className='dashboard_block'>
      <Div className={`dashboard_block--blurred-part ${color}`}></Div>
      <Flex gap={8} align={align || 'start'} className='dashboard_block--header'>
        {icon}
        <Div className='dashboard_block--header--right'>
          <Flex vertical>
            <span style={{ maxWidth: maxWidth }}>
              {title}
              <DashboardInfoTooltip title={tooltipTitle} />
            </span>
            {description && <span>{description}</span>}
          </Flex>
        </Div>
      </Flex>
      <Flex align='center' justify='space-between'>
        <Typography>{content}</Typography>
        {strokeColor && (
          <div className='dashboard_block--tooltip-progress'>
            <Tooltip
              overlayClassName={color}
              getPopupContainer={(trigger) => trigger.parentNode}
              title={content}
              align={{
                useCssRight: true,
                targetOffset: ['100%', '100%'],
              }}
              overlayStyle={{
                left: `calc(${percent}% - 23px)`,
              }}
            >
              <Progress
                percent={percent}
                percentPosition={{
                  align: 'center',
                  type: 'inner',
                }}
                showInfo={false}
                strokeColor={strokeColor}
              />
            </Tooltip>
          </div>
        )}
      </Flex>
    </Card>
  )
}

export default memo(DashboardProgressBlock)
