import React from 'react'
import { Tooltip } from 'antd'
import { getText } from '../../../lang'
import SVGInfoIconSilver from '../../../icons/SVG/SVGInfoIconSilver'
import MyDefaultButton from '../MyDefaultButton'
import { POSITIVE_BUTTON } from '../../../devOptions'
import './MyDefaultPositiveButton.scss'
import Div from '../../Div/Div'

const MyDefaultPositiveButton = ({ buttonText, onClick, disabled, className }) => {
  return (
    <MyDefaultButton
      typeButton={POSITIVE_BUTTON}
      buttonText={buttonText}
      disabled={disabled}
      onClick={onClick}
      className={className}
      icon={
        <Tooltip title={getText('TEXT_BY_CLICKING_YOU_ENABLE_ALL_SEQUENCES')}>
          <Div>
            <SVGInfoIconSilver />
          </Div>
        </Tooltip>
      }
      iconPosition={'end'}
    />
  )
}

export default MyDefaultPositiveButton
