import React, { memo, useState } from 'react'
import { Card, Flex } from 'antd'
import { getText } from '../../../../../lang'
import Div from '../../../../../components/MatDiv/Div'
import SVGDashInfluencedSalescon from '../../../../../icons/SVG/SVGDashInfluencedSalescon'
import MatButton from '../../../../../components/MatButton'
import statActions from '../../../../../store/modules/statActions'
import { notifyError, notifySuccess } from '../../../../../utils/Notify'
import './DashboardInfluencedSales.scss'

const DashboardInfluencedSales = () => {
  const [loading, setLoading] = useState(false)
  return (
    <Card className='dashboard_block dashboard_influenced_sales'>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        <SVGDashInfluencedSalescon />
        <Div className='dashboard_block--header--right'>
          <span>{getText('WORD_SALES_IMPACT_REPORT')}</span>
        </Div>
      </Flex>
      <p className='dashboard_influenced_sales--text'>
        {getText('TEXT_SALES_IMPACT_REPORT')}
      </p>
      <MatButton
        loading={loading}
        buttonText={getText('WORD_REQUEST_REPORT')}
        onClick={async () => {
          setLoading(true)
          const result = await statActions.sendRequestReport()
          if (result.success) {
            notifySuccess(
              getText(
                'TEXT_YOUR_MATADOR_REPRESENTATIVE_WILL_BE_IN_TOUCH_SHORTLY_WITH_YOUR_REPORT'
              )
            )
          } else {
            notifyError(result.errMsg)
          }
          setLoading(false)
        }}
      />
    </Card>
  )
}

export default memo(DashboardInfluencedSales)
