import React, { memo } from 'react'
import { Form } from 'antd'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../store/selectors/selectors'
import authActions from '../../../store/modules/authActions'
import { getText } from '../../../lang'
import SwitchForm from '../../../components/Form/SwitchForm'
import NotificationItem from './NotificationItem'

const NotificationSection = ({
  form,
  userState,
  organization,
  treeData,
  userId,
}) => {
  const user = useSelector(userSelector)

  treeData = treeData.map((item) => ({ ...item, disabled: false }))

  const getAccessibleOrganizationsWithLabel = () => {
    if (userState.accessibleOrganizations.length === 0) {
      return []
    }
    return userState.accessibleOrganizations
  }

  const accessibleOrganizations = getAccessibleOrganizationsWithLabel()

  const selectedMultipleOrganizations =
    userState && userState.accessibleOrganizations.length > 0

  const formChanged = Form.useWatch('notifications')

  return (
    <>
      {Boolean(
        user.isSuperAdmin && !userState.isSuperAdmin && !userState.isSupportAgent
      ) && (
        <SwitchForm
          name={['restrictions', 'allowReceiveNewsletters']}
          text={getText('TEXT_ALLOW_USER_RECEIVE_NEWSLETTER')}
          disabled={false}
        />
      )}
      <NotificationItem
        form={form}
        notification='unreadMessage'
        isPhone={userState.phone}
        isEmail={userState.email}
        switchText={getText('TEXT_UNREAD_CONVERSATIONS')}
        isSuperAdmin={userState.isSuperAdmin}
        receiverNotifications={
          userState.isSuperAdmin ||
          selectedMultipleOrganizations ||
          authActions.hasAccessToMultipleOrganizations()
        }
        accessibleOrganizations={accessibleOrganizations}
        restricedTreeData={treeData}
      />
      {(!userState.isSupportAgent ||
        (organization &&
          organization?.restrictions &&
          !organization?.restrictions
            .restrictSupportAgentsAccessToUnassignedFolder)) && (
        <NotificationItem
          form={form}
          notification='unassignFromConversation'
          isPhone={userState.phone}
          isEmail={userState.email}
          switchText={getText('TEXT_UNASSIGNED_CONVERSATIONS')}
          isSuperAdmin={userState.isSuperAdmin}
          receiverNotifications={
            userState.isSuperAdmin ||
            selectedMultipleOrganizations ||
            authActions.hasAccessToMultipleOrganizations()
          }
          accessibleOrganizations={accessibleOrganizations}
          restricedTreeData={treeData}
        />
      )}
      <NotificationItem
        form={form}
        isPhone={userState.phone}
        isEmail={userState.email}
        notification='assignToConversation'
        switchText={getText('TEXT_ASSIGNED_TO_CONVERSATIONS')}
        isSuperAdmin={userState.isSuperAdmin}
        receiverNotifications={
          userState.isSuperAdmin ||
          selectedMultipleOrganizations ||
          authActions.hasAccessToMultipleOrganizations()
        }
        accessibleOrganizations={accessibleOrganizations}
      />
      <NotificationItem
        form={form}
        notification='taggedInConversation'
        isPhone={userState.phone}
        isEmail={userState.email}
        switchText={getText('TEXT_TAGGED_IN_A_CONVERSATION')}
        isSuperAdmin={userState.isSuperAdmin}
        receiverNotifications={
          userState.isSuperAdmin ||
          selectedMultipleOrganizations ||
          authActions.hasAccessToMultipleOrganizations()
        }
        accessibleOrganizations={accessibleOrganizations}
        restricedTreeData={treeData}
      />
      <NotificationItem
        form={form}
        isPhone={userState.phone}
        isEmail={userState.email}
        notification='paymentStatus'
        switchText={getText('WORD_PAYMENT_NOTIFICATION')}
        isSuperAdmin={userState.isSuperAdmin}
        receiverNotifications={
          userState.isSuperAdmin ||
          selectedMultipleOrganizations ||
          authActions.hasAccessToMultipleOrganizations()
        }
        accessibleOrganizations={accessibleOrganizations}
      />
      {(userState.isSuperAdmin || userState.isAdmin) && userState.email && (
        <SwitchForm
          name={['notifications', 'integrationDisconnected', 'enabled']}
          text={getText('TEXT_RECEIVER_EMAIL_SWITCH')}
        />
      )}
      {!userState.isSupportAgent && userState.email && (
        <NotificationItem
          form={form}
          isPhone={userState.phone}
          isEmail={userState.email}
          notification='unhappyCustomer'
          switchText={getText('TEXT_RECEIVE_EMAILS_FROM_UNHAPPY_CUSTOMERS')}
          isSuperAdmin={userState.isSuperAdmin}
          receiverNotifications={
            userState.isSuperAdmin ||
            selectedMultipleOrganizations ||
            authActions.hasAccessToMultipleOrganizations()
          }
          accessibleOrganizations={accessibleOrganizations}
          restricedTreeData={treeData}
        />
      )}
      <SwitchForm
        hidden={user?._id !== userId}
        name={['notifications', 'doNotDisturb']}
        text={getText('TEXT_DO_NOT_DISTURB_ME_DURING_NON_WORKING_HOURS')}
        disabled={
          formChanged
            ? !Boolean(
                (formChanged.assignToConversation &&
                  formChanged.assignToConversation.enabled) ||
                  (formChanged.paymentStatus && formChanged.paymentStatus.enabled) ||
                  (formChanged.unassignFromConversation &&
                    formChanged.unassignFromConversation.enabled) ||
                  formChanged.enabled ||
                  (formChanged.unhappyCustomer &&
                    formChanged.unhappyCustomer.enabled) ||
                  (formChanged.unreadMessage && formChanged.unreadMessage.enabled)
              )
            : false
        }
      />
    </>
  )
}

export default memo(NotificationSection)
