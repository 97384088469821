import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_18735_102886)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_18735_102886)'>
          <circle cx='18' cy='17' r='13' fill='#4BC3B9' />
        </g>
        <path
          d='M22.0265 19.0332C21.7465 19.3065 21.5865 19.6998 21.6265 20.1198C21.6865 20.8398 22.3465 21.3665 23.0665 21.3665H24.3332V22.1598C24.3332 23.5398 23.2065 24.6665 21.8265 24.6665H15.0865C15.2932 24.4932 15.4732 24.2798 15.6132 24.0398C15.8598 23.6398 15.9998 23.1665 15.9998 22.6665C15.9998 21.1932 14.8065 19.9998 13.3332 19.9998C12.7065 19.9998 12.1265 20.2198 11.6665 20.5865V17.6732C11.6665 16.2932 12.7932 15.1665 14.1732 15.1665H21.8265C23.2065 15.1665 24.3332 16.2932 24.3332 17.6732V18.6332H22.9865C22.6132 18.6332 22.2732 18.7798 22.0265 19.0332Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.6665 18.2734V15.2267C11.6665 14.4334 12.1532 13.7267 12.8932 13.4467L18.1865 11.4467C19.0132 11.1334 19.8998 11.7467 19.8998 12.6334V15.1667'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.039 19.3133V20.6866C25.039 21.0533 24.7457 21.3533 24.3724 21.3666H23.0657C22.3457 21.3666 21.6857 20.84 21.6257 20.12C21.5857 19.7 21.7457 19.3066 22.0257 19.0333C22.2724 18.78 22.6124 18.6333 22.9857 18.6333H24.3724C24.7457 18.6466 25.039 18.9466 25.039 19.3133Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.6665 18H19.3332'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.9998 22.6667C15.9998 23.1667 15.8598 23.64 15.6132 24.04C15.4732 24.28 15.2932 24.4933 15.0865 24.6667C14.6198 25.0867 14.0065 25.3333 13.3332 25.3333C12.3598 25.3333 11.5132 24.8133 11.0532 24.04C10.8065 23.64 10.6665 23.1667 10.6665 22.6667C10.6665 21.8267 11.0532 21.0733 11.6665 20.5867C12.1265 20.22 12.7065 20 13.3332 20C14.8065 20 15.9998 21.1933 15.9998 22.6667Z'
          stroke='white'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.2939 22.6665L12.9539 23.3265L14.3739 22.0132'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_18735_102886)'
      />
      <defs>
        <filter
          id='filter0_f_18735_102886'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_18735_102886'
          />
        </filter>
        <linearGradient
          id='paint0_linear_18735_102886'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_18735_102886'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashValueGeneratedIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashValueGeneratedIcon
