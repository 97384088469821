import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Divider, Flex, Form } from 'antd'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import { getText, getTextServerError } from '../../../../lang'
import MatForm from '../../../../components/Form/MatForm'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultPhoneInput from '../../../../components/MyDefaultPhoneInput/MyDefaultPhoneInput'
import { getInitialCountry } from '../../../../utils'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultSelectLocation from '../../../../components/MyDefaultSelect/MyDefaultSelectLocation'
import MyDefaultSelect from '../../../../components/MyDefaultSelect/MyDefaultSelect'
import MyDefaultSelectUsers from '../../../../components/MyDefaultSelect/MyDefaultSelectUsers'
import { days, defaultWorkingHours } from '../opening_hours'
import Div from '../../../../components/Div/Div'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import voiceAIActions from '../../../../store/modules/voiceAIActions'
import MyDefaultInactiveBlock from '../../../../components/MyDefaultInactiveBlock/MyDefaultInactiveBlock'
import { DaysCheck } from '../opening_hours/DaysCheck'
import { getUsersListIds } from '../../../broadcast/CreateCampaign/CreateSegment/utils'
import { useLayout } from '../../../../layout/LayoutProvider/LayoutProvider'
import { useOrganizationLayout } from '../../organizationProvider/OrganizationProvider'
import SVGMyDefaultDeleteIcon from '../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import useDeviceCheck from '../../../../utils/useDeviceCheck'
import { CANCEL_BTN, SUBMIT_BTN } from '../../../../devOptions'
import SingleWorkingDayVoiceAI from './SingleWorkingDayVoiceAI'
import VoiceAIBlockCallTransfersAdditionalHeader from './VoiceAIBlockCallTransfersAdditionalHeader'
import SingleWorkingDayVoiceAIForTablet from './SingleWorkingDayVoiceAIForTablet'

const VoiceAiEdit = () => {
  const { selectedOrganization: organization } = useOrganizationLayout()
  const { setFooterButtons, setLoading } = useLayout()
  const { isMobileOrTabletOrTabletPortrait } = useDeviceCheck()

  const location = useLocation()
  const navigate = useNavigate()

  const item = location.state

  const [form] = Form.useForm()

  const useOrganizationWorkingHours = Form.useWatch(
    'useOrganizationWorkingHours',
    form
  )
  const callTransfersEnabled = Form.useWatch('callTransfersEnabled', form)
  const enabled = Form.useWatch('enabled', form)

  const callTransferListAddRef = useRef(null)

  useEffect(() => {
    setFooterButtons([
      {
        type: CANCEL_BTN,
      },
      {
        type: SUBMIT_BTN,
        title: getText(
          item?._id ? 'ACTION_SAVE_CHANGES' : 'TEXT_CREATE_NEW_ASSISTANT'
        ),
      },
    ])
  }, [])

  useEffect(() => {
    if (form) {
      const obj = {}
      if (item) {
        obj.assistantName = item?.assistantName || ''
        obj.assistantType = item?.assistantType || []
        obj.firstMessage = item?.firstMessage || ''
        obj.useOrganizationWorkingHours = item?.useOrganizationWorkingHours
        obj.workingHours = item?.useOrganizationWorkingHours
          ? Object.values(organization.workingHours || defaultWorkingHours)
          : Object.values(item.workingHours || defaultWorkingHours)
        obj.callTransfersEnabled = item?.callTransfersEnabled || false
        obj.callTransfers = item?.callTransfers || []
        obj.appointment = item?.appointment || false
        obj.sendToCRM = item?.sendToCRM || false
        obj.usersToTag = getUsersListIds(item?.usersToTag || [])
        obj.usersToAssign = getUsersListIds(item?.usersToAssign || [])
        obj._location_id = item?._location_id || ''
        obj.locationName = item?.locationName || ''
        obj.locationAddress = item?.locationAddress || ''
        obj.phoneNumber = item?.phoneNumber || ''
        obj.enabled = item?.enabled || false
      } else {
        obj.callTransfers = [{ departmentName: '', phoneNumber: '' }]
        obj.callTransfersEnabled = true
        obj.useOrganizationWorkingHours = true
        obj.workingHours = Object.values(
          Object.values(organization.workingHours || defaultWorkingHours)
        )
        obj.enabled = true
        obj.firstMessage =
          'Hi, thanks for calling {{Location_Name}}. How can I help you today?'
        obj.appointment = true
        obj.sendToCRM = true
      }

      form.setFieldsValue(obj)
    }
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    const objSend = {
      enabled: values.enabled,
      assistantName: values.assistantName,
      _location_id: values._location_id,
      locationName: values.locationName,
      locationAddress: values.locationAddress,
      assistantType: values.assistantType,
      firstMessage: values.firstMessage,
      useOrganizationWorkingHours: values.useOrganizationWorkingHours,
      callTransfersEnabled: values.callTransfersEnabled,
      appointment: values.appointment,
      sendToCRM: values.sendToCRM,
      usersToTag: (values.usersToTag || []).map((user) => user?._id || user.value),
      usersToAssign: (values.usersToAssign || []).map(
        (user) => user?._id || user.value
      ),
    }

    if (values.callTransfersEnabled) {
      objSend.callTransfers = values.callTransfers
    }

    if (!values.useOrganizationWorkingHours) {
      const entries = Object.fromEntries(days.entries())
      const mappedValues = Object.keys(entries).map((key) => [
        entries[key],
        values.workingHours[key],
      ])
      const workngHoursObject = Object.fromEntries(mappedValues)
      const openingHoursError = DaysCheck(workngHoursObject)

      if (openingHoursError) {
        notifyError(getTextServerError(openingHoursError))
        return false
      }
      objSend.workingHours = workngHoursObject
    }

    let result

    if (item?._id) {
      result = await voiceAIActions.updateVapiAssistant(objSend, item._id)
    } else {
      result = await voiceAIActions.createVapiAssistant(objSend)
    }

    if (result.success) {
      if (item?._id) {
        notifySuccess(getText('TEXT_ASSISTANT_WAS_UPDATED'))
      } else {
        notifySuccess(getText('TEXT_ASSISTANT_WAS_CREATED_SUCCESSFULLY'))
      }
      navigate(-1)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const checkIsDefaultFirstMessage = (firstMessage) => {
    const regex =
      /^Hi, thanks for calling [A-Za-z0-9\s{}_-]+\. How can I help you today\?$/

    return regex.test(firstMessage)
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MatForm form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <MyDefaultBlock>
          <MyDefaultSwitch
            isForm
            title={getText('TEXT_ASSISTANT_IS_ACTIVE')}
            isFirst
            titleStyle={{ fontSize: 14 }}
            noText
            name={'enabled'}
            icon={
              Boolean(!enabled) && (
                <MyDefaultInactiveBlock
                  tooltipText={getText('TEXT_YOU_CAN_CONTINUE_TO_MODIFY_ASSISTANT')}
                  enableMargin
                />
              )
            }
          />
          <MyDefaultInputText
            isForm
            name='assistantName'
            label={getText('TEXT_ASSISTANT_NAME')}
            required
            isFirst
            maxLength={20}
          />
          {Boolean(item?._id) ? (
            <MyDefaultPhoneInput
              isForm
              initialCountry={getInitialCountry(organization.twilio_number)}
              name='phoneNumber'
              label={getText('WORD_CHAT_')}
              onChange={(value) => {
                form.setFieldsValue({ twilio_number: value })
              }}
              disabled
            />
          ) : (
            <MyDefaultInputText
              isForm
              name='number_disabled'
              label={getText('WORD_CHAT_')}
              placeholder={getText(
                'TEXT_THE_NUMBER_WILL_BE_PROVISIONED_AUTOMATICALLY_ONCE_THE_ASSISTANT_ISCREATED'
              )}
              disabled
            />
          )}

          <MyDefaultSelectLocation
            isForm
            name={'_location_id'}
            label={getText('TEXT_CONNECTED_LOCATION')}
            required
            onSelectItem={(item) => {
              const obj = {}

              obj.locationAddress = item?.address || ''
              obj.locationName = item?.friendlyName || item?.name || ''

              if (item.name && !location.state) {
                const firstMessage = form.getFieldValue('firstMessage')
                if (checkIsDefaultFirstMessage(firstMessage)) {
                  obj.firstMessage = `Hi, thanks for calling ${item.name}. How can I help you today?`
                }
              }

              form.setFieldsValue(obj)
            }}
          />

          <MyDefaultInputText
            isForm
            name='locationName'
            label={getText('WORD_LOCATION_NAME')}
            required
          />

          <MyDefaultTextarea
            isForm
            name={'locationAddress'}
            label={getText('WORD_LOCATION_ADDRESS')}
            required
          />

          <MyDefaultSelect
            isForm
            name={'assistantType'}
            required
            label={getText('TEXT_ASSISTANT_TYPE')}
            options={[
              { label: getText('WORD_SALES'), value: 'sales' },
              { label: getText('WORD_SERVICE'), value: 'service' },
            ]}
            mode={'multiple'}
          />

          <MyDefaultSwitch
            name={'appointment'}
            isForm
            title={getText('WORD_APPOINTMENT_TEXT')}
            isFirst
            titleStyle={{ fontSize: 14 }}
            noText
          />
          <MyDefaultSwitch
            name={'sendToCRM'}
            isForm
            title={getText('FORWARD_NEW_LEADS_TO_CRM')}
            titleStyle={{ fontSize: 14 }}
            noText
            isLast
            titleTooltip={getText('WORD_TURN_ON_TOGGLE_IF')}
          />

          <MyDefaultTextarea
            isForm
            name={'firstMessage'}
            label={getText('TEXT_INTRODUCTION__FIRST_MESSAGE')}
            required
            isFirst
            isLast
          />
        </MyDefaultBlock>

        <MyDefaultBlock enableMarginTop title={getText('WORD_OPENING_HOURS')}>
          <MyDefaultSwitch
            name={'useOrganizationWorkingHours'}
            isForm
            title={getText('TEXT_USE_ORGANIZATION_WORKING_HOURS')}
            titleStyle={{ fontSize: 14 }}
            noText
            isLast
            isFirst
            onChangeSwitch={(val) => {
              const list = Object.values(
                val && organization?.workingHours
                  ? organization?.workingHours
                  : defaultWorkingHours
              )
              form &&
                form.setFieldsValue({
                  workingHours: list,
                })
            }}
          />
          <Div className={'voice_ai_working_hours_wrapper'}>
            <Form.List name='workingHours'>
              {(fields) => {
                return fields.map((field, index) => {
                  if (isMobileOrTabletOrTabletPortrait) {
                    return (
                      <SingleWorkingDayVoiceAIForTablet
                        key={index}
                        field={field}
                        form={form}
                        index={index}
                        disabled={useOrganizationWorkingHours}
                      />
                    )
                  } else {
                    return (
                      <SingleWorkingDayVoiceAI
                        key={index}
                        field={field}
                        form={form}
                        index={index}
                        disabled={useOrganizationWorkingHours}
                      />
                    )
                  }
                })
              }}
            </Form.List>
          </Div>
        </MyDefaultBlock>
        <MyDefaultBlock
          enableMarginTop
          title={
            <Flex align='center' gap={12}>
              {getText('WORD_CALL_TRANSFERS')}
              <MyDefaultSwitch
                noText
                isFirst
                isLast
                isForm
                name={'callTransfersEnabled'}
              />
              {Boolean(!callTransfersEnabled) && (
                <MyDefaultInactiveBlock
                  tooltipText={getText(
                    'TEXT_YOU_CAN_CONTINUE_TO_MODIFY_THE_CALL_TRANSFERS'
                  )}
                />
              )}
            </Flex>
          }
          additionalHeader={
            <VoiceAIBlockCallTransfersAdditionalHeader
              isEnabled={callTransfersEnabled}
              onClick={() => {
                callTransferListAddRef?.current?.add({
                  departmentName: '',
                  phoneNumber: '',
                })
              }}
            />
          }
        >
          <Form.List name='callTransfers'>
            {(fields, { add, remove }) => {
              callTransferListAddRef.current = { add, remove }
              return fields.map((field, index) => {
                return (
                  <>
                    {Boolean(index > 0) && (
                      <Flex justify='end'>
                        <SVGMyDefaultDeleteIcon
                          onClick={() => {
                            remove(index)
                          }}
                        />
                      </Flex>
                    )}
                    <MyDefaultInputText
                      isForm
                      name={[field.name, 'departmentName']}
                      placeholder={getText('TEXT_DEPARTMENT_NAME')}
                      label={getText('TEXT_DEPARTMENT_NAME')}
                      required={callTransfersEnabled}
                    />
                    <MyDefaultPhoneInput
                      isForm
                      initialCountry={getInitialCountry(organization.twilio_number)}
                      name={[field.name, 'phoneNumber']}
                      label={getText('WORD_CHAT_')}
                      onChange={(value) => {
                        form.setFieldsValue({ twilio_number: value })
                      }}
                      required={callTransfersEnabled}
                      isLast
                    />
                    {Boolean(index + 1 < fields.length) && (
                      <Divider style={{ marginBottom: 24, marginTop: 24 }} />
                    )}
                  </>
                )
              })
            }}
          </Form.List>
        </MyDefaultBlock>
        <MyDefaultBlock enableMarginTop>
          <MyDefaultSelectUsers
            isForm
            mode={'multiple'}
            name={'usersToTag'}
            label={getText('TEXT_TAG_USERS_IF_THE_CUSTOMER_NEEDS_A_CALLBACK')}
            required
            labelInValue
          />
          <MyDefaultSelectUsers
            isForm
            mode={'multiple'}
            name={'usersToAssign'}
            label={getText('TEXT_ASSIGN_USERS_TO_A_CONVERSATIONS')}
            labelInValue
          />
        </MyDefaultBlock>
      </MatForm>
    </MyDefaultPageLayout>
  )
}

export default VoiceAiEdit
