import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 19.1654C15.0627 19.1654 19.1667 15.0613 19.1667 9.9987C19.1667 4.93609 15.0627 0.832031 10 0.832031C4.93743 0.832031 0.833374 4.93609 0.833374 9.9987C0.833374 15.0613 4.93743 19.1654 10 19.1654Z'
        stroke={props.color || '#0E0E0E'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0001 14.5807C12.5314 14.5807 14.5834 12.5287 14.5834 9.9974C14.5834 7.46609 12.5314 5.41406 10.0001 5.41406C7.46878 5.41406 5.41675 7.46609 5.41675 9.9974C5.41675 12.5287 7.46878 14.5807 10.0001 14.5807Z'
        stroke={props.color || '#0E0E0E'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const SVGOrgTouchpointIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGOrgTouchpointIcon
