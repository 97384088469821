import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { getText } from '../../../../lang'
import authActions from '../../../../store/modules/authActions'
import locationActions from '../../../../store/modules/locationActions'
import GoogleAuth from '../../../../components/api/GoogleAuth'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MatButton from '../../../../components/MatButton'
import { getGoogleWarningData } from '../../components/locationUtils'
import InputTextSecondary from '../../../../components/InputText/InputTextSecondary'
import FeaturesConnectingData from '../../components/FeaturesConnectingData'
import { userSelector } from '../../../../store/selectors/selectors'

const GoogleConnection = (props) => {
  const { location } = props
  const user = useSelector(userSelector)

  const [email, setEmail] = useState('')
  const [otherIntegrationsData, setOtherIntegrationsData] = useState([])
  const [secondEmailValue, setSecondEmailValue] = useState('')

  useEffect(() => {
    if (location.leadConnection && location.leadConnection.googleEmail) {
      setEmail(location.leadConnection.googleEmail)
    }
    if (location.leadConnection && location.leadConnection.secondaryForwardEmail) {
      setSecondEmailValue(location.leadConnection.secondaryForwardEmail)
    }
  }, [])

  const handleLogin = async (data) => {
    const resultConnect = await authActions.connectGmail(data.code, location.id)
    if (resultConnect.success) {
      await locationActions.checkLoginForInvitationBooking(location.id)
      location.featuresConnectingData.googleTouchpoint.isConnected = true
      location.featuresConnectingData.googleTouchpoint.userData.fullName =
        user.fullName
      location.leadConnection.googleEmail = resultConnect.data.googleEmail
      notifySuccess(getText('TEXT_TOUCHPOINTS_EMAIL_WAS_SUCCESSFULLY_CONNECTED'))
      setEmail(resultConnect.data.googleEmail)
    } else {
      notifyError(resultConnect.errMsg)
    }
  }

  const handleLogout = async (data) => {
    const result = await authActions.disconnectGmail(location.id)
    if (result.success) {
      const result = await locationActions.checkLoginForInvitationBooking(
        location.id
      )
      location.featuresConnectingData.googleTouchpoint.isConnected = false
      location.featuresConnectingData.googleTouchpoint.userData.fullName =
        user.fullName
      location.leadConnection.googleEmail = ''
      notifySuccess(getText('TEXT_TOUCHPOINTS_EMAIL_WAS_SUCCESSFULLY_DISCONNECTED'))
      setEmail(result.data.googleEmail)
    }
  }

  return (
    <>
      <MatBlockWithLabel className={'integration-item-footer-item'}>
        {email && (
          <div className='footer-connected-account'>
            <span>{getText('TEXT_LOGGED_ACCOUNT')}</span>
            <span>{email}</span>
          </div>
        )}
        <FeaturesConnectingData
          featuresConnectingData={
            location.featuresConnectingData &&
            location.featuresConnectingData.googleTouchpoint
          }
        />
        {!Boolean(user.isSuperAdmin && user.isAdmin) && email ? (
          <Tooltip
            placement='top'
            title={getText('TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR')}
          >
            <MatButton
              buttonText={
                email ? getText('WORD_DISCONNECT') : getText('WORD_CONNECT')
              }
              htmlType='button'
              style={{ maxWidth: '190px', cursor: 'not-allowed' }}
              typeButton={'red'}
            />
          </Tooltip>
        ) : (
          <>
            <GoogleAuth
              isConnected={email}
              responseGoogle={handleLogin}
              responseGoogleLogout={handleLogout}
              title={email ? getText('WORD_DISCONNECT') : getText('WORD_CONNECT')}
              typeButton={'red'}
              warning={otherIntegrationsData.length}
              warningText={getGoogleWarningData(otherIntegrationsData)}
              onVisibleChange={async (value) => {
                if (value) {
                  const result = await authActions.connectGmailIntegrations(
                    location?._id
                  )
                  if (result.success) {
                    setOtherIntegrationsData(result.data.otherIntegrationsData)
                  } else {
                    notifyError(result.errMsg)
                  }
                }
              }}
            />
          </>
        )}
      </MatBlockWithLabel>
      {Boolean(user.isSuperAdmin) && (
        <MatBlockWithLabel
          black
          className='integration-item-footer-item-second'
          label={getText('TEXT_FORWARD_ALL_LEADS_TO_EMAIL')}
        >
          {
            <div className='secondary-items-block'>
              <InputTextSecondary
                value={secondEmailValue}
                placeholder={getText('WORD_EMAIL')}
                onChange={(e) => setSecondEmailValue(e.target.value)}
                onClear={() => setSecondEmailValue('')}
              />
              <MatButton
                buttonText={getText('ACTION_UPDATE')}
                htmlType='button'
                style={{ width: '125px' }}
                typeButton={'primary'}
                onClick={async () => {
                  const result = await authActions.connectGmailSecond(
                    secondEmailValue,
                    location?._id
                  )
                  if (result.success) {
                    setSecondEmailValue(result.data.secondaryForwardEmail)
                    notifySuccess(getText('MSG_YOUR_EMAIL_UPDATED_SUCCESSFULLY'))
                  } else {
                    notifyError(result.errMsg)
                  }
                }}
              />
            </div>
          }
        </MatBlockWithLabel>
      )}
    </>
  )
}

export default GoogleConnection
