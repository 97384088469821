import React from 'react'
import Icon from '@ant-design/icons'

const SVGIcon = (props) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_11915_255328)'>
        <rect width='36' height='36' rx='8' fill='white' fillOpacity='0.05' />
        <g opacity='0.2' filter='url(#filter0_f_11915_255328)'>
          <circle cx='18' cy='17' r='13' fill='#777CFD' />
        </g>
        <path
          d='M21.9987 14.7733C21.9587 14.7666 21.9121 14.7666 21.8721 14.7733C20.9521 14.74 20.2188 13.9866 20.2188 13.0533C20.2188 12.1 20.9854 11.3333 21.9387 11.3333C22.8921 11.3333 23.6587 12.1066 23.6587 13.0533C23.6521 13.9866 22.9187 14.74 21.9987 14.7733Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.313 19.6266C22.2263 19.78 23.233 19.62 23.9396 19.1466C24.8796 18.52 24.8796 17.4933 23.9396 16.8666C23.2263 16.3933 22.2063 16.2333 21.293 16.3933'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.9803 14.7733C14.0203 14.7666 14.067 14.7666 14.107 14.7733C15.027 14.74 15.7603 13.9866 15.7603 13.0533C15.7603 12.1 14.9936 11.3333 14.0403 11.3333C13.087 11.3333 12.3203 12.1066 12.3203 13.0533C12.327 13.9866 13.0603 14.74 13.9803 14.7733Z'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.6676 19.6266C13.7543 19.78 12.7476 19.62 12.0409 19.1466C11.1009 18.52 11.1009 17.4933 12.0409 16.8666C12.7543 16.3933 13.7743 16.2333 14.6876 16.3933'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.9987 19.7534C17.9587 19.7467 17.9121 19.7467 17.8721 19.7534C16.9521 19.72 16.2188 18.9667 16.2188 18.0334C16.2188 17.08 16.9854 16.3134 17.9387 16.3134C18.8921 16.3134 19.6587 17.0867 19.6587 18.0334C19.6521 18.9667 18.9187 19.7267 17.9987 19.7534Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.0605 21.8534C15.1205 22.48 15.1205 23.5067 16.0605 24.1334C17.1271 24.8467 18.8738 24.8467 19.9405 24.1334C20.8805 23.5067 20.8805 22.48 19.9405 21.8534C18.8805 21.1467 17.1271 21.1467 16.0605 21.8534Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <rect
        x='0.5'
        y='0.5'
        width='35'
        height='35'
        rx='7.5'
        stroke='url(#paint0_linear_11915_255328)'
      />
      <defs>
        <filter
          id='filter0_f_11915_255328'
          x='-11'
          y='-12'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8'
            result='effect1_foregroundBlur_11915_255328'
          />
        </filter>
        <linearGradient
          id='paint0_linear_11915_255328'
          x1='0'
          y1='0'
          x2='38'
          y2='40.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.12' />
          <stop offset='1' stopColor='white' stopOpacity='0.04' />
        </linearGradient>
        <clipPath id='clip0_11915_255328'>
          <rect width='36' height='36' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const SVGDashVideoWatchRateIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashVideoWatchRateIcon
