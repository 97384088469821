import React, { useState } from 'react'
import { Typography } from 'antd'
import './MyDefaultEllipsisText.scss'
import { getText } from '../../lang'
import MyDefaultImage from '../MyDefaultImage/MyDefaultImage'

const MyDefaultEllipsisText = ({ rows = 1, text, width, image }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Typography.Paragraph
      className={'my_default_ellipsis_text'}
      style={{ maxWidth: width }}
      ellipsis={{
        rows: rows,
        expandable: 'collapsible',
        expanded,
        onExpand: (_, info) => setExpanded(info.expanded),
        symbol: (expanded) =>
          expanded ? getText('ACTION_SHOW_LESS') : getText('ACTION_SHOW_MORE'),
      }}
    >
      {text}
      {image && <MyDefaultImage src={image} />}
    </Typography.Paragraph>
  )
}

export default MyDefaultEllipsisText
