import React from 'react'
import { useSelector } from 'react-redux'
import { organizationSelector } from '../../../../store/selectors/selectors'
import { getText } from '../../../../lang'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import CalendarInviteDelay from './CalendarInviteDelay'
import CalendarInvitePanelItems from './CalendarInvitePanelItems'

const CalendarInvitePanel = (props) => {
  const {
    selectedCalendarSequence,
    selectedIndex,
    form,
    field,
    setSelectedCalendarSequence,
  } = props

  const organization = useSelector(organizationSelector)

  const listVariables = [
    {
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
    },
    {
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
    },
    {
      label: 'appointment_date',
      value: '{{appointment_date}}',
    },
    {
      label: 'location_name',
      value: '{{location_name}}',
    },
    {
      label: 'appointment_time',
      value: '{{appointment_time}}',
    },
  ]

  return (
    <div className='aibot-reply-item-panel-wrapper'>
      <MatBlockWithLabel black label={getText('TEXT_SEND_FOLLOWUP')}>
        <CalendarInviteDelay
          field={field}
          form={form}
          selectedMessage={selectedCalendarSequence}
          selectedIndex={selectedIndex}
        ></CalendarInviteDelay>
      </MatBlockWithLabel>
      <MatBlockWithLabel labelClassName={'aibot-mat-block-label-wrapper'} black>
        {selectedCalendarSequence.isFirstItem ? (
          <CalendarInvitePanelItems
            languages={
              organization.defaultLanguage !== organization.languages[0]
                ? organization.languages.reverse()
                : organization.languages
            }
            variables={listVariables}
            formName={[field, 'text']}
            imageFormName={[field, 'image']}
            form={form}
            organization={organization}
            placeholder={getText('TEXT_CALENDAR_INVITE')}
            selectedItem={selectedCalendarSequence}
            setSelectedItem={setSelectedCalendarSequence}
            selectedIndex={selectedIndex}
          />
        ) : (
          <CalendarInvitePanelItems
            languages={
              organization.defaultLanguage !== organization.languages[0]
                ? organization.languages.reverse()
                : organization.languages
            }
            variables={listVariables}
            formName={[field, 'text']}
            imageFormName={[field, 'image']}
            form={form}
            organization={organization}
            placeholder={getText('TEXT_CALENDAR_INVITES_REMINDER')}
            selectedItem={selectedCalendarSequence}
            setSelectedItem={setSelectedCalendarSequence}
            selectedIndex={selectedIndex}
          />
        )}
      </MatBlockWithLabel>
    </div>
  )
}

export default CalendarInvitePanel
