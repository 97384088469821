import React from 'react'
import { Tooltip } from 'antd'
import SVGPayment from '../../../../../icons/SVG/SVGPayment'
import MatButton from '../../../../../components/MatButton'
import { getText } from '../../../../../lang'
import { GBM, MESSENGER } from '../../../../../devOptions'
import { msgTooltipText } from '../../../utils/messageUtils'
import SendRequestPaymentModal from './SendRequestPaymentModal'
import './MessagePaymentRequest.scss'

const SendPaymentRequestButton = (props) => {
  const {
    conversation,
    customer,
    disabled,
    isAwaitingConsent,
    mobileDeskSize,
    onCloseMobileDesk,
  } = props
  return (
    <>
      {conversation.kind !== MESSENGER &&
      conversation.kind !== GBM &&
      conversation.receiver_phone &&
      !isAwaitingConsent ? (
        <SendRequestPaymentModal
          conversation={conversation}
          customer={customer}
          disabled={disabled}
          onCloseMobileDesk={onCloseMobileDesk}
          trigger={
            <MatButton
              tooltip={!mobileDeskSize}
              tooltipTitle={getText('WORD_PAYMENT_UPF')}
              dataIndex={'payment'}
              icon={
                <SVGPayment
                  width={mobileDeskSize ? 24 : 20}
                  height={mobileDeskSize ? 24 : 20}
                />
              }
              disabled={disabled}
              className='icon-size only-stroke footerAction'
              buttonText={mobileDeskSize ? getText('WORD_PAYMENT_UPF') : ''}
            />
          }
        />
      ) : (
        <Tooltip title={msgTooltipText(conversation, isAwaitingConsent)}>
          <span
            dataIndex={'payment'}
            className={
              !conversation.receiver_phone || isAwaitingConsent
                ? 'icon-size only-stroke tooltip-request-a-payment footerAction'
                : ''
            }
            disabled={true}
          >
            <span className='ant-btn-icon'>
              <SVGPayment color={'var(--thridyTextColor)'} />
            </span>
            {mobileDeskSize ? (
              <span>
                {msgTooltipText(conversation) || getText('WORD_PAYMENT_UPF')}
              </span>
            ) : (
              ''
            )}
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default SendPaymentRequestButton
