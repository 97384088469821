import React from 'react'
import { Flex } from 'antd'
import MyDefaultSwitch from '../MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultInactiveBlock from '../MyDefaultInactiveBlock/MyDefaultInactiveBlock'

const MyDefaultBlockTitleWithSwitch = ({
  title,
  isForm,
  name,
  tooltipText,
  value,
  onChange,
}) => {
  return (
    <Flex align='center' gap={12}>
      {title}
      <MyDefaultSwitch
        noText
        isFirst
        isLast
        isForm={isForm}
        name={name}
        value={value}
        onChangeSwitch={onChange}
      />
      {Boolean(tooltipText) && <MyDefaultInactiveBlock tooltipText={tooltipText} />}
    </Flex>
  )
}

export default MyDefaultBlockTitleWithSwitch
